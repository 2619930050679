import React from "react";

const SelectPVMRadioButton = (props) => {
  return (
    <div className="form-check form-check-inline piik-pointer">
      <input
        className="form-check-input"
        type="radio"
        id={props.id}
        value={props.tab}
        checked={props.status}
        disabled={props.tab === null}
        onClick={props.handleTab}
      />
      <label className="form-check-label" for={props.id}>
        {props.name}
      </label>
    </div>
  );
};

const SelectPVMCheckBoxButton = (props) => {
  return (
    <div className="form-check form-check-inline piik-pointer">
      <input
        className="form-check-input"
        type="checkbox"
        id={props.id}
        value={props.tab}
        checked={props.status}
        disabled={props.tab === null}
        onClick={props.handleTab}
      />
      <label className="form-check-label" for={props.id}>
        {props.name}
      </label>
    </div>
  );
};
export { SelectPVMCheckBoxButton, SelectPVMRadioButton };
