import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";

import { PvmConfig, PvmChartFx } from "./PvmConfig";
import { useDashData } from "../../../hooks/useDash";

import { SelectPVMRadioButton, SelectPVMCheckBoxButton } from "./PvmSelectBtn";
import PvmTable from "./PvmTable";

import {
  convertDisplayDataUnit,
  removeDuplicatesObject,
} from "../Charts/Utility";

const CreatePvm = ({
  fullScreenStatus,
  updateVisualizationIndex,
  handleUpdateVisualizationIndex,
  handleVisualizationTab,
}) => {
  const {
    profile,
    adminAccountInfo,
    account,
    calenderData,
    handleAddVisualization,
    handleUpdateVisualization,
    handleCalculator,
  } = useDashData();

  const [reportCategories, setReportCategories] = useState([]);
  const [selectedReport, setSelectedReport] = useState("");

  const [plotState, setPlotState] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [selectedPvmData, setSelectedPvmData] = useState({});
  const [data, setData] = useState({});
  const [chartData, setChartData] = useState({});
  const [tableData, setTableData] = useState({});
  const [tab, setTab] = useState(null);
  const [tableStatus, setTableStatus] = useState([
    "Active",
    "Discontinued",
    "New",
  ]);

  const [error, setError] = useState({});
  const [title, setTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [status, setStatus] = useState(true);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [generateStatus, setGenerateStatus] = useState(true);

  const handleStatusFilter = (key) => {
    setTableStatus((prev) => {
      if (prev.includes(key)) {
        let temp = prev.filter((d) => d !== key);
        return temp;
      } else {
        return [...prev, key];
      }
    });
  };

  const handleUpdate = () => {
    if (title === "") {
      setErrorMessage("Field Data is Missing");
      return;
    }
    if (!generateStatus) {
      setSubmitStatus(true);
      let requestData = {
        userId: profile.user_id,
        accountId: selectedAccount.uid,
        pvmId: selectedPvmData.id,
        pvmSettings: {
          type: data.hasOwnProperty("exchange") ? "PVMFx" : "PVM",
          report: selectedReport,
          data: {
            categories: data.categories.id,
            price: data.price.id,
            quantity: data.quantity.id,
            amount: data.amount.id,
            exchange: data.hasOwnProperty("exchange") ? data.exchange.id : "",
          },
        },
        pvmName: title,
        createdBy: profile.user_email,
        visualizationType: "Pvm",
      };
      handleUpdateVisualization(requestData)
        .then((response) => {
          if (response) {
            handleClear("type");
          }
          setSubmitStatus(false);
          handleVisualizationTab(updateVisualizationIndex);
          handleUpdateVisualizationIndex(null);
        })
        .catch((error) => {
          setSubmitStatus(false);
        });
    }
  };

  const handleSubmit = () => {
    if (title === "") {
      setErrorMessage("Field Data is Missing");
      return;
    }
    if (!generateStatus) {
      setSubmitStatus(true);
      let requestData = {
        userId: profile.user_id,
        accountId: selectedAccount.uid,
        pvmSettings: {
          type: data.hasOwnProperty("exchange") ? "PVMFx" : "PVM",
          report: selectedReport,
          data: {
            amount: data.amount.id,
            categories: data.categories.id,
            price: data.price.id,
            quantity: data.quantity.id,
            exchange: data.hasOwnProperty("exchange") ? data.exchange.id : "",
          },
        },
        pvmName: title,
        createdBy: profile.user_email,
        visualizationType: "Pvm",
      };
      handleAddVisualization(requestData)
        .then((response) => {
          if (response) {
            handleClear("all");
          }
          setSubmitStatus(false);
          handleUpdateVisualizationIndex(null);
        })
        .catch((error) => {
          setSubmitStatus(false);
        });
    }
  };

  const handleGenerate = () => {
    const requiredProperties = ["categories", "price", "quantity", "amount"];
    const missingProperties = requiredProperties.filter(
      (prop) => !data.hasOwnProperty(prop)
    );
    if (missingProperties.length > 0) {
      const errorMessages = {};
      missingProperties.forEach((prop) => {
        errorMessages[prop] = `Field Data is Missing`;
      });
      setError(errorMessages);
    } else {
      setError({});
      let pvmDataInfo = {
        values: {
          categories: data.categories.values,
          rate: data.price.values,
          quantity: data.quantity.values,
          amount: data.amount.values,
          exchange: data.hasOwnProperty("exchange") ? data.exchange.values : [],
        },
        calender: calenderData,
        type: data.hasOwnProperty("exchange") ? "PVMFx" : "PVM",
      };
      setSubmitStatus(true);
      handleCalculator(pvmDataInfo).then((res) => {
        if (res.status) {
          setGenerateStatus(false);
          setSubmitStatus(false);
          setChartData((prevData) => {
            const updatedData = {
              ...prevData,
              data: [
                {
                  ...prevData.data[0],
                  x: res.data.chart.x,
                  measure: res.data.chart.measure,
                  y: res.data.chart.y,
                  text: res.data.chart.y.map((v) => convertDisplayDataUnit(v)),
                },
              ],
            };
            return updatedData;
          });
          setTableData(() => {
            return res.data.table;
          });
          setTab(1);
        } else {
          setSubmitStatus(false);
        }
      });
    }
  };

  const handleInput = (e) => {
    const { id, value } = e.target;
    if (id === "title") {
      if (title.length > 25) {
        const truncatedValue = value.slice(0, 25);
        setTitle(truncatedValue);
        setErrorMessage("Maximum character limit reached");
      } else {
        setTitle(value);
        setErrorMessage("");
      }
    } else if (id === "category") {
      setSelectedReport(value);
      setChartData(PvmChartFx);
    } else {
      setGenerateStatus(true);
      setData((prevData) => {
        let tempIndex = selectedAccount.data.findIndex((d) => d.id === value);
        if (tempIndex === -1) {
          delete prevData[id];
          return prevData;
        } else {
          let temp = {
            id: value,
            values: selectedAccount.data[tempIndex].data,
          };
          const updatedData = { ...prevData, [id]: temp };
          return updatedData;
        }
      });
      setError({
        ...error,
        [id]: value === "" ? `${id} is required` : "",
      });
    }
  };
  const handleClear = (type) => {
    if (type === "all") {
      setTitle("");
      setChartData({});
      setData({});
      setError({});
      setStatus(true);
      setGenerateStatus(true);
      setSubmitStatus(false);
    } else if (type === "data") {
      setData({});
      setError({});
      setGenerateStatus(true);
      setSubmitStatus(false);
    }
  };
  useEffect(() => {
    if (fullScreenStatus !== plotState) {
      setPlotState((preData) => !preData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullScreenStatus]);

  useEffect(() => {
    const accountIndex = adminAccountInfo.findIndex(
      (data) => data.uid === account
    );
    const selectedAccountData = adminAccountInfo[accountIndex];
    setSelectedAccount(selectedAccountData);
    setReportCategories(() => {
      const uniqueData = removeDuplicatesObject(
        selectedAccountData.data,
        "category"
      );
      const result = uniqueData
        .filter((d) => d.category !== "Date")
        .map((d) => ({
          name: d.category,
          type: d.created,
          record_type: d.record_type,
        }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
      return result;
    });

    if (updateVisualizationIndex !== null) {
      const selectedPvmData =
        selectedAccountData.pvm_id[updateVisualizationIndex];
      setSelectedPvmData(selectedPvmData);
      if (selectedPvmData && selectedPvmData.settings) {
        setTab(1);
        setChartData(PvmChartFx);
        setSelectedReport(selectedPvmData.settings.report);
        setTitle(selectedPvmData.name);
        for (const key in selectedPvmData.settings.data) {
          const value = selectedPvmData.settings.data[key];
          if (value !== "") {
            setData((prevData) => {
              let tempIndex = selectedAccountData.data.findIndex(
                (d) => d.id === value
              );
              if (tempIndex === -1) {
                return;
              }
              let temp = {
                id: value,
                values: selectedAccountData.data[tempIndex].data,
              };
              let updatedData = { ...prevData, [key]: temp };
              return updatedData;
            });
          }
        }
      } else {
        handleUpdateVisualizationIndex(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAccountInfo, account]);
  return (
    <>
      <div
        className={
          fullScreenStatus
            ? "d-flex flex-row justify-content-between  bg-light piik-visual-section-fullscreen piik-create-chart "
            : "d-flex flex-row justify-content-between  bg-light piik-visual-section piik-create-chart "
        }
      >
        <div className="flex-grow-1">
          <div className="d-flex flex-column  h-100 w-100 ">
            <div className="flex-grow-1 w-100  h-100 d-flex flex-column ">
              <div className="d-flex flex-row justify-content-between  align-items-center bg-white px-4 py-2 shadow-sm">
                <div className="piik-text-dashboard text-primary">{title}</div>
                <i className="bi bi-info-circle text-primary"></i>
              </div>
              {tab === 1 ? (
                <>
                  {chartData.hasOwnProperty("data") &&
                    plotState === fullScreenStatus && (
                      <Plot
                        data={chartData.data}
                        layout={chartData.layout}
                        config={PvmConfig}
                        className="w-100  piik-text-visual  flex-grow-1 bg-white shadow-sm "
                      />
                    )}
                </>
              ) : tab === 2 && tableData.hasOwnProperty("columns") ? (
                <PvmTable tableData={tableData} filterKey={tableStatus} />
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="  bg-light shadow-sm d-flex flex-column justify-content-between w-25 piik-create-bar "
          style={{ zIndex: 10 }}
        >
          <div className=" px-3 py-2 piik-text-dashboard border-bottom ">
            <div className=" d-flex flex-row justify-content-between align-items-center">
              <div className="fw-semibold text-primary"> Set Title</div>
              <i
                className="bi bi-eraser piik-pointer"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Clear Layout"
                onClick={() => handleClear("all")}
              ></i>
            </div>
            <div className="my-2">
              <input
                type="text"
                className="form-control form-control-sm w-100"
                id="title"
                placeholder="Max 25 Character"
                value={title}
                onChange={handleInput}
              />
              {errorMessage !== "" && (
                <div className="pt-1 text-danger piik-text-warning">
                  {errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className=" px-3 pt-2 pb-3 piik-text-dashboard border-bottom ">
            <div className=" d-flex flex-row justify-content-between align-items-center mb-2">
              <div className="fw-semibold text-primary">Data Categories </div>
              <i
                className="bi bi-eraser piik-pointer"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Clear Data Category"
                onClick={() => handleClear("type")}
              ></i>
            </div>
            <select
              className="form-select form-select-sm "
              id="category"
              value={selectedReport}
              onChange={handleInput}
            >
              <option selected>Select..</option>
              {reportCategories.length > 0 &&
                reportCategories
                  .filter((d) => d.record_type === "IV")
                  .map((d) => {
                    return <option value={d.name}>{d.name}</option>;
                  })}
            </select>
          </div>

          <div className=" px-3 py-2 shadow-sm piik-text-dashboard">
            <div className=" d-flex flex-row justify-content-between align-items-center">
              <div className="fw-semibold text-primary">Set Parameter</div>
              <i
                className="bi bi-eraser piik-pointer"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Clear Data"
                onClick={() => handleClear("data")}
                disabled={status}
              ></i>
            </div>
          </div>
          <div className=" piik-text-visual flex-grow-1 overflow-y-scroll ">
            {selectedReport && (
              <div className="px-3 py-2">
                <div className="mb-2">
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label
                      htmlFor="categories"
                      className="form-label piik-text-visual mb-1"
                    >
                      Product
                    </label>
                  </div>
                  <select
                    className="form-select form-select-sm"
                    id="categories"
                    value={
                      (data && data.categories && data.categories.id) || ""
                    }
                    onChange={handleInput}
                  >
                    <option selected>Select Data</option>
                    {selectedAccount &&
                      selectedAccount.data &&
                      selectedAccount.data
                        .filter((d) => d.data_type === "str")
                        .filter((d) => d.category === selectedReport)
                        .map((v, i) => {
                          return (
                            <option value={v.id}>
                              {v.name}
                              {v.created && v.created === "custom"
                                ? " *"
                                : null}
                            </option>
                          );
                        })}
                  </select>
                  {error && (
                    <div className="p-1 piik-text-warning text-danger">
                      {error.categories}
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label
                      htmlFor="price"
                      className="form-label piik-text-visual mb-1"
                    >
                      Rate
                    </label>
                  </div>
                  <select
                    className="form-select form-select-sm"
                    id="price"
                    value={(data && data.price && data.price.id) || ""}
                    onChange={handleInput}
                  >
                    <option selected>Select Data</option>
                    {selectedAccount &&
                      selectedAccount.data &&
                      selectedAccount.data
                        .filter((d) => ["int", "float"].includes(d.data_type))
                        .filter((d) => d.category === selectedReport)
                        .map((v, i) => {
                          return (
                            <option value={v.id}>
                              {v.name}{" "}
                              {v.created && v.created === "custom"
                                ? " *"
                                : null}
                            </option>
                          );
                        })}
                  </select>
                  {error && (
                    <div className="p-1 piik-text-warning text-danger">
                      {error.price}
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label
                      htmlFor="chartName"
                      className="form-label piik-text-visual mb-1"
                    >
                      Quantity
                    </label>
                  </div>
                  <select
                    className="form-select form-select-sm"
                    id="quantity"
                    value={(data && data.quantity && data.quantity.id) || ""}
                    onChange={handleInput}
                  >
                    <option selected>Select Data</option>
                    {selectedAccount &&
                      selectedAccount.data &&
                      selectedAccount.data
                        .filter((d) => ["int", "float"].includes(d.data_type))
                        .filter((d) => d.category === selectedReport)
                        .map((v, i) => {
                          return (
                            <option value={v.id}>
                              {v.name}{" "}
                              {v.created && v.created === "custom"
                                ? " *"
                                : null}
                            </option>
                          );
                        })}
                  </select>
                  {error && (
                    <div className="p-1 piik-text-warning text-danger">
                      {error.quantity}
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label
                      htmlFor="amount"
                      className="form-label piik-text-visual mb-1"
                    >
                      Revenue
                    </label>
                  </div>
                  <select
                    className="form-select form-select-sm"
                    id="amount"
                    value={(data && data.amount && data.amount.id) || ""}
                    onChange={handleInput}
                  >
                    <option selected>Select Data</option>
                    {selectedAccount &&
                      selectedAccount.data &&
                      selectedAccount.data
                        .filter((d) => ["int", "float"].includes(d.data_type))
                        .filter((d) => d.category === selectedReport)
                        .map((v, i) => {
                          return (
                            <option value={v.id}>
                              {v.name}
                              {v.created && v.created === "custom"
                                ? " *"
                                : null}
                            </option>
                          );
                        })}
                  </select>
                  {error && (
                    <div className="p-1 piik-text-warning text-danger">
                      {error.quantity}
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <label
                      htmlFor="exchange"
                      className="form-label piik-text-visual mb-1"
                    >
                      Exchange Rate (optional)
                    </label>
                  </div>
                  <select
                    className="form-select form-select-sm"
                    id="exchange"
                    value={(data && data.exchange && data.exchange.id) || ""}
                    onChange={handleInput}
                  >
                    <option selected value="">
                      Select Data
                    </option>
                    {selectedAccount &&
                      selectedAccount.data &&
                      selectedAccount.data
                        .filter((d) => ["int", "float"].includes(d.data_type))
                        .filter((d) => d.category === selectedReport)
                        .map((v, i) => {
                          return (
                            <option value={v.id}>
                              {v.name}{" "}
                              {v.created && v.created === "custom"
                                ? " *"
                                : null}
                            </option>
                          );
                        })}
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <div className="flex-grow-1 bg-light px-4 piik-text-visual border-top">
          <div className="piik-text-dashboard text-primary px-4 pt-2">
            <SelectPVMRadioButton
              id="chart"
              name="Chart"
              value={tab}
              status={tab === 1}
              handleTab={() => {
                setTab(1);
              }}
            />
            <SelectPVMRadioButton
              id="table"
              name="Table"
              value={tab}
              status={tab === 2}
              handleTab={() => {
                setTab(2);
              }}
            />
            {tab === 2 && (
              <>
                <div className="form-check form-check-inline piik-pointer fw-bold">
                  Status Filter:
                </div>

                <SelectPVMCheckBoxButton
                  id="table"
                  name="Active"
                  value={tableStatus.includes("Active")}
                  status={tableStatus.includes("Active")}
                  handleTab={() => {
                    handleStatusFilter("Active");
                  }}
                />
                <SelectPVMCheckBoxButton
                  id="table"
                  name="New"
                  value={tableStatus.includes("New")}
                  status={tableStatus.includes("New")}
                  handleTab={() => {
                    handleStatusFilter("New");
                  }}
                />
                <SelectPVMCheckBoxButton
                  id="table"
                  name="Discontinued"
                  value={tableStatus.includes("Discontinued")}
                  status={tableStatus.includes("Discontinued")}
                  handleTab={() => {
                    handleStatusFilter("Discontinued");
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div
          className="  bg-light shadow-sm d-flex flex-column justify-content-between w-25  piik-create-bar"
          style={{ zIndex: 10 }}
        >
          {submitStatus ? (
            <div className="p-2 piik-text-primary bg-dark-subtle text-center text-white piik-pointer">
              Processing
            </div>
          ) : generateStatus ? (
            <div
              className="p-2 piik-text-dashboard bg-primary text-center text-white piik-pointer"
              onClick={handleGenerate}
            >
              Generate PVM
            </div>
          ) : updateVisualizationIndex === null && !generateStatus ? (
            <div
              className="p-2 piik-text-dashboard bg-success text-center text-white piik-pointer"
              onClick={handleSubmit}
              disabled={status}
            >
              Save
            </div>
          ) : updateVisualizationIndex !== null && !generateStatus ? (
            <div
              className="p-2 piik-text-dashboard bg-success text-center text-white piik-pointer"
              onClick={handleUpdate}
            >
              Update
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default CreatePvm;
