import { createContext, useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useLocalStorage } from "../hooks/useLocalStorage";
import { userClientApi } from "../hooks/useClientApi";
import { userDownloadApi } from "../hooks/useDownload";
import { usePdfGenerator } from "../hooks/usePdfGenerator";

import navigation from "../Data/NavigationData";
import { generateYearMonthArray } from "../Data/DatesGenerator";

const DashContext = createContext();
export const DashProvider = ({ children }) => {
  const { getValue, setValue } = useLocalStorage();
  const { user, getAccessTokenSilently } = useAuth0();

  // Set user Status
  const [userStatus, setUserStatus] = useState(false);
  const [profile, setProfile] = useState({});
  const [accountStatusError, setAccountStatusError] = useState("");
  const [adminAccountInfo, setAdminAccountInfo] = useState([]);
  const [guestAccountInfo, setGuestAccountInfo] = useState([]);

  // Auth Token
  const handleAuthToken = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: "https://piik-dash-api.com",
          },
        });
        resolve(accessToken);
      } catch (error) {
        reject(error);
      }
    });
  };
  const api = userClientApi(user, handleAuthToken);
  const handleUserStatus = () => {
    setUserStatus(true);
  };

  //  handleTableData
  const [tableData, setTableData] = useState(null);
  const handleTableData = (data) => {
    setTableData(data);
  };

  const handleDownloadTableData = (filename) => {
    if (!tableData) return;
    let filePath = "./" + filename;
    tableData.toCSV(true, filePath);
  };

  // Download Image
  const { download_as_png } = userDownloadApi();
  const handleDownloadAsImage = async (ref, data) => {
    try {
      await download_as_png(
        adminAccountInfo,
        guestAccountInfo,
        account,
        ref,
        data
      );
    } catch (error) {
      console.error("Error capturing div as image:", error);
    }
  };

  // Download  Pdf
  const { generateAndDownloadPdf } = usePdfGenerator();
  const handleDownloadPdf = async (targetRef, title) => {
    await generateAndDownloadPdf(
      adminAccountInfo,
      guestAccountInfo,
      account,
      targetRef,
      title
    );
  };

  // LoadingBar
  const [Loading, setLoading] = useState(false);

  // HandleSideBar and Content Tab
  const [tab, setTab] = useState(false);
  const [account, setAccount] = useState("");
  const [contentOption, setContentOption] = useState({
    tab_id: "",
    tab_title: "",
    subTab_id: "",
    subTab_title: "",
    tertiaryTab_id: "",
    tertiaryTab_title: "",
  });

  const handleSettingsTab = () => {
    setTab(!tab);
  };

  const handleAccount = (id) => {
    return new Promise((resolve, reject) => {
      setAccount((prev) => {
        setCalenderData((prev) => {
          if (Object.keys(profile).length > 0) {
            let combinedProfileData = profile.accounts.concat(
              profile.guest_invite
            );
            const accountIndex = combinedProfileData.findIndex(
              (data) => data.company_uid === id
            );
            let currentDate = combinedProfileData[accountIndex].date;
            return currentDate;
          } else {
            return prev;
          }
        });
        return id;
      });
      resolve(id);
    });
  };

  const handleContentTab = (tab_id, sub_tab_id, tertiary_tab_id) => {
    let updatedContent = {};
    if (tab_id === "4") {
      let tabData_4 = navigation.find((tab) => tab.tab_id === tab_id);
      updatedContent = {
        tab_id: tab_id,
        tab_title: tabData_4.name,
        subTab_id: "",
        subTab_title: "",
        tertiaryTab_id: "",
        tertiaryTab_title: "",
      };
    } else if (tab_id === "1") {
      let tabData_1 = navigation.find((tab) => tab.tab_id === tab_id);
      updatedContent = {
        tab_id: tab_id,
        tab_title: tabData_1.name,
        subTab_id: "",
        subTab_title: "",
        tertiaryTab_id: "",
        tertiaryTab_title: "",
      };
    } else if (tab_id === "2") {
      if (tertiary_tab_id === "") {
        let tabData_2 = navigation.find((tab) => tab.tab_id === tab_id);
        updatedContent = {
          tab_id: tabData_2.tab_id,
          tab_title: tabData_2.name,
          subTab_id: "",
          subTab_title: "",
          tertiaryTab_id: "",
          tertiaryTab_title: "",
        };
        setAccount("");
      } else {
        let tabData_2 = navigation.find((tab) => tab.tab_id === tab_id);
        let subTabData_1 = tabData_2.subTab.find(
          (t) => t.id === tertiary_tab_id
        );
        updatedContent = {
          tab_id: tabData_2.tab_id,
          tab_title: tabData_2.name,
          subTab_id: sub_tab_id,
          subTab_title: sub_tab_id,
          tertiaryTab_id: tertiary_tab_id,
          tertiaryTab_title: subTabData_1.name,
        };
      }
    } else if (tab_id === "3") {
      let tabData_3 = navigation.find((tab) => tab.tab_id === tab_id);
      let subTabData_3 = tabData_3.subTab.find((st) => st.id === sub_tab_id);
      updatedContent = {
        tab_id: tabData_3.tab_id,
        tab_title: tabData_3.name,
        subTab_id: sub_tab_id,
        subTab_title: subTabData_3.name,
        tertiaryTab_id: "",
        tertiaryTab_title: "",
      };
    }
    setContentOption({ ...updatedContent });
  };

  // Handle Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  //  Handle Calender Data
  const [calenderDatesData, setCalenderDatesData] = useState([]);
  const [calenderData, setCalenderData] = useState({
    month: "",
    month_index: "",
    year: "",
    year_index: "",
  });

  //  handle Subscribtion Plan
  const [products, setProduct] = useState([]);
  const handleLoadSubscriptionPlan = () => {
    api
      .get("/settings/subscription-plan")
      .then((data) => {
        setProduct(data);
      })
      .catch((error) => {
        console.error("Error:", error);
        return { status: false };
      });
  };

  //  User subscription Details
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const handlesubscriptionDetails = (customerId, subscription_id) => {
    api
      .post("/settings/subscription-active", {
        customerId: customerId,
        subscriptionId: subscription_id,
      })
      .then((data) => {
        setSubscriptionDetails(data);
      })
      .catch((error) => {
        console.error("Error:", error);
        return { status: false };
      });
  };

  // Update subscription plan
  const handleUpdatesubscription = (token, product) => {
    return new Promise((resolve, reject) => {
      let data = {
        token: token,
        subscriptionId: profile.stripe_subscription_id,
        customerId: profile.user_id,
        product: product,
      };

      api
        .post("/settings/subscription-update", data)
        .then((data) => {
          if (data.status) {
            setValue("profileInfo", data.profileInfo);

            setProfile(data.profileInfo);
            setAdminAccountInfo(data.adminAccountInfo);
          }
          resolve(data.status);
        })
        .catch((error) => {
          console.error("Error:", error);
          reject({ status: false });
        });
    });
  };

  // Handle Data Privacy Profile
  const handleDataProfileCreate = (newData) => {
    let profile_data = { ...newData, user_id: profile.user_id };
    let response = api
      .post("/qbooks/create-profile", profile_data)
      .then((data) => {
        if (data.status) {
          setValue("profileInfo", data.profileInfo);

          setProfile(data.profileInfo);
          setAdminAccountInfo(data.adminAccountInfo);
          setGuestAccountInfo(data.guestAccountInfo);
        }
        return data.status;
      })
      .catch((error) => {
        console.error("Error:", error);
        return false;
      });

    return response;
  };

  const handleDataProfileDelete = (newData) => {
    return new Promise((resolve, reject) => {
      api
        .post("/qbooks/delete-profile", newData)
        .then((data) => {
          if (data.status) {
            setValue("profileInfo", data.profileInfo);

            setProfile(data.profileInfo);
            setAdminAccountInfo(data.adminAccountInfo);
            setGuestAccountInfo(data.guestAccountInfo);
          }
          resolve(data.status);
        })
        .catch((error) => {
          console.error("Error:", error);
          reject(false);
        });
    });
  };

  const handleUserInvite = (newData) => {
    let invite_data = { ...newData, user_id: profile.user_id };
    let response = api
      .post("/settings/add-users", invite_data)
      .then((data) => {
        if (data.status) {
          setValue("profileInfo", data.profileInfo);

          setProfile(data.profileInfo);
          setAdminAccountInfo(data.adminAccountInfo);
          setGuestAccountInfo(data.guestAccountInfo);
        }
        return data.status;
      })
      .catch((error) => {
        console.error("Error:", error);
        return false;
      });
    return response;
  };

  // Handle Add New Company
  const handleAddAccount = () => {
    setValue("AddAccountModalState", {
      state: true,
      comment:
        "Please wait while the company data is added to your account. This process may take some time.",
    });
    api
      .post("/qbooks/connect", {
        subscriptionId: profile.stripe_subscription_id,
        productId: profile.product_id,
      })
      .then((data) => {
        if (data.status) {
          window.location.href = data.url;
        } else {
          setValue("AddAccountModalState", {
            state: false,
            comment: "",
          });
          setAccountStatusError(data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        console.error("Error:", error);
        return { status: false };
      });
  };

  // Handle Administrator Access level Data
  const handleRevokeApi = (newData) => {
    return new Promise((resolve, reject) => {
      api
        .post("/qbooks/revoke", newData)
        .then((data) => {
          if (data.status) {
            setValue("profileInfo", data.profileInfo);

            setProfile(data.profileInfo);
            setAdminAccountInfo(data.adminAccountInfo);
          }
          resolve(data);
        })
        .catch((error) => {
          console.error("Error:", error);
          reject(error);
        });
    });
  };

  // handle Refresh Profile
  const handleRefresh = () => {
    setLoading(true);
    api
      .getAuthToken(user)
      .then((data) => {
        setValue("profileInfo", data.profileInfo);

        setProfile(data.profileInfo);
        setAdminAccountInfo(data.adminAccountInfo);
        setGuestAccountInfo(data.guestAccountInfo);

        setContentOption((prevData) => {
          if (prevData.tab_id === "") {
            let tabData_4 = navigation.find((tab) => tab.tab_id === "4");
            let updatedContent = {
              tab_id: "4",
              tab_title: tabData_4.name,
              subTab_id: "",
              subTab_title: "",
              tertiaryTab_id: "",
              tertiaryTab_title: "",
            };
            return updatedContent;
          } else {
            return prevData;
          }
        });
        if (!userStatus) {
          handleUserStatus();
          handleLoadSubscriptionPlan();
          let customerId = data.profileInfo.stripe_customer_id;
          let subscriptionId = data.profileInfo.stripe_subscription_id;
          handlesubscriptionDetails(customerId, subscriptionId);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(true);
      });
  };

  const handleCompanyRefresh = (date) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      let type =
        profile.accounts.findIndex((d) => d.company_uid === account) > -1
          ? "admin"
          : "guest";

      let data = {
        companyId: account,
        userId: profile.user_id,
        date: date.month_index === null ? calenderData : date,
        type: type,
      };

      api
        .post("/settings/data-update", data)
        .then((response) => {
          if (response.status) {
            setValue("profileInfo", response.profileInfo);
            setProfile(response.profileInfo);
            setAdminAccountInfo(response.adminAccountInfo);
            setGuestAccountInfo(response.guestAccountInfo);
            setLoading(false);
            resolve(response.status);
          } else {
            setLoading(true);
            reject(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(true);
          reject(false);
        });
    });
  };

  const handleDataReportRefresh = (data) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      api
        .post("/qbooks/update-report", data)
        .then((data) => {
          if (data.status) {
            setValue("profileInfo", data.profileInfo);
            setProfile(data.profileInfo);
            setAdminAccountInfo(data.adminAccountInfo);
            setLoading(false);
            resolve(data.status);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(false);
          reject(error);
        });
    });
  };

  // Add Visulization to db
  const handleCopyData = (data) => {
    return new Promise((resolve, reject) => {
      api
        .post("/qbooks/duplicate-visualization", data)
        .then((data) => {
          if (data.status) {
            setValue("profileInfo", data.profileInfo);

            setProfile(data.profileInfo);
            setAdminAccountInfo(data.adminAccountInfo);
            resolve(data);
          } else {
            resolve(data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject(error);
        });
    });
  };
  const handleAddVisualization = (data) => {
    return new Promise((resolve, reject) => {
      api
        .post("/qbooks/add-visualization", data)
        .then((data) => {
          if (data.status) {
            setValue("profileInfo", data.profileInfo);

            setProfile(data.profileInfo);
            setAdminAccountInfo(data.adminAccountInfo);
            resolve(data);
          } else {
            resolve(data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject(error);
        });
    });
  };

  const handleUpdateVisualization = (data) => {
    return new Promise((resolve, reject) => {
      api
        .post("/qbooks/update-visualization", data)
        .then((data) => {
          if (data.status) {
            setValue("profileInfo", data.profileInfo);

            setProfile(data.profileInfo);
            setAdminAccountInfo(data.adminAccountInfo);
            resolve(data);
          } else {
            resolve(data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject(error);
        });
    });
  };

  const handleDeleteVisulization = (data) => {
    return new Promise((resolve, reject) => {
      api
        .post("/qbooks/delete-visualization", data)
        .then((data) => {
          if (data.status) {
            setValue("profileInfo", data.profileInfo);

            setProfile(data.profileInfo);
            setAdminAccountInfo(data.adminAccountInfo);
            resolve(data.status);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject(error);
        });
    });
  };

  const handleCalculator = (data) => {
    return new Promise((resolve, reject) => {
      api
        .post("/qbooks/calculator", data)
        .then((res) => {
          if (res.status) {
            resolve(res);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject(error);
        });
    });
  };

  const handleFileUpload = (formData) => {
    return new Promise((resolve, reject) => {
      api
        .post("/qbooks/csv-upload", formData)
        .then((data) => {
          if (data.status) {
            setValue("profileInfo", data.profileInfo);

            setProfile(data.profileInfo);
            setAdminAccountInfo(data.adminAccountInfo);
            resolve(data);
          } else {
            resolve(data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject({ status: false, message: error.message });
        });
    });
  };

  const handleCloudStorageAuthetication = (data) => {
    return new Promise((resolve, reject) => {
      api
        .post("/qbooks/authenticate_cloud_storage", data)
        .then((data) => {
          if (data.status) {
            resolve(data);
          } else {
            resolve(data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject({ status: false, message: error.message });
        });
    });
  };

  const handleCloudStorageUpload = (data) => {
    return new Promise((resolve, reject) => {
      api
        .post("/qbooks/upload_cloud_storage", data)
        .then((data) => {
          if (data.status) {
            resolve(data);
          } else {
            resolve(data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject({ status: false, message: error.message });
        });
    });
  };

  const handleTransferAccount = (data) => {
    return new Promise((resolve, reject) => {
      api
        .post("/settings/transfer-account", data)
        .then((data) => {
          if (data.status) {
            setValue("profileInfo", data.profileInfo);
            setProfile(data.profileInfo);
            setAdminAccountInfo(data.adminAccountInfo);
            resolve(data);
          } else {
            resolve(data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject({ status: false, message: error.message });
        });
    });
  };

  //  handle Profile Name
  const handleProfileData = (data) => {
    return new Promise((resolve, reject) => {
      api
        .post("/settings/update-profile", data)
        .then((response) => {
          if (response.status) {
            setValue("profileInfo", response.profileInfo);
            resolve(response);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          reject({ status: false, message: error.message });
        });
    });
  };

  // On Load
  useEffect(() => {
    try {
      let updatedContent = {
        tab_id: "",
        tab_title: "",
        subTab_id: "",
        subTab_title: "",
        tertiaryTab_id: "",
        tertiaryTab_title: "",
      };
      setContentOption({ ...updatedContent });
      setCalenderDatesData(() => {
        const newDatesArr = generateYearMonthArray(2019);
        setCalenderData(() => {
          let newDate = {
            month: newDatesArr[0].months[0].month,
            month_index: newDatesArr[0].months[0].month_index,
            year: newDatesArr[0].year,
            year_index: newDatesArr[0].year_index,
            fiscal_month_index: 1,
          };
          handleRefresh();
          return newDate;
        });
        return newDatesArr;
      });

      // Get the URL search parameters
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get("code");
      const realmId = searchParams.get("realmId");
      const errorState = searchParams.get("error");
      const profileInfo = getValue("profileInfo");

      if (errorState) {
        if (errorState === "access_denied") {
          setValue("AddAccountModalState", {
            state: true,
            comment: "Access Denied.Please Try again",
          });
          window.history.pushState(
            {},
            document.title,
            window.location.origin + window.location.pathname
          );
        } else {
          setValue("AddAccountModalState", {
            state: false,
            comment: "",
          });
        }
      }

      if (code !== null && realmId !== null) {
        api
          .post("/qbooks/authenticate", {
            authCode: code,
            realmId: realmId,
            profile: profileInfo,
          })
          .then((data) => {
            if (data.status) {
              setValue("profileInfo", data.profileInfo);
              setProfile(data.profileInfo);
              setAdminAccountInfo(data.adminAccountInfo);

              let tabData = navigation.find((tab) => tab.tab_id === "4");
              setContentOption({
                ...{
                  tab_id: tabData.tab_id,
                  tab_title: tabData.name,
                  subTab_id: "",
                  subTab_title: "",
                  tertiaryTab_id: "",
                  tertiaryTab_title: "",
                },
              });
              window.history.pushState(
                {},
                document.title,
                window.location.origin + window.location.pathname
              );
              setValue("AddAccountModalState", {
                state: false,
                comment: "",
              });
            } else {
              setValue("AddAccountModalState", {
                state: true,
                comment:
                  "Acccount with same ID already found in Piik Dash Database.Please try a different Account",
              });
            }
            const currentUrl = window.location.href;
            const updatedUrl = currentUrl.split("?")[0];
            window.history.replaceState({}, document.title, updatedUrl);
          })
          .catch((error) => {
            setValue("AddAccountModalState", {
              state: true,
              comment: "Error.Please try again later",
            });
            const currentUrl = window.location.href;
            const updatedUrl = currentUrl.split("?")[0];
            window.history.replaceState({}, document.title, updatedUrl);
          });
      }
      return () => {
        setCalenderDatesData([]);
        setCalenderData({});
      };
    } catch (err) {
      setValue("AddAccountModalState", {
        state: true,
        comment: "Error.Please try again later",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = {
    accountDetails: user,
    adminAccountInfo,
    guestAccountInfo,
    profile,
    Loading,

    navigation,
    account,
    tab,
    contentOption,
    handleContentTab,
    handleAccount,
    handleSettingsTab,

    calenderData,
    calenderDatesData,
    setCalenderData,
    setCalenderDatesData,

    accountStatusError,
    handleAddAccount,
    handleRevokeApi,

    handleDataProfileCreate,
    handleDataProfileDelete,
    handleUserInvite,

    handleRefresh,
    handleCompanyRefresh,
    handleDataReportRefresh,

    products,
    subscriptionDetails,
    handlesubscriptionDetails,
    handleUpdatesubscription,

    isModalOpen,
    openModal,
    closeModal,

    handleAddVisualization,
    handleUpdateVisualization,
    handleDeleteVisulization,
    handleCalculator,

    handleDownloadAsImage,
    handleDownloadPdf,
    handleFileUpload,
    handleCloudStorageAuthetication,
    handleCloudStorageUpload,
    handleTransferAccount,

    handleTableData,
    handleDownloadTableData,
    handleProfileData,
    handleCopyData,
  };
  return <DashContext.Provider value={data}>{children}</DashContext.Provider>;
};

export const useDashData = () => {
  return useContext(DashContext);
};
