import React, { useEffect, useState } from "react";
import Tab from "../Common/Tab";

import { useDashData } from "../../hooks/useDash";
import { useAuth0 } from "@auth0/auth0-react";

import Subscriptions from "../Stripe/SubscriptionDetails";
import { formatDate } from "../Visualization/Charts/Utility";

const UsersDetails = () => {
  const { user } = useAuth0();
  const { profile, products, subscriptionDetails, handleProfileData } =
    useDashData();

  const [editMode, setEditMode] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    uid: profile.user_id,
  });
  const handleInput = (e) => {
    setUserData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };
  const handleUpdateProfile = () => {
    setSubmit(true);
    handleProfileData(userData).then((response) => {
      if (response.status) {
        setEditMode(false);
      }
      setSubmit(false);
    });
  };

  const [tabId, setTabId] = useState("1");
  const handleTab = (id) => {
    setTabId(id);
  };

  const handleProductName = (name) => {
    let selectedProduct = products.find((p) => p.id === name);
    return selectedProduct.name;
  };

  useEffect(() => {
    setUserData(() => {
      if (
        profile.first_name === undefined ||
        profile.first_name === "" ||
        profile.last_name === undefined ||
        profile.last_name === "" ||
        profile.company === undefined ||
        profile.company === ""
      ) {
        setEditMode(true);
      }
      return {
        uid: profile.user_id,
        firstName: profile.first_name,
        lastName: profile.last_name,
        company: profile.company,
      };
    });
  }, [profile]);

  return (
    <section className="py-2">
      <div className="d-flex flex-row  bg-light border-bottom">
        <Tab
          type="3c"
          title="User Account"
          tab_id="1"
          status={tabId === "1" ? true : false}
          handleClick={handleTab}
        />
        <Tab
          type="3c"
          title="Update Subscription Plan"
          tab_id="2"
          status={tabId === "2" ? true : false}
          handleClick={handleTab}
        />
      </div>
      {tabId === "1" ? (
        <>
          <div className="bg-light">
            <div className="d-flex flex-row justify-content-between align-items-center w-100 shadow-sm fw-semibold">
              <div className="fw-medium p-2"> Profile Details </div>
              <span
                className="px-4 piik-pointer"
                onClick={() => setEditMode(!editMode)}
              >
                <i className="bi bi-pencil-square"></i>
              </span>
            </div>

            <div className="d-flex flex-row ">
              <div className="px-4 flex-grow-1 piik-text-dashboard d-flex flex-row justify-content-between py-4">
                <div className="w-50">
                  <div className="pb-2 d-flex flex-row justify-content-start w-100">
                    <div className="fw-medium w-50">First Name:</div>

                    <input
                      type="text"
                      maxLength={20}
                      className="form-control form-control-sm flex-grow-1"
                      id="firstName"
                      value={userData.firstName}
                      placeholder="Ex.John"
                      disabled={!editMode}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="pb-2 d-flex flex-row justify-content-start w-100">
                    <div className="fw-medium w-50">Last Name:</div>

                    <input
                      type="text"
                      maxLength={20}
                      className="form-control form-control-sm flex-grow-1"
                      id="lastName"
                      value={userData.lastName}
                      placeholder="Ex.Doe"
                      disabled={!editMode}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="pb-2 d-flex flex-row justify-content-start w-100">
                    <div className="fw-medium w-50">Company Name:</div>

                    <input
                      type="text"
                      maxLength={30}
                      className="form-control form-control-sm flex-grow-1"
                      id="company"
                      value={userData.company}
                      placeholder="Ex. ABCD pvt ltd."
                      disabled={!editMode}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="pb-2 d-flex flex-row justify-content-start w-100">
                    <div className="fw-medium w-50">Email:</div>
                    <input
                      type="text"
                      maxLength={20}
                      className="form-control form-control-sm flex-grow-1"
                      id="firstName"
                      value={user.email}
                      placeholder="email"
                      disabled={true}
                    />
                  </div>
                </div>
                <div>
                  <div className="pb-2 text-end">
                    Updated at:
                    <span className="fw-medium px-4">
                      {formatDate(user.updated_at)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {editMode && (
            <div className="text-end w-100 py-1">
              <button
                className="btn btn-primary px-4"
                onClick={handleUpdateProfile}
                disabled={submit}
              >
                Update
              </button>
            </div>
          )}

          <div className="bg-light mt-4">
            <div className="d-flex flex-row justify-content-between w-100 shadow-sm fw-semibold">
              <div className="fw-medium p-2">Subscription Details </div>
            </div>
            <div className="d-flex flex-row justify-content-between ">
              <div className="px-4 flex-grow-1 piik-text-dashboard d-flex flex-row justify-content-between py-4">
                <div className="w-50">
                  <div className="pb-2 d-flex flex-row justify-content-start w-100">
                    <div className="fw-medium w-50">Plan Name:</div>
                    <input
                      className="form-control form-control-sm flex-grow-1"
                      value={handleProductName(
                        subscriptionDetails.plan.product
                      )}
                      disabled
                    />
                  </div>
                  <div className="pb-2 d-flex flex-row justify-content-start w-100">
                    <div className="fw-medium w-50">Status:</div>
                    <input
                      className="form-control form-control-sm flex-grow-1"
                      value={subscriptionDetails.status}
                      disabled
                    />
                  </div>
                  <div className="pb-2 d-flex flex-row justify-content-start w-100">
                    <div className="fw-medium w-50">Usage:</div>
                    <input
                      type="text"
                      className="form-control form-control-sm flex-grow-1"
                      value={subscriptionDetails.quantity}
                      disabled
                    />
                  </div>
                </div>
                <div className="px-4 flex-grow-1 piik-text-dashboard text-end py-4">
                  <div className="pb-2">
                    Created at:
                    <span className="fw-medium px-2">
                      {formatDate(subscriptionDetails.created, true)}
                    </span>
                  </div>
                  <div className="pb-2">
                    Current Billing Period Start at:
                    <span className="fw-medium px-2">
                      {formatDate(
                        subscriptionDetails.current_period_start,
                        true
                      )}
                    </span>
                  </div>
                  <div className="pb-1">
                    Current Billing Period End at:
                    <span className="fw-medium px-2">
                      {formatDate(subscriptionDetails.current_period_end, true)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : tabId === "2" ? (
        <Subscriptions />
      ) : null}
    </section>
  );
};
export default UsersDetails;
