//Utility

function formatDate(inputDate) {
  const dateObj = new Date(inputDate);
  const year = dateObj.getFullYear();
  const monthNumber = dateObj.getMonth();
  const monthAbbreviation = getMonthAbbreviation(monthNumber);
  const formattedDate = `${monthAbbreviation}-${year}`;
  return formattedDate;
}

function getMonthAbbreviation(monthNumber) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let selected_month = months.filter((d, index) => index === monthNumber);
  return selected_month[0];
}

function getEndOfMonth(year, month) {
  const nextMonthFirstDay = new Date(year, month, 1);
  const lastDayOfMonth = new Date(nextMonthFirstDay - 1);
  let formattedDate = `${lastDayOfMonth.getFullYear()}-${(
    lastDayOfMonth.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${lastDayOfMonth.getDate().toString().padStart(2, "0")}`;
  // Handling Leap year
  formattedDate = formattedDate.includes("29")
    ? formattedDate.replace("29", "28")
    : formattedDate;
  return formattedDate;
}

const handleLevelsBalanceSheet = (settings, data) => {
  if (settings.levelThree && settings.levelTwo && settings.levelOne) {
    return data;
  } else if (settings.levelTwo && settings.levelOne) {
    if ((data.tags === "H" || data.tags === "S") && parseInt(data.level) <= 2) {
      return data;
    } else if (data.tags === "S" && parseInt(data.level) <= 3) {
      return { ...data, tags: "R" };
    }
  } else if (settings.levelOne) {
    if (
      (data.tags === "H" || data.tags === "S") &&
      parseInt(data.level) === 1
    ) {
      return data;
    } else {
      if (data.tags === "S" && parseInt(data.level) <= 2) {
        return { ...data, tags: "R" };
      }
    }
  }
  return false;
};
const handleLevelsIncomeStatement = (settings, data) => {
  if (settings.levelThree && settings.levelTwo && settings.levelOne) {
    return data;
  } else if (settings.levelTwo && settings.levelOne) {
    if ((data.tags === "H" || data.tags === "S") && parseInt(data.level) <= 1) {
      return data;
    } else if (data.tags === "S" && parseInt(data.level) <= 2) {
      return { ...data, tags: "R" };
    }
  } else if (settings.levelOne) {
    if (
      (data.tags === "H" || data.tags === "S") &&
      parseInt(data.level) === 1
    ) {
      return data;
    } else {
      if (data.tags === "S" && parseInt(data.level) <= 0) {
        return { ...data, tags: "R" };
      }
    }
  }
  return false;
};

const handleZeroValues = (settings, data, formate_type) => {
  if (settings.zeroValue && data.tags === "R") {
    const keys_to_check =
      formate_type === "II"
        ? ["Current Yr", "Same Month Last Yr", "Fiscal Last Yr"]
        : [
            "Actual",
            "Prior Year",
            "Actual YTD",
            "Prior Year YTD",
            "Budget",
            "Budget YTD",
          ];
    const anyNonZeroValue = (obj, keys) => keys.some((key) => obj[key] !== 0);
    return anyNonZeroValue(data, keys_to_check);
  } else {
    return true;
  }
};

const handleHeader = (settings, d) => {
  if (d.includes("Budget") && !settings.budget) {
    return false;
  }
  if (d.includes("YTD") && !settings.ytd) {
    return false;
  }
  if (d.includes("Prior") && !settings.prior) {
    return false;
  }
  return true;
};

const formateAccountName = (txt) => {
  const characters = txt && txt.match(/[a-zA-Z]/g).join("");
  return characters ? characters.toLowerCase() : "";
};

export {
  formatDate,
  getEndOfMonth,
  formateAccountName,
  handleLevelsBalanceSheet,
  handleLevelsIncomeStatement,
  handleZeroValues,
  handleHeader,
};
