import React from "react";

const VisualizationHeader = (props) => {
  const handleClick = (index) => {
    props.handleVisualizationTab(index);
  };

  return (
    <section className="d-flex flex-row justify-content-between align-items-center piik-create-chart w-100 ">
      {props.data && props.data.length > 0 ? (
        <section className="d-flex flex-row justify-content-start align-items-center piik-create-chart  piik-text-dashboard overflow-x-scroll">
          {props.data.map((value, index) => {
            return (
              <div
                onClick={() => handleClick(index)}
                key={`v-${index}`}
                className={
                  props.visualizationTab === index
                    ? "border-bottom border-3 border-primary piik-text-dashboard px-4 py-2 piik-pointer "
                    : "piik-text-dashboard px-4 py-2 piik-pointer "
                }
                style={{ whiteSpace: "nowrap" }}
              >
                {value.name}
              </div>
            );
          })}
        </section>
      ) : (
        <section className="d-flex flex-row justify-content-start align-items-center piik-create-chart  piik-text-dashboard overflow-x-scroll">
          <div className="px-4 py-2 " style={{ whiteSpace: "nowrap" }}>
            No {props.tab_name} found
            {/* {props.tab_name} Tutorial */}
          </div>
        </section>
      )}
      {props.visibililityStatus && (
        <div
          onClick={() => handleClick(100)}
          key={`v-${100}`}
          className={
            props.visualizationTab === 100
              ? "border-bottom border-3 border-primary piik-text-dashboard px-4 py-2 piik-pointer shadow-sm text-primary   piik-create-bar "
              : "piik-text-dashboard px-4 py-2 piik-pointer  text-primary border-start piik-create-bar"
          }
        >
          <div className="piik-text-dashboard px-2 text-center  ">
            <span className="">Create New {props.tab_name}</span>
          </div>
        </div>
      )}
    </section>
  );
};
export default VisualizationHeader;
