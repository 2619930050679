import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import { useDashData } from "../../hooks/useDash";
import {
  generateYearMonthArray,
  findMinMaxDate,
  getFiscalMonthIndex,
} from "../../Data/DatesGenerator";

const DataRefresh = (props) => {
  const {
    Loading,
    account,
    adminAccountInfo,
    guestAccountInfo,
    calenderDatesData,
    setCalenderDatesData,
    calenderData,
    setCalenderData,
    handleCompanyRefresh,
    handleDataReportRefresh,
    profile,
  } = useDashData();
  const [date, setDate] = useState({
    year: "",
    year_index: null,
    month: "",
    month_index: null,
  });
  const [selectedAccount, setSelectedAccount] = useState({});
  const [category, setCategory] = useState([]);
  const [status, setStatus] = useState("Submit");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    openModal();
  };

  const handleYear = (e) => {
    let id = e.target.id;
    setDate((prevDate) => {
      let tempYearIndex = calenderDatesData.findIndex((d) => d.year === id);
      let temMonthIndex = calenderDatesData[tempYearIndex].months.findIndex(
        (d) => d.status
      );
      let updateDate = {
        ...prevDate,
        year: calenderDatesData[tempYearIndex].year,
        year_index: calenderDatesData[tempYearIndex].year_index,
        month: calenderDatesData[tempYearIndex].months[temMonthIndex].month,
        month_index:
          calenderDatesData[tempYearIndex].months[temMonthIndex].month_index,
      };
      setCategory(() => calenderDatesData[tempYearIndex].months);
      return updateDate;
    });
  };

  const handleMonth = (e) => {
    let id = e.target.id;
    setDate((prevDate) => {
      let tempMonthIndex = category.findIndex(
        (d) => d.month_index === parseInt(id)
      );
      let updateDate = {
        ...prevDate,
        month: category[tempMonthIndex].month,
        month_index: category[tempMonthIndex].month_index,
        year_index: category[tempMonthIndex].year_index,
        year: category[tempMonthIndex].year,
      };
      return updateDate;
    });
  };
  const handleRefresh = () => {
    return new Promise((resolve, reject) => {
      try {
        let combinedProfileData = profile.accounts.concat(profile.guest_invite);
        const accountIndex = combinedProfileData.findIndex(
          (data) => data.company_uid === account
        );
        let selectedData = combinedProfileData[accountIndex];

        let data = {
          companyId: selectedData.company_uid,
          accountType: selectedData.account_name,
          userId: profile.user_id,
        };
        handleDataReportRefresh(data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleSubmit = () => {
    setStatus("Processing...");
    setCalenderData(date);
    handleCompanyRefresh(date)
      .then((response) => {
        if (response) {
          handleRefresh().then(() => {
            handleCloseModal();
            setStatus("Submit");
          });
        }
      })
      .catch((error) => {
        setStatus("Error");
        console.error("Error:", error);
      });
  };

  const handleStatusOfMonth = (data, min, max) => {
    return new Promise((resolve, reject) => {
      try {
        const dateObjectMin = new Date(min);
        const min_year = dateObjectMin.getFullYear();
        const min_month = dateObjectMin.getMonth();

        const dateObjectMax = new Date(max);
        const max_year = dateObjectMax.getFullYear();
        const max_month = dateObjectMax.getMonth();

        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < data[i].months.length; j++) {
            let year_index = data[i].months[j].year_index;
            let month_index = data[i].months[j].month_index;

            if (
              year_index < min_year ||
              (year_index === min_year && month_index < min_month)
            ) {
              data[i].months[j].status = false;
            } else if (
              year_index > max_year ||
              (year_index === max_year && month_index > max_month)
            ) {
              data[i].months[j].status = false;
            } else {
              data[i].months[j].status = true;
            }
          }
        }

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };

  useEffect(() => {
    let combinedProfileData = profile.accounts.concat(profile.guest_invite);
    const accountIndex = combinedProfileData.findIndex(
      (data) => data.company_uid === account
    );
    const CombinedData = adminAccountInfo.concat(guestAccountInfo);
    const accountTempIndex = CombinedData.findIndex(
      (data) => data.uid === account
    );
    if (accountIndex > -1) {
      let currentDate = combinedProfileData[accountIndex].date;
      let fiscal_month_index = getFiscalMonthIndex(
        CombinedData[accountTempIndex].fiscal_year_start_month
      );
      currentDate["fiscal_month_index"] = fiscal_month_index;
      setCalenderData(() => currentDate);
      setDate(() => currentDate);

      setSelectedAccount(CombinedData[accountTempIndex]);
      let accountData = CombinedData[accountTempIndex].data.filter(
        (d) => d.code === "date"
      );

      const result = findMinMaxDate(accountData[0].data, "date");
      const startYearDate = new Date(result.minDate).getFullYear();
      const endYearDate = new Date(result.maxDate).getFullYear();
      const tempCompanyCalenderData = generateYearMonthArray(
        startYearDate,
        endYearDate,
        fiscal_month_index
      );
      handleStatusOfMonth(
        tempCompanyCalenderData,
        result.minDate,
        result.maxDate
      ).then((response) => {
        setCategory(() => {
          setCalenderDatesData(() => {
            return response;
          });
          let tempIndex =
            response.length > 0 &&
            response.findIndex((d) => d.year === currentDate.year);
          if (tempIndex > -1) {
            return response[tempIndex].months;
          } else {
            return response[0].months;
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    account,
    profile.accounts,
    profile.guest_invite,
    adminAccountInfo,
    guestAccountInfo,
  ]);

  return (
    <div className=" d-flex flex-row w-25 align-items-center piik-create-bar">
      <div
        className=" bg-primary text-light px-2 py-2 text-center  flex-grow-1 piik-pointer "
        onClick={handleOpenModal}
      >
        <div className="piik-text-dashboard text-light">
          Upto
          <span className="fw-bold px-1 text-white">{calenderData.month}</span>
          for
          <span className="fw-bold px-1 text-white">
            {selectedAccount &&
            selectedAccount.fiscal_year_start_month &&
            selectedAccount.fiscal_year_start_month === "January"
              ? calenderData.year_index
              : calenderData.year}
          </span>
        </div>
      </div>
      {Loading ? (
        <div className="py-2 px-3 bg-secondary text-white piik-text-dashboard border-start piik-pointer">
          <i
            className="spinner-border spinner-border-sm piik-text-dashboard m-0"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Update Data"
          ></i>
        </div>
      ) : (
        <div
          onClick={handleRefresh}
          className="py-2 px-3 bg-primary text-white piik-text-dashboard border-start piik-pointer"
        >
          <i
            className="bi bi-arrow-repeat piik-text-dashboard"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Update Data"
          ></i>
        </div>
      )}

      <div
        onClick={props.handleFullScreenStatus}
        className="py-2 px-3 bg-primary text-white piik-text-dashboard border-start piik-pointer"
      >
        {props.fullScreenStatus ? (
          <i
            className="bi bi-fullscreen-exit "
            data-toggle="tooltip"
            data-placement="bottom"
            title="Exit Full Screen"
          ></i>
        ) : (
          <i
            className="bi bi-arrows-fullscreen"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Full Screen"
          ></i>
        )}
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          closeModal={handleCloseModal}
          title="Update Dates"
        >
          <div className="d-flex flex-row text-center ">
            <div className="piik-calender-modal ps-1 pt-1">
              {calenderDatesData.length > 0 &&
                calenderDatesData.map((d) => {
                  return date.year === d.year ? (
                    <div
                      className="fw-bold py-3 piik-modal-icon piik-pointer piik-text-dashboard px-4 bg-primary text-white shadow-sm"
                      key={d.year_index}
                      id={d.year_index}
                    >
                      {selectedAccount &&
                      selectedAccount.fiscal_year_start_month &&
                      selectedAccount.fiscal_year_start_month === "January"
                        ? d.year_index
                        : d.year}
                    </div>
                  ) : (
                    <div
                      className="fw-bold py-3 piik-modal-icon piik-pointer piik-text-dashboard px-4 shadow-sm"
                      key={d.year_index}
                      id={d.year}
                      onClick={handleYear}
                    >
                      {selectedAccount &&
                      selectedAccount.fiscal_year_start_month &&
                      selectedAccount.fiscal_year_start_month === "January"
                        ? d.year_index
                        : d.year}
                    </div>
                  );
                })}
            </div>
            <div className="row m-0 flex-grow-1 mx-2 ">
              <div className=" col-12text-start piik-text-dashboard  border-bottom py-3 align-items-center shadow-sm text-dark align-self-center border-bottom ls-2">
                Upto <span className="fw-semibold px-1">{date.month}</span>
                for{" "}
                <span className="fw-semibold">
                  {selectedAccount &&
                  selectedAccount.fiscal_year_start_month &&
                  selectedAccount.fiscal_year_start_month === "January"
                    ? date.year_index
                    : date.year}
                </span>
              </div>
              {category.length > 0 &&
                category.map((d) => {
                  return date.month_index === d.month_index ? (
                    <div
                      className="fw-bold piik-modal-icon piik-pointer piik-text-dashboard col-4  px-4 py-2 bg-primary text-white align-self-center shadow-sm"
                      key={d.month_index}
                      id={d.month_index}
                    >
                      {d.month}
                    </div>
                  ) : d.status ? (
                    <div
                      className="fw-bold py-2 piik-modal-icon piik-pointer piik-text-dashboard px-4 col-4 align-self-center shadow-sm "
                      key={d.month_index}
                      id={d.month_index}
                      onClick={handleMonth}
                    >
                      {d.month}
                    </div>
                  ) : (
                    <div className="fw-bold py-2  piik-text-dashboard px-4 col-4 align-self-center shadow-sm  bg-light text-secondary  piik-pointer-not-allowed ">
                      {d.month}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className=" piik-text-visual text-secondary border-top w-100 text-end ">
            {status === "Submit" ? (
              <div
                className=" bg-primary text-light  py-3 text-center piik-text-dashboard piik-pointer "
                onClick={handleSubmit}
              >
                <div className="px-4 fw-bold">{status}</div>
              </div>
            ) : (
              <div className=" bg-secondary text-light  py-3 text-center piik-text-dashboard piik-pointer ">
                <div className="px-4 fw-bold">{status}</div>
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default DataRefresh;
