import React, { useState } from "react";
// hooks
import { useDashData } from "../../hooks/useDash";
import { useAuth0 } from "@auth0/auth0-react";

import { formatDate } from "../Visualization/Charts/Utility";

// Component
import Tab from "../Common/Tab";
import Modal from "../Common/Modal";

const AccountSettings = (props) => {
  const {
    profile,
    adminAccountInfo,
    isModalOpen,
    openModal,
    closeModal,
    handleRevokeApi,
    handleTransferAccount,
  } = useDashData();
  const { user } = useAuth0();

  // handle Tab
  const [tabId, setTabId] = useState("1");
  const handleTab = (id) => {
    setTabId(id);
  };

  // Handle Delete Account
  const [transferAccountDetails, setTransferAccountDetails] = useState({
    sourceEmail: user.email,
    targetEmail: "",
    companyId: "",
  });
  const [accountStatusError, setAccountStatusError] = useState("");
  const handleTransferDetails = (e) => {
    const { id, value } = e.target;
    setTransferAccountDetails((prev) => {
      const updatedData = { ...prev, [id]: value };
      setAccountStatusError("");
      return updatedData;
    });
  };

  const handleModalOpenForTransfer = () => {
    if (transferAccountDetails.sourceEmail === "") {
      setAccountStatusError("Source Email Missing");
      return;
    } else if (transferAccountDetails.targetEmail === "") {
      setAccountStatusError("Target Email Missing");
      return;
    } else if (transferAccountDetails.companyId === "") {
      setAccountStatusError("Company Name missing");
      return;
    } else if (
      transferAccountDetails.sourceEmail === transferAccountDetails.targetEmail
    ) {
      setAccountStatusError(
        "Target Email should be different from source email"
      );
      return;
    } else {
      openModal();
      setPassword({ value: "", errorMessage: "" });
    }
  };

  const handleAccountTransfer = () => {
    if (password.value.trim() === "") {
      setPassword({ ...password, errorMessage: "Password Required" });
      return;
    } else if (password.value.trim() !== user.email) {
      setPassword({ ...password, errorMessage: "Invalid Password..." });
      return;
    }
    if (password.errorMessage === "" && password.value.trim() !== "") {
      setBtnStatus(true);
      handleTransferAccount(transferAccountDetails)
        .then((data) => {
          if (data.status) {
            setBtnStatus(false);
            setTransferAccountDetails({
              sourceEmail: user.email,
              targetEmail: "",
              companyId: "",
            });
            closeModal();
          } else {
            setPassword({ ...password, errorMessage: data.message });
            setBtnStatus(false);
          }
        })
        .catch((error) => {
          setPassword({ ...password, errorMessage: "Error! Please try again" });
          setBtnStatus(false);
        });
    }
  };

  // Handle Delete Account

  const [btnStatus, setBtnStatus] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [password, setPassword] = useState({ value: "", errorMessage: "" });
  const handlePasswordInputChange = (event) => {
    const value = event.target.value;
    setPassword({ errorMessage: "", value: value });
  };
  const handleModalOpenForRevoke = (company) => {
    openModal();
    setPassword({ value: "", errorMessage: "" });
    setSelectedCompany(company);
  };

  const handleRevoke = (company) => {
    if (password.value.trim() === "") {
      setPassword({ ...password, errorMessage: "Password Required" });
      return;
    } else if (password.value.trim() !== user.email) {
      setPassword({ ...password, errorMessage: "Invalid Password..." });
      return;
    }
    if (password.errorMessage === "" && password.value.trim() !== "") {
      setBtnStatus(true);
      handleRevokeApi({ company: company, password: password.value })
        .then(() => {
          setSelectedCompany({});
          setBtnStatus(false);
          closeModal();
        })
        .catch((error) => {
          setPassword({ ...password, errorMessage: "Error! Please try again" });
          setBtnStatus(false);
        });
    }
  };

  return (
    <section className="py-2">
      <div className="d-flex flex-row  bg-light border-bottom">
        <Tab
          type="3c"
          title="Companies"
          tab_id="1"
          status={tabId === "1" ? true : false}
          handleClick={handleTab}
        />
        <Tab
          type="3b"
          title="Transfer Company"
          tab_id="2"
          status={tabId === "2" ? true : false}
          handleClick={handleTab}
        />
      </div>
      {tabId === "1" ? (
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col" className="piik-text-dashboard ">
                #
              </th>
              <th scope="col" className="piik-text-dashboard ">
                Company Name
              </th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col" className="text-center piik-text-dashboard">
                Created at
              </th>
              <th scope="col" className="text-center piik-text-dashboard">
                Last Updated at
              </th>

              <th scope="col" className="text-center piik-text-dashboard px-4">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {adminAccountInfo.map((data, index) => {
              return (
                <tr>
                  <th scope="row" className="piik-text-dashboard ">
                    {index + 1}
                  </th>
                  <td colSpan="4" className="piik-text-dashboard  ">
                    {data.company_name}
                  </td>
                  <td className="text-center piik-text-dashboard ">
                    {formatDate(data.created_at)}
                  </td>
                  <td className="text-center piik-text-dashboard">
                    {formatDate(data.updated_at)}
                  </td>

                  <td
                    className="piik-text-dashboard bg-danger text-center  piik-pointer"
                    onClick={() => handleModalOpenForRevoke(data)}
                  >
                    <span className="bi bi-trash piik-pointer text-white ">
                      <span className="px-2">Disconnect</span>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : tabId === "2" ? (
        <div className="">
          <div className="row bg-light  mx-0 pt-2 pb-4 px-2 piik-dashboard">
            <div className="col-6">
              <div className="piik-text-visual py-1">Target Email</div>
              <input
                type="email"
                className="form-control form-control-sm"
                id="targetEmail"
                placeholder="Enter email address"
                value={transferAccountDetails.targetEmail}
                onChange={handleTransferDetails}
                required
              />
            </div>
            <div className="col-6">
              <div className="piik-text-visual py-1"> Company</div>
              <select
                className="form-select form-select-sm"
                name="text"
                id="companyId"
                value={transferAccountDetails.companyId}
                onChange={handleTransferDetails}
                required
              >
                <option selected value="">
                  Select
                </option>
                {profile &&
                  profile.accounts.map((account) => {
                    return (
                      <option value={account.company_uid}>
                        {account.company_name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between  py-2  align-items-start">
            {accountStatusError !== "" ? (
              <div className="piik-text-warning text-danger">
                {accountStatusError}
              </div>
            ) : (
              <div>.</div>
            )}
            <button
              type="handleSubmit"
              onClick={handleModalOpenForTransfer}
              className="btn btn-primary px-4"
            >
              Transfer
            </button>
          </div>
        </div>
      ) : null}

      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        title={
          tabId === "1" ? `${selectedCompany.company_name}` : "Transfer Account"
        }
      >
        <div className="row mx-0 justify-content-between px-4 pt-2 pb-4 ">
          <div className="col-12 piik-text-warning p-2 mb-2">
            This will permanently remove the company from the{" "}
            <span className="text-danger fw-semibold px-1">{user.email}</span>
            account.
          </div>
          <div className="col px-2">
            <input
              type="email"
              className="form-control form-control-sm"
              id="password"
              placeholder="Please enter the email
                to confirm"
              value={password.value}
              onChange={handlePasswordInputChange}
            />
            {password.errorMessage !== "" && (
              <label className="piik-text-warning text-danger text-capitalize py-1">
                {password.errorMessage}
              </label>
            )}
          </div>
          {tabId === "1" ? (
            <div className="col-2 px-0 ">
              <button
                className={
                  btnStatus
                    ? "btn btn-sm btn-outline-secondary w-100 px-2"
                    : "btn btn-sm btn-outline-primary w-100 px-2"
                }
                onClick={() => handleRevoke(selectedCompany)}
                disabled={btnStatus}
              >
                Submit
              </button>
            </div>
          ) : (
            <div className="col-2 px-0 ">
              <button
                className={
                  btnStatus
                    ? "btn btn-sm btn-outline-secondary w-100 px-2"
                    : "btn btn-sm btn-outline-primary w-100 px-2"
                }
                onClick={handleAccountTransfer}
                disabled={btnStatus}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      </Modal>
    </section>
  );
};
export default AccountSettings;
