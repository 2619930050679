import React, { useEffect, useState, useRef } from "react";

import BottomIcon from "../../Common/BottomIcons";
import ReportTable from "./ReportTable";

import { useDashData } from "../../../hooks/useDash";
import { removeDuplicatesObject } from "../Charts/Utility";

const CreateReport = ({
  fullScreenStatus,
  updateVisualizationIndex,
  handleUpdateVisualizationIndex,
  handleVisualizationTab,
}) => {
  const {
    profile,
    adminAccountInfo,
    account,
    calenderData,
    calenderDatesData,
    handleAddVisualization,
    handleUpdateVisualization,
  } = useDashData();

  const prevBottomSettingsRef = useRef();
  const [selectedAccount, setSelectedAccount] = useState({});
  const [selectedSelectedReportId, setSelectedSelectedReportId] = useState("");

  const [title, setTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedReport, setSelectedReport] = useState({
    record_type: "",
    record_formate: "",
    category: "",
  });
  const [selectedReportEndpoints, setSelectedReportEndpoints] = useState([]);
  const [errorMessageReport, setErrorMessageReport] = useState("");

  const [reportGlCategory, setReportGlCategory] = useState([]);
  const [selectedColumnFilter, setSelectedColumnFilter] = useState([]);
  const [selectedRowFilter, setSelectedRowFilter] = useState({});
  const [dates, setDate] = useState({});

  const [submitStatus, setSubmitStatus] = useState(false);
  const [reportCategories, setReportCategories] = useState([]);
  const [bottomSettings, setBottomSettings] = useState({
    prior: true,
    ytd: true,
    budget: true,
    levelOne: true,
    levelTwo: true,
    levelThree: true,
    zeroValue: true,
  });
  const [localCalendarDate, setLocalCalendarDates] = useState({
    ...calenderData,
  });
  const [selectedDatapointArr, setSelectedDatapointArr] = useState([]);
  const [selectedDatapoint, setSelectedDatapoint] = useState({
    id: "",
    index: 0,
    position: "-1",
  });

  const handleFormateDatapoint = (e) => {
    const { id, value } = e.target;
    if (value === "") {
      return;
    }
    if (id === "datapoint") {
      setSelectedDatapoint({ id: value, index: 0, position: "-1" });
      setSelectedDatapointArr(() => [...selectedDatapointArr, value]);
    } else if (id === "position") {
      setSelectedDatapoint({ ...selectedDatapoint, position: value });
    } else if (id === "index") {
      setSelectedDatapoint({
        ...selectedDatapoint,
        index: parseInt(value) + parseInt(selectedDatapoint.position),
      });
      setSelectedAccount((prev) => {
        let tempData = prev.data;
        tempData = tempData.map((d) => {
          if (d.id === selectedDatapoint.id) {
            return {
              ...d,
              [id]: parseInt(value) + parseInt(selectedDatapoint.position),
            };
          } else {
            return d;
          }
        });
        let updatedData = { ...prev, data: tempData };
        return updatedData;
      });
    } else {
      setSelectedAccount((prev) => {
        let tempData = prev.data;
        tempData = tempData.map((d) => {
          if (d.id === selectedDatapoint.id) {
            return { ...d, [id]: value };
          } else {
            return d;
          }
        });
        let updatedData = { ...prev, data: tempData };
        return updatedData;
      });
    }
  };

  const extractDetailsByNewFromatedIs = (data, idArray) => {
    if (idArray.length === 0) {
      return [];
    }
    let newCredentials = data.filter((d) => idArray.includes(d.id));
    if (newCredentials.length > 0) {
      let selectedParameters = newCredentials.map((d) => ({
        id: d.id,
        created: d.created,
        tags: d.tags,
        level: d.level,
        index: d.index,
      }));
      return selectedParameters;
    } else {
      return [];
    }
  };

  const handleCalendarLocal = (monthIndex, yearIndex) => {
    setLocalCalendarDates((prev) => {
      let temYearIndex = calenderDatesData.findIndex(
        (y) => y.year_index === parseInt(yearIndex)
      );

      let tempMonth = calenderDatesData[temYearIndex].months.find(
        (m) => m.month_index === parseInt(monthIndex)
      );

      let tempData = { ...prev };
      tempData.month_index = parseInt(monthIndex);
      tempData.month = tempMonth.month;
      tempData.year = calenderDatesData[temYearIndex].year;
      tempData.year_index = calenderDatesData[temYearIndex].year_index;
      tempData.status = tempMonth.status;
      tempData.fiscal_month_index = calenderData.fiscal_month_index;
      return tempData;
    });
  };

  const handleUpdate = () => {
    if (title === "") {
      setErrorMessage("Field Data is Missing");
      return;
    }
    if (selectedReport && selectedReport.record_type === "") {
      setErrorMessageReport("Field Data is Missing");
      return;
    }
    setSubmitStatus(true);
    let accountIndex = adminAccountInfo.findIndex(
      (data) => data.uid === account
    );
    let selectedAccountData = adminAccountInfo[accountIndex];
    let requestData = {
      userId: profile.user_id,
      accountId: selectedAccountData.uid,
      reportName: title,
      reportId: selectedSelectedReportId,
      reportSettings: {
        report_type: selectedReport,
        report_endpoints: selectedReportEndpoints,
        bottom_settings: bottomSettings,
        filter: { row: selectedRowFilter, column: selectedColumnFilter },
      },
      createdBy: profile.user_email,
      data: extractDetailsByNewFromatedIs(
        selectedAccount.data,
        selectedDatapointArr
      ),
      visualizationType: "Report",
    };
    handleUpdateVisualization(requestData)
      .then((response) => {
        if (response) {
          handleClear("all");
        }
        handleVisualizationTab(updateVisualizationIndex);
        handleUpdateVisualizationIndex(null);
        setSelectedSelectedReportId("");
        setSubmitStatus(false);
      })
      .catch((error) => {
        setSubmitStatus(false);
      });
  };

  const handleSubmit = () => {
    if (title === "") {
      setErrorMessage("Field Data is Missing");
      return;
    }
    if (selectedReport === "") {
      setErrorMessageReport("Field Data is Missing");
      return;
    }
    setSubmitStatus(true);
    let accountIndex = adminAccountInfo.findIndex(
      (data) => data.uid === account
    );
    let selectedAccountData = adminAccountInfo[accountIndex];
    let requestData = {
      userId: profile.user_id,
      accountId: selectedAccountData.uid,
      reportSettings: {
        report_type: selectedReport,
        report_endpoints: selectedReportEndpoints,
        bottom_settings: bottomSettings,
        filter: { row: selectedRowFilter, column: selectedColumnFilter },
      },
      reportName: title,
      createdBy: profile.user_email,
      data: extractDetailsByNewFromatedIs(
        selectedAccount.data,
        selectedDatapointArr
      ),
      visualizationType: "Report",
    };
    handleAddVisualization(requestData)
      .then((response) => {
        if (response) {
          handleClear("all");
        }
        setSubmitStatus(false);
        handleUpdateVisualizationIndex(null);
      })
      .catch((error) => {
        setSubmitStatus(false);
      });
  };

  const handleDates = (selectedAccount, dates) => {
    const yearIndex = dates.year_index;
    const monthIndex = dates.month_index;

    const selectedYear = calenderDatesData.find(
      (d) => d.year_index === yearIndex
    );
    const currentDate = {
      ...selectedYear.months.find((d) => d.month_index === monthIndex),
      year: yearIndex,
    };
    const yearIndexPr = monthIndex === 0 ? yearIndex - 1 : yearIndex;
    const monthIndexPr = monthIndex === 0 ? 11 : monthIndex - 1;

    const selectedYearPr = calenderDatesData.find(
      (d) => d.year_index === yearIndexPr
    );
    const lastMonthDate = {
      ...selectedYearPr.months.find((d) => d.month_index === monthIndexPr),
      year: yearIndexPr,
    };
    const FY = calenderDatesData[0].months.find(
      (d) =>
        d.month.toLowerCase() ===
        selectedAccount.fiscal_year_start_month.slice(0, 3).toLowerCase()
    );
    return { currentDate, lastMonthDate, FY };
  };

  const handleGeneralLedgerReport = (baseData) => {
    let category = baseData.map((d) => {
      let obj = {
        name: d.name,
        type: d.data_type,
        name_code: d.name_code,
        id: d.id,
        group_by: "sum",
      };
      return obj;
    });
    return [
      {
        name: "Date",
        type: "datetime",
        name_code: "date",
        id: "date_123",
        group_by: "sum",
      },
      ...category,
    ];
  };

  const handleBottomButton = (e) => {
    prevBottomSettingsRef.current = bottomSettings;
    setBottomSettings((prevData) => {
      let updatedData = { ...prevData };
      const name = e.target.getAttribute("name");
      if (name.includes("level")) {
        if (e.target.checked) {
          if (name === "levelOne") {
            updatedData[name] = true;
          } else if (name === "levelTwo") {
            updatedData["levelOne"] = true;
            updatedData[name] = true;
          } else {
            updatedData["levelOne"] = true;
            updatedData["levelTwo"] = true;
            updatedData[name] = true;
          }
        } else {
          if (name === "levelThree") {
            updatedData[name] = false;
          } else if (name === "levelTwo") {
            updatedData["levelThree"] = false;
            updatedData[name] = false;
          } else {
            updatedData["levelThree"] = false;
            updatedData["levelTwo"] = false;
            updatedData[name] = false;
          }
        }
      } else {
        if (e.target.checked) {
          updatedData[name] = true;
        } else {
          updatedData[name] = false;
        }
      }
      return updatedData;
    });
  };

  const handleColumnFilter = (e) => {
    const { id } = e.target;
    if (e.target.checked) {
      setSelectedColumnFilter((prevData) => {
        let tempIndex = reportGlCategory.findIndex((d) => d.id === id);
        const data = [...prevData, reportGlCategory[tempIndex]];

        const dataTimeColumn = data.filter((d) => d.type === "datetime");
        const categoricalColumn = data.filter((d) => d.type === "str");
        const numericalColumn = data.filter(
          (d) => d.type === "int" || d.type === "float"
        );
        const updatedData = [
          ...dataTimeColumn,
          ...categoricalColumn,
          ...numericalColumn,
        ];
        return updatedData;
      });
    } else {
      setSelectedColumnFilter((prevData) => {
        let tempIndex = prevData.findIndex((d) => d.id === id);
        let dataType = prevData[tempIndex].type;
        if (dataType === "str") {
          const tempIndexCat = prevData.filter(
            (d) =>
              d.type === "str" &&
              d.name !== "Date" &&
              d.name !== selectedRowFilter.name
          );
          if (tempIndexCat.length >= 2) {
            const data = prevData.filter((d) => d.id !== id);
            return data;
          } else {
            return prevData;
          }
        } else {
          const tempIndexNum = prevData.filter(
            (d) => d.type === "int" || d.type === "float"
          );
          if (tempIndexNum.length >= 2) {
            const data = prevData.filter((d) => d.id !== id);
            return data;
          } else {
            return prevData;
          }
        }
      });
    }
  };

  const handleRowFilter = (e) => {
    const { id } = e.target;
    if (e.target.checked) {
      if (selectedReport.record_type === "IV") {
        setSelectedReportEndpoints((prevData) => {
          const data = [...prevData, { id: id }];
          return data;
        });
      } else {
        setSelectedReportEndpoints((prevData) => {
          const data = selectedAccount.data.filter((d) => d.id === id);
          const updateData = [
            ...prevData,
            ...data.map((d) => ({
              id: d.id,
            })),
          ];
          return updateData;
        });
      }
    } else {
      setSelectedReportEndpoints((prevData) => {
        const data = prevData.filter((d) => d.id !== id);
        return data;
      });
    }
  };

  const handleInput = (e) => {
    const { id, value } = e.target;
    if (value === "") {
      return;
    }
    if (id === "title") {
      if (title.length > 25) {
        const truncatedValue = value.slice(0, 25);
        setTitle(truncatedValue);
        setErrorMessage("Maximum character limit reached");
      } else {
        setTitle(value);
        setErrorMessage("");
      }
    } else if (id === "rowfilter") {
      const tempFilterIndex = reportGlCategory.findIndex(
        (d) => d.name_code === value
      );
      setSelectedRowFilter(reportGlCategory[tempFilterIndex]);
      if (value !== "") {
        setSelectedReportEndpoints(() => {
          let temp = [
            ...new Set(
              selectedAccount.data
                .filter((d) => d.name_code === value)[0]
                .data.map((obj) => ({
                  id: obj.value,
                }))
            ),
          ];
          let result = Array.from(
            new Map(temp.map((obj) => [obj.id, obj])).values()
          );

          return result;
        });
      } else {
        setSelectedReportEndpoints([]);
      }
    } else if (id === "report") {
      const record_type_temp_index = reportCategories.findIndex(
        (d) => d.category === value
      );
      const record = reportCategories[record_type_temp_index];
      setSelectedReport({
        category: record.category,
        record_type: record.record_type,
      });
      setSelectedReportEndpoints([]);
      setSelectedRowFilter({});
      setErrorMessageReport("");

      if (record && record.record_type === "IV") {
        setReportGlCategory(() => {
          let selectedBaseData = selectedAccount.data.filter(
            (d) => d.category === value
          );
          let category = handleGeneralLedgerReport(selectedBaseData);
          const dataTimeColumn = category.filter((d) => d.type === "datetime");
          const categoricalColumn = category.filter((d) => d.type === "str");
          const numericalColumn = category.filter(
            (d) => d.type === "int" || d.type === "float"
          );
          const updatedData = [
            ...dataTimeColumn,
            ...categoricalColumn,
            ...numericalColumn,
          ];
          setSelectedColumnFilter(updatedData);
          return updatedData;
        });
      } else {
        setSelectedReportEndpoints(() => {
          const data = selectedAccount.data
            .filter((d) => d.category === value)
            .map((d) => ({
              id: d.id,
            }));
          return data;
        });
      }
      setErrorMessage("");
    } else if (id === "reportFormate") {
      setSelectedReport((prev) => {
        return { ...prev, record_formate: value };
      });
    }
  };

  const handleOperator = (e) => {
    const { id, value } = e.target;
    setSelectedColumnFilter((prevData) => {
      let newData = [...prevData];
      let tempIndexFromReportGL = reportGlCategory.findIndex(
        (d) => d.id === id
      );
      let tempIndexFromSelectedReportGL = newData.findIndex((d) => d.id === id);
      if (tempIndexFromSelectedReportGL > -1) {
        newData[tempIndexFromSelectedReportGL] = {
          ...reportGlCategory[tempIndexFromReportGL],
          group_by: value === "" ? "sum" : value,
        };
        return newData;
      } else {
        newData = [
          ...newData,
          {
            ...reportGlCategory[tempIndexFromReportGL],
            group_by: value,
          },
        ];
        return newData;
      }
    });
  };

  const handleClear = (id) => {
    if (id === "all") {
      setTitle("");
      setSelectedDatapoint({
        id: "",
        index: 0,
        position: "-1",
      });
      setSelectedDatapointArr([]);
      setSelectedReport({
        record_type: "",
        category: "",
      });
      setSelectedReportEndpoints([]);
      setBottomSettings({
        prior: true,
        ytd: true,
        budget: true,
        levelOne: true,
        levelTwo: true,
        levelThree: true,
        zeroValue: true,
      });
    } else if (id === "rep") {
      setSelectedReport({
        record_type: "",
        category: "",
      });
      setSelectedReportEndpoints([]);
      setBottomSettings({
        prior: true,
        ytd: true,
        budget: true,
        levelOne: true,
        levelTwo: true,
        levelThree: true,
        zeroValue: true,
      });
    }
  };

  useEffect(() => {
    let currentMonthIndex = calenderData.month_index;
    let currentYearIndex = calenderData.year_index;
    handleCalendarLocal(currentMonthIndex, currentYearIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calenderData]);

  useEffect(() => {
    const accountIndex = adminAccountInfo.findIndex(
      (data) => data.uid === account
    );
    const selectedAccountData = adminAccountInfo[accountIndex];
    setSelectedAccount(selectedAccountData);
    setReportCategories(() => {
      const result = removeDuplicatesObject(
        selectedAccountData.data,
        "category"
      );

      return result;
    });
    setDate(() => {
      const { currentDate, lastMonthDate, FY } = handleDates(
        selectedAccountData,
        localCalendarDate
      );
      return { currentDate, lastMonthDate, FY };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAccountInfo, account, localCalendarDate]);

  useEffect(() => {
    if (updateVisualizationIndex !== null) {
      const accountIndex = adminAccountInfo.findIndex(
        (data) => data.uid === account
      );
      const selectedAccountData = { ...adminAccountInfo[accountIndex] };
      let newSelectedAccounts =
        selectedAccountData.report_id[updateVisualizationIndex];

      if (newSelectedAccounts && newSelectedAccounts.settings) {
        setSelectedReport(newSelectedAccounts.settings.report_type);
        setSelectedReportEndpoints(
          newSelectedAccounts.settings.report_endpoints
        );
        setBottomSettings(newSelectedAccounts.settings.bottom_settings);
        setSelectedSelectedReportId(newSelectedAccounts.id);
        setTitle(newSelectedAccounts.name);

        setSelectedRowFilter(newSelectedAccounts.settings.filter.row);
        setSelectedColumnFilter(newSelectedAccounts.settings.filter.column);

        if (newSelectedAccounts.settings.report_type.record_type === "IV") {
          setReportGlCategory(() => {
            let selectedBaseData = selectedAccountData.data.filter(
              (d) =>
                d.category === newSelectedAccounts.settings.report_type.category
            );
            let category = handleGeneralLedgerReport(selectedBaseData);
            return category;
          });
        }
      } else {
        handleUpdateVisualizationIndex(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAccountInfo, account]);

  return (
    <>
      <div
        className={
          fullScreenStatus
            ? "d-flex flex-row justify-content-between  bg-light piik-visual-section-fullscreen piik-create-chart "
            : "d-flex flex-row justify-content-between  bg-light piik-visual-section piik-create-chart "
        }
      >
        <div className="flex-grow-1 overflow-scroll">
          <div className="d-flex flex-column  h-100 w-100 ">
            <div className="flex-grow-1 w-100  h-100 d-flex flex-column ">
              <div className="d-flex flex-row justify-content-between  align-items-center bg-white px-4 py-2 shadow-sm">
                <div className="piik-text-dashboard  text-primary">{title}</div>
                <i className="bi bi-info-circle text-primary"></i>
              </div>
              {selectedReportEndpoints.length > 0 && (
                <ReportTable
                  baseData={selectedAccount.data.filter(
                    (d) => d.category === selectedReport.category
                  )}
                  data={selectedReportEndpoints}
                  selectedAccount={selectedAccount}
                  bottomSettings={bottomSettings}
                  reportType={selectedReport}
                  rowFilter={selectedRowFilter}
                  columnFilter={selectedColumnFilter}
                  dates={dates}
                  calenderData={localCalendarDate}
                  calenderDatesData={calenderDatesData}
                  handleCalendarLocal={handleCalendarLocal}
                  handleTableData={null}
                />
              )}
            </div>
          </div>
        </div>
        <div
          className="  bg-light shadow-sm d-flex flex-column justify-content-start w-25 piik-create-bar "
          style={{ zIndex: 10 }}
        >
          <div className=" px-3 py-2 piik-text-dashboard border-bottom ">
            <div className=" d-flex flex-row justify-content-between align-items-center">
              <div className="fw-semibold text-primary"> Set Title</div>
              <i
                className="bi bi-eraser piik-pointer"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Clear all"
                id="all"
                onClick={() => handleClear("all")}
              ></i>
            </div>
            <div className="my-2">
              <input
                type="text"
                className="form-control form-control-sm w-100"
                id="title"
                placeholder="Max 25 Character"
                value={title}
                onChange={handleInput}
              />
              {errorMessage !== "" && (
                <div className="pt-1 text-danger piik-text-warning">
                  {errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className=" px-3 py-2 piik-text-dashboard border-bottom  shadow-sm">
            <div className=" d-flex flex-row justify-content-between align-items-center">
              <div className="fw-semibold text-primary"> Datapoint</div>
              <i
                className="bi bi-eraser piik-pointer"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Clear Report"
                id="rep"
                onClick={() => handleClear("rep")}
              ></i>
            </div>

            <div className="my-2">
              <select
                className="form-select form-select-sm w-100 "
                id="report"
                value={selectedReport.category}
                onChange={handleInput}
              >
                <option selected value="">
                  Select
                </option>
                {reportCategories.length !== 0 &&
                  reportCategories
                    .filter((d) => d.record_type !== "I")
                    .map((data) => {
                      return (
                        <option key={data.category} value={data.category}>
                          <span className="text-uppercase">
                            {data.category}
                          </span>
                        </option>
                      );
                    })}
              </select>
              {errorMessageReport !== "" && (
                <div className="pt-1 text-danger piik-text-warning">
                  {errorMessageReport}
                </div>
              )}
            </div>
            {selectedReport.record_type !== "IV" ? (
              <>
                <div className=" d-flex flex-row justify-content-between align-items-center">
                  <div className="fw-semibold text-primary">Report Formate</div>
                  <i
                    className="bi bi-eraser piik-pointer"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Clear Report"
                    id="rep"
                    onClick={() => handleClear("rep")}
                  ></i>
                </div>

                <div className="my-2">
                  <select
                    className="form-select form-select-sm w-100 "
                    id="reportFormate"
                    value={selectedReport.record_formate}
                    onChange={handleInput}
                  >
                    <option selected value="">
                      Select
                    </option>
                    <option selected value="I">
                      Income Statement
                    </option>
                    <option selected value="II">
                      Balance Sheet
                    </option>
                  </select>
                  {errorMessageReport !== "" && (
                    <div className="pt-1 text-danger piik-text-warning">
                      {errorMessageReport}
                    </div>
                  )}
                </div>
              </>
            ) : null}
          </div>
          {selectedReport && selectedReport.record_type === "IV" ? (
            <>
              <div className=" px-3 py-2 piik-text-dashboard border-bottom">
                <div className=" d-flex flex-row justify-content-between align-items-center">
                  <div className="fw-semibold text-primary">Add Row Filter</div>
                </div>
                <div className="my-2">
                  <select
                    className="form-select form-select-sm w-100 "
                    id="rowfilter"
                    value={selectedRowFilter.name_code}
                    onChange={handleInput}
                  >
                    <option selected value="">
                      Select
                    </option>
                    {reportGlCategory.length !== 0 &&
                      reportGlCategory
                        .filter((d) => d.type === "str")
                        .map((data) => {
                          return (
                            <option key={data.name} value={data.name_code}>
                              <span className="text-uppercase">
                                {data.name}
                              </span>
                            </option>
                          );
                        })}
                  </select>
                  {errorMessageReport !== "" && (
                    <div className="pt-1 text-danger piik-text-warning">
                      {errorMessageReport}
                    </div>
                  )}
                </div>
              </div>

              {selectedAccount &&
                selectedAccount.data &&
                selectedAccount.data.length > 0 &&
                selectedRowFilter &&
                selectedAccount.data
                  .filter((d) => d.category === selectedReport.category)
                  .filter((d) => d.name_code === selectedRowFilter.name_code)
                  .map((d, index) => {
                    return (
                      <div className="px-4 py-2 piik-text-visual overflow-y-scroll h-25">
                        {[...new Set(d.data.map((obj) => obj.value))]
                          .filter((d) => d !== "")
                          .map((endpoint) => {
                            return (
                              <div className="mb-2 d-flex flex-row justify-content-between">
                                <div>{endpoint}</div>

                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id={endpoint}
                                    checked={
                                      selectedReportEndpoints.findIndex(
                                        (d) => d.id === endpoint
                                      ) > -1
                                        ? true
                                        : false
                                    }
                                    onChange={handleRowFilter}
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}

              {selectedRowFilter && selectedRowFilter.name && (
                <div className=" px-3 py-2 piik-text-dashboard border-bottom border-top ">
                  <div className=" d-flex flex-row justify-content-between align-items-center">
                    <div className="fw-semibold text-primary">
                      Add Column Filter
                    </div>
                  </div>
                </div>
              )}
              {selectedRowFilter && selectedRowFilter.name && (
                <div className="px-4 py-2 piik-text-visual overflow-y-scroll">
                  {reportGlCategory.length !== 0 &&
                    reportGlCategory
                      .filter(
                        (d) =>
                          d.name_code !== "date" &&
                          d.name_code !== selectedRowFilter.name_code
                      )

                      .map((d, index) => {
                        return (
                          <div className="mb-3 d-flex flex-row justify-content-between align-items-center">
                            <div> {d.name}</div>
                            <div className=" d-flex flex-row justify-content-end align-items-center">
                              <div className=" px-2">
                                {d.type !== "str" ? (
                                  <select
                                    className="form-select form-select-sm w-100 "
                                    id={d.id}
                                    value={selectedColumnFilter.group_by}
                                    onChange={handleOperator}
                                  >
                                    <option value="">Select</option>
                                    <option value="sum">Sum</option>
                                    <option value="mean">Average</option>
                                    <option value="max">Max</option>
                                    <option value="min">Min</option>
                                  </select>
                                ) : null}
                              </div>
                              <div className="form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id={d.id}
                                  checked={
                                    selectedColumnFilter.length > 0 &&
                                    selectedColumnFilter.findIndex(
                                      (x) => x.id === d.id
                                    ) > -1
                                      ? true
                                      : false
                                  }
                                  onChange={handleColumnFilter}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                </div>
              )}
            </>
          ) : (
            <>
              <div className="px-3 py-2 d-flex flex-row justify-content-between align-items-center piik-text-dashboard bg-white shadow-sm  ">
                <div className="fw-semibold text-primary">
                  Edit Datapoint Format
                </div>
              </div>
              <div className=" px-3 piik-text-visual shadow-sm py-2 h-100 overflow-y-scroll ">
                <div className="mt-2 fw-semibold">Datapoint</div>
                <select
                  className="form-select form-select-sm w-100 "
                  id="datapoint"
                  value={selectedDatapoint.id}
                  onChange={handleFormateDatapoint}
                >
                  <option selected value="">
                    Select
                  </option>
                  {selectedAccount.data &&
                    selectedAccount.data.length > 0 &&
                    selectedAccount.data
                      .filter((d) => d.category === selectedReport.category)
                      .map((endpoint) => {
                        return (
                          <option selected value={endpoint.id}>
                            {endpoint.name}
                          </option>
                        );
                      })}
                </select>
                <div className="mt-2 fw-semibold">Tags</div>
                <select
                  className="form-select form-select-sm w-100 mt-1"
                  id="tags"
                  value={
                    (selectedDatapoint.id !== "" &&
                      selectedAccount.data.find(
                        (d) => d.id === selectedDatapoint.id
                      ).tags) ||
                    ""
                  }
                  onChange={handleFormateDatapoint}
                  disabled={selectedDatapoint.id === "" ? true : false}
                >
                  <option selected value="">
                    Select Tags
                  </option>
                  <option value="H">Header</option>
                  <option value="R">Rows</option>
                  <option value="S">Summary</option>
                </select>
                <div className="mt-2 fw-semibold">Indentation level</div>
                <select
                  className="form-select form-select-sm w-100 mt-1"
                  id="level"
                  value={
                    (selectedDatapoint.id !== "" &&
                      selectedAccount.data.find(
                        (d) => d.id === selectedDatapoint.id
                      ).level) ||
                    ""
                  }
                  onChange={handleFormateDatapoint}
                  disabled={selectedDatapoint.id === "" ? true : false}
                >
                  <option selected value="">
                    Select Levels
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>

                <div className="mt-2 fw-semibold">Position</div>
                <div className="d-flex flex-row justify-content-between align-items-center mt-1">
                  <select
                    className="form-select form-select-sm me-1"
                    id="position"
                    value={selectedDatapoint.position}
                    onChange={handleFormateDatapoint}
                    disabled={selectedDatapoint.id === "" ? true : false}
                  >
                    <option value="">Select</option>
                    <option value="1">Below</option>
                    <option value="-1">Above</option>
                  </select>

                  <select
                    className="form-select form-select-sm"
                    id="index"
                    value={selectedDatapoint.index}
                    onChange={handleFormateDatapoint}
                    disabled={selectedDatapoint.id === "" ? true : false}
                  >
                    <option selected value="">
                      Select Levels
                    </option>
                    {selectedAccount.data &&
                      selectedAccount.data.length > 0 &&
                      selectedAccount.data
                        .filter((d) => d.category === selectedReport.category)
                        .map((endpoint) => {
                          return (
                            <option value={endpoint.index}>
                              {endpoint.name}
                            </option>
                          );
                        })}
                  </select>
                </div>
              </div>
              <div className="px-3 py-2 d-flex flex-row justify-content-between align-items-center piik-text-dashboard bg-white  shadow-sm">
                <div className="fw-semibold text-primary">
                  Add | Remove Datapoint
                </div>
              </div>
              <div className=" px-3 py-2 piik-text-visual overflow-y-scroll h-50">
                {selectedAccount &&
                  selectedAccount.data &&
                  selectedAccount.data.length > 0 &&
                  selectedAccount.data
                    .filter((d) => d.category === selectedReport.category)
                    .filter((d) => d.tags !== "H")
                    .map((endpoint, index) => {
                      return (
                        <div className="mb-3">
                          <div className="d-flex flex-row justify-content-between align-self-center mb-2">
                            <div
                              className={`piik-text-visual  text-capitalize ${
                                endpoint.tags === "H" ? "fw-semibold" : null
                              }`}
                            >
                              {endpoint.name}
                              {endpoint.created && endpoint.created === "custom"
                                ? " *"
                                : null}
                            </div>
                            <div
                              className="form-check form-switch "
                              style={{ minHeight: "0rem" }}
                            >
                              {endpoint.tags === "H" ? null : (
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id={endpoint.id}
                                  checked={
                                    selectedReportEndpoints.findIndex(
                                      (d) => d.id === endpoint.id
                                    ) > -1
                                      ? true
                                      : false
                                  }
                                  onChange={handleRowFilter}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <div className="flex-grow-1 bg-light">
          <div className="d-flex flex-row align-items-center justify-content-between pt-2  border-top px-4">
            {selectedReport.record_type === "IV" ? (
              <div className="d-flex flex-row align-items-center piik-text-visual  justify-content-between w-100">
                <div className="d-flex flex-row align-items-center">
                  <BottomIcon
                    status={bottomSettings.levelOne}
                    name="Level-I"
                    type="ancillaryChart"
                    code="levelOne"
                    handleChange={handleBottomButton}
                  />
                  <BottomIcon
                    status={bottomSettings.levelTwo}
                    name="Level-II"
                    type="ancillaryChart"
                    code="levelTwo"
                    handleChange={handleBottomButton}
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex flex-row align-items-center justify-content-between w-100 ">
                <div className="d-flex flex-row align-items-center">
                  <BottomIcon
                    status={bottomSettings.levelOne}
                    name="Level-I"
                    type="ancillaryChart"
                    code="levelOne"
                    handleChange={handleBottomButton}
                  />
                  <BottomIcon
                    status={bottomSettings.levelTwo}
                    name="Level-II"
                    type="ancillaryChart"
                    code="levelTwo"
                    handleChange={handleBottomButton}
                  />
                  <BottomIcon
                    status={bottomSettings.levelThree}
                    name="Level-III"
                    type="ancillaryChart"
                    code="levelThree"
                    handleChange={handleBottomButton}
                  />
                </div>
                {selectedReport && selectedReport.record_formate === "I" ? (
                  <div className="d-flex flex-row align-items-center px-2 mx-2">
                    <BottomIcon
                      status={bottomSettings.budget}
                      name="Budget"
                      type="ancillaryChart"
                      code="budget"
                      handleChange={handleBottomButton}
                    />
                    <BottomIcon
                      status={bottomSettings.prior}
                      name="Prior Year"
                      type="ancillaryChart"
                      code="prior"
                      handleChange={handleBottomButton}
                    />
                    <BottomIcon
                      status={bottomSettings.ytd}
                      name="Year To Date"
                      type="ancillaryChart"
                      code="ytd"
                      handleChange={handleBottomButton}
                    />
                    <BottomIcon
                      status={bottomSettings.zeroValue}
                      name="Hide Zero Value"
                      type="ancillaryChart"
                      code="zeroValue"
                      handleChange={handleBottomButton}
                    />
                  </div>
                ) : (
                  <div className="px-4 mx-4 flex-grow-1 text-end">
                    <BottomIcon
                      status={bottomSettings.zeroValue}
                      name="Hide Zero Value"
                      type="ancillaryChart"
                      code="zeroValue"
                      handleChange={handleBottomButton}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          className="  bg-light shadow-sm d-flex flex-column justify-content-between w-25 piik-create-bar "
          style={{ zIndex: 10 }}
        >
          {submitStatus ? (
            <div className="p-2 piik-text-primary bg-dark-subtle text-center text-white piik-pointer">
              Processing
            </div>
          ) : updateVisualizationIndex === null ? (
            <div
              className="p-2 piik-text-dashboard bg-primary text-center text-white piik-pointer"
              onClick={handleSubmit}
            >
              Save
            </div>
          ) : (
            <div
              className="p-2 piik-text-dashboard bg-primary text-center text-white piik-pointer"
              onClick={handleUpdate}
            >
              Update
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateReport;
