import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
// Hooks
import { DashProvider } from "../hooks/useDash";

// Component
import SideBar from "../Components/SideBar/SideBar";
import MainContent from "../Components/Common/MainContent";
import ProgressBar from "../Components/Common/ProgressBar";

const Dashboard = () => {
  return (
    <DashProvider>
      <div className="piik-home-container">
        <SideBar />
        <MainContent />
      </div>
    </DashProvider>
  );
};

export default withAuthenticationRequired(Dashboard, {
  onRedirecting: () => <ProgressBar />,
});
