import React from "react";

const Tab = (props) => {
  const handleTab = () => {
    if (props.type === "3a") {
      props.handleClick(props.tab_id);
    } else {
      props.handleClick(props.tab_id, props.subTab_id, props.tertiaryTab_id);
    }
  };
  return props.status ? (
    <div className="bg-primary  px-4 py-2 piik-pointer">
      <div className="text-light piik-text-dashboard  ">{props.title}</div>
    </div>
  ) : (
    <div className="bg-light px-4 py-2 piik-pointer  " onClick={handleTab}>
      <div className="text-primary piik-text-dashboard ">{props.title}</div>
    </div>
  );
};

export default Tab;
