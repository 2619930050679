import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Modal from "../../Common/Modal";

import { useDashData } from "../../../hooks/useDash";
import DisplayBottomIcon from "../DisplayBottomIcon";
import EndpointTable from "./EndpointTable";

const DisplayEndpoints = ({
  index,
  fullScreenStatus,
  handleVisualizationTab,
  handleUpdateVisualizationIndex,
}) => {
  const {
    profile,
    adminAccountInfo,
    guestAccountInfo,
    account,
    calenderData,
    isModalOpen,
    openModal,
    closeModal,
    handleDeleteVisulization,
  } = useDashData();

  const { user } = useAuth0();
  const [selectedAccount, setSelectedAccount] = useState({});
  const [endPoint, setEndpoint] = useState({});

  const [password, setPassword] = useState({ value: "", errorMessage: "" });
  const [btn, setBtnState] = useState(false);

  const handlePasswordInputChange = (event) => {
    const value = event.target.value;
    setPassword({ errorMessage: "", value: value });
  };

  const handleDeleteReport = async () => {
    setBtnState(true);
    if (password.value !== user.email) {
      setPassword({ errorMessage: "Invalid email", value: "" });
      return;
    } else {
      let deletedDataInfo;
      if (endPoint.action === "query") {
        deletedDataInfo = {
          userId: profile.user_id,
          accountId: selectedAccount.uid,
          endpointId: endPoint.id,
          category: endPoint.name,
          visualizationType: "EndpointQuery",
        };
      } else if (endPoint.action === "cloud") {
        deletedDataInfo = {
          userId: profile.user_id,
          accountId: selectedAccount.uid,
          endpointId: endPoint.id,
          category: endPoint.name,
          visualizationType: "cloudEndpoint",
        };
      } else if (endPoint.action === "upload") {
        deletedDataInfo = {
          userId: profile.user_id,
          accountId: selectedAccount.uid,
          category: endPoint.name,
          visualizationType: "CsvData",
        };
      } else {
        deletedDataInfo = {
          userId: profile.user_id,
          accountId: selectedAccount.uid,
          endpointId: endPoint.id,
          visualizationType: "Endpoint",
        };
      }

      handleDeleteVisulization(deletedDataInfo)
        .then((response) => {
          if (response) {
            setBtnState(false);
            setEndpoint({});
            setPassword({
              errorMessage: "",
              value: "",
            });
            handleVisualizationTab(0);

            closeModal();
          }
        })
        .catch((error) => {
          setBtnState(false);
        });
    }
  };

  const handleEditChart = () => {
    handleUpdateVisualizationIndex(index);
    handleVisualizationTab(100);
  };

  useEffect(() => {
    let CombinedChartData = adminAccountInfo.concat(guestAccountInfo);
    const accountIndex = CombinedChartData.findIndex(
      (data) => data.uid === account
    );
    let accountData = CombinedChartData[accountIndex];
    setSelectedAccount(accountData);
    const selectedAccountData = JSON.parse(
      JSON.stringify(CombinedChartData[accountIndex])
    );
    let newSelectedAccounts = selectedAccountData.custom_data[index];
    if (newSelectedAccounts && newSelectedAccounts.action) {
      setEndpoint(newSelectedAccounts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAccountInfo, account, calenderData, index]);

  return (
    endPoint &&
    endPoint.action && (
      <>
        <div
          className={
            fullScreenStatus
              ? "d-flex flex-row justify-content-between  bg-light piik-visual-section-fullscreen piik-create-chart "
              : "d-flex flex-row justify-content-between  bg-light piik-visual-section piik-create-chart "
          }
        >
          <div className="flex-grow-1 overflow-y-scroll">
            <div className="d-flex flex-column  h-100 w-100 ">
              {endPoint && endPoint.action && endPoint.action === "group" ? (
                <EndpointTable
                  action={endPoint.action}
                  selectedAccount={selectedAccount}
                  selectedReportEndpoints={endPoint.data_endpoints}
                  calenderData={calenderData}
                />
              ) : endPoint && endPoint.action && endPoint.action === "new" ? (
                <EndpointTable
                  action={endPoint.action}
                  selectedAccount={selectedAccount}
                  customEndpoint={endPoint.data_endpoints}
                  calenderData={calenderData}
                  formula={endPoint.formula}
                />
              ) : endPoint && endPoint.action && endPoint.action === "query" ? (
                <EndpointTable
                  action={endPoint.action}
                  selectedAccount={selectedAccount}
                  customEndpoint={endPoint}
                  calenderData={calenderData}
                  type="display"
                />
              ) : endPoint && endPoint.action && endPoint.action === "cloud" ? (
                <EndpointTable
                  action={endPoint.action}
                  selectedAccount={selectedAccount}
                  customEndpoint={{
                    query_endpoints: endPoint && endPoint.endpoints,
                    category: endPoint && endPoint.authentication_details,
                  }}
                />
              ) : endPoint &&
                endPoint.action &&
                endPoint.action === "upload" ? (
                <EndpointTable
                  action={endPoint.action}
                  fileType={endPoint.settings.type}
                  customEndpoint={
                    endPoint.settings.type === "others"
                      ? endPoint.settings.header
                      : endPoint.settings.datapoint
                  }
                />
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-row justify-content-between  bg-light border-top align-self-center"
          style={{ zIndex: 2 }}
        >
          <div className="py-2 piik-text-visual text-primary  px-4">
            Category : <span className="text-uppercase">{endPoint.action}</span>
          </div>
          <div className="d-flex flex-row justify-content-end">
            {profile.user_id === selectedAccount.owner_id ? (
              <>
                <DisplayBottomIcon
                  title="Edit Chart"
                  icon="bi-pencil-square"
                  handleIconAction={handleEditChart}
                />
                <DisplayBottomIcon
                  title="Delete Report"
                  icon="bi-trash"
                  handleIconAction={openModal}
                />
              </>
            ) : null}
            <DisplayBottomIcon
              title="Report Description"
              icon="bi-info-circle"
              status={false}
            />
          </div>
          <Modal
            isOpen={isModalOpen}
            closeModal={closeModal}
            title={endPoint.name}
          >
            {isModalOpen && selectedAccount ? (
              <div className="row mx-0 justify-content-between px-4 pt-2 pb-4 ">
                <div className="col-12 piik-text-warning px-2 pt-2 pb-4">
                  This will permanently delete the data(also the
                  charts,PVM,reports where the data endpoint is used) from the
                  <span className="text-danger fw-semibold px-1">
                    {user.email}
                  </span>{" "}
                  account
                </div>
                <div className="col px-2 ">
                  <input
                    type="email"
                    className="form-control form-control-sm"
                    id="password"
                    placeholder="Please enter the email
                  to confirm"
                    value={password.value}
                    onChange={handlePasswordInputChange}
                  />
                  {password.errorMessage !== "" && (
                    <label className="piik-text-warning text-danger text-capitalize py-1">
                      {password.errorMessage}
                    </label>
                  )}
                </div>

                <div className="col-2 px-0">
                  <button
                    className="btn btn-sm btn-outline-primary w-100 px-2"
                    onClick={handleDeleteReport}
                    disabled={btn}
                  >
                    {btn ? (
                      <span className="piik-text-icon text-warning">
                        Processing
                      </span>
                    ) : (
                      "Delete"
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mx-0 justify-content-between px-4 pt-2 pb-4 ">
                <div className="col-12 piik-text-dashboard px-2 pt-2 pb-4">
                  Sorry you are not allowed to delete all the Report. Add a new
                  report first to clear the selected report
                </div>
              </div>
            )}
          </Modal>
        </div>
      </>
    )
  );
};

export default DisplayEndpoints;
