import React from "react";

export default function DisplayBottomIcon({
  handleIconAction,
  title,
  icon,
  status = true,
}) {
  return status ? (
    <div
      className="px-3 py-1 bg-primary piik-pointer border-end"
      onClick={handleIconAction}
    >
      <i
        className={`"bi ${icon} text-white piik-text-dashboard p-0 m-0`}
        data-toggle="tooltip"
        data-placement="top"
        title={title}
      ></i>
    </div>
  ) : (
    <div className="px-3 py-1 bg-secondary piik-pointer border-end">
      <i
        className={`"bi ${icon} text-white piik-text-dashboard p-0 m-0`}
        data-toggle="tooltip"
        data-placement="top"
        title={title}
      ></i>
    </div>
  );
}
