import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import Modal from "../Common/Modal";

// Hooks
import { useDashData } from "../../hooks/useDash";

// import Components
import ContentHeader from "./ContentHeader";
import AddAccount from "../Settings/AddAccount";
import CompanyCard from "../Visualization/CompanyCard.jsx";
import AccountSettings from "../Settings/AccountSettings";
import UsersDetails from "../Settings/UsersDetails.jsx";
import Subscription from "../Stripe/SubscriptionDetails";
import DataSettings from "../Settings/DataSettings.jsx";
import Visualization from "../Visualization/Visualization";
import ProgressBar from "./ProgressBar";

//  Error Bouuntry Fallback
import FallBackRender from "../Common/ErrorBoundry";

const MainContent = () => {
  const {
    adminAccountInfo,
    guestAccountInfo,
    navigation,
    account,
    contentOption,
    handleContentTab,
    handleAccount,
  } = useDashData();
  const { getValue, setValue } = useLocalStorage();
  const [modalStatus, setModalStatus] = useState({ state: false, comment: "" });

  let tabHeaderContent =
    contentOption.tab_id !== ""
      ? navigation.find((content) => content.tab_id === contentOption.tab_id)
      : null;

  const VisualizationContent =
    tabHeaderContent !== null
      ? adminAccountInfo
          .concat(guestAccountInfo)
          .find((content) => content.uid === contentOption.subTab_id)
      : null;

  const [visualizationTab, setVisualizationTab] = useState(0);
  const handleVisualizationTab = (id) => {
    setVisualizationTab(id);
  };
  const handleModalClosed = () => {
    setValue("AddAccountModalState", {
      state: false,
      comment: "",
    });
    setModalStatus({ state: false, comment: "" });
  };

  useEffect(() => {
    let updatedModalStatus = getValue("AddAccountModalState");
    if (
      updatedModalStatus !== undefined &&
      (modalStatus.state !== updatedModalStatus.state ||
        modalStatus.comment !== updatedModalStatus.comment)
    ) {
      setModalStatus(() => updatedModalStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValue("AddAccountModalState")]);

  return (
    <div className="piik-home-main-content px-4 py-2">
      <div className="piik-home-main-content-body">
        {/* Header */}
        <ContentHeader />
        {/* Body */}
        {tabHeaderContent === null ? (
          <ErrorBoundary FallbackComponent={FallBackRender}>
            <ProgressBar />
          </ErrorBoundary>
        ) : tabHeaderContent !== null &&
          tabHeaderContent.tab_id === "4" &&
          adminAccountInfo.length === 0 &&
          guestAccountInfo.length === 0 ? (
          <div className="py-2">
            <ErrorBoundary FallbackComponent={FallBackRender}>
              <Subscription />
            </ErrorBoundary>
          </div>
        ) : tabHeaderContent !== null &&
          tabHeaderContent.tab_id === "4" &&
          (adminAccountInfo.length > 0 || guestAccountInfo.length > 0) ? (
          <ErrorBoundary fallbackRender={FallBackRender}>
            <CompanyCard
              handleContentTab={handleContentTab}
              handleAccount={handleAccount}
            />
          </ErrorBoundary>
        ) : tabHeaderContent !== null && tabHeaderContent.tab_id === "1" ? (
          <ErrorBoundary FallbackComponent={FallBackRender}>
            <AddAccount />
          </ErrorBoundary>
        ) : tabHeaderContent !== null && tabHeaderContent.tab_id === "2" ? (
          <ErrorBoundary FallbackComponent={FallBackRender}>
            <Visualization
              navigationData={navigation}
              account={account}
              selectedContentOption={contentOption}
              handleContentTab={handleContentTab}
              visualizationContent={VisualizationContent}
              visualizationTab={visualizationTab}
              handleVisualizationTab={handleVisualizationTab}
            />
          </ErrorBoundary>
        ) : tabHeaderContent !== null && tabHeaderContent.tab_id === "3" ? (
          <>
            {contentOption.subTab_id === "1" ? (
              <ErrorBoundary FallbackComponent={FallBackRender}>
                <DataSettings />
              </ErrorBoundary>
            ) : contentOption.subTab_id === "2" ? (
              <ErrorBoundary FallbackComponent={FallBackRender}>
                <AccountSettings />
              </ErrorBoundary>
            ) : contentOption.subTab_id === "3" ? (
              <ErrorBoundary FallbackComponent={FallBackRender}>
                <UsersDetails />
              </ErrorBoundary>
            ) : contentOption.subTab_id === "4" ? (
              <div className="py-2">
                <ErrorBoundary FallbackComponent={FallBackRender}>
                  <Subscription />
                </ErrorBoundary>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
      <Modal
        isOpen={modalStatus && modalStatus.state}
        closeModal={handleModalClosed}
        title="Add Account"
      >
        <div className="row mx-0 justify-content-between px-4 pt-2 pb-4 ">
          <div className="col-12 piik-text-warning p-2 mb-2">
            {modalStatus && modalStatus.comment}
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default MainContent;
