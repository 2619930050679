import React, { useState, useEffect } from "react";
import CountryFlag from "../Common/CountryFlag";
import { useDashData } from "../../hooks/useDash";
import navigation from "../../Data/NavigationData";
import { formatDate } from "../Visualization/Charts/Utility";

const Icon = (props) => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center text-white piik-card-icon  rounded-end-2 border-secondary"
      style={{ zIndex: props.index }}
      onClick={() => props.handleClick(props.tabId, props.subTabId)}
    >
      <div className={`bi ${props.data.logo} pb-2`}></div>
      <div className="piik-text-icon">{props.data.name}</div>
    </div>
  );
};

const CompanyCard = (props) => {
  const {
    profile,
    handleAddAccount,
    adminAccountInfo,
    guestAccountInfo,
    handleDataReportRefresh,
  } = useDashData();

  const handleValidate = () => {
    handleAddAccount({
      name: profile.name,
      email: profile.email,
    });
  };

  const icon_data = navigation[1].subTab;
  const [tab, setTab] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState([]);

  const handleTab = (subTabId, tertiaryTabId) => {
    if (tertiaryTabId !== "7") {
      props.handleAccount(subTabId).then(() => {
        props.handleContentTab("2", subTabId, tertiaryTabId);
      });
    }
  };

  const handleRefresh = (tab_id, _) => {
    return new Promise((resolve, reject) => {
      let combinedProfileData = profile.accounts.concat(profile.guest_invite);
      const accountIndex = combinedProfileData.findIndex(
        (data) => data.company_uid === tab_id
      );
      let selectedData = combinedProfileData[accountIndex];

      let data = {
        companyId: selectedData.company_uid,
        accountType: selectedData.account_name,
        userId: profile.user_id,
      };
      handleDataReportRefresh(data);
      resolve(data);
    });
  };

  useEffect(() => {
    setTab((pre) => {
      if (pre === null) {
        let tab = adminAccountInfo.length > 0 ? 1 : 2;
        if (tab === 1) {
          setSelectedAccount(() => {
            return adminAccountInfo;
          });
        } else if (tab === 2) {
          setSelectedAccount(() => {
            return guestAccountInfo;
          });
        }
        return tab;
      } else {
        if (pre === 1) {
          setSelectedAccount(() => {
            return adminAccountInfo;
          });
        } else if (pre === 2) {
          setSelectedAccount(() => {
            return guestAccountInfo;
          });
        }
        return pre;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, adminAccountInfo]);

  return (
    <div className="my-2 ">
      <div className="d-flex flex-row ">
        <div
          className={
            tab === 1
              ? "px-4 py-2 bg-primary text-white w-25 piik-text-dashboard piik-pointer d-flex flex-row justify-content-between align-item-center piik-border-bottom-2"
              : "px-4 py-2 bg-light text-primary w-25 piik-text-dashboard piik-pointer border-bottom d-flex flex-row justify-content-between align-item-center"
          }
          onClick={() => setTab(1)}
        >
          <div>Administrator </div>
          <div>{adminAccountInfo.length}</div>
        </div>
        <div
          className={
            tab === 2
              ? "px-4 py-2 bg-primary text-white w-25 piik-text-dashboard piik-pointer piik-border-bottom-1 d-flex flex-row justify-content-between align-item-center"
              : "px-4 py-2 bg-light text-primary w-25 piik-text-dashboard piik-pointer border-bottom d-flex flex-row justify-content-between align-item-center"
          }
          onClick={() => setTab(2)}
        >
          <div> Guest </div>
          <div>{guestAccountInfo.length}</div>
        </div>
      </div>
      <div className="p-4 bg-light rounded-bottom piik-home-card">
        {selectedAccount.length > 0 ? (
          <>
            {selectedAccount.map((content, index) => {
              return (
                <section
                  className="d-flex flex-row mb-2 shadow-sm  "
                  key={`account_${index}`}
                >
                  <div
                    className={`bg-white flex-grow-1 w-75 py-2 px-4 ${
                      tab === 1 ? "piik-border-side-2" : " piik-border-side-1"
                    } h-100`}
                  >
                    <div className="d-flex flex-row justify-content-start align-items-center ">
                      <div className="py-2">
                        <div className="piik-text-icon pb-1">
                          <span>
                            {content.status &&
                            content.creater_id === content.owner_id ? (
                              <span className="bi bi-circle-fill text-primary piik-text-icon "></span>
                            ) : (
                              <i className="bi bi-circle-fill text-danger piik-text-icon"></i>
                            )}
                          </span>
                          <span className="px-1 ">{content.account_name}</span>
                        </div>
                        <div className="fw-bold text-uppercase pt-1">
                          {content.company_name}
                          <CountryFlag
                            countryCode={content.country.toLowerCase()}
                          />
                        </div>

                        <div className="piik-text-icon">
                          Last updated at
                          <span className="fw-semibold px-1">
                            {formatDate(content.updated_at)}
                          </span>
                        </div>
                      </div>

                      {content.account_name === "quickbooks" &&
                      content.creater_id !== content.owner_id ? (
                        <div
                          className="mx-4 alert alert-danger my-0 flex-grow-1"
                          role="alert"
                          onClick={handleValidate}
                        >
                          <div className="piik-text-alert text-danger fw-bold d-flex flex-row align-items-start">
                            <div className="bi bi-exclamation-circle-fill pe-1 "></div>
                            <div className="">
                              Authentication Required!
                              {content.owner_id === profile.user_id ? (
                                <span className="piik-pointer text-primary px-1">
                                  Click Here
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <Icon
                    tabId={content.uid}
                    subTabId="1"
                    data={icon_data[0]}
                    index={50}
                    handleClick={handleTab}
                  />

                  <Icon
                    tabId={content.uid}
                    subTabId="2"
                    data={icon_data[1]}
                    index={40}
                    handleClick={handleTab}
                  />

                  <Icon
                    tabId={content.uid}
                    subTabId="4"
                    data={icon_data[2]}
                    index={30}
                    handleClick={handleTab}
                  />

                  <Icon
                    tabId={content.uid}
                    subTabId="3"
                    data={icon_data[3]}
                    index={20}
                    handleClick={handleTab}
                  />
                  {profile.user_id === content.owner_id && (
                    <Icon
                      tabId={content.uid}
                      subTabId="5"
                      data={icon_data[4]}
                      index={10}
                      handleClick={handleTab}
                    />
                  )}
                  {profile.user_id === content.owner_id && (
                    <Icon
                      tabId={content.uid}
                      subTabId="6"
                      data={{
                        logo: "bi bi-database text-piik-visual",
                        name: "Data",
                      }}
                      index={5}
                      handleClick={handleTab}
                    />
                  )}

                  <Icon
                    tabId={content.uid}
                    subTabId="7"
                    data={{
                      logo: "bi bi-arrow-repeat text-piik-visual",
                      name: "Update",
                    }}
                    index={0}
                    handleClick={handleRefresh}
                  />
                </section>
              );
            })}
          </>
        ) : (
          <div className="px-2 fw-semibold"> No Company Data Found</div>
        )}
      </div>
    </div>
  );
};
export default CompanyCard;
