const generateYearMonthArray = (
  startYear = 2022,
  endYear = null,
  fiscalMonth = 3
) => {
  const currentYear = endYear !== null ? endYear : new Date().getFullYear();
  let n = currentYear - startYear + 1;
  const result = [];
  const months_data = [
    { month: "Jan", month_index: 0 },
    { month: "Feb", month_index: 1 },
    { month: "Mar", month_index: 2 },
    { month: "Apr", month_index: 3 },
    { month: "May", month_index: 4 },
    { month: "Jun", month_index: 5 },
    { month: "Jul", month_index: 6 },
    { month: "Aug", month_index: 7 },
    { month: "Sep", month_index: 8 },
    { month: "Oct", month_index: 9 },
    { month: "Nov", month_index: 10 },
    { month: "Dec", month_index: 11 },
  ];

  for (let i = 0; i < n; i++) {
    const year = currentYear - i;
    const yearIndex = year;

    const rotatedMonths = months_data
      .slice(fiscalMonth - 1)
      .concat(months_data.slice(0, fiscalMonth - 1));

    const months = rotatedMonths.map((month, index) => {
      const yr = yearIndex + Math.floor((fiscalMonth - 1 + index) / 12);
      const updatedMonths = {
        ...month,
        year_index: yr,
        year: `${yearIndex}/${yearIndex + 1}`,
      };
      return updatedMonths;
    });

    result.push({
      year: `${yearIndex}/${yearIndex + 1}`,
      year_index: yearIndex,
      months: months,
    });
  }
  return result;
};

function getEndOfMonthDates(monthNumber, year, fiscalMonthIndex) {
  const previousYear = monthNumber < fiscalMonthIndex - 1 ? year - 1 : year;
  const endDateThisYear = new Date(year, monthNumber + 1, 0);
  const endDatePrevYear = new Date(
    previousYear,
    fiscalMonthIndex - 1 > 0 ? fiscalMonthIndex - 1 : 0,
    1
  );
  const formattedEndDateThisYear = endDateThisYear.toISOString().split("T")[0];
  const formattedEndDatePrevYear = endDatePrevYear.toISOString().split("T")[0];
  return [formattedEndDateThisYear, formattedEndDatePrevYear];
}

function filterByDateRange(accountData, date) {
  if (accountData.length === 0) {
    return accountData;
  }
  const newAccountData = [...accountData];
  const fiscalMonthIndex = date.fiscal_month_index;
  const data = newAccountData[0].data;
  const [end, start] = getEndOfMonthDates(
    date.month_index,
    date.year_index,
    fiscalMonthIndex
  );

  const startDate = new Date(start).getTime();
  const endDate = new Date(end).getTime();

  const filterData = data.filter((item) => {
    const itemDate = new Date(item.date).getTime();
    return itemDate >= startDate && itemDate <= endDate;
  });

  if (filterData.length > 0) {
    newAccountData[0].data = filterData;
  } else {
    newAccountData[0].data = data.map((d) => ({
      date: d.date,
      value: 0,
    }));
  }

  return newAccountData;
}

function findMinMaxDate(array, dateKey) {
  if (!Array.isArray(array) || array.length === 0) {
    return null;
  }

  let minDate = new Date(array[0][dateKey]);
  let maxDate = new Date(array[0][dateKey]);

  for (let i = 1; i < array.length; i++) {
    const currentDate = new Date(array[i][dateKey]);

    if (currentDate < minDate) {
      minDate = currentDate;
    }

    if (currentDate > maxDate) {
      maxDate = currentDate;
    }
  }

  return {
    minDate: minDate.toISOString().split("T")[0],
    maxDate: maxDate.toISOString().split("T")[0],
  };
}

function getFiscalMonthIndex(monthName) {
  const months = {
    january: 1,
    february: 2,
    march: 3,
    april: 4,
    may: 5,
    june: 6,
    july: 7,
    august: 8,
    september: 9,
    october: 10,
    november: 11,
    december: 12,
  };
  const lowercaseMonthName = monthName.toLowerCase();
  if (months.hasOwnProperty(lowercaseMonthName)) {
    return months[lowercaseMonthName];
  } else {
    return 1;
  }
}

export {
  generateYearMonthArray,
  getEndOfMonthDates,
  filterByDateRange,
  findMinMaxDate,
  getFiscalMonthIndex,
};
