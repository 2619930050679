import React, { useEffect, useState } from "react";
import {
  config,
  baseChartsConfig,
  chartsOrginal,
  colorsArray,
  areaFillOptions,
  textPosition,
  legendPosition,
  lineShape,
  linetype,
  hoverPosition,
  displayUnits,
  markerTypes,
  textSize,
  lineWidth,
  marginDirection,
} from "./ChartConfig";
import { useDashData } from "../../../hooks/useDash";
import {
  removeKeysFromObjects,
  addKeysToObjects,
  extractUniqueValues,
  convertDisplayDataUnit,
  generateUniqueCode,
  aggegateCategoricalData,
  handleGroupAndAggregateData,
  handleHoverTemplate,
  transformDate,
} from "./Utility";

import { filterByDateRange } from "../../../Data/DatesGenerator";
import BottomIcon from "../../Common/BottomIcons";
import PlotChart from "./Plot";

const Tab = ({ label, active, onClick }) => (
  <div
    className={`d-flex flex-row justify-content-between align-items-center 
                ${
                  active
                    ? " border-bottom border-primary border-5"
                    : "border-bottom  border-light border-5"
                }
                shadow-sm piik-text-dashboard w-50`}
    onClick={onClick}
  >
    <div className="px-3 py-2 piik-pointer  text-grey  piik-text-dashboard">
      {label}
    </div>
  </div>
);

const CreateChart = ({
  fullScreenStatus,
  updateVisualizationIndex,
  handleUpdateVisualizationIndex,
  handleVisualizationTab,
}) => {
  const {
    profile,
    calenderData,
    adminAccountInfo,
    account,
    handleAddVisualization,
    handleUpdateVisualization,
  } = useDashData();

  const [charts, setCharts] = useState(chartsOrginal);
  const [visual, setVisual] = useState({});
  const [plotState, setPlotState] = useState(false);
  const [data, setData] = useState([]);
  const [layout, setLayout] = useState({});

  const [features, setFeatures] = useState({
    xaxisFeatures: [],
    yaxisFeatures: [],
  });
  const [featuresCategory, setFeaturesCategory] = useState({
    xaxisCategory: [],
    yaxisCategory: [],
  });
  const [title, setTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [status, setStatus] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);

  const [tab, setTab] = useState(true);
  const [styleTab, setStyleTab] = useState(true);
  const [dataTab, setDataTab] = useState(true);
  const [filterActivity, setFilterActivity] = useState(true);

  const handleUpdate = () => {
    if (title === "") {
      setErrorMessage("Field Data is Missing");
      return;
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i].y.length === 0) {
        return;
      }
    }
    setSubmitStatus(true);
    let accountIndex = adminAccountInfo.findIndex(
      (data) => data.uid === account
    );
    let selectedAccountData = adminAccountInfo[accountIndex];
    visual.data = removeKeysFromObjects(data);
    visual.layout = layout;

    let requestData = {
      userId: profile.user_id,
      accountId: selectedAccountData.uid,
      chartId: visual.id,
      chartSettings: { ...visual },
      chartName: title,
      createdBy: profile.user_email,
      visualizationType: "Chart",
    };
    handleUpdateVisualization(requestData)
      .then((response) => {
        if (response.status) {
          handleClear("all").then(() => {
            handleVisualizationTab(updateVisualizationIndex);
            handleUpdateVisualizationIndex(null);
            setSubmitStatus(false);
          });
        } else {
          handleVisualizationTab(updateVisualizationIndex);
          handleUpdateVisualizationIndex(null);
          setSubmitStatus(false);
        }
      })
      .catch((error) => {
        setSubmitStatus(false);
      });
  };

  const handleSubmit = () => {
    if (title === "") {
      setErrorMessage("Field Data is Missing");
      return;
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i].y.length === 0) {
        return;
      }
    }
    setSubmitStatus(true);
    let accountIndex = adminAccountInfo.findIndex(
      (data) => data.uid === account
    );
    let selectedAccountData = adminAccountInfo[accountIndex];
    visual.data = removeKeysFromObjects(data);
    visual.layout = layout;
    let requestData = {
      userId: profile.user_id,
      accountId: selectedAccountData.uid,
      chartSettings: { ...visual },
      chartName: title,
      createdBy: profile.user_email,
      visualizationType: "Chart",
    };
    handleAddVisualization(requestData)
      .then((response) => {
        if (response.status) {
          handleClear().then(() => {
            setSubmitStatus(false);
            handleUpdateVisualizationIndex(null);
          });
        } else {
          setSubmitStatus(false);
          handleUpdateVisualizationIndex(null);
        }
      })
      .catch((error) => {
        setSubmitStatus(false);
      });
  };

  const selectFeatures = (accountData, axisType) => {
    let features = accountData.data.filter((d) =>
      axisType.includes(d.data_type)
    );
    return features;
  };

  const handleFeature = (Chartdata, selectedAccountData) => {
    setFeatures((prevLayout) => {
      const updatedLayout = { ...prevLayout };
      let axisType_x = Chartdata.xaxisType;
      let xaxisFeatures = selectFeatures(selectedAccountData, axisType_x);

      let axisType_y = Chartdata.yaxisType;
      let yaxisFeatures = selectFeatures(selectedAccountData, axisType_y);
      updatedLayout.xaxisFeatures = xaxisFeatures;
      updatedLayout.yaxisFeatures = yaxisFeatures;
      setFeaturesCategory((prevData) => {
        const updatedCatgeory = { ...prevData };
        updatedCatgeory.xaxisCategory = extractUniqueValues(
          xaxisFeatures,
          "category"
        );
        updatedCatgeory.yaxisCategory = extractUniqueValues(
          yaxisFeatures,
          "category"
        );
        return updatedCatgeory;
      });
      return updatedLayout;
    });
  };

  const updateYaxisFeature = (Chartdata, selectedAccountData) => {
    setFeatures((prevData) => {
      const updatedData = { ...prevData };
      let axisType_y = Chartdata.yaxisType;
      if (data[0].xaxisDataType !== "datetime") {
        let filterAccountData = selectedAccountData.data.filter(
          (d) => d.category === data[0].xaxisCategory
        );
        let yaxisFeatures = filterAccountData.filter((d) =>
          axisType_y.includes(d.data_type)
        );
        updatedData.yaxisFeatures = yaxisFeatures;
        return updatedData;
      } else {
        let yaxisFeatures = selectedAccountData.data.filter((d) =>
          axisType_y.includes(d.data_type)
        );
        updatedData.yaxisFeatures = yaxisFeatures;
        return updatedData;
      }
    });
  };

  const handleDataStyle = (e) => {
    const { id, name, value } = e.target;
    if (name === "dataPoint") {
      setVisual((prev) => {
        const newData = { ...prev };
        newData[id] = {
          name: value,
          id: value !== "" ? data.find((d) => d.name === value).yaxisIds : "",
          category:
            value !== ""
              ? data.find((d) => d.name === value).yaxisCategory
              : "",
          index:
            e.target.selectedIndex === 0 ? null : e.target.selectedIndex - 1,
        };
        return newData;
      });
    } else {
      const dataIndex = visual.selectedDataPoint.index;
      setData((prevData) => {
        const updatedData = [...prevData];
        let newDataPoint = prevData[dataIndex];

        if (name === "name") {
          newDataPoint["name"] = value;
          newDataPoint.hovertemplate = handleHoverTemplate(
            newDataPoint.orientation,
            value
          );
          updatedData[dataIndex] = newDataPoint;
          return updatedData;
        } else if (name === "text") {
          const tag = id.split("-")[1];
          if (tag === "textposition") {
            newDataPoint[tag] = value;
          } else if (tag === "color") {
            newDataPoint["textfont"] = {
              ...newDataPoint["textfont"],
              [tag]: value,
            };
          } else if (tag === "size") {
            newDataPoint["textfont"] = {
              ...newDataPoint["textfont"],
              [tag]: parseInt(value),
            };
          } else if (tag === "textangle") {
            newDataPoint = {
              ...newDataPoint,
              [tag]: parseInt(value),
            };
          }
          updatedData[dataIndex] = newDataPoint;
          return updatedData;
        } else if (name === "line") {
          const tag = id.split("-")[1];
          if (tag === "fill") {
            newDataPoint[tag] = value;
          } else if (tag === "width") {
            newDataPoint["line"] = {
              ...newDataPoint["line"],
              [tag]: parseInt(value),
            };
          } else {
            newDataPoint["line"] = {
              ...newDataPoint["line"],
              [tag]: value,
            };
          }
          updatedData[dataIndex] = newDataPoint;
          return updatedData;
        } else if (name === "marker") {
          const tag = id.split("-")[1];
          if (tag === "opacity") {
            newDataPoint[tag] = value;
          } else if (tag === "size") {
            newDataPoint["marker"] = {
              ...newDataPoint["marker"],
              [tag]: parseInt(value),
            };
          } else if (tag === "line") {
            const subTag = id.split("-")[2];
            newDataPoint["marker"] = {
              ...newDataPoint["marker"],
              line: {
                ...newDataPoint["marker"]["line"],
                [subTag]: subTag === "width" ? parseInt(value) : value,
              },
            };
          } else {
            newDataPoint["marker"] = {
              ...newDataPoint["marker"],
              [tag]: value,
            };
          }
          updatedData[dataIndex] = newDataPoint;
          return updatedData;
        }
      });
    }
  };

  const handleLayoutStyle = (e) => {
    const { id, name, value } = e.target;
    setLayout((prevLayout) => {
      const newLayout = { ...prevLayout };
      if (name === "xaxis" || name === "yaxis") {
        const tag = id.split("-")[1];
        if (tag === "font") {
          newLayout[name] = {
            ...newLayout[name],
            title: {
              ...newLayout[name].title,
              [tag]: { size: parseInt(value) },
            },
          };
          if (prevLayout.hasOwnProperty("yaxis2") && name === "yaxis") {
            newLayout["yaxis2"] = {
              ...newLayout["yaxis2"],
              title: {
                ...newLayout["yaxis2"].title,
                [tag]: { size: parseInt(value) },
              },
            };
          }
        } else if (tag === "tickfont") {
          newLayout[name] = {
            ...newLayout[name],
            [tag]: { size: parseInt(value) },
          };
          if (prevLayout.hasOwnProperty("yaxis2") && name === "yaxis") {
            newLayout["yaxis2"] = {
              ...newLayout["yaxis2"],
              [tag]: { size: parseInt(value) },
            };
          }
        } else if (tag === "text") {
          newLayout[name] = {
            ...newLayout[name],
            title: {
              ...newLayout[name].title,
              [tag]: value,
            },
          };
          if (prevLayout.hasOwnProperty("yaxis2") && name === "yaxis") {
            newLayout["yaxis2"] = {
              ...newLayout["yaxis2"],
              title: {
                ...newLayout["yaxis2"].title,
                [tag]: value + " YTD",
              },
            };
          }
        } else if (
          tag === "showgrid" ||
          tag === "showticklabels" ||
          tag === "showline"
        ) {
          newLayout[name] = {
            ...newLayout[name],
            [tag]: e.target.checked,
          };
          if (prevLayout.hasOwnProperty("yaxis2") && name === "yaxis") {
            newLayout["yaxis2"] = {
              ...newLayout["yaxis2"],
              [tag]: e.target.checked,
              zeroLine: false,
            };
          }
        }
      } else if (name === "general") {
        if (id === "displayUnits") {
          newLayout[id] = value;
        } else if (id === "hovermode") {
          newLayout[id] = value;
        } else if (id === "legend-orientation") {
          newLayout["legend"] = { ...newLayout["legend"], orientation: value };
        } else if (id === "legend-position") {
          let legend_cordinate = handleLegendTextPosition(value, false);
          newLayout["showlegend"] = parseInt(value) !== 0 ? true : false;
          newLayout["legend"] = {
            ...newLayout["legend"],
            ...legend_cordinate,
          };
        } else if (id.includes("margin")) {
          const direction = id.split("-")[1];
          newLayout["margin"] = {
            ...newLayout["margin"],
            [direction]:
              value !== null && value.length > 0 && value > -1 ? value : 0,
          };
        } else if (id.includes("sortBy")) {
          const code = id.split("-")[1];
          newLayout["sortBy"] = {
            ...newLayout["sortBy"],
            [code]: value,
          };
        }
      }
      return newLayout;
    });
  };

  const handleBarPlotDirection = (e) => {
    const orientation = e.target.value;
    if (orientation === "") {
      return;
    }
    setData((prevData) => {
      const newTrace = [...prevData];
      const updatedTrace = newTrace.map((d) => {
        return {
          ...d,
          orientation: orientation,
          hovertemplate: handleHoverTemplate(orientation, d.name),
        };
      });
      return updatedTrace;
    });
  };

  const handleLegendTextPosition = (value, mode) => {
    if (mode) {
      if (value.x === 1 && value.y === 1) {
        return 1;
      } else if (value.x === 0 && value.y === 1) {
        return 2;
      } else if (value.x === 1 && value.y === 0) {
        return 3;
      } else if (value.x === 0 && value.y === 0) {
        return 4;
      } else if (value.x === null && value.y === null) {
        return 0;
      }
    } else {
      if (parseInt(value) === 1) {
        return { x: 1, y: 1, xanchor: "inside" };
      } else if (parseInt(value) === 2) {
        return { x: 0, y: 1, xanchor: "inside" };
      } else if (parseInt(value) === 3) {
        return { x: 1, y: 0, xanchor: "right" };
      } else if (parseInt(value) === 4) {
        return { x: 0, y: 0, xanchor: "inside" };
      } else if (parseInt(value) === 0) {
        return { x: null, y: null };
      }
    }
  };

  const handleChangePlotType = (e) => {
    const { value } = e.target;
    if (value === "") {
      return;
    }
    const selectedCharts = [...charts];
    const dataIndex = visual.selectedDataPoint.index;
    const selectedChartIndex = selectedCharts.findIndex(
      (d) => d.visualId === parseInt(value)
    );

    setData((prevData) => {
      const updatedData = [...prevData];
      const oldPlotDetails = updatedData[dataIndex];

      const newPlotDetails = selectedCharts[selectedChartIndex];
      const updatePlotDetails = {
        ...newPlotDetails.data[0],
        x: oldPlotDetails.x,
        y: oldPlotDetails.y,
        text: oldPlotDetails.text,
        index: oldPlotDetails.index,
        xaxisIds: oldPlotDetails.xaxisIds,
        xaxisCategory: oldPlotDetails.xaxisCategory,
        xaxisDataType: oldPlotDetails.xaxisDataType,
        yaxisIds: oldPlotDetails.yaxisIds,
        yaxisCategory: oldPlotDetails.yaxisCategory,
        yaxisDataType: oldPlotDetails.yaxisDataType,
        yaxis: oldPlotDetails.yaxis,
        xaxis: oldPlotDetails.xaxis,
        name: oldPlotDetails.name,
        groupBy: oldPlotDetails.groupBy,
        legendId: oldPlotDetails.legendId,
        chartType: oldPlotDetails.chartType,
        additionChartType: oldPlotDetails.additionChartType,
        hovertemplate: handleHoverTemplate(
          oldPlotDetails.orientation,
          oldPlotDetails.name
        ),
        filterEndpoint: oldPlotDetails.filterEndpoint,
      };
      updatedData[dataIndex] = updatePlotDetails;
      return updatedData;
    });
  };

  const handleDataAggregate = (e) => {
    const { value, name } = e.target;
    setData((prevData) => {
      let updatedData = [...prevData];
      let dataIndex = updatedData.findIndex((d) => d.index === name);
      let newData = { ...updatedData[dataIndex] };
      let filterEndpoints = newData.filterEndpoint;

      if (newData["yaxisIds"] === "") {
        newData["groupBy"] = value;
        updatedData[dataIndex] = newData;
        return updatedData;
      } else if (newData["yaxisIds"] !== "") {
        let accountIndex = adminAccountInfo.findIndex(
          (data) => data.uid === account
        );
        let selectedAccountData = JSON.parse(
          JSON.stringify(adminAccountInfo[accountIndex])
        );
        let TempData = [
          ...selectedAccountData.data.filter((d) => d.id === newData.yaxisIds),
        ];
        const sourceDateData = selectedAccountData.data.find(
          (d) => d.id === "date_1234"
        );
        const TempDataXaxis = [
          ...selectedAccountData.data.filter((d) => d.id === newData.xaxisIds),
        ];
        let xaxis = TempDataXaxis[0];
        let xaxisDataType = newData.xaxisDataType;
        let yaxisDataType = newData.yaxisDataType;
        let yaxis = TempData[0];

        let legendId = newData.legendId;
        let groupBy = value;

        if (xaxisDataType === "str") {
          const filterXaxisData = [...filterByDateRange([xaxis], calenderData)];
          const filterYaxisData = [...filterByDateRange([yaxis], calenderData)];

          let x_value = filterXaxisData[0].data.map((d) => d.value);
          let y_value = filterYaxisData[0].data.map((d) => d.value);

          let operationType = value;
          const { x, y } = aggegateCategoricalData(
            x_value,
            y_value,
            operationType,
            selectedAccountData,
            calenderData,
            filterEndpoints
          );

          newData.x = x.length > 0 ? x : [];
          newData.y = y.length > 0 ? y : [];
          newData.text =
            y.length > 0
              ? y.map(
                  (v) => `${convertDisplayDataUnit(v, layout.displayUnits)}`
                )
              : [];
          newData.yaxisIds = yaxis.id;
          newData.yaxisDataType = yaxisDataType;
          newData.name = yaxis.name + "-" + operationType;
          newData.groupBy = operationType;
          newData.hovertemplate = handleHoverTemplate(
            newData.orientation,
            yaxis.name + "-" + operationType
          );
          updatedData[dataIndex] = newData;
        } else if (xaxisDataType === "datetime" && legendId === "") {
          const filterYaxisData = [...filterByDateRange([yaxis], calenderData)];
          let x_value = filterYaxisData[0].data.map((d) => d.date);
          let y_value = filterYaxisData[0].data.map((d) => d.value);
          let operationType = value;
          let { x, y } = aggegateCategoricalData(
            x_value,
            y_value,
            operationType,
            selectedAccountData,
            calenderData,
            filterEndpoints
          );
          const date = x.map(
            (d) => sourceDateData.data.find((v) => v.date === d).value
          );
          newData.x = date.length > 0 ? date : [];
          newData.y = y.length > 0 ? y : [];
          newData.text =
            y.length > 0
              ? y.map(
                  (v) => `${convertDisplayDataUnit(v, layout.displayUnits)}`
                )
              : [];
          newData.yaxisDataType = yaxisDataType;
          newData.name = yaxis.name + "-" + value;
          newData.groupBy = operationType;
          newData.hovertemplate = handleHoverTemplate(
            newData.orientation,
            yaxis.name + "-" + value
          );
          updatedData[dataIndex] = newData;
          let tempYtdIndex = updatedData.findIndex(
            (d) => d.chartType === "YTD" && d.yaxisIds === newData.yaxisIds
          );
          if (tempYtdIndex > -1) {
            let ytdOldData = updatedData[tempYtdIndex];
            ytdOldData.groupBy = value;
            updatedData[tempYtdIndex] = handleYearToDate(ytdOldData);
          }

          let tempPryIndex = updatedData.findIndex(
            (d) => d.chartType === "PRY" && d.yaxisIds === newData.yaxisIds
          );
          if (tempPryIndex > -1) {
            let pryOldData = updatedData[tempPryIndex];
            pryOldData.groupBy = value;
            updatedData[tempPryIndex] = handlePriorYear(pryOldData);
          }
        } else if (xaxisDataType === "datetime" && legendId !== "") {
          updatedData = [...prevData].filter((d) => !d.index.includes(name));
          const filterYaxisData = [...filterByDateRange([yaxis], calenderData)];
          const yaxisData = filterYaxisData[0].data;
          const legend = selectedAccountData.data.find(
            (d) => d.id === legendId
          );
          const filterLegendData = [
            ...filterByDateRange([legend], calenderData),
          ];
          const legendData = filterLegendData[0].data;
          const filterSourceDateData = [
            ...filterByDateRange([sourceDateData], calenderData),
          ];
          const dateData = filterSourceDateData[0].data;
          const resultData = handleGroupAndAggregateData(
            dateData,
            yaxisData,
            legendData,
            groupBy,
            selectedAccountData,
            calenderData,
            filterEndpoints
          );
          for (let i = 0; i < resultData.length; i++) {
            const newplot = {
              ...newData,
              x: resultData[i]["data"].map((d) => d.date),
              y: resultData[i]["data"].map((d) => d.value),
              text: resultData[i]["data"].map(
                (d) => `${convertDisplayDataUnit(d.value, layout.displayUnits)}`
              ),
              name: `${yaxis.name}-${resultData[i].name}-${value}`,
              chartType: i === 0 ? "actual" : "CAT",
              yaxisIds: yaxis.id,
              yaxisDataType: yaxis.data_type,
              groupBy: groupBy,
              index: i === 0 ? newData.index : `${newData.index}-CAT-${i}`,
              hovertemplate: handleHoverTemplate(
                newData.orientation,
                `${yaxis.name}-${resultData[i].name}-${value}`
              ),
            };
            updatedData = [...updatedData, newplot];
          }
        } else if (xaxisDataType === "int" || xaxisDataType === "float") {
          const sourceXaxisData = selectedAccountData.data.find(
            (d) => d.id === newData.xaxisIds
          );
          const filterXaxisData = [
            ...filterByDateRange([sourceXaxisData], calenderData),
          ];

          let x_valueXaxis = filterXaxisData[0].data.map((d) => d.date);
          let y_valueXaxis = filterXaxisData[0].data.map((d) => d.value);

          const xAxisAggegateResult = aggegateCategoricalData(
            x_valueXaxis,
            y_valueXaxis,
            groupBy,
            selectedAccountData,
            calenderData,
            filterEndpoints
          );

          const filterYaxisData = [...filterByDateRange([yaxis], calenderData)];
          let x_valueYaxis = filterYaxisData[0].data.map((d) => d.date);
          let y_valueYaxis = filterYaxisData[0].data.map((d) => d.value);
          const yAxisAggegateResult = aggegateCategoricalData(
            x_valueYaxis,
            y_valueYaxis,
            groupBy,
            selectedAccountData,
            calenderData,
            filterEndpoints
          );

          newData.x =
            xAxisAggegateResult && xAxisAggegateResult.y.length > 0
              ? xAxisAggegateResult.y
              : [];
          newData.y =
            yAxisAggegateResult && yAxisAggegateResult.y.length > 0
              ? yAxisAggegateResult.y
              : [];
          newData.text =
            yAxisAggegateResult.y.length > 0
              ? yAxisAggegateResult.y.map(
                  (d) => `${convertDisplayDataUnit(d, layout.displayUnits)}`
                )
              : [];
          newData.groupBy = value;
          newData.name = yaxis.name + "-" + value;
          newData.hovertemplate = handleHoverTemplate(
            newData.orientation,
            yaxis.name + "-" + value
          );

          updatedData[dataIndex] = newData;
        }
      }
      return updatedData;
    });
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "visual") {
      const selectedChart = [...charts];
      let selectedIndex = selectedChart.findIndex(
        (chart) => chart.visualId === parseInt(value)
      );
      if (selectedIndex > -1) {
        let accountIndex = adminAccountInfo.findIndex(
          (data) => data.uid === account
        );
        let selectedAccountData = adminAccountInfo[accountIndex];
        setVisual({ ...selectedChart[selectedIndex] });
        setLayout({ ...selectedChart[selectedIndex].layout });

        if (data.length > 0) {
          setData((prevData) => {
            const updatedData = [...prevData];
            const newData = updatedData.map((d) => {
              const selectedChartIndex = selectedChart.findIndex(
                (d) => d.visualId === selectedChart[selectedIndex].visualId
              );
              const newPlotDetails = { ...selectedChart[selectedChartIndex] };
              const updatePlotDetails = {
                ...newPlotDetails.data[0],
                x: d.x,
                y: d.y,
                index: d.index,
                xaxisIds: d.xaxisIds,
                xaxisCategory: d.xaxisCategory,
                xaxisDataType: d.xaxisDataType,
                yaxisIds: d.yaxisIds,
                yaxisCategory: d.yaxisCategory,
                yaxisDataType: d.yaxisDataType,
                text: d.text,
                name: d.name,
                groupBy: d.groupBy,
                chartType: d.chartType,
                additionChartType: d.additionChartType,
              };
              return updatePlotDetails;
            });
            return newData;
          });
        } else {
          let newChartData = [...selectedChart[selectedIndex].data];
          newChartData[0]["index"] = generateUniqueCode();
          setData(newChartData);
          setStatus(false);
          handleFeature(selectedChart[selectedIndex], selectedAccountData);
        }
      }
    } else if (id === "visualBarMode") {
      setLayout((prevLayout) => {
        const updatedLayout = { ...prevLayout };
        updatedLayout.barmode = value;
        return updatedLayout;
      });
    } else if (id === "title-text") {
      if (title.length > 25) {
        const truncatedValue = value.slice(0, 25);
        setTitle(truncatedValue);
        setErrorMessage("Maximum character limit reached");
      } else {
        setTitle(value);
        setErrorMessage("");
      }
    }
  };

  const handleDataCategory = (e) => {
    const { id, value } = e.target;
    const key = e.target.getAttribute("name");
    if (id === "xaxis-value") {
      setData((prevData) => {
        let updatedData = [...prevData];
        if (updatedData[0].xaxisCategory === "") {
          let dataSchema = updatedData[0];
          dataSchema.xaxisCategory = value;
          updatedData = [dataSchema];
        } else {
          updatedData.forEach((d) => {
            d.xaxisCategory = value;
          });
        }
        return updatedData;
      });
    } else {
      setData((prevData) => {
        let updatedData = [...prevData];
        if (updatedData[0].yaxisCategory === "") {
          let dataSchema = updatedData[0];
          dataSchema.yaxisCategory = value;
          updatedData = [dataSchema];
        } else {
          let selectedIndex = -1;
          selectedIndex = updatedData.findIndex((d) => d.yaxisIds === "");
          if (selectedIndex === -1) {
            let index = updatedData.findIndex((d) => d.index === key);
            selectedIndex = index;
          }
          if (selectedIndex > -1) {
            updatedData[selectedIndex].yaxisCategory = value;
          }
        }
        return updatedData;
      });
    }
  };

  const handleConnectedDataPoint = (e) => {
    const { name } = e.target;
    const checkedStatus = e.target.checked;
    let actionType = name.split("-")[0];
    let feildIndex = name.split("-")[1];

    setData((prevData) => {
      const updatedData = [...prevData].filter(
        (d) => !d.index.includes(`${feildIndex}-CAT`)
      );
      let selectedIndex = updatedData.findIndex(
        (d) => d.index === feildIndex && d.chartType === "actual"
      );

      if (selectedIndex > -1) {
        if (checkedStatus) {
          let oldDataSchema = updatedData[selectedIndex];
          const accountIndex = adminAccountInfo.findIndex(
            (data) => data.uid === account
          );
          const selectedAccountData = JSON.parse(
            JSON.stringify(adminAccountInfo[accountIndex])
          );

          let filterEndpoints = oldDataSchema.filterEndpoint;
          const sourceDateData = selectedAccountData.data.find(
            (d) => d.id === "date_1234"
          );
          const TempData = [
            ...selectedAccountData.data.filter(
              (d) => d.id === oldDataSchema.yaxisIds
            ),
          ];
          const yaxis = TempData[0];
          const filterData = [...filterByDateRange([yaxis], calenderData)];
          let x_value = yaxis.data.map((d) => d.date);
          let y_value = yaxis.data.map((d) => d.value);
          let operationType =
            yaxis.datatype === "str" ? "count" : oldDataSchema.groupBy;
          const { x, y } = aggegateCategoricalData(
            x_value,
            y_value,
            operationType,
            selectedAccountData,
            calenderData,
            filterEndpoints
          );
          const date = x.map(
            (d) => sourceDateData.data.find((v) => v.date === d).value
          );
          oldDataSchema.x = date.length > 0 ? date : [];
          oldDataSchema.y = y.length > 0 ? y : [];
          oldDataSchema.text =
            y.length > 0
              ? y.map(
                  (v) => `${convertDisplayDataUnit(v, layout.displayUnits)}`
                )
              : [];
          oldDataSchema.legendId = "";
          oldDataSchema.name = filterData[0].name + "-" + operationType;

          oldDataSchema.additionChartType = [
            ...oldDataSchema.additionChartType,
            actionType,
          ];

          updatedData[selectedIndex] = oldDataSchema;
          let newDatapoint = { ...oldDataSchema };
          newDatapoint["legendId"] = "";
          newDatapoint["chartType"] = actionType;
          newDatapoint["additionChartType"] = [name];
          newDatapoint["index"] = `${newDatapoint["index"]}-${actionType}`;
          if (newDatapoint.index.includes("-")) {
            newDatapoint["name"] = `${
              newDatapoint.name.split("-")[0]
            }-${actionType}`;
            updatedData[selectedIndex].name = newDatapoint.name.split("-")[0];
            updatedData[selectedIndex].legendId = "";
          } else {
            newDatapoint["name"] = `${newDatapoint.name}-${actionType}`;
          }

          if (actionType === "PRY") {
            newDatapoint = handlePriorYear(newDatapoint);
            let newData = [...updatedData, newDatapoint];
            return newData;
          } else if (actionType === "YTD") {
            newDatapoint["yaxis"] = "y2";
            newDatapoint["plotName"] = "Line";
            newDatapoint["mode"] = "lines+markers+text";
            newDatapoint["type"] = "scatter";
            newDatapoint["textposition"] = "bottom";
            newDatapoint["textfont"] = {
              size: 12,
              color: "black",
            };
            newDatapoint["marker"] = {
              symbol: "circle",
              color: "",
              size: 8,
            };
            newDatapoint["line"] = {
              color: "",
              width: 1,
              shape: "linear",
              dash: "solid",
            };

            newDatapoint = handleYearToDate(newDatapoint);
            let newData = [...updatedData, newDatapoint];
            return newData;
          }
        } else {
          updatedData[selectedIndex].additionChartType = updatedData[
            selectedIndex
          ].additionChartType.filter((d) => d !== actionType);

          let newData = updatedData.filter(
            (d) => !d.additionChartType.includes(name)
          );

          if (actionType === "YTD") {
            let checkYTD = newData.findIndex((d) => d.chartType === "YTD");
            if (checkYTD === -1) {
              setLayout((preLayout) => {
                if (preLayout.hasOwnProperty("yaxis2")) {
                  const { yaxis2, ...updatedLayout } = { ...preLayout };
                  return updatedLayout;
                } else {
                  return preLayout;
                }
              });
            }
          }
          return newData;
        }
      }
    });
  };

  const handleValueChange = async (e) => {
    const { id, value, name } = e.target;
    if (value === "") {
      return;
    }

    const accountIndex = adminAccountInfo.findIndex(
      (data) => data.uid === account
    );
    const selectedAccountData = JSON.parse(
      JSON.stringify(adminAccountInfo[accountIndex])
    );
    const sourceDateData = selectedAccountData.data.find(
      (d) => d.id === "date_1234"
    );
    const TempData = [
      ...selectedAccountData.data.filter((d) => d.id === value),
    ];

    setData((prevData) => {
      let updatedData = [...prevData];
      if (id === "xaxis-value") {
        if (TempData[0].data_type === "str") {
          const filterData = [...filterByDateRange(TempData, calenderData)];
          if (updatedData[0].xaxisIds === "") {
            let dataSchema = updatedData[0];
            dataSchema.x =
              filterData.length > 0
                ? filterData[0].data.map((obj) => obj.value)
                : [];
            dataSchema.x_original =
              filterData.length > 0
                ? filterData[0].data.map((obj) => obj.value)
                : [];

            dataSchema.xaxisIds = value;
            dataSchema.xaxisDataType = filterData[0].data_type;
            dataSchema.yaxisCategory = dataSchema.xaxisCategory;
            updatedData = [dataSchema];
          } else {
            let x_data =
              filterData.length > 0
                ? filterData[0].data.map((obj) => obj.value)
                : [];

            updatedData.forEach((d) => {
              d.xaxisIds = value;
              d.x = x_data;
              d.x_original = x_data;
              d.xaxisDataType = filterData[0].data_type;

              d.y = [];
              d.yaxisIds = "";
              d.yaxisDataType = "";
              d.yaxisCategory = d.xaxisCategory;
              d.text = [];
              d.name = "";
            });
          }
          let selectedCharts = [...charts];
          let selectedIndex = selectedCharts.findIndex(
            (chart) => chart.visualId === visual.visualId
          );
          updateYaxisFeature(
            selectedCharts[selectedIndex],
            selectedAccountData
          );
          return updatedData;
        } else {
          const filterData = [...filterByDateRange(TempData, calenderData)];
          if (updatedData[0].xaxisIds === "") {
            let dataSchema = updatedData[0];
            dataSchema.x =
              filterData.length > 0
                ? filterData[0].data.map((obj) => obj.value)
                : [];
            dataSchema.x_original =
              filterData.length > 0
                ? filterData[0].data.map((obj) => obj.value)
                : [];
            dataSchema.xaxisIds = value;
            dataSchema.xaxisDataType = filterData[0].data_type;
            updatedData = [dataSchema];
          } else {
            let x_data =
              filterData.length > 0
                ? filterData[0].data.map((obj) => obj.value)
                : [];
            updatedData.forEach((d) => {
              d.xaxisIds = value;
              d.x = x_data;
              d.xaxisDataType = filterData[0].data_type;

              d.y = [];
              d.yaxisIds = "";
              d.yaxisDataType = "";
              d.legendId = "";
              d.text = [];
              d.name = "";
            });
          }
          let selectedCharts = [...charts];
          let selectedIndex = selectedCharts.findIndex(
            (chart) => chart.visualId === visual.visualId
          );
          updateYaxisFeature(
            selectedCharts[selectedIndex],
            selectedAccountData
          );
          return updatedData;
        }
      } else if (id.includes("yaxis-value")) {
        const newDataTempIndex = updatedData.findIndex((d) => d.index === name);
        const newData = updatedData[newDataTempIndex];

        let filterEndpoints = newData.filterEndpoint;

        const legendId = newData.legendId;
        const groupBy = newData.groupBy;
        const xaxisDataType = newData.xaxisDataType;

        const yaxis = TempData[0];
        const yaxisDataType = yaxis.data_type;

        if (xaxisDataType === "str") {
          const filterData = [...filterByDateRange([yaxis], calenderData)];
          let x_value = newData.x.map((d) => `${d}`);
          let y_value = yaxis.data.map((d) => d.value);
          let operationType = yaxisDataType === "str" ? "count" : groupBy;
          const { x, y } = aggegateCategoricalData(
            x_value,
            y_value,
            operationType,
            selectedAccountData,
            calenderData,
            filterEndpoints
          );

          newData.x = x.length > 0 ? x : [];
          newData.y = y.length > 0 ? y : [];
          newData.text =
            y.length > 0
              ? y.map(
                  (v) => `${convertDisplayDataUnit(v, layout.displayUnits)}`
                )
              : [];
          newData.yaxisIds = value;
          newData.yaxisDataType = yaxisDataType;
          newData.groupBy = operationType;

          let name = filterData[0].name + "-" + groupBy;
          newData.name = name;
          newData.hovertemplate = handleHoverTemplate(
            newData.orientation,
            name
          );
          updatedData[newDataTempIndex] = newData;
        } else if (xaxisDataType === "datetime" && legendId === "") {
          const filterData = [...filterByDateRange([yaxis], calenderData)];
          let x_value = yaxis.data.map((d) => d.date);
          let y_value = yaxis.data.map((d) => d.value);
          let operationType = yaxisDataType === "str" ? "count" : groupBy;
          const { x, y } = aggegateCategoricalData(
            x_value,
            y_value,
            operationType,
            selectedAccountData,
            calenderData,
            filterEndpoints
          );

          const date =
            xaxisDataType === "datetime"
              ? x.map(
                  (d) => sourceDateData.data.find((v) => v.date === d).value
                )
              : x;

          newData.x = date.length > 0 ? date : [];
          newData.y = y.length > 0 ? y : [];
          newData.text =
            y.length > 0
              ? y.map(
                  (v) => `${convertDisplayDataUnit(v, layout.displayUnits)}`
                )
              : [];
          newData.yaxisIds = value;
          newData.yaxisDataType = yaxisDataType;
          newData.groupBy = operationType;

          let name = filterData[0].name + "-" + groupBy;
          newData.name = name;
          newData.hovertemplate = handleHoverTemplate(
            newData.orientation,
            name
          );

          updatedData[newDataTempIndex] = newData;
        } else if (xaxisDataType === "datetime" && legendId !== "") {
          updatedData = [...prevData].filter((d) => !d.index.includes(name));
          const legend = selectedAccountData.data.find(
            (d) => d.id === legendId
          );
          const filterLegendData = [
            ...filterByDateRange([legend], calenderData),
          ];
          const filterSourceDateData = [
            ...filterByDateRange([sourceDateData], calenderData),
          ];
          const filterYaxisData = [...filterByDateRange([yaxis], calenderData)];
          const dateData = filterSourceDateData[0].data;
          const legendData = filterLegendData[0].data;
          const yaxisData = filterYaxisData[0].data;

          const resultData = handleGroupAndAggregateData(
            dateData,
            yaxisData,
            legendData,
            groupBy,
            selectedAccountData,
            calenderData,
            filterEndpoints
          );

          for (let i = 0; i < resultData.length; i++) {
            let name =
              yaxis.name + "-" + resultData[i].name + "-" + newData.groupBy;
            let hoverText = handleHoverTemplate(newData.orientation, name);
            const newplot = {
              ...newData,
              x: resultData[i]["data"].map((d) => d.date),
              y: resultData[i]["data"].map((d) => d.value),
              text: resultData[i]["data"].map(
                (d) => `${convertDisplayDataUnit(d.value, layout.displayUnits)}`
              ),
              name: name,
              chartType: i === 0 ? "actual" : "CAT",
              yaxisIds: yaxis.id,
              yaxisDataType: yaxis.data_type,
              index: i === 0 ? newData.index : `${newData.index}-CAT-${i}`,
              hovertemplate: hoverText,
            };
            updatedData = [...updatedData, newplot];
          }
        } else if (xaxisDataType === "int" || xaxisDataType === "float") {
          const sourceXaxisData = selectedAccountData.data.find(
            (d) => d.id === newData.xaxisIds
          );
          const filterXaxisData = [
            ...filterByDateRange([sourceXaxisData], calenderData),
          ];

          let x_valueXaxis = filterXaxisData[0].data.map((d) => d.date);
          let y_valueXaxis = filterXaxisData[0].data.map((d) => d.value);

          const xAxisAggegateResult = aggegateCategoricalData(
            x_valueXaxis,
            y_valueXaxis,
            groupBy,
            selectedAccountData,
            calenderData,
            filterEndpoints
          );

          const filterYaxisData = [...filterByDateRange([yaxis], calenderData)];
          let x_valueYaxis = filterYaxisData[0].data.map((d) => d.date);
          let y_valueYaxis = filterYaxisData[0].data.map((d) => d.value);
          const yAxisAggegateResult = aggegateCategoricalData(
            x_valueYaxis,
            y_valueYaxis,
            groupBy,
            selectedAccountData,
            calenderData,
            filterEndpoints
          );

          newData.x =
            xAxisAggegateResult && xAxisAggegateResult.y.length > 0
              ? xAxisAggegateResult.y
              : [];
          newData.y =
            yAxisAggegateResult && yAxisAggegateResult.y.length > 0
              ? yAxisAggegateResult.y
              : [];
          newData.text =
            yAxisAggegateResult.y.length > 0
              ? yAxisAggegateResult.y.map(
                  (d) => `${convertDisplayDataUnit(d, layout.displayUnits)}`
                )
              : [];
          newData.yaxisIds = value;
          newData.name =
            filterYaxisData.length > 0
              ? filterYaxisData[0].name
              : value + newData.groupBy;
          newData.yaxisDataType = filterYaxisData[0].data_type;
          newData.hovertemplate = handleHoverTemplate(
            newData.orientation,
            value + newData.groupBy
          );
          updatedData[newDataTempIndex] = newData;
        }
      }
      return updatedData;
    });
  };

  const handlePlotStatus = (e) => {
    const index = e.target.id;
    setData((prevData) => {
      const updatedData = [...prevData];
      const newData = updatedData.map((d) => {
        if (d.index === index) {
          return { ...d, visibilityState: !d.visibilityState };
        } else {
          return d;
        }
      });
      return newData;
    });
  };

  const handleLegendOptions = (e) => {
    const { id, value } = e.target;
    if (value === "" || value === undefined) {
      setData((prevData) => {
        const updatedData = [...prevData].filter(
          (d) => !d.index.includes(`${id}-CAT`)
        );
        const tempIndex = updatedData.findIndex((d) => d.index === id);
        const newData = updatedData[tempIndex];

        const accountIndex = adminAccountInfo.findIndex(
          (data) => data.uid === account
        );
        const selectedAccountData = JSON.parse(
          JSON.stringify(adminAccountInfo[accountIndex])
        );
        let filterEndpoints = newData.filterEndpoint;

        const TempData = [
          ...selectedAccountData.data.filter((d) => d.id === newData.yaxisIds),
        ];
        const yaxis = TempData[0];
        const filterData = [...filterByDateRange([yaxis], calenderData)];
        let x_value = yaxis.data.map((d) => d.date);
        let y_value = yaxis.data.map((d) => d.value);
        let operationType = newData.groupBy;
        const { x, y } = aggegateCategoricalData(
          x_value,
          y_value,
          operationType,
          selectedAccountData,
          calenderData,
          filterEndpoints
        );

        const sourceDateData = selectedAccountData.data.find(
          (d) => d.id === "date_1234"
        );
        const date = x.map(
          (d) => sourceDateData.data.find((v) => v.date === d).value
        );

        newData.x = date.length > 0 ? date : [];
        newData.y = y.length > 0 ? y : [];
        newData.text =
          y.length > 0
            ? y.map((v) => `${convertDisplayDataUnit(v, layout.displayUnits)}`)
            : [];
        newData.legendId = "";
        newData.name = filterData[0].name + "-" + operationType;
        updatedData[tempIndex] = newData;
        return updatedData;
      });
    } else {
      setData((prevData) => {
        let updatedData = [...prevData];
        const tempIndex = updatedData.findIndex((d) => d.index === id);

        if (updatedData[tempIndex].yaxisIds === "") {
          updatedData[tempIndex] = {
            ...updatedData[tempIndex],
            legendId: value,
          };
        } else {
          const newData = updatedData[tempIndex];

          updatedData = [...prevData].filter((d) => !d.index.includes(id));
          const accountIndex = adminAccountInfo.findIndex(
            (data) => data.uid === account
          );
          const selectedAccountData = JSON.parse(
            JSON.stringify(adminAccountInfo[accountIndex])
          );
          const filterEndpoints = newData.filterEndpoint;
          const sourceDateData = selectedAccountData.data.find(
            (d) => d.id === "date_1234"
          );
          const TempData = [
            ...selectedAccountData.data.filter(
              (d) => d.id === newData.yaxisIds
            ),
          ];

          const legendId = value;
          const groupBy = newData.groupBy;
          const yaxis = TempData[0];

          const legend = selectedAccountData.data.find(
            (d) => d.id === legendId
          );
          const filterLegendData = [
            ...filterByDateRange([legend], calenderData),
          ];
          const filterSourceDateData = [
            ...filterByDateRange([sourceDateData], calenderData),
          ];
          const filterYaxisData = [...filterByDateRange([yaxis], calenderData)];

          const dateData = filterSourceDateData[0].data;
          const legendData = filterLegendData[0].data;
          const yaxisData = filterYaxisData[0].data;

          const resultData = handleGroupAndAggregateData(
            dateData,
            yaxisData,
            legendData,
            groupBy,
            selectedAccountData,
            calenderData,
            filterEndpoints
          );

          for (let i = 0; i < resultData.length; i++) {
            let name =
              yaxis.name + "-" + resultData[i].name + "-" + newData.groupBy;
            let hoverText = handleHoverTemplate(newData.orientation, name);
            const newplot = {
              ...newData,
              x: resultData[i]["data"].map((d) => d.date),
              y: resultData[i]["data"].map((d) => d.value),
              text: resultData[i]["data"].map(
                (d) => `${convertDisplayDataUnit(d.value, layout.displayUnits)}`
              ),
              name: name,
              chartType: i === 0 ? "actual" : "CAT",
              yaxisIds: yaxis.id,
              yaxisDataType: yaxis.data_type,
              index: i === 0 ? newData.index : `${newData.index}-CAT-${i}`,
              hovertemplate: hoverText,
              legendId: legendId,
            };
            updatedData = [...updatedData, newplot];
          }
        }
        return updatedData;
      });
    }
  };

  const handlePriorYear = (data) => {
    const prevCalanderData = {
      ...calenderData,
      year_index: calenderData.year_index - 1,
    };
    const accountIdx = [...adminAccountInfo].findIndex(
      (data) => data.uid === account
    );
    const selectedAccountDataPr = JSON.parse(
      JSON.stringify(adminAccountInfo[accountIdx])
    );

    let filterEndpoints = data.filterEndpoint;

    if (data.xaxisDataType === "datetime") {
      const yaxisTempIndex = selectedAccountDataPr.data.findIndex(
        (acc) => acc.id === data.yaxisIds
      );
      const filteredDataPr = [
        ...filterByDateRange(
          [selectedAccountDataPr.data[yaxisTempIndex]],
          prevCalanderData
        ),
      ];

      let x_value = filteredDataPr[0].data.map((obj) => obj.date);
      let y_value = filteredDataPr[0].data.map((obj) => obj.value);

      let operationType = data.groupBy;
      const { x, y } = aggegateCategoricalData(
        x_value,
        y_value,
        operationType,
        selectedAccountDataPr,
        prevCalanderData,
        filterEndpoints
      );
      data.x = x.map((d) => transformDate(d, 1));
      data.y = y.length > 0 ? y : [];
      data.text =
        y.length > 0
          ? y.map((v) => `${convertDisplayDataUnit(v, layout.displayUnits)}`)
          : [];
      data.hovertemplate = handleHoverTemplate(data.orientation, data.name);
      return data;
    }
  };

  const handleYearToDate = (data) => {
    const accountIdx = adminAccountInfo.findIndex(
      (data) => data.uid === account
    );
    const selectedAccountDataYtd = JSON.parse(
      JSON.stringify(adminAccountInfo[accountIdx])
    );

    let filterEndpoints = data.filterEndpoint;
    const tempIdx = selectedAccountDataYtd.data.findIndex(
      (acc) => acc.id === data.yaxisIds
    );
    const filteredDataYtd = [
      ...filterByDateRange(
        [selectedAccountDataYtd.data[tempIdx]],
        calenderData
      ),
    ];

    let x_value = filteredDataYtd[0].data.map((obj) => obj.date);
    let y_value = filteredDataYtd[0].data.map((obj) => obj.value);

    let operationType = data.groupBy;
    const { y } = aggegateCategoricalData(
      x_value,
      y_value,
      operationType,
      selectedAccountDataYtd,
      calenderData,
      filterEndpoints
    );

    let cum_y_data = y.reduce(
      (acc, val) => [...acc, acc.length > 0 ? acc[acc.length - 1] + val : val],
      []
    );
    data.y = cum_y_data.length > 0 ? cum_y_data : [];
    data.text =
      cum_y_data.length > 0
        ? cum_y_data.map(
            (v) => `${convertDisplayDataUnit(v, layout.displayUnits)}`
          )
        : [];
    data.hovertemplate = handleHoverTemplate(data.orientation, data.name);

    setLayout((preLayout) => {
      if (!preLayout.hasOwnProperty("yaxis2")) {
        const updatedLayout = { ...preLayout };
        updatedLayout["yaxis2"] = {
          ...updatedLayout.yaxis,
          title: {
            ...updatedLayout["yaxis"].title,
            text: updatedLayout["yaxis"].title.text + " YTD",
          },
          gridcolor: "rgb(211, 211, 211)",
          griddash: "dot",
          overlaying: "y",
          side: "right",
        };
        return updatedLayout;
      } else {
        return preLayout;
      }
    });
    return data;
  };

  const handleDeleteFeild = (e) => {
    const key = e.target.getAttribute("name");
    if (data.length > 1) {
      setData((prevData) => {
        let updatedData = [...prevData];
        let newData = updatedData.filter(
          (d) =>
            d.index !== key &&
            d.index !== key + "-PRY" &&
            d.index !== key + "-YTD" &&
            !d.index.includes(key + "-CAT")
        );
        let checkYTD = newData.findIndex((d) => d.chartType === "YTD");
        if (checkYTD === -1) {
          setLayout((preLayout) => {
            if ("yaxis2" in preLayout) {
              const { yaxis2, ...updatedLayout } = { ...preLayout };
              return updatedLayout;
            } else {
              return preLayout;
            }
          });
        }
        return newData;
      });
    }
  };

  const handleAddFeild = () => {
    setData((prevData) => {
      let updatedData = [
        ...prevData,
        {
          ...prevData[0],
          x: prevData[0].x,
          y: [],
          text: [],
          xaxisIds: prevData[0].xaxisIds,
          yaxisIds: "",

          groupBy: "sum",
          chartType: "actual",
          additionChartType: [],
          index: generateUniqueCode(),
        },
      ];
      return updatedData;
    });
  };

  const handleClear = () => {
    return new Promise((resolve, reject) => {
      try {
        setTitle("");
        setData(() => []);
        setVisual({});
        setLayout({});
        setFeaturesCategory({ xaxisCategory: [], yaxisCategory: [] });
        setStatus(false);
        setCharts(baseChartsConfig);
        resolve("Cleared successfully");
      } catch (error) {
        reject(error);
      }
    });
  };

  const selectFilterData = (id) => {
    let accountIndex = adminAccountInfo.findIndex((d) => d.uid === account);
    const selectedAccountData = adminAccountInfo[accountIndex];
    const filterData = selectedAccountData.data.find((d) => d.id === id);
    const filterValue = filterData.data.map((d) => d.value);
    const uniqueValues = [...new Set(filterValue)];
    if (uniqueValues.length > 0) {
      return uniqueValues;
    } else {
      return [];
    }
  };

  const handleAddFilterFeild = (id) => {
    setFilterActivity((preStatus) => {
      setData((prevData) => {
        const updatedData = [...prevData];
        const feildIndex = updatedData[id].index;
        const feildCode = generateUniqueCode();
        const newData = updatedData.map((d) => {
          if (d.index.includes(feildIndex)) {
            return {
              ...d,
              filterEndpoint: [
                ...d.filterEndpoint,
                { id: "", endpointList: [], feildCode: feildCode },
              ],
            };
          } else {
            return d;
          }
        });
        return newData;
      });
      return !preStatus;
    });
  };

  const handleRemoveFilterFeild = (id, name) => {
    setFilterActivity((preStatus) => {
      setData((prevData) => {
        const updatedData = [...prevData];
        const feildIndex = updatedData[id].index;
        const newData = updatedData.map((d) => {
          if (d.index.includes(feildIndex)) {
            return {
              ...d,
              filterEndpoint: d.filterEndpoint.filter(
                (x) => x.feildCode !== name
              ),
            };
          } else {
            return d;
          }
        });
        return newData;
      });
      return !preStatus;
    });
  };

  const handleAddFilterEndpoint = (e) => {
    setFilterActivity((preStatus) => {
      const { id, value, name } = e.target;
      setData((prevData) => {
        const updatedData = [...prevData];
        const feildIndex = updatedData[id].index;
        const feildCode = generateUniqueCode();
        const newData = updatedData.map((d) => {
          if (d.index.includes(feildIndex)) {
            if (d.filterEndpoint.length === 0) {
              d["filterEndpoint"] = [
                {
                  id: value,
                  feildCode: feildCode,
                  endpointList: value === "" ? [] : selectFilterData(value),
                },
              ];
            } else {
              d["filterEndpoint"][name] = {
                id: value,
                feildCode: d["filterEndpoint"][name].feildCode,
                endpointList: value === "" ? [] : selectFilterData(value),
              };
            }
            return d;
          } else {
            return d;
          }
        });

        return newData;
      });
      return !preStatus;
    });
  };

  const handleFilterParams = (e) => {
    const { name, id } = e.target;
    setFilterActivity((preStatus) => {
      setData((prevData) => {
        const updatedData = [...prevData];
        const feildIndex = updatedData[visual.selectedDataPoint.index].index;
        const newData = updatedData.map((d) => {
          if (d.index.includes(feildIndex)) {
            let filters =
              d["filterEndpoint"].length > 0
                ? d["filterEndpoint"].find((x) => x.id === id).endpointList
                : [];
            const tempFilterIndex = d["filterEndpoint"].findIndex(
              (x) => x.id === id
            );
            if (e.target.checked) {
              filters.push(name);
              d["filterEndpoint"][tempFilterIndex] = {
                ...d["filterEndpoint"][tempFilterIndex],
                endpointList: filters,
              };
              return d;
            } else {
              let updatedFilters = filters.filter((d) => d !== name);
              d["filterEndpoint"][tempFilterIndex] = {
                ...d["filterEndpoint"][tempFilterIndex],
                endpointList: updatedFilters,
              };

              return d;
            }
          } else {
            return d;
          }
        });
        return newData;
      });
      return !preStatus;
    });
  };

  useEffect(() => {
    if (fullScreenStatus !== plotState) {
      setPlotState((preData) => !preData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullScreenStatus]);

  useEffect(() => {
    if (updateVisualizationIndex !== null) {
      const accountIndex = adminAccountInfo.findIndex(
        (data) => data.uid === account
      );
      const selectedAccountData = { ...adminAccountInfo[accountIndex] };
      addKeysToObjects(
        selectedAccountData,
        updateVisualizationIndex,
        calenderData
      )
        .then((response) => {
          setVisual(response.settings);
          setLayout(response.settings.layout);
          setData(response.settings.data);
          setTitle(response.name);
          setStatus(false);
          handleFeature(response.settings, selectedAccountData);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // return () => {
    //   handleClear();
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAccountInfo, account, layout.displayUnits]);

  useEffect(() => {
    if (data.length > 0) {
      const accountIndex = adminAccountInfo.findIndex(
        (data) => data.uid === account
      );
      const selectedAccountData = JSON.parse(
        JSON.stringify(adminAccountInfo[accountIndex])
      );

      let plotData = {
        settings: {
          data: data,
          layout: layout,
        },
      };
      addKeysToObjects(
        selectedAccountData,
        null,
        calenderData,
        layout.displayUnits,
        plotData
      )
        .then((response) => {
          setLayout(response.settings.layout);
          setData(response.settings.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calenderData, layout.displayUnits, filterActivity]);

  useEffect(() => {
    if (tab && data.length > 0) {
      setVisual((preData) => {
        const updatedData = {
          ...preData,
          selectedDataPoint: {
            name: data[0].name,
            index: 0,
            id: data[0].yaxisIds,
            category: data[0].yaxisCategory,
          },
        };
        return updatedData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div
        className={
          fullScreenStatus
            ? "d-flex flex-row justify-content-between  bg-light piik-visual-section-fullscreen piik-create-chart "
            : "d-flex flex-row justify-content-between  bg-light piik-visual-section piik-create-chart "
        }
      >
        <div className="flex-grow-1">
          <div className="d-flex flex-column  h-100 w-100 ">
            <div className="flex-grow-1  w-100 h-100 d-flex flex-column ">
              <div className="d-flex flex-row justify-content-between  align-items-center bg-white px-4 py-2 shadow-sm">
                <h4 className="piik-text-dashboard  text-primary">{title}</h4>
                <i className="bi bi-info-circle text-primary"></i>
              </div>

              {visual.hasOwnProperty("data") &&
                plotState === fullScreenStatus && (
                  <PlotChart
                    data={data}
                    layout={layout}
                    config={config}
                    className="w-100 piik-text-visual flex-grow-1 bg-white shadow-sm "
                  />
                )}
            </div>
          </div>
        </div>
        <div
          className="  bg-light shadow-sm d-flex flex-column justify-content-start w-25 piik-create-bar "
          style={{ zIndex: 10 }}
        >
          <div className=" px-3 py-2 piik-text-dashboard border-bottom ">
            <div className=" d-flex flex-row justify-content-between align-items-center">
              <div className="fw-semibold text-primary"> Set Title</div>
              {updateVisualizationIndex === null && (
                <i
                  className="bi bi-eraser piik-pointer"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Clear All"
                  onClick={() => handleClear()}
                ></i>
              )}
            </div>
            <div className="my-2">
              <input
                type="text"
                className="form-control form-control-sm w-100"
                id="title-text"
                placeholder="Max 25 Character"
                value={title}
                onChange={handleInputChange}
              />
              {errorMessage !== "" && (
                <div className="pt-1 text-danger piik-text-warning">
                  {errorMessage}
                </div>
              )}
            </div>
          </div>

          {tab ? (
            <>
              <div className=" d-flex flex-row justify-content-between shadow-sm piik-text-dashboard ">
                <div
                  className="px-3 py-2 w-50 piik-pointer text-secondary"
                  onClick={() => setTab(true)}
                >
                  <div className="fw-semibold ">
                    <i className="bi bi-pencil-square"></i>
                    <span className="px-2">Build</span>
                  </div>
                </div>
                <div className="fw-semibold px-3 py-2 w-50 piik-pointer  bg-primary text-white ">
                  <div className="fw-semibold" onClick={() => setTab(false)}>
                    <i className="bi bi-gear"></i>
                    <span className="px-2">Settings</span>
                  </div>
                </div>
              </div>

              <div className=" px-3 pt-2 pb-3 piik-text-dashboard ">
                <div className=" d-flex flex-row justify-content-between align-items-center mb-2">
                  <div className="fw-semibold text-primary">
                    Select Chart Type
                  </div>
                  {updateVisualizationIndex === null && (
                    <i
                      className="bi bi-eraser piik-pointer"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Clear Data"
                      onClick={() => handleClear()}
                    ></i>
                  )}
                </div>

                <select
                  className="form-select form-select-sm"
                  id="visual"
                  value={(visual && visual.visualId) || ""}
                  onChange={handleInputChange}
                >
                  <option value="" selected>
                    Select
                  </option>
                  {charts.map((chart, index) => {
                    return (
                      <option key={`chartIcon-${index}`} value={chart.visualId}>
                        {chart.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className=" px-3 py-2 shadow-sm piik-text-dashboard border-top ">
                <div className=" d-flex flex-row justify-content-between align-items-center">
                  <div className="fw-semibold text-primary"> Add Datapoint</div>
                </div>
              </div>
              {data.length > 0 && (
                <div className=" piik-text-visual flex-grow-1 overflow-y-scroll ">
                  <div className="mb-2 border-top pb-1 ">
                    <div className="bg-white px-3 py-2 shadow-sm ">
                      <div className="piik-text-visual fw-semibold ">
                        {data.length > 0 && data[0].orientation === "v"
                          ? "X-axis"
                          : "Y-axis"}
                      </div>
                    </div>
                    <div className="px-3 py-1">
                      <div className="piik-text-visual py-1">Category</div>
                      <select
                        className="form-select form-select-sm "
                        id="xaxis-value"
                        value={(data.length > 0 && data[0].xaxisCategory) || ""}
                        onChange={handleDataCategory}
                      >
                        <option selected value="">
                          Select
                        </option>
                        {featuresCategory.xaxisCategory.map((data) => {
                          return (
                            <option key={data} value={data}>
                              <span className="text-uppercase">{data}</span>
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="px-3 py-1">
                      <div className="piik-text-visual py-1">Datapoint</div>
                      <select
                        className="form-select form-select-sm "
                        id="xaxis-value"
                        name={data[0].index}
                        value={(data.length > 0 && data[0].xaxisIds) || ""}
                        onChange={handleValueChange}
                      >
                        <option value="" selected>
                          Select Data
                        </option>

                        {features &&
                          data.length > 0 &&
                          features.xaxisFeatures.length > 0 &&
                          [...features.xaxisFeatures]
                            .filter((d) => d.category === data[0].xaxisCategory)
                            .filter((d) => d.tags !== "H")
                            .map((d) => {
                              return (
                                <>
                                  d.status&&
                                  <option key={d.id} value={d.id}>
                                    {d.name}{" "}
                                    {d.created && d.created === "custom"
                                      ? " *"
                                      : null}
                                  </option>
                                </>
                              );
                            })}
                      </select>
                    </div>
                  </div>

                  {features.yaxisFeatures.length > 0 &&
                    data.length > 0 &&
                    data[0].xaxisIds !== "" &&
                    data.map((d, index) => {
                      return (
                        <>
                          {d.chartType !== "YTD" &&
                            d.chartType !== "PRY" &&
                            d.chartType !== "CAT" && (
                              <div
                                className="border-top pb-1"
                                key={`y_axis_${index}`}
                              >
                                <div className="d-flex flex-row justify-content-between align-items-center px-3 py-1 bg-white shadow-sm">
                                  <div className="piik-text-visual fw-semibold">
                                    {data.length > 0 && d.orientation === "v"
                                      ? "Y-axis"
                                      : "X-axis"}
                                  </div>

                                  <div className="d-flex flex-row justify-content-end ">
                                    <div className="form-check form-switch px-2 py-1 ">
                                      {data.length -
                                        data.filter((v) =>
                                          ["PRY", "YTD", "CAT"].some((text) =>
                                            v.chartType.includes(text)
                                          )
                                        ).length >
                                        1 && (
                                        <span
                                          name={d.index}
                                          className="bi bi-trash px-2 piik-pointer px-2"
                                          onClick={handleDeleteFeild}
                                        ></span>
                                      )}

                                      <span
                                        className="bi bi-plus ps-2 piik-pointer"
                                        onClick={handleAddFeild}
                                      ></span>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex flex-row row-justify-content-between px-3 py-1 ">
                                  {d.xaxisDataType === "datetime" && (
                                    <div className=" pe-1 w-100 ">
                                      <div className="piik-text-visual py-1">
                                        Category
                                      </div>
                                      <select
                                        className="form-select form-select-sm "
                                        name={d.index}
                                        id={`yaxis-category-${index}`}
                                        value={
                                          (data.length > 0 &&
                                            data[index].yaxisCategory) ||
                                          ""
                                        }
                                        onChange={handleDataCategory}
                                      >
                                        <option selected value="">
                                          Select
                                        </option>
                                        {featuresCategory.yaxisCategory.map(
                                          (name) => {
                                            return (
                                              <option key={name} value={name}>
                                                <span className="text-uppercase">
                                                  {name}
                                                </span>
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                    </div>
                                  )}

                                  <div className="w-100">
                                    <div className="piik-text-visual py-1">
                                      Aggegate By
                                    </div>
                                    <select
                                      className="form-select form-select-sm"
                                      id={d.yaxisIds}
                                      value={d.groupBy}
                                      name={d.index}
                                      onChange={handleDataAggregate}
                                    >
                                      {d.yaxisDataType !== "str" && (
                                        <option value="sum" selected>
                                          Sum
                                        </option>
                                      )}
                                      <option value="count">Count</option>
                                      <option value="distinct">Distinct</option>
                                      {d.yaxisDataType !== "str" && (
                                        <option value="mean">Mean</option>
                                      )}
                                      {d.yaxisDataType !== "str" && (
                                        <option value="max">Max</option>
                                      )}
                                      {d.yaxisDataType !== "str" && (
                                        <option value="min">Min</option>
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="px-3 py-1">
                                  <div className="d-flex flex-row align-items-center justify-content-between py-1">
                                    <div className="piik-text-visual ">
                                      Datapoint
                                    </div>

                                    <div className="d-flex flex-row align-items-center justify-content-between ">
                                      {d.xaxisDataType !== "str" &&
                                        d.xaxisDataType !== "int" &&
                                        d.xaxisDataType !== "float" &&
                                        d.yaxisIds !== "" && (
                                          <BottomIcon
                                            status={
                                              d.additionChartType.length > 0 &&
                                              d.additionChartType.findIndex(
                                                (d) => d === "PRY"
                                              ) > -1
                                            }
                                            name="PRY"
                                            type={d.yaxisIds}
                                            code={`PRY-${d.index}`}
                                            handleChange={
                                              handleConnectedDataPoint
                                            }
                                          />
                                        )}
                                      {d.xaxisDataType !== "str" &&
                                        d.xaxisDataType !== "int" &&
                                        d.xaxisDataType !== "float" &&
                                        d.yaxisIds !== "" && (
                                          <BottomIcon
                                            status={
                                              d.additionChartType.length > 0 &&
                                              d.additionChartType.findIndex(
                                                (d) => d === "YTD"
                                              ) > -1
                                            }
                                            name="YTD"
                                            type={d.yaxisIds}
                                            code={`YTD-${d.index}`}
                                            handleChange={
                                              handleConnectedDataPoint
                                            }
                                          />
                                        )}
                                    </div>
                                  </div>
                                  {d.xaxisDataType === "datetime" ? (
                                    <select
                                      className="form-select form-select-sm"
                                      name={data[index].index}
                                      id={`yaxis-value-${index}`}
                                      value={
                                        (data.length > 0 &&
                                          data[index].yaxisIds) ||
                                        ""
                                      }
                                      onChange={handleValueChange}
                                    >
                                      <option value="" selected>
                                        Select Data
                                      </option>
                                      {features &&
                                        features.yaxisFeatures.length > 0 &&
                                        [...features.yaxisFeatures]
                                          .filter(
                                            (d) =>
                                              d.category ===
                                              data[index].yaxisCategory
                                          )
                                          .filter((d) => d.tags !== "H")
                                          .map((v) => {
                                            return (
                                              <option key={v.id} value={v.id}>
                                                {v.name}{" "}
                                                {v.created &&
                                                v.created === "custom"
                                                  ? " *"
                                                  : null}
                                              </option>
                                            );
                                          })}
                                    </select>
                                  ) : (
                                    <select
                                      className="form-select form-select-sm"
                                      name={data[index].index}
                                      id={`yaxis-value-${index}`}
                                      value={
                                        (data.length > 0 &&
                                          data[index].yaxisIds) ||
                                        ""
                                      }
                                      onChange={handleValueChange}
                                    >
                                      <option value="" selected>
                                        Select Data
                                      </option>
                                      {features &&
                                        features.xaxisFeatures.length > 0 &&
                                        [...features.yaxisFeatures]
                                          .filter((f) => f.tags !== "H")
                                          .map((v) => {
                                            return (
                                              <option key={v.id} value={v.id}>
                                                {v.name}{" "}
                                                {v.created &&
                                                v.created === "custom"
                                                  ? " *"
                                                  : null}
                                              </option>
                                            );
                                          })}
                                    </select>
                                  )}
                                </div>
                                {d.xaxisDataType !== "str" &&
                                  d.xaxisDataType !== "int" &&
                                  d.xaxisDataType !== "float" &&
                                  d.yaxisIds !== "" &&
                                  d.additionChartType.length === 0 && (
                                    <div className="px-3 py-1 mb-2">
                                      <div className="d-flex flex-row justify-content-between">
                                        <div className="piik-text-visual py-1">
                                          Add Legend
                                        </div>
                                        <div className="piik-text-visual py-1">
                                          <i
                                            className="bi bi-eraser piik-pointer"
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title="Clear Legend"
                                            id={d.index}
                                            onClick={handleLegendOptions}
                                          ></i>
                                        </div>
                                      </div>

                                      <select
                                        className="form-select form-select-sm"
                                        id={d.index}
                                        value={d.legendId}
                                        onChange={handleLegendOptions}
                                      >
                                        <option value="" selected>
                                          Select
                                        </option>
                                        {features &&
                                          features.xaxisFeatures.length > 0 &&
                                          [...features.yaxisFeatures]
                                            .filter(
                                              (x) =>
                                                x.tags !== "H" &&
                                                x.data_type === "str" &&
                                                x.category === d.yaxisCategory
                                            )
                                            .map((v) => {
                                              return (
                                                <option key={v.id} value={v.id}>
                                                  {v.name}{" "}
                                                  {v.created &&
                                                  v.created === "custom"
                                                    ? " *"
                                                    : null}
                                                </option>
                                              );
                                            })}
                                      </select>
                                    </div>
                                  )}
                              </div>
                            )}
                        </>
                      );
                    })}
                </div>
              )}
            </>
          ) : (
            <>
              {/*  Settings */}
              <div className=" d-flex flex-row justify-content-between shadow-sm piik-text-dashboard ">
                <div
                  className=" px-3 py-2 w-50 piik-pointer bg-primary text-white"
                  onClick={() => setTab(true)}
                >
                  <div className="fw-semibold  ">
                    <i className="bi bi-pencil-square "></i>
                    <span className="px-2">Build</span>
                  </div>
                </div>
                <div className="fw-semibold px-3 py-2 w-50 piik-pointer  text-secondary ">
                  <div className="fw-semibold" onClick={() => setTab(false)}>
                    <i className="bi bi-gear "></i>
                    <span className="px-2">Settings</span>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row justify-content-start align-items-center border-bottom">
                <Tab
                  label="Layout Style"
                  active={styleTab}
                  onClick={() => setStyleTab(!styleTab)}
                />
                <Tab
                  label="Data"
                  active={!styleTab}
                  onClick={() => setStyleTab(!styleTab)}
                />
              </div>

              {/*  Layout */}
              {visual && visual.selectedDataPoint && styleTab ? (
                <div className=" piik-text-visual overflow-y-scroll flex-grow-1 ">
                  <div className="mb-2 border-top">
                    <div className="bg-white px-3 py-2  d-flex flex-row justify-content-between shadow-sm ">
                      <div className="piik-text-visual fw-semibold ">
                        {data.length > 0 && data[0].orientation === "v"
                          ? "X-axis"
                          : "Y-axis"}
                      </div>
                    </div>
                    <div className="px-3 py-1">
                      <div className="piik-text-visual py-1">Label Text</div>
                      <input
                        type="text"
                        className="form-control form-control-sm w-100"
                        placeholder="Type Here..."
                        id="xlabel-text"
                        value={
                          layout &&
                          layout.xaxis &&
                          layout.xaxis.title &&
                          layout.xaxis.title.text
                        }
                        name="xaxis"
                        onChange={handleLayoutStyle}
                        disabled={status}
                      />
                    </div>

                    <div className="px-3 py-1">
                      <div className="mb-1"> Label Size</div>
                      <select
                        className="form-select form-select-sm"
                        id="xlabel-font"
                        name="xaxis"
                        value={
                          layout &&
                          layout.xaxis &&
                          layout.xaxis.title &&
                          layout.xaxis.title.font &&
                          layout.xaxis.title.font.size
                        }
                        onChange={handleLayoutStyle}
                        disabled={status}
                      >
                        <option value={12} selected>
                          Default
                        </option>
                        {textSize.map((d) => {
                          return <option value={d.value}>{d.name}</option>;
                        })}
                      </select>
                    </div>
                    <div className="px-3 py-1">
                      <div className="mb-1">Tick Size</div>
                      <select
                        className="form-select form-select-sm"
                        id="xlabel-tickfont"
                        name="xaxis"
                        value={
                          layout &&
                          layout.xaxis &&
                          layout.xaxis.tickfont &&
                          layout.xaxis.tickfont.size
                        }
                        onChange={handleLayoutStyle}
                        disabled={status}
                      >
                        <option value={12} selected>
                          Default
                        </option>
                        {textSize.map((d) => {
                          return <option value={d.value}>{d.name}</option>;
                        })}
                      </select>
                    </div>
                    <div className="px-3 pt-2 d-flex flex-row justify-content-between">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="xlabel-showgrid"
                          name="xaxis"
                          checked={
                            layout && layout.xaxis && layout.xaxis.showgrid
                          }
                          onChange={handleLayoutStyle}
                          disabled={status}
                        />
                        <div className="mb-1"> Grid</div>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="xlabel-showline"
                          name="xaxis"
                          checked={
                            layout && layout.xaxis && layout.xaxis.showline
                          }
                          onChange={handleLayoutStyle}
                          disabled={status}
                        />
                        <div className="mb-1"> Line</div>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="xlabel-showticklabels"
                          name="xaxis"
                          checked={
                            layout &&
                            layout.xaxis &&
                            layout.xaxis.showticklabels
                          }
                          onChange={handleLayoutStyle}
                          disabled={status}
                        />
                        <div className="mb-1"> Tick</div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-2  border-top">
                    <div className="bg-white px-3 py-2  d-flex flex-row justify-content-between shadow-sm ">
                      <div className="piik-text-visual fw-semibold ">
                        {data.length > 0 && data[0].orientation === "v"
                          ? "Y-axis"
                          : "X-axis"}
                      </div>
                    </div>
                    <div className="px-3 py-1">
                      <div className="piik-text-visual py-1">Label Text</div>
                      <input
                        type="text"
                        className="form-control form-control-sm w-100"
                        placeholder="Type Here..."
                        id="ylabel-text"
                        value={
                          layout &&
                          layout.yaxis &&
                          layout.yaxis.title &&
                          layout.yaxis.title.text
                        }
                        name="yaxis"
                        onChange={handleLayoutStyle}
                        disabled={status}
                      />
                    </div>

                    <div className="px-3 py-1">
                      <div className="mb-1">Label Size</div>
                      <select
                        className="form-select form-select-sm"
                        id="ylabel-font"
                        name="yaxis"
                        value={
                          layout &&
                          layout.yaxis &&
                          layout.yaxis.title &&
                          layout.yaxis.title.font &&
                          layout.yaxis.title.font.size
                        }
                        onChange={handleLayoutStyle}
                        disabled={status}
                      >
                        <option value={12} selected>
                          Default
                        </option>
                        {textSize.map((d) => {
                          return <option value={d.value}>{d.name}</option>;
                        })}
                      </select>
                    </div>
                    <div className="px-3 py-1">
                      <div className="mb-1">Tick Size</div>
                      <select
                        className="form-select form-select-sm"
                        id="ylabel-tickfont"
                        name="yaxis"
                        value={
                          layout &&
                          layout.yaxis &&
                          layout.yaxis.tickfont &&
                          layout.yaxis.tickfont.size
                        }
                        onChange={handleLayoutStyle}
                        disabled={status}
                      >
                        <option value={12} selected>
                          Default
                        </option>
                        {textSize.map((d) => {
                          return <option value={d.value}>{d.name}</option>;
                        })}
                      </select>
                    </div>
                    <div className="px-3 pt-2 d-flex flex-row justify-content-between">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="ylabel-showgrid"
                          name="yaxis"
                          checked={
                            layout && layout.yaxis && layout.yaxis.showgrid
                          }
                          onChange={handleLayoutStyle}
                          disabled={status}
                        />
                        <div className="mb-1"> Grid</div>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="ylabel-showline"
                          name="yaxis"
                          checked={
                            layout && layout.yaxis && layout.yaxis.showline
                          }
                          onChange={handleLayoutStyle}
                          disabled={status}
                        />
                        <div className="mb-1"> Line</div>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="ylabel-showticklabels"
                          name="yaxis"
                          checked={
                            layout &&
                            layout.yaxis &&
                            layout.yaxis.showticklabels
                          }
                          onChange={handleLayoutStyle}
                          disabled={status}
                        />
                        <div className="mb-1"> Tick</div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3  border-top">
                    <div className="bg-white px-3 py-2  d-flex flex-row justify-content-between shadow-sm ">
                      <div className="piik-text-visual fw-semibold ">
                        General
                      </div>
                    </div>
                    {data.length > 0 &&
                      data[0].xaxisDataType !== "datetime" && (
                        <div className="px-3 py-2">
                          <label className="form-label piik-text-visual mb-1">
                            Sort Plot
                          </label>

                          <select
                            className="form-select form-select-sm"
                            id="sortBy-code"
                            name="general"
                            onChange={handleLayoutStyle}
                            value={
                              layout && layout.sortBy && layout.sortBy.code
                            }
                            disabled={status}
                          >
                            <option value="" selected>
                              Select
                            </option>
                            <option value="A">Ascending</option>
                            <option value="D">Descending</option>
                            <option value="AN">Botton N value</option>
                            <option value="DN">Top N value</option>
                          </select>
                          {layout &&
                            layout.sortBy &&
                            (layout.sortBy.code === "AN" ||
                              layout.sortBy.code === "DN") && (
                              <select
                                className="form-select form-select-sm mt-2"
                                id="sortBy-nValue"
                                name="general"
                                onChange={handleLayoutStyle}
                                value={
                                  layout &&
                                  layout.sortBy &&
                                  layout.sortBy.nValue
                                }
                                disabled={status}
                              >
                                <option value="" selected>
                                  Select N Value
                                </option>
                                <option value={3}>3</option>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                              </select>
                            )}
                        </div>
                      )}
                    <div className="px-3 py-2">
                      <label className="form-label piik-text-visual mb-1">
                        Plot Orientation
                      </label>

                      <select
                        className="form-select form-select-sm"
                        id="visualBarOrientation"
                        value={(data && data[0].orientation) || ""}
                        onChange={handleBarPlotDirection}
                      >
                        <option value="h">Horizontal</option>
                        <option value="v">Vertical</option>
                      </select>
                    </div>
                    {visual && visual.visualId === 3 ? (
                      <div className="px-3 py-2">
                        <label className="form-label piik-text-visual mb-1">
                          Bar Mode
                        </label>
                        <select
                          className="form-select form-select-sm"
                          id="visualBarMode"
                          value={(layout && layout.barmode) || ""}
                          onChange={handleInputChange}
                        >
                          <option value="group">Group</option>
                          <option value="stack">Stack</option>
                          <option value="overlay">Overlay</option>
                          <option value="relative">Relative</option>
                        </select>
                      </div>
                    ) : null}

                    <div className="px-3 py-2">
                      <div className="pb-1"> Legend Position</div>
                      <select
                        className="form-select form-select-sm"
                        id="legend-position"
                        name="general"
                        onChange={handleLayoutStyle}
                        value={
                          layout &&
                          layout.legend &&
                          handleLegendTextPosition(layout.legend, true)
                        }
                        disabled={status}
                      >
                        <option value="" selected>
                          Select
                        </option>
                        {legendPosition.map((data) => {
                          return (
                            <option value={data.value}>{data.title}</option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="px-3 py-2">
                      <div className="pb-1"> Legend Orientation</div>
                      <select
                        className="form-select form-select-sm"
                        id="legend-orientation"
                        name="general"
                        onChange={handleLayoutStyle}
                        value={
                          layout && layout.legend && layout.legend.orientation
                        }
                        disabled={status}
                      >
                        <option value="" selected>
                          Select
                        </option>
                        <option value="v">Vertical</option>
                        <option value="h">Horizontal</option>
                      </select>
                    </div>
                    <div className="px-3 pb-2">
                      <div className="py-1"> Display Units</div>
                      <select
                        className="form-select form-select-sm"
                        id="displayUnits"
                        value={layout ? layout.displayUnits : ""}
                        name="general"
                        onChange={handleLayoutStyle}
                        disabled={status}
                      >
                        <option value="" selected>
                          Select Units
                        </option>
                        {displayUnits.map((data) => {
                          return (
                            <option value={data.value}>{data.title}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="px-3 pb-2">
                      <div className="py-1"> Hover Mode</div>

                      <select
                        className="form-select form-select-sm"
                        id="hovermode"
                        value={layout ? layout.hovermode : ""}
                        name="general"
                        onChange={handleLayoutStyle}
                        disabled={status}
                      >
                        <option value="" selected>
                          Select Mode
                        </option>
                        {hoverPosition.map((data) => {
                          return (
                            <option value={data.value}>{data.title}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="px-3 pb-2">
                      <div className="row">
                        {marginDirection.map((data) => {
                          return (
                            <div className="col-6 pb-2">
                              <div className="pb-1">
                                {data.name === "Padding"
                                  ? data.name
                                  : `Margin-${data.name}`}
                              </div>
                              <input
                                className="form-control form-control-sm "
                                type="number"
                                id={`margin-${data.value}`}
                                name="general"
                                value={
                                  layout &&
                                  layout.margin &&
                                  layout.margin[`${data.value}`]
                                }
                                onChange={handleLayoutStyle}
                                disabled={status}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* DataPoint */}
              {!styleTab && (
                <div className="d-flex flex-row justify-content-start align-items-center border-bottom">
                  <Tab
                    label="Edit Style"
                    active={dataTab}
                    onClick={() => setDataTab(!dataTab)}
                  />
                  <Tab
                    label="Add Filter"
                    active={!dataTab}
                    onClick={() => setDataTab(!dataTab)}
                  />
                </div>
              )}

              {!styleTab && dataTab ? (
                <>
                  {data.length > 0 && data[0].y.length > 0 && !styleTab && (
                    <div className="px-3 py-2  border-bottom shadow-sm">
                      <div className="mb-2">
                        <div className="mb-1 piik-text-visual">
                          Select Datapoint
                        </div>
                        <select
                          className="form-select form-select-sm"
                          name="dataPoint"
                          id="selectedDataPoint"
                          value={
                            visual &&
                            visual.selectedDataPoint &&
                            visual.selectedDataPoint.name
                          }
                          onChange={handleDataStyle}
                          disabled={status}
                        >
                          <option value="" selected>
                            Select..
                          </option>
                          {data.length > 0 &&
                            data.map((d) => {
                              return (
                                <option value={d.name} selected>
                                  {d.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="mb-2">
                        <div className="mb-1 piik-text-visual">
                          Change Plot Type
                        </div>
                        <select
                          className="form-select form-select-sm"
                          id="plotStyle"
                          value={
                            visual &&
                            visual.selectedDataPoint &&
                            visual.selectedDataPoint.index !== null &&
                            data.length > 0 &&
                            charts.find(
                              (d) =>
                                d.name ===
                                data[visual.selectedDataPoint.index].plotName
                            ).visualId
                          }
                          onChange={handleChangePlotType}
                        >
                          <option value="" selected>
                            Select
                          </option>
                          {charts.map((chart) => {
                            return (
                              <option value={chart.visualId}>
                                {chart.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {data &&
                        data.length > 0 &&
                        (data[visual.selectedDataPoint.index].index.includes(
                          "CAT"
                        ) ||
                          data[visual.selectedDataPoint.index].legendIds !==
                            "") && (
                          <div className="d-flex flex-row justify-content-end ">
                            <div className=" form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id={
                                  data &&
                                  data.length > 0 &&
                                  data[visual.selectedDataPoint.index].index
                                }
                                name="xaxis"
                                checked={
                                  data &&
                                  data.length > 0 &&
                                  data[visual.selectedDataPoint.index]
                                    .visibilityState
                                }
                                onChange={handlePlotStatus}
                              />
                            </div>
                            <div className="px-2 piik-text-visual">
                              Visibility Status
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                  {visual &&
                  visual.selectedDataPoint &&
                  visual.selectedDataPoint.index !== null &&
                  !styleTab ? (
                    <div className=" piik-text-visual overflow-y-scroll flex-grow-1 ">
                      <div className="px-3 py-2 bg-white fw-semibold border-top shadow-sm">
                        Text Style
                      </div>
                      <div className="px-3 py-2 ">
                        <div className="mb-2">
                          <div className="mb-1"> Rename Datapoint</div>
                          <input
                            className="form-control form-control-sm w-100"
                            placeholder="Type Here..."
                            name="name"
                            id="text-name"
                            onChange={handleDataStyle}
                            value={
                              data &&
                              data.length > 0 &&
                              data[visual.selectedDataPoint.index].name
                            }
                            disabled={status}
                          />
                        </div>
                        <div className="mb-2">
                          <div className="mb-1"> Position</div>
                          <select
                            className="form-select form-select-sm"
                            name="text"
                            id="text-textposition"
                            onChange={handleDataStyle}
                            value={
                              data &&
                              data.length > 0 &&
                              data[visual.selectedDataPoint.index].textposition
                            }
                            disabled={status}
                          >
                            <option value="" selected>
                              Select Position
                            </option>
                            {textPosition
                              .filter(
                                (d) =>
                                  d.type ===
                                  data[visual.selectedDataPoint.index].type
                              )
                              .map((d) => {
                                return (
                                  <option value={d.value}>{d.position}</option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="mb-2">
                          <div className="mb-1"> Color</div>
                          <select
                            className="form-select form-select-sm"
                            id="text-color"
                            name="text"
                            onChange={handleDataStyle}
                            value={
                              data &&
                              data.length > 0 &&
                              data[visual.selectedDataPoint.index].textfont &&
                              data[visual.selectedDataPoint.index].textfont
                                .color
                            }
                            disabled={status}
                          >
                            <option value="" selected>
                              Select..
                            </option>
                            {colorsArray.map((d) => {
                              return <option value={d.rgb}>{d.name}</option>;
                            })}
                          </select>
                        </div>
                        <div className="mb-2">
                          <div className="mb-1">Size</div>
                          <select
                            className="form-select form-select-sm"
                            id="text-size"
                            name="text"
                            onChange={handleDataStyle}
                            value={
                              data &&
                              data.length > 0 &&
                              data[visual.selectedDataPoint.index].textfont &&
                              data[visual.selectedDataPoint.index].textfont.size
                            }
                            disabled={status}
                          >
                            <option value={12} selected>
                              Default
                            </option>
                            {textSize.map((d) => {
                              return <option value={d.value}>{d.name}</option>;
                            })}
                          </select>
                        </div>
                        <div className="mb-2">
                          <div className="mb-1">Orientation</div>
                          <select
                            className="form-select form-select-sm"
                            id="text-textangle"
                            name="text"
                            onChange={handleDataStyle}
                            value={
                              data &&
                              data.length > 0 &&
                              data[visual.selectedDataPoint.index].textangle
                            }
                            disabled={status}
                          >
                            <option value={-90}>Vertical</option>
                            <option value={0} selected>
                              Horizontal
                            </option>
                          </select>
                        </div>
                      </div>
                      {data.length > 0 &&
                        data &&
                        data[visual.selectedDataPoint.index].type !== "bar" && (
                          <>
                            <div className="px-3 py-2 bg-white fw-semibold border-top shadow-sm">
                              Line Style
                            </div>
                            <div className="px-3 py-2 ">
                              <div className="mb-2">
                                <div className="mb-1"> Shape</div>
                                <select
                                  className="form-select form-select-sm"
                                  id="line-shape"
                                  name="line"
                                  onChange={handleDataStyle}
                                  value={
                                    data &&
                                    data.length > 0 &&
                                    data[visual.selectedDataPoint.index].line &&
                                    data[visual.selectedDataPoint.index].line
                                      .shape
                                  }
                                  disabled={status}
                                >
                                  <option value="" selected>
                                    Select..
                                  </option>
                                  {lineShape.map((d) => {
                                    return (
                                      <option value={d.value}>{d.title}</option>
                                    );
                                  })}
                                </select>
                              </div>

                              <div className="mb-2">
                                <div className="mb-1"> Fill Type</div>
                                <select
                                  className="form-select form-select-sm"
                                  id="line-fill"
                                  name="line"
                                  onChange={handleDataStyle}
                                  value={
                                    data &&
                                    data.length > 0 &&
                                    data[visual.selectedDataPoint.index].fill
                                  }
                                  disabled={status}
                                >
                                  <option value="" selected>
                                    Select..
                                  </option>
                                  {areaFillOptions.map((d) => {
                                    return (
                                      <option value={d.fill}>{d.name}</option>
                                    );
                                  })}
                                </select>
                              </div>
                              {data.length > 0 &&
                                data &&
                                data[visual.selectedDataPoint.index]
                                  .plotName === "Line" && (
                                  <>
                                    <div className="mb-2">
                                      <div className="mb-1"> Type</div>
                                      <select
                                        className="form-select form-select-sm"
                                        id="line-dash"
                                        name="line"
                                        onChange={handleDataStyle}
                                        value={
                                          data &&
                                          data.length > 0 &&
                                          data[visual.selectedDataPoint.index]
                                            .line &&
                                          data[visual.selectedDataPoint.index]
                                            .line.dash
                                        }
                                        disabled={status}
                                      >
                                        <option value="" selected>
                                          Select..
                                        </option>
                                        {linetype.map((d) => {
                                          return (
                                            <option value={d.value}>
                                              {d.title}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>

                                    <div className="mb-2">
                                      <div className="mb-1">Color</div>
                                      <select
                                        className="form-select form-select-sm"
                                        id="line-color"
                                        name="line"
                                        onChange={handleDataStyle}
                                        value={
                                          data &&
                                          data.length > 0 &&
                                          data[visual.selectedDataPoint.index]
                                            .line &&
                                          data[visual.selectedDataPoint.index]
                                            .line.color
                                        }
                                        disabled={status}
                                      >
                                        <option value="" selected>
                                          Select..
                                        </option>
                                        {colorsArray.map((d) => {
                                          return (
                                            <option value={d.rgb}>
                                              {d.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="mb-2">
                                      <div className="mb-1"> Width</div>
                                      <select
                                        className="form-select form-select-sm"
                                        id="line-width"
                                        name="line"
                                        onChange={handleDataStyle}
                                        value={
                                          data &&
                                          data.length > 0 &&
                                          data[visual.selectedDataPoint.index]
                                            .line &&
                                          data[visual.selectedDataPoint.index]
                                            .line.width
                                        }
                                        disabled={status}
                                      >
                                        <option value={1} selected>
                                          Select..
                                        </option>
                                        {lineWidth.map((d) => {
                                          return (
                                            <option value={d.value}>
                                              {d.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </>
                                )}
                            </div>
                          </>
                        )}

                      <div className="px-3 py-2 bg-white fw-semibold border-top shadow-sm">
                        Marker Style
                      </div>
                      <div className="px-3 py-2 ">
                        {data.length > 0 &&
                          data &&
                          data[visual.selectedDataPoint.index].type !==
                            "bar" && (
                            <div className="mb-2">
                              <div className="d-flex flex-row justify-content-between align-self-center mb-1"></div>
                              <div className="mb-1"> Symbol</div>
                              <select
                                className="form-select form-select-sm"
                                id="marker-symbol"
                                name="marker"
                                onChange={handleDataStyle}
                                value={
                                  data &&
                                  data.length > 0 &&
                                  data[visual.selectedDataPoint.index].marker &&
                                  data[visual.selectedDataPoint.index].marker
                                    .symbol
                                }
                                disabled={status}
                              >
                                <option value="" selected>
                                  Select..
                                </option>
                                {markerTypes.map((d) => {
                                  return (
                                    <option value={d.rgb}>{d.name}</option>
                                  );
                                })}
                              </select>
                            </div>
                          )}

                        <div className="mb-2">
                          <div className="d-flex flex-row justify-content-between align-self-center mb-1"></div>
                          <div className="mb-1"> Color</div>
                          <select
                            className="form-select form-select-sm"
                            id="marker-color"
                            name="marker"
                            onChange={handleDataStyle}
                            value={
                              data &&
                              data.length > 0 &&
                              data[visual.selectedDataPoint.index].marker &&
                              data[visual.selectedDataPoint.index].marker.color
                            }
                            disabled={status}
                          >
                            <option value="" selected>
                              Select..
                            </option>
                            {colorsArray.map((d) => {
                              return <option value={d.rgb}>{d.name}</option>;
                            })}
                          </select>
                        </div>
                        {data.length > 0 &&
                          data &&
                          data[visual.selectedDataPoint.index].type !==
                            "bar" && (
                            <div className="mb-2">
                              <div className="mb-1">Size</div>
                              <select
                                className="form-select form-select-sm"
                                id="marker-size"
                                name="marker"
                                onChange={handleDataStyle}
                                value={
                                  data &&
                                  data.length > 0 &&
                                  data[visual.selectedDataPoint.index].marker &&
                                  data[visual.selectedDataPoint.index].marker
                                    .size
                                }
                                disabled={status}
                              >
                                <option value={12} selected>
                                  Default
                                </option>
                                {textSize.map((d) => {
                                  return (
                                    <option value={d.value}>{d.name}</option>
                                  );
                                })}
                              </select>
                            </div>
                          )}
                        <div className="mb-2">
                          <div className="d-flex flex-row justify-content-between align-self-center mb-1"></div>
                          <div className="mb-1"> Opacity</div>
                          <select
                            className="form-select form-select-sm"
                            id="marker-opacity"
                            name="marker"
                            onChange={handleDataStyle}
                            value={
                              data &&
                              data.length > 0 &&
                              data[visual.selectedDataPoint.index].opacity
                            }
                            disabled={status}
                          >
                            <option value="" selected>
                              Select..
                            </option>
                            <option value=".2">Low</option>
                            <option value=".6">Medium</option>
                            <option value=".9">High</option>
                          </select>
                        </div>
                        {data.length > 0 &&
                          data &&
                          data[visual.selectedDataPoint.index].type ===
                            "bar" && (
                            <>
                              <div className="mb-2">
                                <div className="mb-1">Line Color</div>
                                <select
                                  className="form-select form-select-sm"
                                  id="marker-line-color"
                                  name="marker"
                                  onChange={handleDataStyle}
                                  value={
                                    data &&
                                    data.length > 0 &&
                                    data[visual.selectedDataPoint.index]
                                      .marker &&
                                    data[visual.selectedDataPoint.index].marker
                                      .line.color
                                  }
                                  disabled={status}
                                >
                                  <option value="" selected>
                                    Select..
                                  </option>
                                  {colorsArray.map((d) => {
                                    return (
                                      <option value={d.rgb}>{d.name}</option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="mb-2">
                                <div className="mb-1"> Line Width</div>
                                <select
                                  className="form-select form-select-sm"
                                  id="marker-line-width"
                                  name="marker"
                                  onChange={handleDataStyle}
                                  value={
                                    data &&
                                    data.length > 0 &&
                                    data[visual.selectedDataPoint.index]
                                      .marker &&
                                    data[visual.selectedDataPoint.index].marker
                                      .line.width
                                  }
                                  disabled={status}
                                >
                                  <option value={1} selected>
                                    Select..
                                  </option>
                                  {lineWidth.map((d) => {
                                    return (
                                      <option value={d.value}>{d.name}</option>
                                    );
                                  })}
                                </select>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                !styleTab && (
                  <>
                    {data.length > 0 && data[0].y.length > 0 && !styleTab && (
                      <div className="px-3 py-2  border-bottom shadow-sm">
                        <div className="mb-2">
                          <div className="mb-1 piik-text-visual">
                            Select Datapoint
                          </div>
                          <select
                            className="form-select form-select-sm"
                            name="dataPoint"
                            id="selectedDataPoint"
                            value={
                              visual &&
                              visual.selectedDataPoint &&
                              visual.selectedDataPoint.name
                            }
                            onChange={handleDataStyle}
                            disabled={status}
                          >
                            <option value="" selected>
                              Select..
                            </option>
                            {data.length > 0 &&
                              data
                                .filter((d) => d.chartType === "actual")
                                .map((d) => {
                                  return (
                                    <option value={d.name}>{d.name}</option>
                                  );
                                })}
                          </select>
                        </div>
                      </div>
                    )}

                    {visual.selectedDataPoint &&
                    visual.selectedDataPoint.index !== null &&
                    data &&
                    data.length > 0 &&
                    data[visual.selectedDataPoint.index].filterEndpoint.length >
                      0 ? (
                      <div className="overflow-y-scroll">
                        {data[
                          visual.selectedDataPoint.index
                        ].filterEndpoint.map((filterParams, index) => {
                          return (
                            <>
                              <div className="d-flex flex-column justify-content-between align-items-start px-3 py-2 bg-white shadow-sm">
                                <div className="piik-text-visual fw-semibold pb-2 d-flex flex-row justify-content-between w-100">
                                  <div>Filter</div>
                                  <div className="piik-text-visual d-flex flex-row justify-content-end w-100">
                                    {index > 0 && (
                                      <div
                                        className="bi bi-trash piik-pointer px-4"
                                        onClick={() =>
                                          handleRemoveFilterFeild(
                                            visual.selectedDataPoint.index,
                                            filterParams.feildCode
                                          )
                                        }
                                      ></div>
                                    )}

                                    <div
                                      className="bi bi-plus piik-pointer"
                                      onClick={() =>
                                        handleAddFilterFeild(
                                          visual.selectedDataPoint.index
                                        )
                                      }
                                    ></div>
                                  </div>
                                </div>
                                <select
                                  className="form-select form-select-sm mb-2"
                                  id={visual.selectedDataPoint.index}
                                  name={index}
                                  value={filterParams.id}
                                  onChange={handleAddFilterEndpoint}
                                >
                                  <option value="" selected>
                                    Select
                                  </option>
                                  {features &&
                                    features.xaxisFeatures.length > 0 &&
                                    [...features.yaxisFeatures]
                                      .filter(
                                        (x) =>
                                          x.tags !== "H" &&
                                          x.data_type === "str" &&
                                          x.category ===
                                            visual.selectedDataPoint.category
                                      )
                                      .map((v) => {
                                        return (
                                          <option value={v.id}>{v.name}</option>
                                        );
                                      })}
                                </select>
                              </div>
                              {filterParams.id !== undefined &&
                                filterParams.id !== "" &&
                                selectFilterData(filterParams.id).map(
                                  (datapoint) => {
                                    return (
                                      <div className=" form-check form-switch d-flex flex-row justify-content-between my-2 px-2">
                                        <label
                                          className="form-check-label piik-text-visual px-2"
                                          for="flexSwitchCheckChecked"
                                        >
                                          <span>{datapoint}</span>
                                        </label>
                                        <input
                                          className="form-check-input piik-pointer mx-2"
                                          type="checkbox"
                                          name={datapoint}
                                          id={filterParams.id}
                                          onChange={handleFilterParams}
                                          checked={filterParams.endpointList.some(
                                            (obj) => obj === datapoint
                                          )}
                                        />
                                      </div>
                                    );
                                  }
                                )}
                            </>
                          );
                        })}
                      </div>
                    ) : visual.selectedDataPoint &&
                      visual.selectedDataPoint.index !== null ? (
                      <div className="overflow-y-scroll">
                        <div className="d-flex flex-column justify-content-between align-items-start px-3 py-2 bg-white shadow-sm">
                          <div className="piik-text-visual fw-semibold pb-2 d-flex flex-row justify-content-between w-100">
                            <div>Filter</div>
                            <div className="bi bi-plus piik-pointer"></div>
                          </div>
                          <select
                            className="form-select form-select-sm mb-2"
                            id={
                              visual.selectedDataPoint &&
                              visual.selectedDataPoint.index !== null
                                ? visual.selectedDataPoint.index
                                : 0
                            }
                            name={0}
                            value={
                              data &&
                              data.length > 0 &&
                              data.find(
                                (d) =>
                                  d.yaxisIds === visual.selectedDataPoint.id
                              ) &&
                              data.find(
                                (d) =>
                                  d.yaxisIds === visual.selectedDataPoint.id
                              ).filterId
                            }
                            onChange={handleAddFilterEndpoint}
                          >
                            <option value="" selected>
                              Select
                            </option>
                            {features &&
                              features.xaxisFeatures.length > 0 &&
                              [...features.yaxisFeatures]
                                .filter(
                                  (x) =>
                                    x.tags !== "H" &&
                                    x.data_type === "str" &&
                                    x.category ===
                                      visual.selectedDataPoint.category
                                )
                                .map((v) => {
                                  return (
                                    <option key={v.id} value={v.id}>
                                      {v.name}
                                    </option>
                                  );
                                })}
                          </select>
                        </div>
                      </div>
                    ) : null}
                  </>
                )
              )}
            </>
          )}
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <div className="flex-grow-1 bg-light px-3 py-1 piik-text-visual">
          PRY - Prior Year | YTD - Year To Date
        </div>
        <div
          className="  bg-light shadow-sm d-flex flex-column justify-content-between w-25  piik-create-bar"
          style={{ zIndex: 10 }}
        >
          {submitStatus ? (
            <div className="p-2 piik-text-primary bg-dark-subtle text-center text-white piik-pointer">
              Processing
            </div>
          ) : updateVisualizationIndex === null ? (
            <>
              {features.xaxisFeatures.length > 0 ||
              features.yaxisFeatures.length > 0 ? (
                <div
                  className="p-2 piik-text-dashboard bg-primary text-center text-white piik-pointer"
                  onClick={handleSubmit}
                >
                  Save
                </div>
              ) : (
                <div className="p-2 piik-text-dashboard bg-secondary text-center text-white piik-pointer">
                  Save
                </div>
              )}
            </>
          ) : (
            <div
              className="p-2 piik-text-dashboard bg-primary text-center text-white piik-pointer"
              onClick={handleUpdate}
            >
              Update
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateChart;
