import generatePDF from "react-to-pdf";

export const usePdfGenerator = () => {
  const generateAndDownloadPdf = (
    adminAccountInfo,
    guestAccountInfo,
    account,
    targetRef,
    title
  ) => {
    const CombinedChartData = adminAccountInfo.concat(guestAccountInfo);
    const accountIndex = CombinedChartData.findIndex(
      (data) => data.uid === account
    );
    const selectedAccountData = JSON.parse(
      JSON.stringify(CombinedChartData[accountIndex])
    );

    const company_name = selectedAccountData.company_name;
    const currentDate = new Date();
    const isoDate = currentDate.toISOString().split("T")[0];

    const fileName = company_name + "_" + title + "_" + isoDate + ".pdf";
    generatePDF(targetRef, { filename: fileName });
  };
  return { generateAndDownloadPdf };
};
