import React, { useEffect, useState } from "react";

import { convertDisplayDataUnit } from "../Charts/Utility";
import {
  getEndOfMonth,
  handleLevelsIncomeStatement,
  handleZeroValues,
  formateAccountName,
  handleHeader,
} from "./Utility";
import { yearToDate } from "../Charts/Utility";

export default function IncomeStatement(props) {
  const [incomeStatement, setIncomeStatement] = useState(null);
  const incomeStatementTable = (props) => {
    // Filter By Date
    let currentDate = getEndOfMonth(
      props.calenderData.year_index,
      props.calenderData.month_index + 1
    );
    let sameMonthLastYr = getEndOfMonth(
      props.calenderData.year_index - 1,
      props.calenderData.month_index + 1
    );

    let endpoints = [...props.selectedAccount.data].filter((d) =>
      props.selectedEndpointId.map((v) => v.id).includes(d.id)
    );
    // Prior Year and YeartoDate Calculation
    let dataValues = [...endpoints].map((d) => {
      let actual = [...d.data].find((v) => v.date === currentDate);
      let priorYr = [...d.data].find((v) => v.date === sameMonthLastYr);
      let actualYTD = yearToDate([{ ...d }], props.calenderData)[0].data.find(
        (v) => v.date === currentDate
      );
      let priorYrYTD = yearToDate([{ ...d }], props.calenderData)[0].data.find(
        (v) => v.date === sameMonthLastYr
      );
      let tempData = {
        Account: d.name,
        Actual: actual ? actual.value : 0,
        "Prior Year": priorYr ? priorYr.value : 0,
        "Actual YTD": actualYTD ? actualYTD.value : 0,
        "Prior Year YTD": priorYrYTD ? priorYrYTD.value : 0,
        id: d.id,
        tags: d.tags,
        level: d.level,
        index: d.index,
        type: d.group,
        sub_type: "",
      };
      return tempData;
    });
    // Variance Calculation
    let dataValuesWithVariance = [...dataValues].map((d) => ({
      ...d,
      "Var To Prior": d["Actual"] - d["Prior Year"],
      "Var % To Prior": (d["Actual"] - d["Prior Year"]) / d["Prior Year"],
      "Var To Prior YTD": d["Actual YTD"] - d["Prior Year YTD"],
      "Var % To Prior YTD":
        (d["Actual YTD"] - d["Prior Year YTD"]) / d["Prior Year YTD"],
    }));
    let total_income_names = [
      "totalincome",
      "totalrevenue",
      "income",
      "revenue",
    ];

    // Budget Calculation Prior Year and YeartoDate
    let endpointsNameActual = dataValuesWithVariance.map((d) => d.Account);
    const budgetData = [...props.selectedAccount.data].filter(
      (d) => d.code === "budget" && endpointsNameActual.includes(d.name)
    );
    if (budgetData.length > 0) {
      const dataValuesWithBudget = [...dataValuesWithVariance].map((d) => {
        let tempIndex = [...props.selectedAccount.data].findIndex(
          (d) => d.name === d.Account
        );
        if (tempIndex > -1) {
          let budget_data_ = [...props.selectedAccount.data[tempIndex].data];
          let budget_value =
            budget_data_.find((v) => v.date === currentDate).value || 0;

          let budget_value_ytd =
            yearToDate([{ ...budget_data_ }], props.calenderData)[0].data.find(
              (v) => v.date === currentDate
            ).value || 0;
          return {
            ...d,
            Budget: budget_value,
            "Var To Budget": d["Actual"] - budget_value,
            "Var % To Budget": d["Actual"] - budget_value / budget_value,
            "Budget YTD": budget_value_ytd,
            "Var To Budget YTD": d["Actual YTD"] - budget_value_ytd,
            "Var % To Budget YTD":
              d["Actual YTD"] - budget_value_ytd / budget_value_ytd,
          };
        } else {
          return {
            ...d,
            Budget: 0,
            "Var To Budget": 0,
            "Var% To Budget": 0,
            "Budget YTD": 0,
            "Var To Budget YTD": 0,
            "Var % To Budget YTD": 0,
          };
        }
      });
      dataValuesWithVariance = dataValuesWithBudget;
    } else {
      const dataValuesWithBudget = [...dataValuesWithVariance].map((d) => ({
        ...d,
        Budget: 0,
        "Var To Budget": 0,
        "Var % To Budget": 0,
        "Budget YTD": 0,
        "Var To Budget YTD": 0,
        "Var % To Budget YTD": 0,
      }));
      dataValuesWithVariance = dataValuesWithBudget;
    }

    // Percentage of Revenue Calculation
    const total_income = dataValuesWithVariance.find(
      (d) =>
        d.type === "Income" &&
        d.tags === "S" &&
        total_income_names.includes(formateAccountName(d.Account))
    );

    if (total_income !== undefined) {
      const dataValuesWithPercentage = [...dataValuesWithVariance].map((d) => ({
        ...d,
        "% of Rev1": (d["Actual"] / total_income["Actual"]) * 100,
        "% of Budget Rev2": (d["Budget"] / total_income["Budget"]) * 100,
        "% of  Prior Rev3":
          (d["Prior Year"] / total_income["Prior Year"]) * 100,
        "% of Rev4 YTD": (d["Actual"] / total_income["Actual"]) * 100,
        "% of Rev5 Budget YTD":
          (d["Budget YTD"] / total_income["Budget YTD"]) * 100,
        "% of Rev6 Prior YTD":
          (d["Prior Year YTD"] / total_income["Prior Year YTD"]) * 100,
      }));
      dataValuesWithVariance = dataValuesWithPercentage;
    }
    let visible_header = [
      "Account",
      "Actual",
      "% of Rev1",
      "Budget",
      "% of Budget Rev2",
      "Prior Year",
      "% of  Prior Rev3",
      "Var To Budget",
      "Var % To Budget",
      "Var To Prior",
      "Var % To Prior",
      "Actual YTD",
      "% of Rev4 YTD",
      "Budget YTD",
      "% of Rev5 Budget YTD",
      "Prior Year YTD",
      "% of Rev6 Prior YTD",
      "Var To Budget YTD",
      "Var % To Budget YTD",
      "Var To Prior YTD",
      "Var % To Prior YTD",
    ];

    let tableData = dataValuesWithVariance.map((d) => {
      const newData = {};
      [
        ...visible_header,
        "id",
        "tags",
        "level",
        "type",
        "sub_type",
        "index",
      ].map((key) => (newData[key] = d[key]));
      return newData;
    });
    let dataHeader = Object.keys(tableData[0])
      .map((d) => (visible_header.includes(d) ? d : null))
      .filter((d) => d !== null);
    return { tableData, dataHeader };
  };

  useEffect(() => {
    setIncomeStatement((prev) => {
      const { tableData, dataHeader } = incomeStatementTable(props);
      const dataHeaderFilter = dataHeader.filter((d) =>
        handleHeader(props.bottomSettings, d)
      );
      const YtdHeaderLength = dataHeaderFilter.filter((d) =>
        d.includes("YTD")
      ).length;
      const montlyHeaderLength = dataHeaderFilter.length - YtdHeaderLength - 1;
      return {
        tableData: tableData,
        dataHeader: dataHeaderFilter,
        montlyHeaderLength: montlyHeaderLength,
        YtdHeaderLength: YtdHeaderLength,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedEndpointId, props.bottomSettings, props.selectedAccount]);

  return (
    incomeStatement && (
      <table
        ref={props.divRef}
        className="table table-hover table-bordered  h-100"
      >
        <thead
          className="shadow-sm"
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <tr>
            <th colSpan="1">
              <div className="d-flex  flex-column justify-content-start  piik-text-visual  align-items-start px-2">
                <div className="piik-text-visual"> For the Period Ended</div>
                <select
                  value={props.calenderData && props.calenderData.month_index}
                  onChange={(e) =>
                    props.handleCalendarLocal(
                      e.target.value,
                      props.calenderData.year_index
                    )
                  }
                  className="form-select form-select-sm w-100 "
                  style={{ minWidth: "100px" }}
                >
                  {props.months &&
                    props.months
                      .filter((m) => m.status)
                      .map((m) => {
                        return (
                          <option value={m.month_index}>
                            {m.year_index}-{m.month}
                          </option>
                        );
                      })}
                </select>
              </div>
            </th>

            <th
              colSpan={incomeStatement && incomeStatement.montlyHeaderLength}
              className="piik-text-dashboard text-center"
            >
              Monthly [{props.calenderData.month}-
              {props.calenderData.year_index}]
            </th>

            {props.bottomSettings.ytd && (
              <th
                colSpan={incomeStatement && incomeStatement.YtdHeaderLength}
                className="piik-text-dashboard text-center"
              >
                Year to Date
              </th>
            )}
          </tr>

          <tr className="table-active ">
            {incomeStatement &&
              incomeStatement.dataHeader &&
              incomeStatement.dataHeader.map((header) => {
                if (header === "Account") {
                  return (
                    <th className="piik-text-visual">
                      <span className="px-2">Accounts</span>
                    </th>
                  );
                } else if (header.includes("Rev")) {
                  return (
                    <th className="piik-text-visual text-end text-wrap">
                      % Of Revenue
                    </th>
                  );
                } else if (header.includes("YTD")) {
                  return (
                    <th className="piik-text-visual text-end text-wrap">
                      {header.split("YTD")[0]}
                    </th>
                  );
                } else {
                  return (
                    <th className="piik-text-visual text-end text-wrap">
                      {header}
                    </th>
                  );
                }
              })}
          </tr>
        </thead>

        <tbody>
          {incomeStatement &&
            incomeStatement.tableData &&
            incomeStatement.tableData
              .sort((a, b) => parseInt(a.index) - parseInt(b.index))
              .map((d) => handleLevelsIncomeStatement(props.bottomSettings, d))
              .filter((d) => d)
              .filter((d) => handleZeroValues(props.bottomSettings, d, "I"))
              .map((data, index) => {
                if (data["tags"] === "H") {
                  return (
                    <tr
                      key={index}
                      className={`${
                        data["tags"] === "S" ? "table-active" : null
                      }`}
                    >
                      <td
                        className="piik-text-visual fw-bold"
                        colspan={incomeStatement.dataHeader.length}
                        style={{
                          paddingLeft: `${data["level"]}rem
                                      `,
                        }}
                      >
                        {data["Account"]}
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr
                      key={index}
                      className={`${
                        data["tags"] === "S" ? "table-active" : null
                      }`}
                    >
                      {incomeStatement.dataHeader
                        .filter((d) => handleHeader(props.bottomSettings, d))
                        .map((header) => {
                          if (header === "Account") {
                            return (
                              <td
                                className={`piik-text-visual ${
                                  data["tags"] === "S" ? "fw-bold " : null
                                }`}
                                style={{
                                  paddingLeft: `${data["level"]}rem
                                      `,
                                }}
                              >
                                {data[header]}
                              </td>
                            );
                          } else if (header.includes("%")) {
                            return (
                              <td className="piik-text-visual text-end">
                                {!isNaN(data[header]) &&
                                data[header] !== Infinity &&
                                data[header] !== -Infinity ? (
                                  <span
                                    className={`${
                                      data[header] > 0 && header.includes("%")
                                        ? "text-success"
                                        : data[header] < 0 &&
                                          header.includes("%")
                                        ? "text-danger"
                                        : null
                                    } ${data.tags === "S" ? "fw-bold" : null}`}
                                  >
                                    {convertDisplayDataUnit(
                                      data[header],
                                      "RF"
                                    ) + "%"}
                                  </span>
                                ) : (
                                  "-"
                                )}
                              </td>
                            );
                          } else {
                            return (
                              <td className="piik-text-visual text-end">
                                {!isNaN(data[header]) &&
                                data[header] !== Infinity &&
                                data[header] !== -Infinity ? (
                                  <span
                                    className={`${
                                      data[header] > 0 && header.includes("Var")
                                        ? "text-success"
                                        : data[header] < 0 &&
                                          header.includes("Var")
                                        ? "text-danger"
                                        : null
                                    } ${data.tags === "S" ? "fw-bold" : null}`}
                                  >
                                    {convertDisplayDataUnit(data[header], "RF")}
                                  </span>
                                ) : (
                                  "NA"
                                )}
                              </td>
                            );
                          }
                        })}
                    </tr>
                  );
                }
              })}
        </tbody>
      </table>
    )
  );
}
