import React, { useState } from "react";
import { useDashData } from "../../hooks/useDash";

import NavIcon from "./NavIcon";

const SideBar = () => {
  const [mode, setMode] = useState(true);
  const {
    adminAccountInfo,
    guestAccountInfo,
    navigation,
    tab,
    account,
    contentOption,
    handleContentTab,
    handleAccount,
    handleSettingsTab,
  } = useDashData();

  const handleTab = (tabId, subTabId, tertiaryTabId) => {
    handleContentTab(tabId, subTabId, tertiaryTabId);
    handleAccount(subTabId);
  };

  const handleMode = (state) => {
    if (contentOption.tab_id === "4") {
      setMode(true);
    } else {
      setMode(state);
    }
  };

  return (
    <div
      className={
        mode
          ? "piik-home-sidebar-deactive shadow"
          : "piik-home-sidebar-active shadow"
      }
      onMouseEnter={() => handleMode(true)}
      onMouseLeave={() => handleMode(false)}
    >
      {mode ? (
        <div className="d-flex flex-row justify-content-start align-items-center">
          <img
            src="images/PiikDashLogo.svg"
            className="px-3 py-2  text-start"
            alt="Piik-dash logo"
            height="44px"
          />
        </div>
      ) : (
        <div className="d-flex flex-row justify-content-start align-items-center">
          <img
            src="images/PiikDashIcon.svg"
            className=" px-3 py-2 text-center"
            alt="Piik-dash logo"
            height="44px"
          />
        </div>
      )}

      <div>
        <NavIcon
          mode={mode}
          setMode={handleMode}
          key="a2"
          type="btn"
          tab_id={navigation[3].tab_id}
          title={navigation[3].name}
          subTab_id=""
          tertiary_tab_id=""
          logo={navigation[3].logo}
          backgroundColor="bg-primary"
          color="text-light"
          handleClick={handleContentTab}
        />
      </div>
      <div>
        <NavIcon
          mode={mode}
          setMode={handleMode}
          key="a1"
          type="btn"
          tab_id={navigation[0].tab_id}
          title={navigation[0].name}
          logo={navigation[0].logo}
          backgroundColor="bg-primary border-top border-bottom  border-secondary-subtle"
          color="text-light"
          icon="bi-plus text-light"
          handleClick={handleContentTab}
        />
      </div>

      <div className="flex-grow-1 h-100 d-flex flex-column overflow-y-scroll ">
        {adminAccountInfo.length !== 0 &&
          adminAccountInfo.map((data, index) => {
            return (
              <NavIcon
                mode={mode}
                setMode={handleMode}
                key={`b${index}`}
                type="btn"
                tab_id={navigation[1].tab_id}
                subTab_id={data.uid}
                tertiary_tab_id={"1"}
                title={
                  data && data.company_name && data.company_name.toUpperCase()
                }
                id={data.uid}
                backgroundColor="bg-primary piik-border-bottom-2 "
                color="text-light"
                icon={
                  account === data.uid
                    ? "bi-check-circle-fill text-white"
                    : "text-white"
                }
                handleClick={handleTab}
              />
            );
          })}
        {guestAccountInfo.length !== 0 &&
          guestAccountInfo.map((data, index) => {
            return (
              <NavIcon
                mode={mode}
                setMode={handleMode}
                key={`b${index}`}
                type="btn"
                tab_id={navigation[1].tab_id}
                subTab_id={data.uid}
                tertiary_tab_id={"1"}
                title={data.company_name}
                id={data.uid}
                backgroundColor="bg-primary piik-border-bottom-2 "
                color="text-light"
                icon={
                  account === data.uid
                    ? "bi-check-circle-fill text-white"
                    : "text-white"
                }
                handleClick={handleTab}
              />
            );
          })}
      </div>

      <div>
        <NavIcon
          mode={mode}
          setMode={setMode}
          key={`c`}
          type="dropdown"
          tab_id={navigation[2].tab_id}
          logo={navigation[2].logo}
          title={navigation[2].name}
          backgroundColor="bg-primary"
          color="text-light"
          icon={tab ? "bi-chevron-down text-white" : "bi-chevron-up text-white"}
          handleClick={handleSettingsTab}
        />
      </div>
      {tab &&
        navigation[2].subTab.map((items, index) => {
          return (
            <NavIcon
              setMode={handleMode}
              mode={mode}
              key={`c${index}`}
              type="btn"
              tab_id={navigation[2].tab_id}
              subTab_id={items.id}
              logo={items.logo}
              title={items.name}
              backgroundColor="bg-secondary border-top border-secondary-subtle"
              color="text-light"
              icon={
                contentOption.subTab_id === items.id
                  ? "bi-check-circle-fill text-white"
                  : null
              }
              handleClick={handleContentTab}
            />
          );
        })}

      <NavIcon
        setMode={handleMode}
        key="4"
        mode={mode}
        tab_id="4"
        subTab_id="1"
        logo="bi-c-circle"
        title="2023-2024 | Piik Dash"
        backgroundColor="bg-dark border-top border-secondary-subtle"
        color="text-light"
      />
    </div>
  );
};

export default SideBar;
