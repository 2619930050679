import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";

export default function EndpointTable(props) {
  const [customData, setCustomData] = useState([]);
  const handleDataBasedOnDate = (AccountData, id, date) => {
    const tempIndex = AccountData.findIndex((d) => d.id === id);
    const selectedAccountData = AccountData[tempIndex];

    const filterData = selectedAccountData.data.find(
      (d) =>
        d.date.includes(`${date.month_index}`) &&
        d.date.includes(`${date.year_index}`)
    );
    return filterData;
  };

  const handleFormula = (data, formula, action) => {
    let text = formula;
    for (let i = 0; i < data.length; i++) {
      let selectedData = props.selectedAccount.data.find((d) =>
        action === "group" ? d.id === data[i].id : d.id === data[i].endpointId
      );
      if (selectedData) {
        if (action === "group") {
          if (i === 0) {
            let newText = `EP_${i + 1}[${selectedData.name}]`;
            let textUpdated = text + newText;
            text = textUpdated;
            textUpdated = "";
          } else {
            let newText = `+ EP_${i + 1}[${selectedData.name}] `;
            let textUpdated = text + newText;
            text = textUpdated;
            textUpdated = "";
          }
        } else {
          let newText = ` ${data[i].name} [${selectedData.name}]`;
          let textUpdated = text.replace(
            new RegExp(data[i].name, "g"),
            newText
          );
          text = textUpdated;
          textUpdated = "";
        }
      }
    }
    return text;
  };

  const handleQueryIdDetails = (type, id) => {
    if (type === "class") {
      let tempIndex = props.selectedAccount.class.findIndex((d) => d.id === id);
      if (tempIndex > -1) {
        return props.selectedAccount.class[tempIndex].name;
      } else {
        return "NA";
      }
    } else if (type === "item") {
      let tempIndex = props.selectedAccount.item.findIndex((d) => d.id === id);
      if (tempIndex > -1) {
        return props.selectedAccount.item[tempIndex].name;
      } else {
        return "NA";
      }
    } else if (type === "customer") {
      let tempIndex = props.selectedAccount.customer.findIndex(
        (d) => d.id === id
      );
      if (tempIndex > -1) {
        return props.selectedAccount.customer[tempIndex].name;
      } else {
        return "NA";
      }
    } else {
      let tempIndex = props.selectedAccount.financial_account.findIndex(
        (d) => d.id === id
      );
      if (tempIndex > -1) {
        return props.selectedAccount.financial_account[tempIndex].name;
      } else {
        return "NA";
      }
    }
  };

  useEffect(() => {
    if (props.action === "query" && props.type === "display") {
      let data = props.selectedAccount.data.filter(
        (d) =>
          d.category.toLowerCase() === props.customEndpoint.name.toLowerCase()
      );
      setCustomData(() => {
        return data;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return props.action === "new" ? (
    <div className="d-flex flex-column h-100  w-100">
      <div
        className="piik-text-dashboard px-4 py-2 bg-white border-bottom  "
        style={{ width: "100%" }}
      >
        <div className="fw-semibold text-primary pb-2">Formula</div>
        <div className="pb-2 font-monospace text-wrap text-break">
          {handleFormula(props.customEndpoint, props.formula, "new")}
        </div>
      </div>
      {props.customEndpoint.length > 0 && props.action === "new" ? (
        <div className="flex-grow-1 w-100  h-100 d-flex flex-column ">
          <div className="row piik-text-dashboard border-bottom mx-0 px-2 py-2 bg-white">
            <div className="col-1 fw-semibold">#</div>
            <div className="col fw-semibold ">Category</div>
            <div className="col-5 fw-semibold">Name</div>
            <div className="col fw-semibold">Data Type</div>
            <div className="col fw-semibold">Data Sample</div>
          </div>
          {props.customEndpoint.length > 0 &&
            props.customEndpoint.map((data, index) => {
              return (
                <div className="row piik-text-dashboard border-bottom mx-0 px-2 py-2 bg-light">
                  <div className="col-1">{data.name}</div>
                  <div className="col fw-semibold text-primary">
                    {props.selectedAccount.data.find(
                      (d) => d.id === data.endpointId
                    ) &&
                      props.selectedAccount.data.find(
                        (d) => d.id === data.endpointId
                      ).category}
                  </div>
                  <div className="col-5 fw-semibold text-primary">
                    {props.selectedAccount.data.find(
                      (d) => d.id === data.endpointId
                    ) &&
                      props.selectedAccount.data.find(
                        (d) => d.id === data.endpointId
                      ).name}
                  </div>
                  <div className="col fw-semibold text-primary">
                    {props.selectedAccount.data.find(
                      (d) => d.id === data.endpointId
                    ) &&
                      props.selectedAccount.data.find(
                        (d) => d.id === data.endpointId
                      ).data_type}
                  </div>

                  <div className="col fw-semibold text-primary">
                    {props.selectedAccount.data.find(
                      (d) => d.id === data.endpointId
                    ) &&
                      handleDataBasedOnDate(
                        props.selectedAccount.data,
                        data.endpointId,
                        props.calenderData
                      ).value}
                  </div>
                </div>
              );
            })}
        </div>
      ) : null}
    </div>
  ) : props.action === "query" && props.type === "create" ? (
    <div className="d-flex flex-column h-100  w-100">
      <div
        className="piik-text-dashboard px-4 py-2 bg-white border-bottom  "
        style={{ width: "100%" }}
      >
        <div className="fw-semibold text-primary pb-2">Query Parameter</div>
      </div>

      {props.customEndpoint.query_endpoints &&
      props.customEndpoint.query_endpoints.length > 0 &&
      props.action === "query" ? (
        <div className="flex-grow-1 w-100  h-100 d-flex flex-column ">
          <div className="row piik-text-dashboard border-bottom mx-0 px-2 py-2 bg-white">
            <div className="col-1 fw-semibold">#</div>
            <div className="col fw-semibold ">Category</div>
            <div className="col-5 fw-semibold">Name</div>
            <div className="col fw-semibold text-end px-4 ">Type</div>
          </div>
          {props.customEndpoint.query_endpoints &&
            props.customEndpoint.query_endpoints.length > 0 &&
            props.customEndpoint.query_endpoints.map((data, index) => {
              return (
                <div className="row piik-text-dashboard border-bottom mx-0 px-2 py-2 bg-light">
                  <div className="col-1">{index + 1}</div>
                  <div className="col">{props.customEndpoint.category}</div>
                  <div className="col-5">
                    {handleQueryIdDetails(data.type, data.id)}
                  </div>
                  <div className="col text-end px-4">{data.type}</div>
                </div>
              );
            })}
        </div>
      ) : null}
    </div>
  ) : props.action === "query" && props.type === "display" ? (
    <div className="d-flex flex-column h-100  w-100">
      <div
        className="piik-text-dashboard px-4 py-2 bg-white border-bottom  "
        style={{ width: "100%" }}
      >
        <div className="fw-semibold text-primary pb-2">Query Parameter</div>
      </div>
      {props.customEndpoint.query_endpoints &&
      props.customEndpoint.query_endpoints.length > 0 &&
      props.action === "query" ? (
        <div className="w-100 d-flex flex-column ">
          <div className="row piik-text-dashboard border-bottom mx-0 px-2 py-2 bg-white">
            <div className="col-1 fw-semibold">#</div>
            <div className="col fw-semibold ">Category</div>
            <div className="col-5 fw-semibold">Name</div>
            <div className="col fw-semibold text-end px-4 ">Type</div>
          </div>
          {props.customEndpoint.query_endpoints &&
            props.customEndpoint.query_endpoints.length > 0 &&
            props.customEndpoint.query_endpoints.map((data, index) => {
              return (
                <div className="row piik-text-dashboard border-bottom mx-0 px-2 py-2 bg-light">
                  <div className="col-1">{index + 1}</div>
                  <div className="col">{props.customEndpoint.category}</div>
                  <div className="col-5">
                    {handleQueryIdDetails(data.type, data.id)}
                  </div>
                  <div className="col text-end px-4">{data.type}</div>
                </div>
              );
            })}
        </div>
      ) : null}

      <div
        className="piik-text-dashboard px-4 py-2 bg-white border-bottom"
        style={{ width: "100%" }}
      >
        <div className="fw-semibold text-primary pb-2">Query Result</div>
      </div>
      <div className="row piik-text-dashboard border-bottom mx-0 px-2 py-2 bg-white">
        <div className="col-1 fw-semibold">#</div>

        <div className="col fw-semibold ">Endpoint Name</div>
        <div className="col-5 fw-semibold">Data Type</div>
        <div className="col fw-semibold text-end px-4 ">Sample</div>
        <div className="col fw-semibold text-end px-4 ">Status</div>
      </div>

      {customData.length > 0 &&
        customData.map((d, i) => {
          return (
            <div className="row piik-text-dashboard border-bottom mx-0 px-2 py-2 bg-light">
              <div className="col-1 ">{i + 1}</div>

              <div className="col  ">{d.name}</div>
              <div className="col-5 ">{d.data_type}</div>
              <div className="col  text-end px-4 ">{d.data[0].value}</div>
              <div className="col  text-end px-4 ">
                {d.status ? "Active" : "Disabled"}
              </div>
            </div>
          );
        })}
    </div>
  ) : props.action === "cloud" ? (
    <div className="d-flex flex-column h-100  w-100">
      {props.customEndpoint &&
        props.customEndpoint.category &&
        props.customEndpoint.category.cloud_storage === "azure" && (
          <div
            className="piik-text-dashboard px-4 py-2 bg-white border-bottom  "
            style={{ width: "100%" }}
          >
            <div className="piik-text-dashboard d-flex flex-row justify-content-start">
              <div>
                <span className="fw-semibold text-primary pb-2">
                  Cloud Service :
                </span>
                <span className="pb-2 font-monospace text-wrap text-break">
                  {props.customEndpoint &&
                    props.customEndpoint.category.cloud_storage}
                </span>
              </div>
              <div className="px-4">
                <span className="fw-semibold text-primary pb-2">
                  Account Name :
                </span>
                <span className="pb-2 font-monospace text-wrap text-break">
                  {props.customEndpoint &&
                    props.customEndpoint.category.account_name}
                </span>
              </div>
              <div className="px-4">
                <span className="fw-semibold text-primary pb-2">
                  Container Name :
                </span>
                <span className="pb-2 font-monospace text-wrap text-break">
                  {props.customEndpoint &&
                    props.customEndpoint.category.container_name}
                </span>
              </div>
              <div className="px-4">
                <span className="fw-semibold text-primary pb-2">
                  File Name :
                </span>
                {props.customEndpoint &&
                  props.customEndpoint.category.file_name &&
                  props.customEndpoint.category.file_type && (
                    <span className="pb-2 font-monospace text-wrap text-break">
                      {props.customEndpoint &&
                        props.customEndpoint.category.file_name}
                      .{props.customEndpoint.category.file_type}
                    </span>
                  )}
              </div>
            </div>
          </div>
        )}
      <div className="w-100 d-flex flex-column ">
        <div className="row piik-text-visual border-bottom mx-0 px-2 py-2 bg-white">
          <div className="col-1 fw-semibold">#</div>
          <div className="col fw-semibold ">Category</div>
          <div className="col-5 fw-semibold">Source Datapoint</div>
          <div className="col fw-semibold  px-4 ">Formate Data Type</div>
          <div className="col fw-semibold text-end px-4 ">Aggregate By</div>
        </div>
      </div>
      {props.customEndpoint &&
        props.customEndpoint.query_endpoints &&
        props.customEndpoint.query_endpoints
          .filter((d) => d.valueType !== "list" && d.valueType !== "dic")
          .map((d, index) => {
            return (
              <div className="w-100 d-flex flex-column">
                <div className="row piik-text-dashboard border-bottom mx-0 px-2 py-2 bg-light  fw-s">
                  <div className="col-1">{index + 1}</div>
                  <div className="col fw-semibold ">{d.key}</div>
                  <div className="col-5 fw-semibold "> {d.key_name}</div>
                  <div className="col text-primary fw-semibold">
                    <select
                      className="form-select form-select-sm "
                      id={d.key_name}
                      name="assignValueType"
                      value={d.assignValueType}
                      onChange={
                        props.type === "create" ? props.handleData : null
                      }
                      disabled={props.type !== "create"}
                    >
                      <option selected value="">
                        Select
                      </option>
                      <option selected value="float">
                        Numerical
                      </option>
                      <option selected value="str">
                        Categorical
                      </option>
                      <option selected value="datetime">
                        Date
                      </option>
                    </select>
                  </div>
                  <div className="col text-end px-4  text-primary fw-semibold">
                    {d.assignValueType === "float" ? (
                      <select
                        className="form-select form-select-sm "
                        id={d.key_name}
                        name="groupBy"
                        value={d.groupBy}
                        onChange={
                          props.type === "create" ? props.handleData : null
                        }
                        disabled={props.type !== "create"}
                      >
                        <option selected value="">
                          Select
                        </option>
                        <option selected value="count">
                          Count
                        </option>
                        <option selected value="sum">
                          Sum
                        </option>
                        <option selected value="mean">
                          Mean
                        </option>
                      </select>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
    </div>
  ) : props.action === "upload" ? (
    <div className="w-100 d-flex flex-column ">
      <div className="row piik-text-visual border-bottom mx-0 px-2 py-2 bg-white">
        <div className="col-1 fw-semibold">#</div>
        <div className="col fw-semibold">Datapoint</div>
        {props.fileType && props.fileType === "others" && (
          <div className="col-3 fw-semibold  px-4 text-end ">
            Assign Data Type
          </div>
        )}
        <div className="col-1 fw-semibold  px-4 text-end ">Select</div>
      </div>
      {props.customEndpoint.length > 0 &&
        props.customEndpoint.map((d, index) => {
          return (
            <div className="row piik-text-visual border-bottom mx-0 px-2 py-2">
              <div className="col-1 fw-semibold">{index + 1}</div>
              <div className="col ">{d.title}</div>
              {props.fileType && props.fileType === "others" && (
                <div className="col-3 fw-semibold  px-4 text-end ">
                  <select
                    className="form-select form-select-sm "
                    id={d.title}
                    name="assignValueType"
                    value={d.type}
                    onChange={props.type === "create" ? props.handleData : null}
                    disabled={props.type !== "create" || !d.isNew}
                  >
                    <option selected value="">
                      Select
                    </option>
                    <option selected value="float">
                      Numerical
                    </option>
                    <option selected value="str">
                      Categorical
                    </option>
                    <option selected value="datetime">
                      Date
                    </option>
                  </select>
                </div>
              )}

              <div className="col-1  px-4 text-end ">
                <Form>
                  <Form.Check
                    type="switch"
                    id={d.title}
                    checked={d.status}
                    disabled={props.type !== "create"}
                    onChange={
                      props.type === "create" ? props.handleDataSelection : null
                    }
                  />
                </Form>
              </div>
            </div>
          );
        })}
    </div>
  ) : null;
}
