import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Home from "./routes/Home";
import NotFound from "./routes/NotFound";
import ProgressBar from "./Components/Common/ProgressBar";

import "./Styles.css";

const App = () => {
  const { isLoading } = useAuth0();
  if (isLoading) {
    return (
      <div className="p-4 m-4">
        <ProgressBar />
      </div>
    );
  }
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
