import React from "react";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

function CountryFlag({ countryCode }) {
  return (
    <span className="px-2 py-1 rounded ">
      {getUnicodeFlagIcon(countryCode)}
    </span>
  );
}
export default CountryFlag;
