const csvToArrayOfObjects = (csv, fileType) => {
  return new Promise((resolve, reject) => {
    parseCsv(csv)
      .then((data) => {
        const headers = Object.keys(data[0]);
        if (fileType === "Others") {
          Promise.all(createHeader(headers, fileType))
            .then((header) => {
              resolve({
                data: data,
                header: header,
                status: true,
              });
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          Promise.all([createHeader(headers, fileType), createDataPoint(data)])
            .then(([header, datapoint]) => {
              resolve({
                data: data,
                header: header,
                datapoint: datapoint,
                status: true,
              });
            })
            .catch((error) => {
              reject(error);
            });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const validateCSV = (header, data, fileType) => {
  switch (fileType) {
    case "balanceSheet":
    case "profit&loss":
    case "budgetSheet":
      return validateQuickBooksFormat(header, data);
    case "others":
      return validateOthersFormat(header, data);
    default:
      throw new Error("Invalid fileType provided");
  }
};

const validateOldDataWithNewData = (oldData, newData, fileType) => {
  let status = true;
  if (fileType === "others") {
    for (let old of oldData.header) {
      let tempIndex = newData.header.findIndex((d) => d.title === old.title);
      if (tempIndex === -1 && status) {
        status = false;
      }
    }
  } else {
    for (let old of oldData.datapoint) {
      let tempIndex = newData.datapoint.findIndex((d) => d.title === old.title);
      if (tempIndex === -1 && status) {
        status = false;
      }
    }
  }
  return status;
};

function mergeOldDataWithNew(OldData, NewData, key) {
  let oldArray = OldData[key];
  let newArray = NewData[key];
  // Convert oldArray to a map for easier lookup
  const oldMap = {};
  oldArray.forEach((obj) => {
    oldMap[obj.title] = obj;
  });

  // Iterate through newArray, add objects not present in oldArray
  newArray.forEach((newObj) => {
    if (!oldMap.hasOwnProperty(newObj.title)) {
      newObj.isNew = true;
      oldArray.push(newObj);
    }
  });

  // Mark old objects as not new
  oldArray.forEach((obj) => {
    if (!obj.hasOwnProperty("isNew") && obj.status) {
      obj.isNew = false;
    }
    if (!obj.hasOwnProperty("isNew") && !obj.status) {
      obj.isNew = true;
    }
    if (obj.status) {
      obj.isNew = false;
    }
  });

  return oldArray;
}

//  helper function
const validateQuickBooksFormat = (header, data) => {
  if (header.length === 0) {
    return { status: false, message: "Header is empty" };
  }
  if (!header.map((d) => d.title.toLowerCase()).includes("account")) {
    return { status: false, message: "Account Column is missing" };
  }
  if (
    !checkDateFormat(header.filter((d) => d.title.toLowerCase() !== "account"))
  ) {
    return { status: false, message: "Invalid Date Format" };
  }
  return { status: true, message: "" };
};

const validateOthersFormat = (header, data) => {
  if (header.length === 0) {
    return { status: false, message: "Header is empty" };
  }
  if (!header.map((d) => d.title.toLowerCase()).includes("date")) {
    return { status: false, message: "Date column is missing" };
  }
  if (!checkDateFormateInData(data)) {
    return { status: false, message: "Invalid Date Format" };
  }
  return { status: true, message: "" };
};

// Utility
const parseCsv = (csv) => {
  return new Promise((resolve, reject) => {
    const lines = csv.split("\n");
    const result = [];
    const headers = lines[0].split(",");

    for (let i = 1; i < lines.length; i++) {
      if (!lines[i]) continue;
      const obj = {};
      const currentline = lines[i].split(",");
      if (headers.length === 0 || currentline.length !== headers.length) {
        return reject({ headers, status: false });
      }
      for (let j = 0; j < headers.length; j++) {
        let key = headers[j].replace("\r", "");
        let value = currentline[j] ? currentline[j].replace("\r", "") : "";
        obj[key] = value;
      }
      console.log(obj);
      result.push(obj);
    }
    resolve(result);
  });
};

function createHeader(headers, fileType) {
  return new Promise((resolve, reject) => {
    try {
      let header;
      if (fileType === "others") {
        header = headers.map((d) => ({
          title: d.replace("\r", ""),
          type: "",
          status: true,
          isNew: true,
        }));
      } else {
        header = headers.map((d) => ({
          title: d.replace("\r", ""),
          type: d.replace("\r", "") === "Account" ? "str" : "float",
          status: true,
          isNew: true,
        }));
      }
      resolve(header);
    } catch (error) {
      reject(error);
    }
  });
}

function createDataPoint(data) {
  return new Promise((resolve, reject) => {
    try {
      let datapoint = data.map((d) => ({
        title: d.Account ? d.Account.replace("\r", "") : "",
        status: true,
      }));
      resolve(datapoint);
    } catch (error) {
      reject(error);
    }
  });
}

function checkDateFormateInData(data) {
  let status = true;
  for (let obj of data) {
    if (status) {
      let newObj = {};
      for (let key in obj) {
        newObj[key.toLowerCase()] = obj[key];
      }
      const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
      status = dateFormatRegex.test(newObj["date"]);
    }
  }
  return status;
}

function checkDateFormat(headers) {
  const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
  for (const col of headers) {
    if (!dateFormatRegex.test(col.title)) {
      return false;
    }
  }
  return true;
}

export {
  csvToArrayOfObjects,
  validateCSV,
  validateOldDataWithNewData,
  mergeOldDataWithNew,
};
