import React, { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useDashData } from "../../../hooks/useDash";

import KpiPlot from "./KpiPlot";
import Modal from "../../Common/Modal";
import DisplayBottomIcon from "../DisplayBottomIcon";

const DisplayKpi = ({
  fullScreenStatus,
  index,
  handleVisualizationTab,
  handleUpdateVisualizationIndex,
}) => {
  const {
    profile,
    adminAccountInfo,
    guestAccountInfo,
    calenderData,
    calenderDatesData,
    account,
    isModalOpen,
    openModal,
    closeModal,
    handleDeleteVisulization,
    handleCopyData,
  } = useDashData();
  const { user } = useAuth0();

  const [selectedAccount, setSelectedAccount] = useState({});
  const [btn, setBtnState] = useState(false);
  const [category, setCategory] = useState([]);
  const [kpiData, setKpiData] = useState({});
  const [password, setPassword] = useState({ value: "", errorMessage: "" });
  const [localCalendarDate, setLocalCalendarDates] = useState({
    ...calenderData,
  });

  const handleDuplicate = () => {
    let duplicate_data = {
      type: "kpi",
      id: kpiData.id,
      accountId: selectedAccount.uid,
      userId: selectedAccount.owner_id,
    };
    handleCopyData(duplicate_data).then((response) => {
      if (response.status) {
        handleUpdateVisualizationIndex(selectedAccount.kpi_id.length);
        handleVisualizationTab(100);
      }
    });
  };

  const handleCalendarLocal = (monthIndex, yearIndex) => {
    setLocalCalendarDates((prev) => {
      let temYearIndex = calenderDatesData.findIndex(
        (y) => y.year_index === yearIndex
      );
      let tempMonth = calenderDatesData[temYearIndex].months.find(
        (m) => m.month_index === parseInt(monthIndex)
      );
      let tempData = { ...prev };
      tempData.month_index = parseInt(monthIndex);
      tempData.month = tempMonth.month;
      tempData.year = calenderDatesData[temYearIndex].year;
      tempData.year_index = calenderDatesData[temYearIndex].year_index;
      tempData.status = tempMonth.status;
      return tempData;
    });
  };

  const handlePasswordInputChange = (event) => {
    const value = event.target.value;
    setPassword({ errorMessage: "", value: value });
  };

  const handleEditKpi = () => {
    handleUpdateVisualizationIndex(index);
    handleVisualizationTab(100);
  };

  const handleDeleteKpi = async () => {
    if (password.value !== user.email) {
      setPassword({ errorMessage: "Invalid email", value: "" });
      return;
    } else {
      let deletedDataInfo = {
        userId: profile.user_id,
        accountId: selectedAccount.uid,
        kpiId: kpiData.id,
        visualizationType: "Kpi",
      };
      setBtnState(true);
      handleDeleteVisulization(deletedDataInfo)
        .then((response) => {
          if (response) {
            setCategory([]);
            setBtnState(false);
            setPassword({
              errorMessage: "",
              value: "",
            });
            handleVisualizationTab(0);
            closeModal();
          }
        })
        .catch((error) => {
          setBtnState(false);
        });
    }
  };

  const handleGenerateKPI = (category, selectedAccountData) => {
    setCategory(() => {
      const newData = category.map((kpi) => {
        const tempIndex = selectedAccountData.kpi_endpoints.findIndex(
          (d) => d.id === kpi.id
        );

        if (tempIndex > -1) {
          return selectedAccountData.kpi_endpoints[tempIndex];
        } else {
          return null;
        }
      });
      const newUpdatedData = newData.filter((d) => d !== null);
      return newUpdatedData;
    });
  };

  useEffect(() => {
    let currentMonthIndex = calenderData.month_index;
    let currentYearIndex = calenderData.year_index;
    handleCalendarLocal(currentMonthIndex, currentYearIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calenderData]);

  useEffect(() => {
    let CombinedChartData = adminAccountInfo.concat(guestAccountInfo);
    const accountIndex = CombinedChartData.findIndex(
      (data) => data.uid === account
    );
    const selectedAccountData = JSON.parse(
      JSON.stringify(CombinedChartData[accountIndex])
    );
    setSelectedAccount(selectedAccountData);

    if (parseInt(index) > selectedAccountData.kpi_id.length - 1) {
      let kpiTempData = selectedAccountData.kpi_id[0];
      if (kpiTempData) {
        setKpiData(kpiTempData);
        handleGenerateKPI(kpiTempData.settings, selectedAccountData);
      }
    } else {
      let kpiTempData = selectedAccountData.kpi_id[index];
      if (kpiTempData) {
        setKpiData(kpiTempData);
        handleGenerateKPI(kpiTempData.settings, selectedAccountData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAccountInfo, account, index, localCalendarDate]);

  return (
    category.length > 0 && (
      <>
        <div
          className={
            fullScreenStatus
              ? "d-flex flex-column justify-content-between piik-visual-section-fullscreen piik-create-chart "
              : "d-flex flex-column justify-content-between piik-visual-section piik-create-chart "
          }
        >
          <div className="flex-grow-1 overflow-y-scroll">
            <div className="d-flex flex-column  h-100 w-100 ">
              <div className="flex-grow-1 w-100  h-100 d-flex flex-column ">
                <KpiPlot
                  category={category}
                  calenderDatesData={calenderDatesData}
                  calenderData={localCalendarDate}
                  account={selectedAccount}
                  handleCalendarLocal={handleCalendarLocal}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-row justify-content-between  bg-light border-top align-self-center"
          style={{ zIndex: 2 }}
        >
          <div className="py-1 piik-text-dashboard text-primary  px-4 fw-bold">
            Period : {localCalendarDate.month}-{localCalendarDate.year_index}
          </div>
          <div className="d-flex flex-row justify-content-end">
            {profile.user_id === selectedAccount.owner_id ? (
              <>
                <DisplayBottomIcon
                  title="Copy Chart"
                  icon="bi-clipboard-plus"
                  handleIconAction={handleDuplicate}
                />
                <DisplayBottomIcon
                  title="Edit Kpi"
                  icon="bi-pencil-square"
                  handleIconAction={handleEditKpi}
                />

                <DisplayBottomIcon
                  title="Delete Kpi"
                  icon="bi-trash"
                  handleIconAction={openModal}
                />
              </>
            ) : null}

            <DisplayBottomIcon
              title="Kpi Description"
              icon="bi-info-circle"
              status={false}
              // handleIconAction=
            />
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          closeModal={closeModal}
          title={kpiData && kpiData.name ? kpiData.name : "Loading..."}
        >
          {isModalOpen && selectedAccount ? (
            <div className="row mx-0 justify-content-between px-4 pt-2 pb-4 ">
              <div className="col-12 piik-text-warning px-2 pt-2 pb-4">
                This will permanently delete the dashboard from the
                <span className="text-danger fw-semibold px-1">
                  {user.email}
                </span>{" "}
                account.
              </div>
              <div className="col px-2 ">
                <input
                  type="email"
                  className="form-control form-control-sm"
                  id="password"
                  placeholder="Please enter the email
                  to confirm"
                  value={password.value}
                  onChange={handlePasswordInputChange}
                />
                {password.errorMessage !== "" && (
                  <label className="piik-text-warning text-danger text-capitalize py-1">
                    {password.errorMessage}
                  </label>
                )}
              </div>

              <div className="col-2 px-0">
                <button
                  className="btn btn-sm btn-outline-primary w-100 px-2"
                  onClick={handleDeleteKpi}
                  disabled={btn}
                >
                  {btn ? (
                    <span className="piik-text-icon text-warning">
                      Processing
                    </span>
                  ) : (
                    "Delete"
                  )}
                </button>
              </div>
            </div>
          ) : (
            <div className="row mx-0 justify-content-between px-4 pt-2 pb-4 ">
              <div className="col-12 piik-text-dashboard px-2 pt-2 pb-4">
                Sorry you are not allowed to delete all the Dashboard. Add a new
                dashboard first to clear the selected Chart
              </div>
            </div>
          )}
        </Modal>
      </>
    )
  );
};

export default DisplayKpi;
