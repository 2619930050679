export const useLocalStorage = () => {
  const getValue = (keyName) => {
    const value = window.localStorage.getItem(keyName);
    if (value) {
      return JSON.parse(value);
    }
  };

  const setValue = (keyName, newValue) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {
      console.log(err);
    }
  };

  const setRemoveValue = (keyName) => {
    try {
      window.localStorage.removeItem(keyName);
    } catch (err) {
      console.log(err);
    }
  };

  const getAuthState = (keyName) => {
    const value = window.localStorage.getItem(keyName);
    if (value) {
      return value;
    }
  };

  const setAuthState = (keyName, newValue) => {
    try {
      window.localStorage.setItem(keyName, newValue);
    } catch (err) {
      console.log(err);
    }
  };

  const removeAuthState = (keyName) => {
    try {
      window.localStorage.removeItem(keyName);
    } catch (err) {
      console.log(err);
    }
  };
  return {
    getValue,
    setValue,
    setRemoveValue,
    getAuthState,
    setAuthState,
    removeAuthState,
  };
};
