import React, { useState, useEffect, useRef } from "react";
import { config } from "./ChartConfig";
import { useDashData } from "../../../hooks/useDash";
import { useAuth0 } from "@auth0/auth0-react";

import Modal from "../../Common/Modal";
import DisplayBottomIcon from "../DisplayBottomIcon";
import PlotChart from "./Plot";

import { addKeysToObjects } from "./Utility";

const Chart = ({
  fullScreenStatus,
  index,
  handleVisualizationTab,
  handleUpdateVisualizationIndex,
}) => {
  const {
    adminAccountInfo,
    guestAccountInfo,
    calenderData,
    account,
    profile,
    isModalOpen,
    openModal,
    closeModal,
    handleDeleteVisulization,
    handleDownloadAsImage,
    handleCopyData,
  } = useDashData();
  const { user } = useAuth0();

  const divRef = useRef(null);
  const [plotState, setPlotState] = useState(false);
  const [selectedAccountData, setSelectedAccountData] = useState({});
  const [chartData, setChartData] = useState({});

  const [password, setPassword] = useState({ value: "", errorMessage: "" });
  const [btn, setBtnState] = useState(false);

  const handlePasswordInputChange = (event) => {
    const value = event.target.value;
    setPassword({ errorMessage: "", value: value });
  };

  const handleDownload = async () => {
    handleDownloadAsImage(divRef, chartData);
  };
  const handleDuplicate = () => {
    let duplicate_data = {
      type: "chart",
      id: chartData.id,
      accountId: selectedAccountData.uid,
      userId: selectedAccountData.owner_id,
    };
    handleCopyData(duplicate_data).then((response) => {
      if (response.status) {
        handleUpdateVisualizationIndex(selectedAccountData.chart_id.length);
        handleVisualizationTab(100);
      }
    });
  };

  const handleDeleteChart = async () => {
    if (password.value !== user.email) {
      setPassword({ errorMessage: "Invalid email", value: "" });
      return;
    } else {
      let data = {
        userId: profile.user_id,
        accountId: selectedAccountData.uid,
        chartId: chartData.id,
        visualizationType: "Chart",
      };
      handleDeleteVisulization(data)
        .then((response) => {
          if (response) {
            setBtnState(false);
            setPassword({
              errorMessage: "",
              value: "",
            });
            setChartData({});
            handleVisualizationTab(0);
            closeModal();
          }
        })
        .catch((error) => {
          setBtnState(false);
        });
    }
  };

  const handleEditChart = () => {
    handleUpdateVisualizationIndex(index);
    handleVisualizationTab(100);
  };

  useEffect(() => {
    if (fullScreenStatus !== plotState) {
      setPlotState((preData) => !preData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullScreenStatus]);

  useEffect(() => {
    let CombinedChartData = adminAccountInfo.concat(guestAccountInfo);
    let accountIndex = CombinedChartData.findIndex(
      (data) => data.uid === account
    );
    const accountData = JSON.parse(
      JSON.stringify(CombinedChartData[accountIndex])
    );
    setSelectedAccountData(accountData);
    addKeysToObjects(accountData, index, calenderData)
      .then((data) => {
        setChartData(data);
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, adminAccountInfo, index, calenderData]);

  return chartData &&
    chartData.settings &&
    chartData.settings.hasOwnProperty("name") ? (
    <>
      <div
        className={
          fullScreenStatus
            ? "d-flex flex-column justify-content-between piik-visual-section-fullscreen piik-create-chart "
            : "d-flex flex-column justify-content-between piik-visual-section piik-create-chart"
        }
      >
        <div
          className="flex-grow-1  w-100  h-100 d-flex flex-column bg-white pt-4 "
          ref={divRef}
        >
          {plotState === fullScreenStatus && (
            <PlotChart
              data={chartData && chartData.settings && chartData.settings.data}
              layout={
                chartData && chartData.settings && chartData.settings.layout
              }
              config={config}
              className="w-100 piik-text-visual flex-grow-1 bg-white shadow-sm "
            />
          )}
        </div>
      </div>
      <div
        className="d-flex flex-row justify-content-between  bg-light border-top align-self-center"
        style={{ zIndex: 2 }}
      >
        <div className="py-1 piik-text-dashboard text-primary  px-4">
          {chartData.name}
        </div>

        <div className="d-flex flex-row justify-content-end">
          <DisplayBottomIcon
            title="Download"
            icon="bi-download"
            handleIconAction={handleDownload}
          />

          {profile.user_id === selectedAccountData.owner_id ? (
            <>
              <DisplayBottomIcon
                title="Copy Chart"
                icon="bi-clipboard-plus"
                handleIconAction={handleDuplicate}
              />
              <DisplayBottomIcon
                title="Edit Chart"
                icon="bi-pencil-square"
                handleIconAction={handleEditChart}
              />

              <DisplayBottomIcon
                title="Delete Chart"
                icon="bi-trash"
                handleIconAction={openModal}
              />
            </>
          ) : null}

          <DisplayBottomIcon
            title="Chart Description"
            icon="bi-info-circle"
            status={false}
          />
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        title={chartData ? `${chartData.name} chart` : "Loading..."}
      >
        {isModalOpen && selectedAccountData ? (
          <div className="row mx-0 justify-content-between px-4 pt-2 pb-4 ">
            <div className="col-12 piik-text-warning px-2 pt-2 pb-4">
              This will permanently delete the chart from the
              <span className="text-danger fw-semibold px-1">
                {user.email}
              </span>{" "}
              account.
            </div>
            <div className="col px-2 ">
              <input
                type="email"
                className="form-control form-control-sm"
                id="password"
                placeholder="Please enter the email
                  to confirm"
                value={password.value}
                onChange={handlePasswordInputChange}
              />
              {password.errorMessage !== "" && (
                <label className="piik-text-warning text-danger text-capitalize py-1">
                  {password.errorMessage}
                </label>
              )}
            </div>

            <div className="col-2 px-0">
              <button
                className="btn btn-sm btn-outline-primary w-100 px-2"
                onClick={handleDeleteChart}
                disabled={btn}
              >
                {btn ? (
                  <span className="piik-text-icon text-warning">
                    Processing
                  </span>
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className="row mx-0 justify-content-between px-4 pt-2 pb-4 ">
            <div className="col-12 piik-text-dashboard px-2 pt-2 pb-4">
              Sorry you are not allowed to delete all the Chart. Add a new Chart
              first to delete this Chart
            </div>
          </div>
        )}
      </Modal>
    </>
  ) : null;
  // <VideoTutorials src="source/piikByDash-Charts.mp4" />
};

export default Chart;
