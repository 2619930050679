import React from "react";
const BottomIcon = ({ name, handleChange, status, code, type }) => {
  return (
    <div className="ps-2 piik-text-visual piik-pointer">
      <div className="form-switch">
        <input
          className="form-check-input py-0 p"
          type="checkbox"
          role="switch"
          id={type}
          name={code}
          checked={status}
          onChange={handleChange}
        />
        <span className="px-1"> {name}</span>
      </div>
    </div>
  );
};
export default BottomIcon;
