import React, { useEffect, useState } from "react";
import { convertDisplayDataUnit } from "../Charts/Utility";
import { SelectPVMRadioButton } from "./PvmSelectBtn";

export default function PvmTable({ tableData, filterKey }) {
  const [search, setSearch] = useState("");
  const [pvmData, setPvmData] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState(1);

  const sortByKey = (objectsArray, key, type = 1) => {
    if (key !== "") {
      if (type === 1) {
        const sortedArray = objectsArray.sort((a, b) =>
          a[key] > b[key] ? 1 : -1
        );
        return sortedArray;
      } else {
        const sortedArray = objectsArray.sort((a, b) =>
          a[key] < b[key] ? 1 : -1
        );
        return sortedArray;
      }
    } else {
      return objectsArray;
    }
  };

  const handleSort = (e) => {
    setSortBy(e.target.value);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleTotalCalculation = (data, key) => {
    let total = 0;
    for (let i = 0; i < data.length; i++) {
      total += parseFloat(data[i][key]);
    }
    const result = convertDisplayDataUnit(total);
    return result;
  };

  useEffect(() => {
    setPvmData(() => {
      const tempTableData = tableData.data.filter((d) =>
        filterKey.includes(d.Status)
      );
      if (search !== "") {
        const normalizedSearchTerm = search.toLowerCase();
        const filter_data = tempTableData.filter((item) =>
          item.Name.toLowerCase().includes(normalizedSearchTerm)
        );
        const sorted_data = sortByKey(filter_data, sortBy, sortType);
        return sorted_data;
      } else {
        const sorted_data = sortByKey(tempTableData, sortBy, sortType);
        return sorted_data;
      }
    });
    return () => {
      setPvmData([]);
    };
  }, [tableData, search, sortType, sortBy, filterKey]);

  return (
    pvmData.length > 0 && (
      <>
        <div className="d-flex flex-row justify-content-between bg-light border-bottom  px-2 pt-2 pb-3">
          <div className="w-100 pe-2">
            <div className="piik-text-visual px-2 pb-1">Search By Name</div>
            <input
              type="text"
              className="form-control form-control-sm bg-white  "
              value={search}
              onChange={handleSearch}
              placeholder="Type here..."
            />
          </div>
          <div>
            <div className="piik-text-visual px-2 pb-1">Sort By</div>
            <div className=" d-flex flex-row justify-content-between align-items-center">
              <select
                className="form-select form-select-sm"
                id="sort"
                value={sortBy}
                onChange={handleSort}
                style={{ width: "300px" }}
              >
                <option selected>Select..</option>
                {tableData.columns.map((v, i) => {
                  return <option value={v}>{v}</option>;
                })}
              </select>
              <div className="piik-text-dashboard px-2 d-flex flex-row justify-content-between">
                <SelectPVMRadioButton
                  id="asc"
                  name="Asc"
                  value={sortType}
                  status={sortType === 2}
                  handleTab={() => {
                    setSortType(2);
                  }}
                />
                <SelectPVMRadioButton
                  id="desc"
                  name="Desc"
                  value={sortType}
                  status={sortType === 1}
                  handleTab={() => {
                    setSortType(1);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {pvmData.length > 0 && (
          <table className="table table-striped piik-text-visual">
            <thead className="px-4">
              <tr>
                <th scope="col">#</th>
                {tableData.columns.map((key) => {
                  return key === "Status" || key === "Name" ? (
                    <th scope="col">
                      {sortBy === key ? (
                        <span className="text-primary px-2">{key}</span>
                      ) : (
                        <span className="px-2">{key}</span>
                      )}
                    </th>
                  ) : (
                    <th scope="col" className="text-end px-2">
                      {sortBy === key ? (
                        <span className="text-primary">{key}</span>
                      ) : (
                        <span>{key}</span>
                      )}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {pvmData.map((d, index) => {
                return (
                  <tr className="px-2">
                    <th scope="row">{index + 1}</th>
                    {tableData.columns.map((key) => {
                      return key === "Status" || key === "Name" ? (
                        <td className="text-start px-2">{d[key]}</td>
                      ) : (
                        <td className="text-end px-2">
                          {convertDisplayDataUnit(d[key])}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              <tr className="px-2 fw-bold ">
                <th scope="row"></th>
                <td colspan="2" className="text-start px-2 text-primary">
                  Total
                </td>
                {tableData &&
                  tableData.columns &&
                  tableData.columns
                    .filter((d) => d !== "Status" && d !== "Name")
                    .map((key) => {
                      return (
                        <td className="text-end px-2 text-primary">
                          {pvmData && handleTotalCalculation(pvmData, key)}
                        </td>
                      );
                    })}
              </tr>
            </tbody>
          </table>
        )}
      </>
    )
  );
}
