import React, { useState } from "react";
import {
  Elements,
  useElements,
  useStripe,
  CardElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDashData } from "../../hooks/useDash";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const PaymentForm = ({ productId, handlePaymentMode }) => {
  const {
    handleUpdatesubscription,
    handlesubscriptionDetails,
    handleContentTab,
    profile,
  } = useDashData();

  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    setError({});
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      setError(error.message);
      setLoading(false);
      return;
    } else {
      let response = await handleUpdatesubscription(paymentMethod, productId);
      if (response) {
        let customerId = profile.stripe_customer_id;
        let subscriptionId = profile.stripe_subscription_id;
        handlesubscriptionDetails(customerId, subscriptionId);
        handlePaymentMode();
        setLoading(false);
        handleContentTab("1", "", "");
      } else {
        setLoading(false);
        setError({ message: "Error! Please try again later" });
      }
    }
  };

  return (
    <div className="w-100  ">
      <form onSubmit={handleSubmit}>
        <div className="piik-text-dashboard text-primary pb-2">
          Add Payment Details{" "}
        </div>
        <div className="card p-4 ">
          <CardElement />
          {error && (
            <div className="text-danger piik-text-icon pt-1">
              {error.message}
            </div>
          )}
        </div>

        <div className="d-flex flex-row justify-content-between pt-2">
          <button
            onClick={handlePaymentMode}
            disabled={!stripe || loading}
            className="piik-text-dashboard piik-pointer border-0 bg-light"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary w-25 mt-2"
            disabled={!stripe || loading}
          >
            {loading ? "Processing..." : "Activate"}
          </button>
        </div>
      </form>
    </div>
  );
};

const Stripe = ({ handlePaymentMode, productId }) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        handlePaymentMode={handlePaymentMode}
        productId={productId}
      />
    </Elements>
  );
};

export default Stripe;
