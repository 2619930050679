import React from "react";

const FallBackRender = ({ error }) => {
  const handleReturnHomeBtn = () => {
    window.location.reload();
  };

  return (
    <div className="d-flex flex-row justify-content-center align-items-center w-100 vh-75 py-4 bg-light shadow-sm my-2">
      <div className=""></div>
      <div className="text-center">
        <div className="piik-error-heading bi bi-bug-fill"></div>
        <div className="piik-text-dashboard pb-2">Error!</div>
        <pre className="piik-text-dashboard">{error.message}</pre>
        <button className="btn btn-primary px-4" onClick={handleReturnHomeBtn}>
          Refresh
        </button>
      </div>
    </div>
  );
};

export default FallBackRender;
