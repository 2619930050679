import React, { useState, useEffect } from "react";
import { useDashData } from "../../hooks/useDash";
import { useAuth0 } from "@auth0/auth0-react";
import { formatDate } from "../Visualization/Charts/Utility";

import Tab from "../Common/Tab";
import Modal from "../Common/Modal";

const DataItem = (props) => {
  let status =
    props.selectedEndpoints.length > 0
      ? props.selectedEndpoints.includes(props.data.id)
      : false;
  return (
    <div
      className={`row mx-0  py-4 ${
        props.id % 2 === 0 ? "bg-light" : "bg-white"
      }`}
    >
      <div className="col-1 px-4  piik-text-dashboard ">{props.id + 1}</div>
      <div className="col-8 px-4 piik-text-dashboard"> {props.data.name}</div>
      <div className="col-1 text-center px-4">
        <div className="form-check form-switch">
          <input
            className="form-check-input "
            type="checkbox"
            role="switch"
            id={props.data.id}
            onChange={props.handleCheckBoxChange}
            checked={status}
          />
        </div>
      </div>
      <div className="col-2 text-end piik-text-dashboard">
        {" "}
        {status ? "Selected" : null}
      </div>
    </div>
  );
};

const DataSettings = () => {
  const {
    profile,
    adminAccountInfo,
    isModalOpen,
    openModal,
    closeModal,

    handleDataProfileCreate,
    handleDataProfileDelete,
    handleUserInvite,
  } = useDashData();

  const { user } = useAuth0();
  const [tabId, setTabId] = useState("1");
  const handleTab = (id) => {
    setTabId(id);
  };
  const [subTabId, setSubTabId] = useState("1");
  const handleSubTab = (id) => {
    setSubTabId(id);
  };
  // Tab-I
  const [modalType, setModalType] = useState("");
  const [selectedProfile, setSelectedProfile] = useState({});
  const [viewProfile, setViewProfile] = useState({
    profile: "",
    company: "",
    userEmail: [],
    selectedEndpoints: [],
  });
  const [btnStatus, setBtnStatus] = useState(false);
  const [password, setPassword] = useState({ value: "", errorMessage: "" });
  const handlePasswordInputChange = (event) => {
    const value = event.target.value;
    setPassword({ errorMessage: "", value: value });
  };
  const handleModalOpen = (type, data) => {
    setModalType(type);
    if (type === "view") {
      openModal();
      const allowedKeys = ["chart", "dashboard", "kpi", "report", "pvm"];
      const flattenedArray = Object.entries(data.profile_data)
        .filter(([key]) => allowedKeys.includes(key))
        .flatMap(([key, value]) =>
          Array.isArray(value)
            ? value.map((item) => ({ type: key, value: item }))
            : [{ type: key, value }]
        );

      setViewProfile({
        profile_name: data.profile_data.profile_name,
        company_name: data.company_name,
        data: flattenedArray,
      });
    } else {
      openModal();
      setPassword({ value: "", errorMessage: "" });
      setSelectedProfile(data);
    }
  };
  const handleModalClose = () => {
    setModalType("");
    closeModal();
  };

  const handleDeleteProfile = () => {
    if (password.value.trim() === "") {
      setPassword({ ...password, errorMessage: "Password Required" });
      return;
    } else if (password.value.trim() !== user.email) {
      setPassword({ ...password, errorMessage: "Invalid Password..." });
      return;
    }
    if (password.errorMessage === "" && password.value.trim() !== "") {
      setBtnStatus(true);
      let delete_profile_info = {
        company_uid: selectedProfile.company_data.uid,
        user_id: selectedProfile.company_data.owner_id,
        profile_uid: selectedProfile.profile_data.profile_uid,
      };
      handleDataProfileDelete({
        info: delete_profile_info,
        password: password.value,
      })
        .then((status) => {
          setBtnStatus(false);
          closeModal();
        })
        .catch((error) => {
          setBtnStatus(false);
          setPassword({
            ...password,
            errorMessage: "Error. Please try again.",
          });
        });
    }
  };

  // Tab-II
  const [profileData, setProfileData] = useState({
    profile: "",
    company_uid: "",
    companyData: [],
    selectedEndpoints: [],
    endpointStatus: false,
  });
  const [profileDataErrors, setProfileDataErros] = useState({
    profile: "",
    company: "",
  });

  const handleProfileInputChange = (event, index) => {
    const { id, value } = event.target;

    if (index === null) {
      setProfileData({
        ...profileData,
        [id]: value,
        endpointStatus: false,
      });
      setProfileDataErros({
        ...profileDataErrors,
        [id]: value === "" ? `${id} is required` : "",
      });
    }
  };

  const handleEndpointStatus = () => {
    let indexValue = adminAccountInfo.some((obj) =>
      obj.profiles.some(
        (nameObj) => nameObj.profile_name === profileData.profile
      )
    );
    if (indexValue) {
      setProfileDataErros(() => {
        return {
          ...profileDataErrors,
          profile: `Profile Name already exist`,
        };
      });
      return false;
    }

    if (
      profileData.profile.trim() !== "" &&
      profileData.company_uid.trim() !== ""
    ) {
      let selectCompanyData = adminAccountInfo.find(
        (company) => company.uid === profileData.company_uid
      );
      setProfileData({
        ...profileData,
        companyData: selectCompanyData,
        endpointStatus: true,
      });

      setProfileDataErros({
        profile: "",
        company_uid: "",
      });
    } else {
      setProfileDataErros({
        profile: "Profile Name is required",
        company: "Company Name is required",
      });
    }
  };

  const handleCheckBoxChange = (event) => {
    const name = event.target.id;
    let selectedEndpoints = profileData.selectedEndpoints;
    console.log(name, selectedEndpoints);

    if (selectedEndpoints.length > 0) {
      let status = selectedEndpoints.includes(name);
      if (status) {
        selectedEndpoints = selectedEndpoints.filter((id) => id !== name);
      } else {
        selectedEndpoints = [...selectedEndpoints, name];
      }
    } else {
      selectedEndpoints = [name];
    }
    setProfileData((prevState) => ({
      ...prevState,
      selectedEndpoints: selectedEndpoints,
    }));
  };

  const handleNewProfileSubmit = () => {
    if (profileData.selectedEndpoints.length > 0) {
      let data = {
        profile: profileData.profile,
        company_uid: profileData.company_uid,
        userEmail: [],
        selectedEndpoints: profileData.selectedEndpoints,
      };
      let response = handleDataProfileCreate(data);
      if (response) {
        setProfileData({
          profile: "",
          company_uid: "",
          companyData: [],
          selectedEndpoints: [],
          endpointStatus: false,
        });
      }
    }
  };

  // Tab-III
  const [inviteData, setInviteData] = useState({
    company_uid: "",
    profiles: [],
    profile_uid: "",
    userEmail: [{ email: "", errorMessage: "" }],
    userEndpointStatus: false,
  });

  const [inviteDataErrors, setInviteDataErros] = useState({
    profile_uid_error: "",
    company_uid_error: "",
  });

  const handleUserInputChange = (event, index) => {
    const { id, value } = event.target;
    let updatedData = { ...inviteData };
    let updatedErrors = { ...inviteDataErrors };

    if (id === "company_uid") {
      const selectedCompany = adminAccountInfo.find(
        (data) => data.uid === value
      );
      updatedData = {
        ...updatedData,
        company_uid: value,
        profiles: selectedCompany.profiles,
        userEndpointStatus: false,
      };
      updatedErrors = { ...updatedErrors, company_uid_error: "" };
    } else if (id === "profile_uid") {
      const history =
        profile.host_invite.length > 0
          ? profile.host_invite.find((data) => data.profile_uid === value)
          : null;

      const emailHistory =
        history !== null && history !== undefined
          ? history.user_email
          : inviteData.userEmail;
      updatedData = {
        ...updatedData,
        profile_uid: value,
        userEmail: emailHistory,
        userEndpointStatus: false,
      };
      updatedErrors = { ...updatedErrors, profile_uid_error: "" };
    } else if (id === "email") {
      const newFormData = [...inviteData.userEmail];
      newFormData[index] = {
        ...newFormData[index],
        email: value.toLowerCase(),
        errorMessage: "",
      };
      const uniqueArrayOfObjects = newFormData.filter(
        (obj, index, self) =>
          self.findIndex((el) => el.email === obj.email) === index
      );
      updatedData = { ...updatedData, userEmail: uniqueArrayOfObjects };
    }
    setInviteData(updatedData);
    setInviteDataErros(updatedErrors);
  };

  const handleUserEndpointStatus = () => {
    if (
      inviteData.profile_uid.trim() !== "" &&
      inviteData.company_uid.trim() !== ""
    ) {
      setInviteData({
        ...inviteData,
        userEndpointStatus: true,
      });
      setInviteDataErros({
        profile_uid: "",
        company_uid: "",
      });
    } else {
      setInviteDataErros({
        profile_uid_error: "Profile Name is required",
        company_uid_error: "Company Name is required",
      });
    }
  };

  const handleRemoveField = (e, index) => {
    let userEmail = inviteData.userEmail;
    if (userEmail.length > 1) {
      let filter = userEmail.filter((_, i) => i !== index);
      setInviteData({
        ...inviteData,
        userEmail: filter,
      });
    }
  };
  const handleAddField = () => {
    let userEmail = inviteData.userEmail;
    setInviteData({
      ...inviteData,
      userEmail: [...userEmail, { email: "", errorMessage: "" }],
    });
  };

  const handleUserDataSubmit = () => {
    const updatedUserEmail = inviteData.userEmail.map((data) => {
      if (data.email === "") {
        return {
          ...data,
          errorMessage: "Email Address is Required",
        };
      } else if (data.email === profile.user_email) {
        return {
          ...data,
          errorMessage: "Request Denined. Please use a valid email address",
        };
      } else {
        return data;
      }
    });

    const hasEmptyEmail = updatedUserEmail.some(
      (data) => data.email === "" || data.email === profile.user_email
    );
    if (hasEmptyEmail) {
      setInviteData({
        ...inviteData,
        userEmail: updatedUserEmail,
      });
    } else {
      let response = handleUserInvite(inviteData);
      if (response) {
        setInviteData({
          company_uid: "",
          profiles: [],
          profile_uid: "",
          userEmail: [{ email: "", errorMessage: "" }],
          userEndpointStatus: false,
        });
      }
    }
  };

  useEffect(() => {
    return () => {
      setInviteData({
        company_uid: "",
        profiles: [],
        profile_uid: "",
        userEmail: [{ email: "", errorMessage: "" }],
        userEndpointStatus: false,
      });
      setProfileData({
        profile: "",
        company_uid: "",
        companyData: [],
        selectedEndpoints: [],
        endpointStatus: false,
      });
    };
  }, [tabId]);

  return (
    <section className="py-2">
      <div className="d-flex flex-row bg-light border-bottom">
        <Tab
          type="3a"
          title="Access Profile Overview"
          tab_id="1"
          status={tabId === "1" ? true : false}
          handleClick={handleTab}
        />
        <Tab
          type="3a"
          title="Create New Access Profile"
          tab_id="2"
          status={tabId === "2" ? true : false}
          handleClick={handleTab}
        />
        <Tab
          type="3a"
          title="Add Users to Profile"
          tab_id="3"
          status={tabId === "3" ? true : false}
          handleClick={handleTab}
        />
      </div>
      {tabId === "1" ? (
        <table className="table table-hover piik-content-section ">
          <thead>
            <tr>
              <th scope="col" className="piik-text-dashboard ">
                #
              </th>
              <th scope="col" className="piik-text-dashboard ">
                Profile Name
              </th>
              <th scope="col" className="piik-text-dashboard ">
                Company Name
              </th>
              <th scope="col" className="text-center piik-text-dashboard">
                Created At
              </th>
              <th colspan="2" className="text-center piik-text-dashboard px-4">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {adminAccountInfo.map((data, index) => {
              if (data.profile_details.length > 0) {
                return data.profile_details.map((d, i) => (
                  <tr key={i}>
                    <th scope="row" className="piik-text-dashboard ">
                      {index + 1}
                    </th>
                    <td colSpan="1" className="piik-text-dashboard">
                      {d.profile_name}
                    </td>
                    <td colSpan="1" className="piik-text-dashboard">
                      {data.company_name}
                    </td>
                    <td colSpan="1" className="piik-text-dashboard text-center">
                      {formatDate(d.created_at)}
                    </td>
                    <td
                      className="text-center piik-text-dashboard bg-primary text-white border-end piik-pointer "
                      onClick={() =>
                        handleModalOpen("view", {
                          profile_data: d,
                          company_name: data.company_name,
                        })
                      }
                    >
                      <div className="bi bi-eye ">
                        <span className="px-2">Show</span>
                      </div>
                    </td>
                    <td
                      className="text-center piik-text-dashboard bg-primary text-white  piik-pointer"
                      onClick={() =>
                        handleModalOpen("delete", {
                          profile_data: d,
                          company_data: data,
                        })
                      }
                    >
                      <div className="bi bi-trash">
                        <span className="px-2">Remove</span>
                      </div>
                    </td>
                  </tr>
                ));
              }
              return null;
            })}
          </tbody>
        </table>
      ) : tabId === "2" ? (
        <>
          <div className=" bg-white px-2 py-2 row mx-0">
            <div className="form-group pb-2 col-5">
              <label className="piik-text-dashboard py-1">Profile Name</label>
              <input
                type="text"
                className="form-control form-control-sm "
                id="profile"
                placeholder="Enter the Name"
                value={profileData.profile}
                onChange={(e) => handleProfileInputChange(e, null)}
                required
              />
              {profileDataErrors.profile && (
                <label className="piik-text-warning text-danger text-capitalize py-1">
                  {profileDataErrors.profile}
                </label>
              )}
            </div>
            <div className="form-group px-2 col-5">
              <label className="piik-text-dashboard py-1">Company </label>
              <select
                className="form-control form-control-sm"
                id="company_uid"
                value={profileData.company}
                onChange={(e) => handleProfileInputChange(e, null)}
              >
                <option value="" selected>
                  Select Company ...
                </option>
                {adminAccountInfo.map((data, index) => {
                  return (
                    <option key={`company_${index}`} value={data.uid}>
                      {data.company_name}
                    </option>
                  );
                })}
              </select>
              {profileDataErrors.company && (
                <label className="piik-text-warning text-danger text-capitalize py-1">
                  {profileDataErrors.company}
                </label>
              )}
            </div>
            <div className="form-group pb-2 col">
              <label className="piik-text-dashboard py-1">.</label>
              <button
                className="btn btn-sm btn-outline-primary  w-100"
                onClick={handleEndpointStatus}
              >
                <span className="px-2">Next</span>
              </button>
            </div>
          </div>
          {profileData.endpointStatus ? (
            <div className="mx-0 my-2">
              <div className="d-flex flex-row bg-light border-bottom">
                <Tab
                  type="3a"
                  title="Dashboard"
                  tab_id="1"
                  status={subTabId === "1" ? true : false}
                  handleClick={handleSubTab}
                />
                <Tab
                  type="3a"
                  title="KPIs"
                  tab_id="2"
                  status={subTabId === "2" ? true : false}
                  handleClick={handleSubTab}
                />
                <Tab
                  type="3a"
                  title="Reports"
                  tab_id="4"
                  status={subTabId === "4" ? true : false}
                  handleClick={handleSubTab}
                />
                <Tab
                  type="3a"
                  title="Analysis"
                  tab_id="3"
                  status={subTabId === "3" ? true : false}
                  handleClick={handleSubTab}
                />
              </div>
              <div className="d-flex flex-column piik-content-section  ">
                <div className="row mx-0  py-2 bg-white fw-bold">
                  <div className="col-1 px-4 piik-text-dashboard">#</div>
                  <div className="col-8 px-4 piik-text-dashboard">Name</div>
                  <div className="col-1 px-4 text-center piik-text-dashboard">
                    Action
                  </div>
                  <div className="col-2 text-end piik-text-dashboard">
                    Status
                  </div>
                </div>

                {subTabId === "1" ? (
                  <>
                    {profileData.companyData &&
                      profileData.companyData.length !== 0 &&
                      profileData.companyData.dashboard_id.map(
                        (items, index) => {
                          return (
                            <DataItem
                              data={items}
                              key={index}
                              id={index}
                              subTabId={subTabId}
                              company={profileData.company}
                              selectedEndpoints={profileData.selectedEndpoints}
                              handleCheckBoxChange={handleCheckBoxChange}
                            />
                          );
                        }
                      )}
                  </>
                ) : subTabId === "2" ? (
                  <>
                    {profileData.companyData &&
                      profileData.companyData.length !== 0 &&
                      profileData.companyData.kpi_id.map((items, index) => {
                        return (
                          <DataItem
                            data={items}
                            key={index}
                            id={index}
                            subTabId={subTabId}
                            company={profileData.company}
                            selectedEndpoints={profileData.selectedEndpoints}
                            handleCheckBoxChange={handleCheckBoxChange}
                          />
                        );
                      })}
                  </>
                ) : subTabId === "3" ? (
                  <>
                    {profileData.companyData &&
                      profileData.companyData.length !== 0 &&
                      profileData.companyData.pvm_id.map((items, index) => {
                        return (
                          <DataItem
                            data={items}
                            key={index}
                            id={index}
                            subTabId={subTabId}
                            company={profileData.company}
                            selectedEndpoints={profileData.selectedEndpoints}
                            handleCheckBoxChange={handleCheckBoxChange}
                          />
                        );
                      })}
                  </>
                ) : subTabId === "4" ? (
                  <>
                    {profileData.companyData &&
                      profileData.companyData.length !== 0 &&
                      profileData.companyData.report_id.map((items, index) => {
                        return (
                          <DataItem
                            data={items}
                            key={index}
                            id={index}
                            subTabId={subTabId}
                            company={profileData.company}
                            selectedEndpoints={profileData.selectedEndpoints}
                            handleCheckBoxChange={handleCheckBoxChange}
                          />
                        );
                      })}
                  </>
                ) : subTabId === "5" ? (
                  <>
                    {profileData.companyData &&
                      profileData.companyData.length !== 0 &&
                      profileData.companyData.chart_id.map((items, index) => {
                        return (
                          <DataItem
                            data={items}
                            key={index}
                            id={index}
                            subTabId={subTabId}
                            company={profileData.company}
                            selectedEndpoints={profileData.selectedEndpoints}
                            handleCheckBoxChange={handleCheckBoxChange}
                          />
                        );
                      })}
                  </>
                ) : null}
              </div>
              <div className="d-flex flex-row bg-light w-100 mb-1 piik-text-dashboard px-4 py-1">
                Scoll to check for more data
              </div>
              <div className="d-flex flex-column justify-content-end align-items-end py-2 ">
                <button
                  onClick={handleNewProfileSubmit}
                  className="btn btn-primary px-4 w-25"
                >
                  Save
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : tabId === "3" ? (
        <>
          <div className=" bg-white px-2 py-2 row mx-0">
            <div className="form-group px-2 col-5">
              <label className="piik-text-dashboard py-1">Company </label>
              <select
                className="form-control form-control-sm"
                id="company_uid"
                value={inviteData.company_uid}
                onChange={(e) => handleUserInputChange(e)}
              >
                <option value="" selected>
                  Select Account ...
                </option>
                {adminAccountInfo.map((data, index) => {
                  return (
                    <option key={index} value={data.uid}>
                      {data.company_name}
                    </option>
                  );
                })}
              </select>
              {inviteDataErrors.company_uid_error && (
                <label className="piik-text-warning text-danger text-capitalize py-1">
                  {inviteDataErrors.company_uid_error}
                </label>
              )}
            </div>
            <div className="form-group px-2 col-5">
              <label className="piik-text-dashboard py-1">Profile </label>
              <select
                className="form-control form-control-sm"
                id="profile_uid"
                value={inviteData.profile_uid}
                onChange={(e) => handleUserInputChange(e)}
              >
                <option value="" selected>
                  Select Profile ...
                </option>
                {inviteData.profiles &&
                  inviteData.profiles.length !== 0 &&
                  inviteData.profiles.map((data, index) => {
                    return (
                      <option key={index} value={data.profile_uid}>
                        {data.profile_name}
                      </option>
                    );
                  })}
              </select>
              {inviteDataErrors.profile_uid_error && (
                <label className="piik-text-warning text-danger text-capitalize py-1">
                  {inviteDataErrors.profile_uid_error}
                </label>
              )}
            </div>
            <div className="form-group pb-2 col">
              <label className="piik-text-dashboard py-1">.</label>
              <button
                className="btn btn-sm btn-outline-primary  w-100"
                onClick={handleUserEndpointStatus}
              >
                <span className="px-2">Next</span>
              </button>
            </div>
          </div>
          {inviteData.userEndpointStatus ? (
            <>
              <div className="bg-white px-2 piik-content-section my-2 p-4">
                {inviteData.userEmail.map((feild, index) => {
                  return (
                    <div className="row mx-0 justify-content-between py-2">
                      <div className="form-group pb-1 col-12">
                        <div className="input-group">
                          <input
                            type="email"
                            placeholder="Recipient's username"
                            className="form-control form-control-sm"
                            id="email"
                            value={feild.email}
                            onChange={(e) => handleUserInputChange(e, index)}
                          />
                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            onClick={(e) => handleRemoveField(e, index)}
                          >
                            <i class="bi bi-trash3"></i>
                          </button>
                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            onClick={handleAddField}
                          >
                            <i class="bi bi-person-fill-add"></i>
                          </button>
                        </div>

                        {feild.errorMessage !== "" && (
                          <label className="piik-text-warning text-danger text-capitalize py-1">
                            {feild.errorMessage}
                          </label>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="d-flex flex-column justify-content-end align-items-end py-2 ">
                <button
                  onClick={handleUserDataSubmit}
                  className="btn btn-primary px-4 w-25"
                >
                  Save
                </button>
              </div>
            </>
          ) : null}
        </>
      ) : null}

      {/* View Profile Modal */}
      {modalType === "view" ? (
        <Modal
          isOpen={isModalOpen}
          closeModal={handleModalClose}
          title={`${viewProfile.company_name} | ${viewProfile.profile_name} `}
        >
          <table className="table table-hover piik-text-dashboard mb-4 mx-auto text-capitalize">
            <thead>
              <tr>
                <th>#</th>
                <th>Type</th>
                <th>Name</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {viewProfile.data.map((items, index) => {
                return (
                  <tr>
                    <td>{index + 1}.</td>
                    <td>{items.type}</td>
                    <td>{items.value.name}</td>
                    <td>{items.value.created_by}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Modal>
      ) : modalType === "delete" ? (
        <Modal
          isOpen={isModalOpen}
          closeModal={handleModalClose}
          title={`${selectedProfile.company_data.company_name} | ${selectedProfile.profile_data.profile_name} `}
        >
          <div className="row mx-0 justify-content-between px-4 pt-2 pb-4 ">
            <div className="col-12 piik-text-warning p-2 mb-2">
              This will permanently remove the profile from the
              <span className="text-danger fw-semibold px-1">
                {user.email}
              </span>{" "}
              account.
            </div>
            <div className="col px-2">
              <input
                type="email"
                className="form-control form-control-sm"
                id="password"
                placeholder="Please enter the email
                  to confirm"
                value={password.value}
                onChange={handlePasswordInputChange}
              />
              {password.errorMessage !== "" && (
                <label className="piik-text-warning text-danger text-capitalize py-1">
                  {password.errorMessage}
                </label>
              )}
            </div>

            <div className="col-2 px-0">
              <button
                className={
                  btnStatus
                    ? "btn btn-sm btn-outline-secondary w-100 px-2"
                    : "btn btn-sm btn-outline-primary w-100 px-2"
                }
                onClick={handleDeleteProfile}
                disabled={btnStatus}
              >
                Remove
              </button>
            </div>
          </div>
        </Modal>
      ) : null}
    </section>
  );
};

export default DataSettings;
