import React, { useState, useEffect } from "react";
import DataRefresh from "../Common/DataRefresh";
import Tab from "../Common/Tab";
import VisualizationHeader from "./VisualizationHeader";

import CreateChart from "./Charts/CreateChart";
import Chart from "./Charts/DisplayChart";

import CreateDashboard from "./Dashboards/CreateDashboard";
import DisplayDashboard from "./Dashboards/DisplayDashboard";

import CreatePvm from "./Pvm/CreatePvm";
import DisplayPvm from "./Pvm/DisplayPvm";

import CreateKpi from "./Kpi/CreateKpi";
import DisplayKpi from "./Kpi/DisplayKpi";

import CreateReport from "./Reports/CreateReport";
import DisplayReport from "./Reports/DisplayReport";

import CreateEndpoint from "./Endpoints/CreateEndpoints";

import { useDashData } from "../../hooks/useDash";
import DisplayEndpoints from "./Endpoints/DisplayEndpoints";

const Visualization = (props) => {
  const { profile, account, adminAccountInfo, guestAccountInfo } =
    useDashData();

  const [adminStatus, setAdminStatus] = useState(true);
  const [updateVisualization, setUpdateVisualization] = useState(null);
  const [fullScreenStatus, setFullScreenStatus] = useState(false);

  const handleFullScreenStatus = () => {
    setFullScreenStatus(!fullScreenStatus);
  };

  useEffect(() => {
    let CombinedChartData = adminAccountInfo.concat(guestAccountInfo);
    const accountIndex = CombinedChartData.findIndex(
      (data) => data.uid === account
    );
    const selectedAccountData = JSON.parse(
      JSON.stringify(CombinedChartData[accountIndex])
    );
    setAdminStatus(() => {
      if (profile.user_id === selectedAccountData.owner_id) {
        return true;
      } else {
        return false;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAccountInfo, account]);

  return (
    <section className={fullScreenStatus ? "piik-full-screen" : "py-2"}>
      <div className="m-0 p-0 ">
        {/* Header */}
        <div className="d-flex flex-row bg-light border-bottom align-items-center w-100 ">
          <div className=" flex-grow-1">
            <div className=" d-flex flex-row">
              {fullScreenStatus && (
                <img
                  src="images/PiikDashIconWhite.svg"
                  className="py-2 px-3 text-start bg-primary border-end"
                  alt="Piik-dash logo"
                  height="35px"
                />
              )}

              {props.navigationData[1].subTab.map((item, index) => {
                return item.name !== "Data" && item.name !== "Charts" ? (
                  <Tab
                    key={index}
                    title={item.name}
                    tab_id="2"
                    subTab_id={props.account}
                    tertiaryTab_id={item.id}
                    status={
                      props.selectedContentOption.tertiaryTab_id === item.id
                        ? true
                        : false
                    }
                    handleClick={props.handleContentTab}
                  />
                ) : item.name === "Data" && adminStatus ? (
                  <Tab
                    key={index}
                    title={item.name}
                    tab_id="2"
                    subTab_id={props.account}
                    tertiaryTab_id={item.id}
                    status={
                      props.selectedContentOption.tertiaryTab_id === item.id
                        ? true
                        : false
                    }
                    handleClick={props.handleContentTab}
                  />
                ) : item.name === "Charts" && adminStatus ? (
                  <Tab
                    key={index}
                    title={item.name}
                    tab_id="2"
                    subTab_id={props.account}
                    tertiaryTab_id={item.id}
                    status={
                      props.selectedContentOption.tertiaryTab_id === item.id
                        ? true
                        : false
                    }
                    handleClick={props.handleContentTab}
                  />
                ) : null;
              })}
            </div>
          </div>
          <DataRefresh
            handleFullScreenStatus={handleFullScreenStatus}
            fullScreenStatus={fullScreenStatus}
          />
        </div>
        {/* Sub Header */}
        <div className="d-flex flex-row bg-light border-bottom">
          {props.selectedContentOption.tertiaryTab_id === "1" ? (
            <VisualizationHeader
              visibililityStatus={adminStatus}
              tab_name="Dashboard"
              tertiaryTab_id={props.selectedContentOption.tertiaryTab_id}
              data={props.visualizationContent.dashboard_id}
              visualizationTab={props.visualizationTab}
              handleUpdateVisualizationIndex={setUpdateVisualization}
              handleVisualizationTab={props.handleVisualizationTab}
            />
          ) : props.selectedContentOption.tertiaryTab_id === "2" ? (
            <VisualizationHeader
              visibililityStatus={adminStatus}
              tab_name="KPI"
              tertiaryTab_id={props.selectedContentOption.tertiaryTab_id}
              data={props.visualizationContent.kpi_id}
              visualizationTab={props.visualizationTab}
              handleUpdateVisualizationIndex={setUpdateVisualization}
              handleVisualizationTab={props.handleVisualizationTab}
            />
          ) : props.selectedContentOption.tertiaryTab_id === "3" ? (
            <VisualizationHeader
              visibililityStatus={adminStatus}
              tab_name="Analysis"
              tertiaryTab_id={props.selectedContentOption.tertiaryTab_id}
              data={props.visualizationContent.pvm_id}
              visualizationTab={props.visualizationTab}
              handleUpdateVisualizationIndex={setUpdateVisualization}
              handleVisualizationTab={props.handleVisualizationTab}
            />
          ) : props.selectedContentOption.tertiaryTab_id === "4" ? (
            <VisualizationHeader
              visibililityStatus={adminStatus}
              tab_name="Report"
              tertiaryTab_id={props.selectedContentOption.tertiaryTab_id}
              data={props.visualizationContent.report_id}
              visualizationTab={props.visualizationTab}
              handleUpdateVisualizationIndex={setUpdateVisualization}
              handleVisualizationTab={props.handleVisualizationTab}
            />
          ) : props.selectedContentOption.tertiaryTab_id === "5" &&
            adminStatus ? (
            <VisualizationHeader
              visibililityStatus={adminStatus}
              tab_name="Chart"
              tertiaryTab_id={props.selectedContentOption.tertiaryTab_id}
              data={props.visualizationContent.chart_id}
              visualizationTab={props.visualizationTab}
              handleUpdateVisualizationIndex={setUpdateVisualization}
              handleVisualizationTab={props.handleVisualizationTab}
            />
          ) : props.selectedContentOption.tertiaryTab_id === "6" &&
            adminStatus ? (
            <VisualizationHeader
              visibililityStatus={adminStatus}
              tab_name="Data"
              tertiaryTab_id={props.selectedContentOption.tertiaryTab_id}
              data={props.visualizationContent.custom_data}
              visualizationTab={props.visualizationTab}
              handleUpdateVisualizationIndex={setUpdateVisualization}
              handleVisualizationTab={props.handleVisualizationTab}
            />
          ) : null}
        </div>
        <div>
          {props.selectedContentOption.tertiaryTab_id === "5" &&
          props.visualizationTab === 100 &&
          adminStatus ? (
            <CreateChart
              fullScreenStatus={fullScreenStatus}
              updateVisualizationIndex={updateVisualization}
              handleUpdateVisualizationIndex={setUpdateVisualization}
              handleVisualizationTab={props.handleVisualizationTab}
            />
          ) : props.selectedContentOption.tertiaryTab_id === "5" ? (
            <Chart
              fullScreenStatus={fullScreenStatus}
              index={props.visualizationTab}
              handleVisualizationTab={props.handleVisualizationTab}
              handleUpdateVisualizationIndex={setUpdateVisualization}
            />
          ) : props.selectedContentOption.tertiaryTab_id === "1" &&
            props.visualizationTab === 100 &&
            adminStatus ? (
            <CreateDashboard
              fullScreenStatus={fullScreenStatus}
              updateVisualizationIndex={updateVisualization}
              handleUpdateVisualizationIndex={setUpdateVisualization}
              handleVisualizationTab={props.handleVisualizationTab}
            />
          ) : props.selectedContentOption.tertiaryTab_id === "1" ? (
            <DisplayDashboard
              fullScreenStatus={fullScreenStatus}
              index={props.visualizationTab}
              handleVisualizationTab={props.handleVisualizationTab}
              handleUpdateVisualizationIndex={setUpdateVisualization}
            />
          ) : props.selectedContentOption.tertiaryTab_id === "3" &&
            props.visualizationTab === 100 &&
            adminStatus ? (
            <CreatePvm
              fullScreenStatus={fullScreenStatus}
              updateVisualizationIndex={updateVisualization}
              handleUpdateVisualizationIndex={setUpdateVisualization}
              handleVisualizationTab={props.handleVisualizationTab}
            />
          ) : props.selectedContentOption.tertiaryTab_id === "3" ? (
            <DisplayPvm
              fullScreenStatus={fullScreenStatus}
              index={props.visualizationTab}
              handleVisualizationTab={props.handleVisualizationTab}
              handleUpdateVisualizationIndex={setUpdateVisualization}
            />
          ) : props.selectedContentOption.tertiaryTab_id === "2" &&
            props.visualizationTab === 100 &&
            adminStatus ? (
            <CreateKpi
              fullScreenStatus={fullScreenStatus}
              updateVisualizationIndex={updateVisualization}
              handleUpdateVisualizationIndex={setUpdateVisualization}
              handleVisualizationTab={props.handleVisualizationTab}
            />
          ) : props.selectedContentOption.tertiaryTab_id === "2" ? (
            <DisplayKpi
              fullScreenStatus={fullScreenStatus}
              index={props.visualizationTab}
              handleVisualizationTab={props.handleVisualizationTab}
              handleUpdateVisualizationIndex={setUpdateVisualization}
            />
          ) : props.selectedContentOption.tertiaryTab_id === "4" &&
            props.visualizationTab === 100 &&
            adminStatus ? (
            <CreateReport
              fullScreenStatus={fullScreenStatus}
              updateVisualizationIndex={updateVisualization}
              handleUpdateVisualizationIndex={setUpdateVisualization}
              handleVisualizationTab={props.handleVisualizationTab}
            />
          ) : props.selectedContentOption.tertiaryTab_id === "4" ? (
            <DisplayReport
              fullScreenStatus={fullScreenStatus}
              index={props.visualizationTab}
              handleVisualizationTab={props.handleVisualizationTab}
              handleUpdateVisualizationIndex={setUpdateVisualization}
            />
          ) : props.selectedContentOption.tertiaryTab_id === "6" &&
            props.visualizationTab === 100 &&
            adminStatus ? (
            <CreateEndpoint
              fullScreenStatus={fullScreenStatus}
              updateVisualizationIndex={updateVisualization}
              handleUpdateVisualizationIndex={setUpdateVisualization}
              handleVisualizationTab={props.handleVisualizationTab}
            />
          ) : props.selectedContentOption.tertiaryTab_id === "6" ? (
            <DisplayEndpoints
              fullScreenStatus={fullScreenStatus}
              index={props.visualizationTab}
              handleVisualizationTab={props.handleVisualizationTab}
              handleUpdateVisualizationIndex={setUpdateVisualization}
            />
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default Visualization;
