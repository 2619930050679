const PvmConfig = {
  staticPlot: false,
  // displayModeBar: false,
  scrollZoom: true,
  responsive: true,
  displaylogo: false,
};

const PvmChartFx = {
  id: 1,
  name: "Waterfall",
  icon: "",
  xaxisType: ["str"],
  yaxisType: ["int", "float"],
  multiMode: "",
  data: [
    {
      type: "waterfall",
      x: [],
      y: [],
      connector: { line: { color: "rgb(63, 63, 63,0.1)", width: "1" } },
      increasing: { marker: { color: "rgba(22, 160, 133, .8)" } },
      decreasing: { marker: { color: "rgba(255, 76, 48, .8)" } },
      initials: { marker: { color: "rgba(108, 122, 137, 1)" } },
      totals: { marker: { color: "rgba(108, 122, 137, 1)" } },
      xaxisIds: "",
      yaxisIds: "",
      text: [],
      hovertemplate: "Revenue: %{y:$.2f}<extra></extra>",
      textposition: "outside",
      textfont: {
        size: 12,
        color: "black",
      },
    },
  ],
  legend: {
    x: 1,
    y: 1,
  },
  layout: {
    annotations: [],
    yaxis: {
      title: "Revenue ($)",
      titlefont: {
        size: 12,
      },
    },
    margin: { t: 0 },
  },
};
export { PvmConfig, PvmChartFx };
