import React, { useEffect, useState } from "react";
import DataFrame from "dataframe-js";

import { convertDisplayDataUnit } from "../Charts/Utility";
import {
  formatDate,
  getEndOfMonth,
  handleLevelsBalanceSheet,
  handleZeroValues,
} from "./Utility";

export default function BalanceSheet(props) {
  const [budgetData, setBudgetData] = useState(null);

  //Utility

  const CreateTable = (props) => {
    //  Create the formate  for the table
    let endpoints = props.selectedAccount.data.filter((d) =>
      props.selectedEndpointId.map((v) => v.id).includes(d.id)
    );

    let enpointData = endpoints.map((d) => {
      return {
        datapoint: d.data,
        name: d.name,
        id: d.id,
        tags: d.tags,
        level: d.level,
        index: d.index,
      };
    });

    let valueByName = enpointData
      .map((d) => ({
        [d.name]: d.datapoint.map((v) => v.value),
      }))
      .reduce((accumulator, currentObject) => {
        return { ...accumulator, ...currentObject };
      }, {});

    let columnDate = { date: enpointData[0].datapoint.map((d) => d.date) };
    let columnId = { id: enpointData.map((d) => d.id) };

    // Create the Dataframe
    let dataFrameDataValues = {
      ...columnId,
      ...columnDate,
      ...valueByName,
    };
    let dataFrameColumns = Object.keys(dataFrameDataValues);
    let df = new DataFrame(dataFrameDataValues, dataFrameColumns);

    // Filter By Date
    let currentDate = getEndOfMonth(
      props.calenderData.year_index,
      props.calenderData.month_index + 1
    );

    let sameMonthLastYr = getEndOfMonth(
      props.calenderData.year_index - 1,
      props.calenderData.month_index + 1
    );

    let fiscalEndLastYr = getEndOfMonth(
      props.calenderData.year_index - 1,
      props.calenderData.fiscal_month_index === 1
        ? 12
        : props.calenderData.fiscal_month_index - 1
    );

    // Create the data
    //  Current Year
    let dfCurrentDate = df.filter((row) => row.get("date") === currentDate);
    let currentDate_dict = dfCurrentDate.toDict();
    if (!currentDate_dict.hasOwnProperty("date")) {
      return;
    }
    let currentDate_key = formatDate(currentDate_dict["date"][0]);
    let columnAccountForCurrentDate = {
      Account: Object.keys(currentDate_dict).filter(
        (key) => !["id", "date", "level", "tags", "index"].includes(key)
      ),
      [currentDate_key]: Object.keys(currentDate_dict)
        .filter(
          (key) => !["id", "date", "level", "tags", "index"].includes(key)
        )
        .map((d) => currentDate_dict[d][0]),
    };

    //  Same Month Last Year
    let dfSameMonthLastYr = df.filter(
      (row) => row.get("date") === sameMonthLastYr
    );
    let sameMonthLastYr_dict = dfSameMonthLastYr.toDict();
    if (Object.keys(sameMonthLastYr_dict).length === 0) {
      sameMonthLastYr_dict = currentDate_dict;
      for (let key in currentDate_dict) {
        if (key === "date") {
          sameMonthLastYr_dict[key] = [sameMonthLastYr];
        } else if (!["id", "level", "tags", "index"].includes(key)) {
          sameMonthLastYr_dict[key] = [0];
        }
      }
    }

    let sameMonthLastYr_key = formatDate(sameMonthLastYr_dict["date"][0]);

    let columnAccountForSameMonthLastYr = {
      [sameMonthLastYr_key + "LY"]: Object.keys(sameMonthLastYr_dict)
        .filter(
          (key) => !["id", "date", "level", "tags", "index"].includes(key)
        )
        .map((d) => sameMonthLastYr_dict[d][0]),
    };

    //  Fiscal year End
    let dfFiscalMonthLastYr = df.filter(
      (row) => row.get("date") === fiscalEndLastYr
    );
    let fiscalMonthLastYr_dict = dfFiscalMonthLastYr.toDict();
    if (Object.keys(fiscalMonthLastYr_dict).length === 0) {
      fiscalMonthLastYr_dict = currentDate_dict;
      for (let key in currentDate_dict) {
        if (key === "date") {
          fiscalMonthLastYr_dict[key] = [fiscalEndLastYr];
        } else if (!["id", "level", "tags", "index"].includes(key)) {
          fiscalMonthLastYr_dict[key] = [0];
        }
      }
    }
    let fiscalMonthLastYr_key = formatDate(fiscalMonthLastYr_dict["date"][0]);
    let columnFiscalMonthLastYr = {
      [fiscalMonthLastYr_key + "LFY"]: Object.keys(fiscalMonthLastYr_dict)
        .filter(
          (key) => !["id", "date", "level", "tags", "index"].includes(key)
        )
        .map((d) => fiscalMonthLastYr_dict[d][0]),
    };

    let columnTags = { tags: enpointData.map((d) => d.tags) };
    let columnLevel = { level: enpointData.map((d) => d.level) };
    let columnIndex = { index: enpointData.map((d) => d.index) };
    let balanceSheetDf = new DataFrame(
      {
        ...columnAccountForCurrentDate,
        ...columnAccountForSameMonthLastYr,
        ...columnFiscalMonthLastYr,
        ...columnTags,
        ...columnLevel,
        ...columnIndex,
      },
      [
        "Account",
        "Current Yr",
        "Same Month Last Yr",
        "Fiscal Last Yr",
        "tags",
        "level",
        "index",
      ]
    );

    balanceSheetDf = balanceSheetDf.chain((row) =>
      row.set("Variance", row.get("Current Yr") - row.get("Same Month Last Yr"))
    );
    balanceSheetDf = balanceSheetDf.map((row) =>
      row.set(
        "Variance %",
        (row.get("Variance") / row.get("Same Month Last Yr")) * 100
      )
    );

    balanceSheetDf = balanceSheetDf.map((row) =>
      row.set("Variance_", row.get("Current Yr") - row.get("Fiscal Last Yr"))
    );
    balanceSheetDf = balanceSheetDf.map((row) =>
      row.set(
        "Variance_ %",
        (row.get("Variance_") / row.get("Fiscal Last Yr")) * 100
      )
    );
    balanceSheetDf = balanceSheetDf.restructure([
      "tags",
      "level",
      "index",
      "Account",
      "Current Yr",
      "Same Month Last Yr",
      "Variance",
      "Variance %",
      "Fiscal Last Yr",
      "Variance_",
      "Variance_ %",
    ]);

    let tableData = balanceSheetDf.toCollection();
    props.handleTableData && props.handleTableData(tableData);
    let dateHeader = [
      "Account",
      currentDate_key,
      sameMonthLastYr_key,
      "",
      "",
      fiscalMonthLastYr_key,
      "",
      "",
    ];
    return { tableData, dateHeader };
  };

  useEffect(() => {
    setBudgetData(() => {
      const data = CreateTable(props);
      if (data) {
        const { tableData, dateHeader } = data;
        return { tableData: tableData, dateHeader: dateHeader };
      } else {
        return null;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedEndpointId, props.bottomSettings, props.selectedAccount]);
  return (
    budgetData && (
      <table ref={props.divRef} className="table table-hover table-bordered ">
        <thead
          className="shadow-sm"
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <tr>
            {budgetData &&
              budgetData.tableData &&
              Object.keys(budgetData.tableData[0])
                .filter((d) => !["tags", "level", "index"].includes(d))
                .map((header) => {
                  if (header === "Account") {
                    return (
                      <th className="piik-text-visual">
                        <div className="d-flex  flex-column justify-content-start align-items-start">
                          As of Period:
                          <select
                            value={
                              props.calenderData &&
                              props.calenderData.month_index
                            }
                            onChange={(e) =>
                              props.handleCalendarLocal(
                                e.target.value,
                                props.calenderData.year_index
                              )
                            }
                            className="form-select form-select-sm w-100"
                            style={{ minWidth: "100px" }}
                          >
                            {props.months &&
                              props.months
                                .filter((m) => m.status)
                                .map((m, i) => {
                                  return (
                                    <option value={m.month_index}>
                                      {m.year_index}-{m.month}
                                    </option>
                                  );
                                })}
                          </select>
                        </div>
                      </th>
                    );
                  } else {
                    return (
                      <th className="piik-text-visual text-end">{header}</th>
                    );
                  }
                })}
          </tr>
          <tr className="table-active">
            {budgetData &&
              budgetData.dateHeader &&
              budgetData.dateHeader.map((header) => {
                if (header === "Account") {
                  return <th className="piik-text-visual">{header}</th>;
                } else {
                  return (
                    <th className="piik-text-visual text-end">{header}</th>
                  );
                }
              })}
          </tr>
        </thead>
        <tbody>
          {budgetData &&
            budgetData.tableData &&
            budgetData.tableData
              .sort((a, b) => a.index - b.index)
              .map((d) => handleLevelsBalanceSheet(props.bottomSettings, d))
              .filter((d) => handleZeroValues(props.bottomSettings, d, "II"))
              .map((data, index) => {
                if (data["tags"] === "H") {
                  return (
                    <tr key={index}>
                      <td
                        colSpan={Object.keys(data).length}
                        className="piik-text-visual fw-bold "
                        style={{
                          paddingLeft: `${parseInt(data["level"]) + 1}rem
                                    `,
                        }}
                      >
                        {data["Account"]}
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr
                      key={index}
                      className={`${
                        data["tags"] === "S" ? "table-active" : null
                      }`}
                    >
                      {Object.keys(data)
                        .filter((d) => !["tags", "level", "index"].includes(d))
                        .map((header) => {
                          if (header === "Account") {
                            return (
                              <td
                                className={`piik-text-visual ${
                                  data["tags"] === "H" || data["tags"] === "S"
                                    ? "fw-bold "
                                    : null
                                }`}
                                style={{
                                  paddingLeft: `${
                                    parseInt(data["level"]) + 1
                                  }rem
                                    `,
                                }}
                              >
                                {data[header]}
                              </td>
                            );
                          } else if (header.includes("%")) {
                            return (
                              <td className="piik-text-visual text-end">
                                {data["tags"] === "H" ? (
                                  ""
                                ) : !isNaN(data[header]) &&
                                  data[header] !== Infinity &&
                                  data[header] !== -Infinity ? (
                                  <span
                                    className={`${
                                      data[header] > 0 && header.includes("Var")
                                        ? "text-success"
                                        : data[header] < 0 &&
                                          header.includes("Var")
                                        ? "text-danger"
                                        : null
                                    } ${data.tags === "S" ? "fw-bold" : null}`}
                                  >
                                    {convertDisplayDataUnit(
                                      data[header],
                                      "RF"
                                    ) + "%"}
                                  </span>
                                ) : (
                                  "-"
                                )}
                              </td>
                            );
                          } else {
                            return (
                              <td className="piik-text-visual text-end">
                                {data["tags"] === "H" ? (
                                  ""
                                ) : !isNaN(data[header]) &&
                                  data[header] !== Infinity &&
                                  data[header] !== -Infinity ? (
                                  <span
                                    className={`${
                                      data[header] > 0 && header.includes("Var")
                                        ? "text-success"
                                        : data[header] < 0 &&
                                          header.includes("Var")
                                        ? "text-danger"
                                        : null
                                    } ${data.tags === "S" ? "fw-bold" : null}`}
                                  >
                                    {convertDisplayDataUnit(data[header], "RF")}
                                  </span>
                                ) : (
                                  "NA"
                                )}
                              </td>
                            );
                          }
                        })}
                    </tr>
                  );
                }
              })}
        </tbody>
      </table>
    )
  );
}
