const config = {
  staticPlot: false,
  // displayModeBar: false,
  scrollZoom: true,
  responsive: true,
  displaylogo: false,
};
const baseChartsConfig = [
  {
    visualId: 1,
    name: "Scatter",
    icon: "",
    xaxisType: ["int", "float", "datetime"],
    yaxisType: ["int", "float", "str"],
    multiMode: "All",
    selectedDataPoint: { name: "", index: null, id: "", category: "" },
    data: [
      {
        x: [],
        y: [],
        yaxis: "y",
        xaxis: "x",
        index: "0",
        xaxisIds: "",
        xaxisCategory: "",
        xaxisDataType: "",
        yaxisIds: "",
        yaxisCategory: "",
        yaxisDataType: "",
        text: [],
        textangle: 0,
        name: "",
        groupBy: "sum",
        chartType: "actual",
        additionChartType: [],

        plotName: "Scatter",
        type: "scatter",
        mode: "markers+text",
        textposition: "auto",
        textfont: {
          size: 12,
          color: "black",
        },
        marker: {
          symbol: "circle",
          color: "",
          size: 8,
        },
        opacity: 0.5,
        orientation: "v",
        hovertemplate: "Yaxis: %{y:.0f}<br><extra></extra>",
        legendId: "",
        visibilityState: true,
        filterEndpoint: [],
      },
    ],
    layout: {
      displayUnits: "A",
      sortBy: { code: "", nValue: 5, xais: "yaxis" },
      annotations: [],
      xaxis: {
        title: { text: "", font: { size: 12 } },
        showline: false,
        showgrid: true,
        showticklabels: true,
        ticktextposition: "inside",
        tickfont: {
          size: 10,
        },
      },
      yaxis: {
        title: { text: "", font: { size: 12 } },
        showline: false,
        zeroLine: false,
        showgrid: true,
        showticklabels: true,
        ticktextposition: "inside",
        tickfont: {
          size: 10,
        },
      },
      legend: {
        x: 0,
        y: 1,
        orientation: "h",
        xanchor: "right",
      },
      showlegend: true,
      margin: { t: 0, l: 80, r: 80, b: 80, pad: 10 },
      hovermode: "closest",
    },
  },
  {
    visualId: 2,
    name: "Line",
    icon: "",
    xaxisType: ["int", "float", "datetime"],
    yaxisType: ["int", "float", "str"],
    multiMode: "All",
    selectedDataPoint: { name: "", index: null, id: "", category: "" },
    data: [
      {
        x: [],
        y: [],
        xaxis: "x",
        yaxis: "y",
        index: "0",
        xaxisIds: "",
        xaxisCategory: "",
        xaxisDataType: "",
        yaxisIds: "",
        yaxisCategory: "",
        yaxisDataType: "",
        name: "",
        text: [],
        textangle: 0,
        groupBy: "sum",
        chartType: "actual",
        additionChartType: [],

        plotName: "Line",
        mode: "lines+markers+text",
        type: "scatter",
        textposition: "bottom",
        textfont: {
          size: 12,
          color: "black",
        },
        marker: {
          symbol: "circle",
          color: "",
          size: 8,
        },
        line: {
          color: "",
          width: 1,
          shape: "linear",
          dash: "solid",
        },
        fill: "",
        opacity: 0.5,
        orientation: "v",

        hovertemplate: "Yaxis: %{y:.0f}<br><extra></extra>",
        legendId: "",
        visibilityState: true,
        filterEndpoint: [],
      },
    ],
    layout: {
      displayUnits: "A",
      sortBy: { code: "", nValue: 5, xais: "yaxis" },
      xaxis: {
        title: { text: "", font: { size: 12 } },
        showline: false,
        showgrid: true,
        showticklabels: true,
        ticktextposition: "inside",
        tickfont: {
          size: 10,
        },
      },
      yaxis: {
        title: { text: "", font: { size: 12 } },
        showline: false,
        zeroLine: false,
        showgrid: true,
        showticklabels: true,
        ticktextposition: "inside",
        tickfont: {
          size: 10,
        },
      },
      legend: {
        x: 1,
        y: 0,
        orientation: "v",
        xanchor: "left",
      },
      showlegend: true,
      margin: { t: 0, l: 80, r: 80, b: 80, pad: 10 },

      hovermode: "closest",
    },
  },
  {
    visualId: 3,
    name: "Bar",
    icon: "",
    xaxisType: ["datetime", "str"],
    yaxisType: ["int", "float", "str"],
    multiMode: "All",
    selectedDataPoint: { name: "", index: null, id: "", category: "" },
    data: [
      {
        x: [],
        y: [],
        xaxis: "x",
        yaxis: "y",
        index: "0",
        xaxisIds: "",
        xaxisCategory: "",
        xaxisDataType: "",
        yaxisIds: "",
        yaxisCategory: "",
        yaxisDataType: "",
        text: [],
        textangle: 0,
        name: "",
        groupBy: "sum",
        chartType: "actual",
        additionChartType: [],

        plotName: "Bar",
        type: "bar",
        orientation: "v",
        textposition: "auto",
        textfont: {
          size: 12,
          color: "black",
        },
        opacity: 0.5,
        marker: {
          color: "",
          line: {
            color: "",
            width: 1.5,
            dash: "solid",
          },
        },
        legendId: "",
        hovertemplate: "Yaxis: %{y:.0f}<br><extra></extra>",
        visibilityState: true,
        filterEndpoint: [],
      },
    ],
    layout: {
      displayUnits: "A",
      sortBy: { code: "", nValue: 5, xais: "yaxis" },
      xaxis: {
        title: { text: "", font: { size: 12 } },
        showline: false,
        showgrid: true,
        showticklabels: true,
        ticktextposition: "inside",
      },
      yaxis: {
        title: { text: "", font: { size: 12 } },
        showline: false,
        zeroLine: false,
        showgrid: true,
        showticklabels: true,
        ticktextposition: "outside",
        tickfont: {
          size: 10,
        },
      },
      legend: {
        x: 1,
        y: 1,
        orientation: "h",
        xanchor: "right",
      },
      showlegend: true,
      margin: { t: 0, l: 80, r: 80, b: 80, pad: 10 },
      barmode: "group",
      hovermode: "closest",
      tickfont: {
        size: 10,
      },
    },
  },
];

const chartsOrginal = deepCopy(baseChartsConfig);

const colorsArray = [
  { name: "Red", rgb: "rgb(255, 0, 0)" },
  { name: "Green", rgb: "rgb(0, 255, 0)" },
  { name: "Blue", rgb: "rgb(0, 0, 255)" },
  { name: "Yellow", rgb: "rgb(255, 255, 0)" },
  { name: "Purple", rgb: "rgb(128, 0, 128)" },
  { name: "Orange", rgb: "rgb(255, 165, 0)" },
  { name: "Cyan", rgb: "rgb(0, 255, 255)" },
  { name: "Magenta", rgb: "rgb(255, 0, 255)" },
  { name: "Lime", rgb: "rgb(0, 255, 0)" },
  { name: "Pink", rgb: "rgb(255, 192, 203)" },
  { name: "Teal", rgb: "rgb(0, 128, 128)" },
  { name: "Brown", rgb: "rgb(165, 42, 42)" },
  { name: "Navy", rgb: "rgb(0, 0, 128)" },
  { name: "Maroon", rgb: "rgb(128, 0, 0)" },
  { name: "Olive", rgb: "rgb(128, 128, 0)" },
  { name: "Indigo", rgb: "rgb(75, 0, 130)" },
  { name: "Turquoise", rgb: "rgb(64, 224, 208)" },
  { name: "Gold", rgb: "rgb(255, 215, 0)" },
  { name: "Silver", rgb: "rgb(192, 192, 192)" },
  { name: "Gray", rgb: "rgb(128, 128, 128)" },
  { name: "Black", rgb: "rgb(0, 0, 0)" },
  { name: "White", rgb: "rgb(255, 255, 255)" },
  { name: "Violet", rgb: "rgb(238, 130, 238)" },
  { name: "Khaki", rgb: "rgb(240, 230, 140)" },
  { name: "Coral", rgb: "rgb(255, 127, 80)" },
];

const areaFillOptions = [
  { name: "None", fill: "none" },
  { name: "To Y", fill: "tozeroy" },
  { name: "To Next Y", fill: "tonexty" },
  { name: "To Y Next", fill: "tozeroyx" },
  { name: "To Next Y Next", fill: "tonextyx" },
];

const textPosition = [
  { type: "scatter", position: "top", value: "top" },
  { type: "scatter", position: "bottom", value: "bottom" },
  { type: "scatter", position: "middle", value: "middle" },
  { type: "scatter", position: "left", value: "left" },
  { type: "scatter", position: "right", value: "right" },
  { type: "scatter", position: "top left", value: "top left" },
  { type: "scatter", position: "top center", value: "top center" },
  { type: "scatter", position: "top right", value: "top right" },
  { type: "scatter", position: "middle left", value: "middle left" },
  { type: "scatter", position: "middle center", value: "middle center" },
  { type: "scatter", position: "middle right", value: "middle right" },
  { type: "scatter", position: "bottom left", value: "bottom left" },
  { type: "scatter", position: "bottom center", value: "bottom center" },
  { type: "scatter", position: "bottom right", value: "bottom right" },
  { type: "scatter", position: "auto", value: "auto" },
  { type: "scatter", position: "none", value: "" },
  { type: "bar", position: "inside", value: "inside" },
  { type: "bar", position: "outside", value: "outside" },
  { type: "bar", position: "auto", value: "auto" },
  { type: "bar", position: "none", value: "" },
];

const hoverPosition = [
  { title: "X", value: "x" },
  { title: "Y", value: "y" },
  { title: "Closest", value: "closest" },
  { title: "X Unified", value: "x unified" },
  { title: "Y Unified", value: "y unified" },
  { title: "None", value: false },
];

const lineShape = [
  { title: "Horizontal-Verticle", value: "hv" },
  { title: "Verticle-Horizontal", value: "vh" },
  { title: "Horizontal-Verticle-Horizontal", value: "hvh" },
  { title: "Verticle-Horizontal-Verticle", value: "vhv" },
  { title: "Spline", value: "spline" },
  { title: "Linear", value: "linear" },
];

const linetype = [
  { title: "Solid", value: "solid" },
  { title: "Dot", value: "dot" },
  { title: "DashDot", value: "dashdot" },
];

const displayUnits = [
  { title: "Auto", value: "A" },
  { title: "Thousand", value: "T" },
  { title: "Millions", value: "M" },
  { title: "Billions", value: "B" },
  { title: "Rounded", value: "R" },
  { title: "None", value: "none" },
];

const legendPosition = [
  { title: "None", value: 0 },
  { title: "Right Top", value: 1 },
  { title: "Left Top", value: 2 },
  { title: "Right Bottom", value: 3 },
  { title: "Left Bottom", value: 4 },
];

const markerTypes = [
  { name: "circle", value: "0" },
  { name: "circle-open", value: "100" },
  { name: "circle-dot", value: "200" },
  { name: "circle-open-dot", value: "300" },
  { name: "square", value: "1" },
  { name: "square-open", value: "101" },
  { name: "square-dot", value: "201" },
  { name: "square-open-dot", value: "301" },
  { name: "diamond", value: "2" },
  { name: "diamond-open", value: "102" },
  { name: "diamond-dot", value: "202" },
  { name: "diamond-open-dot", value: "302" },
  { name: "cross", value: "3" },
  { name: "cross-open", value: "103" },
  { name: "cross-dot", value: "203" },
  { name: "cross-open-dot", value: "303" },
  { name: "x", value: "4" },
  { name: "x-open", value: "104" },
  { name: "x-dot", value: "204" },
  { name: "x-open-dot", value: "304" },
  { name: "triangle-up", value: "5" },
  { name: "triangle-up-open", value: "105" },
  { name: "triangle-up-dot", value: "205" },
  { name: "triangle-up-open-dot", value: "305" },
  { name: "triangle-down", value: "6" },
  { name: "triangle-down-open", value: "106" },
  { name: "triangle-down-dot", value: "206" },
  { name: "triangle-down-open-dot", value: "306" },
  { name: "triangle-left", value: "7" },
  { name: "triangle-left-open", value: "107" },
  { name: "triangle-left-dot", value: "207" },
  { name: "triangle-left-open-dot", value: "307" },
  { name: "triangle-right", value: "8" },
  { name: "triangle-right-open", value: "108" },
  { name: "triangle-right-dot", value: "208" },
  { name: "triangle-right-open-dot", value: "308" },
  { name: "triangle-ne", value: "9" },
  { name: "triangle-ne-open", value: "109" },
  { name: "triangle-ne-dot", value: "209" },
  { name: "triangle-ne-open-dot", value: "309" },
  { name: "triangle-se", value: "10" },
  { name: "triangle-se-open", value: "110" },
  { name: "triangle-se-dot", value: "210" },
  { name: "triangle-se-open-dot", value: "310" },
  { name: "triangle-sw", value: "11" },
  { name: "triangle-sw-open", value: "111" },
  { name: "triangle-sw-dot", value: "211" },
  { name: "triangle-sw-open-dot", value: "311" },
  { name: "triangle-nw", value: "12" },
  { name: "triangle-nw-open", value: "112" },
  { name: "triangle-nw-dot", value: "212" },
  { name: "triangle-nw-open-dot", value: "312" },
  { name: "pentagon", value: "13" },
  { name: "pentagon-open", value: "113" },
  { name: "pentagon-dot", value: "213" },
  { name: "pentagon-open-dot", value: "313" },
  { name: "hexagon", value: "14" },
  { name: "hexagon-open", value: "114" },
  { name: "hexagon-dot", value: "214" },
  { name: "hexagon-open-dot", value: "314" },
  { name: "hexagon2", value: "15" },
  { name: "hexagon2-open", value: "115" },
  { name: "hexagon2-dot", value: "215" },
  { name: "hexagon2-open-dot", value: "315" },
  { name: "octagon", value: "16" },
  { name: "octagon-open", value: "116" },
  { name: "octagon-dot", value: "216" },
  { name: "octagon-open-dot", value: "316" },
  { name: "star", value: "17" },
  { name: "star-open", value: "117" },
  { name: "star-dot", value: "217" },
  { name: "star-open-dot", value: "317" },
  { name: "hexagram", value: "18" },
  { name: "hexagram-open", value: "118" },
  { name: "hexagram-dot", value: "218" },
  { name: "hexagram-open-dot", value: "318" },
  { name: "star-triangle-up", value: "19" },
  { name: "star-triangle-up-open", value: "119" },
  { name: "star-triangle-up-dot", value: "219" },
  { name: "star-triangle-up-open-dot", value: "319" },
  { name: "star-triangle-down", value: "20" },
  { name: "star-triangle-down-open", value: "120" },
  { name: "star-triangle-down-dot", value: "220" },
  { name: "star-triangle-down-open-dot", value: "320" },
  { name: "star-square", value: "21" },
  { name: "star-square-open", value: "121" },
  { name: "star-square-dot", value: "221" },
  { name: "star-square-open-dot", value: "321" },
  { name: "star-diamond", value: "22" },
  { name: "star-diamond-open", value: "122" },
  { name: "star-diamond-dot", value: "222" },
  { name: "star-diamond-open-dot", value: "322" },
  { name: "diamond-tall", value: "23" },
  { name: "diamond-tall-open", value: "123" },
  { name: "diamond-tall-dot", value: "223" },
  { name: "diamond-tall-open-dot", value: "323" },
  { name: "diamond-wide", value: "24" },
  { name: "diamond-wide-open", value: "124" },
  { name: "diamond-wide-dot", value: "224" },
  { name: "diamond-wide-open-dot", value: "324" },
  { name: "hourglass", value: "25" },
  { name: "hourglass-open", value: "125" },
  { name: "bowtie", value: "26" },
  { name: "bowtie-open", value: "126" },
  { name: "circle-cross", value: "27" },
  { name: "circle-cross-open", value: "127" },
  { name: "circle-x", value: "28" },
  { name: "circle-x-open", value: "128" },
  { name: "square-cross", value: "29" },
  { name: "square-cross-open", value: "129" },
  { name: "square-x", value: "30" },
  { name: "square-x-open", value: "130" },
  { name: "diamond-cross", value: "31" },
  { name: "diamond-cross-open", value: "131" },
  { name: "diamond-x", value: "32" },
  { name: "diamond-x-open", value: "132" },
  { name: "cross-thin", value: "33" },
  { name: "cross-thin-open", value: "133" },
  { name: "x-thin", value: "34" },
  { name: "x-thin-open", value: "134" },
  { name: "asterisk", value: "35" },
  { name: "asterisk-open", value: "135" },
  { name: "hash", value: "36" },
  { name: "hash-open", value: "136" },
  { name: "hash-dot", value: "236" },
  { name: "hash-open-dot", value: "336" },
  { name: "y-up", value: "37" },
  { name: "y-up-open", value: "137" },
  { name: "y-down", value: "38" },
  { name: "y-down-open", value: "138" },
  { name: "y-left", value: "39" },
  { name: "y-left-open", value: "139" },
  { name: "y-right", value: "40" },
  { name: "y-right-open", value: "140" },
  { name: "line-ew", value: "41" },
  { name: "line-ew-open", value: "141" },
  { name: "line-ns", value: "42" },
  { name: "line-ns-open", value: "142" },
  { name: "line-ne", value: "43" },
  { name: "line-ne-open", value: "143" },
  { name: "line-nw", value: "44" },
  { name: "line-nw-open", value: "144" },
  { name: "arrow-up", value: "45" },
  { name: "arrow-up-open", value: "145" },
  { name: "arrow-down", value: "46" },
  { name: "arrow-down-open", value: "146" },
  { name: "arrow-left", value: "47" },
  { name: "arrow-left-open", value: "147" },
  { name: "arrow-right", value: "48" },
  { name: "arrow-right-open", value: "148" },
  { name: "arrow-bar-up", value: "49" },
  { name: "arrow-bar-up-open", value: "149" },
  { name: "arrow-bar-down", value: "50" },
  { name: "arrow-bar-down-open", value: "150" },
  { name: "arrow-bar-left", value: "51" },
  { name: "arrow-bar-left-open", value: "151" },
  { name: "arrow-bar-right", value: "52" },
  { name: "arrow-bar-right-open", value: "152" },
  { name: "arrow", value: "53" },
  { name: "arrow-open", value: "153" },
  { name: "arrow-wide", value: "54" },
  { name: "arrow-wide-open", value: "154" },
];

const textSize = [
  {
    name: "Ultra Small",
    value: 4,
  },
  {
    name: "Super Small",
    value: 6,
  },
  {
    name: "Extra Small",
    value: 8,
  },
  {
    name: "Small",
    value: 10,
  },
  {
    name: "Medium",
    value: 14,
  },
  {
    name: "Large",
    value: 16,
  },
  {
    name: "Extra Large",
    value: 20,
  },
  {
    name: "XX-Large",
    value: 24,
  },
];

const lineWidth = [
  { name: "Ultra Thin", value: 0.5 },
  { name: "Thin", value: 1 },
  { name: "Normal", value: 2 },
  { name: "SemiBold", value: 3 },
  { name: "Bold", value: 4 },
];

const marginDirection = [
  { name: "Left", value: "l" },
  { name: "Right", value: "r" },
  { name: "Top", value: "t" },
  { name: "Bottom", value: "b" },
  { name: "Padding", value: "pad" },
];

function deepCopy(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj; // Return primitive types and null as is
  }
  if (Array.isArray(obj)) {
    return obj.map(deepCopy);
  }
  const newObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key] = deepCopy(obj[key]);
    }
  }

  return newObj;
}

export {
  config,
  baseChartsConfig,
  chartsOrginal,
  colorsArray,
  areaFillOptions,
  textPosition,
  legendPosition,
  lineShape,
  linetype,
  hoverPosition,
  displayUnits,
  markerTypes,
  textSize,
  lineWidth,
  marginDirection,
};
