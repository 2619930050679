const navigation = [
  {
    tab_id: "1",
    name: "Add Company",
    logo: "bi-link-45deg ",
  },
  {
    tab_id: "2",
    name: "Companies",
    logo: "bi-building ",
    subTab: [
      {
        id: "1",
        name: "Dashboard",
        logo: "bi-clipboard2-data ",
      },

      {
        id: "2",
        name: "KPIs",
        logo: "bi-speedometer2 ",
      },

      {
        id: "4",
        name: "Reports",
        logo: "bi-file-earmark-spreadsheet",
      },
      {
        id: "3",
        name: "Analysis",
        logo: "bi-bar-chart ",
      },
      {
        id: "5",
        name: "Charts",
        logo: "bi-graph-up ",
      },
      {
        id: "6",
        name: "Data",
        logo: "bi-database",
      },
    ],
  },

  {
    tab_id: "3",
    name: "Settings",
    logo: "bi-gear",
    subTab: [
      {
        id: "1",
        name: "Data Permission",
        logo: "bi-database-fill-gear ",
      },
      {
        id: "2",
        name: "Companies",
        logo: "bi-building-fill-gear ",
      },
      {
        id: "3",
        name: "User",
        logo: "bi-person-circle",
      },
    ],
  },
  {
    tab_id: "4",
    name: "Home",
    logo: "bi-house",
  },
];

export default navigation;
