import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import ReportTable from "./ReportTable";
import Modal from "../../Common/Modal";

import { useDashData } from "../../../hooks/useDash";
import DisplayBottomIcon from "../DisplayBottomIcon";

const DisplayReport = ({
  index,
  fullScreenStatus,
  handleVisualizationTab,
  handleUpdateVisualizationIndex,
}) => {
  const {
    profile,
    adminAccountInfo,
    guestAccountInfo,
    account,
    calenderData,
    calenderDatesData,
    isModalOpen,
    openModal,
    closeModal,
    handleDeleteVisulization,
    handleDownloadAsImage,
    handleCopyData,
  } = useDashData();

  const { user } = useAuth0();
  const [data, setData] = useState({});
  const [selectedAccount, setSelectedAccount] = useState({});
  const [selectedSelectedReportId, setSelectedReportId] = useState("");
  const [selectedReportEndpoints, setSelectedReportEndpoints] = useState([]);

  const [selectedColumnFilter, setSelectedColumnFilter] = useState([]);
  const [selectedRowFilter, setSelectedRowFilter] = useState({});
  const [localCalendarDate, setLocalCalendarDates] = useState({
    ...calenderData,
  });

  const [dates, setDate] = useState({});
  const [title, setTitle] = useState("");
  const [selectedReport, setSelectedReport] = useState({
    record_type: "",
    record_formate: "",
    category: "",
  });
  const [bottomSettings, setBottomSettings] = useState({
    prior: false,
    ytd: false,
    month: false,
  });
  const [password, setPassword] = useState({ value: "", errorMessage: "" });
  const [btn, setBtnState] = useState(false);

  const handleDates = (selectedAccount, calenderData) => {
    let yearIndex = calenderData.year_index;
    const monthIndex = calenderData.month_index;
    const selectedYear = calenderDatesData.find(
      (d) => d.year_index === yearIndex
    );

    const currentDate = {
      ...selectedYear.months.find((d) => d.month_index === monthIndex),
      year: yearIndex,
    };

    const yearIndexPr = monthIndex === 0 ? yearIndex - 1 : yearIndex;
    const monthIndexPr = monthIndex === 0 ? 11 : monthIndex - 1;

    const selectedYearPr = calenderDatesData.find(
      (d) => d.year_index === yearIndexPr
    );
    const lastMonthDate = {
      ...selectedYearPr.months.find((d) => d.month_index === monthIndexPr),
      year: yearIndexPr,
    };
    const FY = calenderDatesData[0].months.find(
      (d) =>
        d.month.toLowerCase() ===
        selectedAccount.fiscal_year_start_month.slice(0, 3).toLowerCase()
    );
    return { currentDate, lastMonthDate, FY };
  };

  const handleEditReport = () => {
    handleUpdateVisualizationIndex(index);
    handleVisualizationTab(100);
  };

  const handlePasswordInputChange = (event) => {
    const value = event.target.value;
    setPassword({ errorMessage: "", value: value });
  };

  const handleDeleteReport = async () => {
    setBtnState(true);
    if (password.value !== user.email) {
      setPassword({ errorMessage: "Invalid email", value: "" });
      return;
    } else {
      let deletedDataInfo = {
        userId: profile.user_id,
        accountId: selectedAccount.uid,
        reportId: selectedSelectedReportId,
        visualizationType: "Report",
      };
      handleDeleteVisulization(deletedDataInfo)
        .then((response) => {
          if (response) {
            setBtnState(false);
            setPassword({
              errorMessage: "",
              value: "",
            });
            handleVisualizationTab(0);
            setSelectedReport({
              record_type: "",
              category: "",
            });
            setSelectedReportEndpoints([]);
            setBottomSettings({
              prior: false,
              ytd: false,
              month: false,
            });
            setSelectedReportId("");
            setTitle("");
            closeModal();
          }
        })
        .catch((error) => {
          setBtnState(false);
        });
    }
  };

  const handleCalendarLocal = (monthIndex, yearIndex) => {
    setLocalCalendarDates((prev) => {
      let temYearIndex = calenderDatesData.findIndex(
        (y) => y.year_index === yearIndex
      );

      let tempMonth = calenderDatesData[temYearIndex].months.find(
        (m) => m.month_index === parseInt(monthIndex)
      );
      let tempData = { ...prev };

      tempData.month_index = parseInt(monthIndex);
      tempData.month = tempMonth.month;
      tempData.year = calenderDatesData[temYearIndex].year;
      tempData.year_index = calenderDatesData[temYearIndex].year_index;
      tempData.status = tempMonth.status;
      tempData.fiscal_month_index = calenderData.fiscal_month_index;
      return tempData;
    });
  };
  const handleDuplicate = () => {
    let duplicate_data = {
      type: "report",
      id: data.id,
      accountId: selectedAccount.uid,
      userId: selectedAccount.owner_id,
    };
    handleCopyData(duplicate_data).then((response) => {
      if (response.status) {
        handleUpdateVisualizationIndex(selectedAccount.report_id.length);
        handleVisualizationTab(100);
      }
    });
  };

  const targetRef = useRef();
  const handleDownloadPdf = () => {
    handleDownloadAsImage(targetRef, data);
  };

  useEffect(() => {
    let currentMonthIndex = calenderData.month_index;
    let currentYearIndex = calenderData.year_index;
    handleCalendarLocal(currentMonthIndex, currentYearIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calenderData]);

  useEffect(() => {
    let CombinedChartData = adminAccountInfo.concat(guestAccountInfo);
    const accountIndex = CombinedChartData.findIndex(
      (data) => data.uid === account
    );
    let accountData = CombinedChartData[accountIndex];
    setSelectedAccount(accountData);
    const selectedAccountData = JSON.parse(
      JSON.stringify(CombinedChartData[accountIndex])
    );
    let newSelectedAccounts = selectedAccountData.report_id[index];

    if (
      newSelectedAccounts &&
      newSelectedAccounts.settings &&
      newSelectedAccounts.settings.report_type
    ) {
      setData(newSelectedAccounts);
      setSelectedReport(newSelectedAccounts.settings.report_type);
      setSelectedReportEndpoints(newSelectedAccounts.settings.report_endpoints);
      setBottomSettings(newSelectedAccounts.settings.bottom_settings);
      setSelectedReportId(newSelectedAccounts.id);
      setTitle(newSelectedAccounts.name);

      setSelectedRowFilter(newSelectedAccounts.settings.filter.row);
      setSelectedColumnFilter(newSelectedAccounts.settings.filter.column);

      setDate(() => {
        let { currentDate, lastMonthDate, FY } = handleDates(
          selectedAccountData,
          localCalendarDate
        );
        return { currentDate, lastMonthDate, FY };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAccountInfo, account, index, localCalendarDate]);

  return (
    selectedReportEndpoints.length > 0 && (
      <>
        <div
          className={
            fullScreenStatus
              ? "d-flex flex-row justify-content-between  bg-light piik-visual-section-fullscreen piik-create-chart "
              : "d-flex flex-row justify-content-between  bg-light piik-visual-section piik-create-chart "
          }
        >
          <div className="flex-grow-1 overflow-y-scroll">
            <div className="d-flex flex-column  h-100 w-100 ">
              <div className="flex-grow-1 w-100  h-100 d-flex flex-column">
                {selectedReportEndpoints.length > 0 && (
                  <ReportTable
                    baseData={selectedAccount.data.filter(
                      (d) => d.category === selectedReport.category
                    )}
                    data={selectedReportEndpoints}
                    selectedAccount={selectedAccount}
                    bottomSettings={bottomSettings}
                    divRef={targetRef}
                    id="container-pdf"
                    reportType={selectedReport}
                    rowFilter={selectedRowFilter}
                    columnFilter={selectedColumnFilter}
                    dates={dates}
                    calenderData={localCalendarDate}
                    calenderDatesData={calenderDatesData}
                    handleCalendarLocal={handleCalendarLocal}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-row justify-content-between  bg-light border-top align-self-center"
          style={{ zIndex: 2 }}
        >
          <div className="py-1 piik-text-dashboard text-primary  px-4 fw-bold">
            Period : {localCalendarDate.month}-{localCalendarDate.year_index}
          </div>
          <div className="d-flex flex-row justify-content-end">
            <DisplayBottomIcon
              title="Download Pdf"
              icon="bi-download"
              handleIconAction={handleDownloadPdf}
            />

            {profile.user_id === selectedAccount.owner_id ? (
              <>
                <DisplayBottomIcon
                  title="Copy Chart"
                  icon="bi-clipboard-plus"
                  handleIconAction={handleDuplicate}
                />
                <DisplayBottomIcon
                  title="Edit Report"
                  icon="bi-pencil-square"
                  handleIconAction={handleEditReport}
                />

                <DisplayBottomIcon
                  title="Delete Report"
                  icon="bi-trash"
                  handleIconAction={openModal}
                />
              </>
            ) : null}
            <DisplayBottomIcon
              title="Report Description"
              icon="bi-info-circle"
              status={false}
            />
          </div>
          <Modal isOpen={isModalOpen} closeModal={closeModal} title={title}>
            {isModalOpen && selectedAccount ? (
              <div className="row mx-0 justify-content-between px-4 pt-2 pb-4 ">
                <div className="col-12 piik-text-warning px-2 pt-2 pb-4">
                  This will permanently delete the Report from the
                  <span className="text-danger fw-semibold px-1">
                    {user.email}
                  </span>{" "}
                  account.
                </div>
                <div className="col px-2 ">
                  <input
                    type="email"
                    className="form-control form-control-sm"
                    id="password"
                    placeholder="Please enter the email
                  to confirm"
                    value={password.value}
                    onChange={handlePasswordInputChange}
                  />
                  {password.errorMessage !== "" && (
                    <label className="piik-text-warning text-danger text-capitalize py-1">
                      {password.errorMessage}
                    </label>
                  )}
                </div>

                <div className="col-2 px-0">
                  <button
                    className="btn btn-sm btn-outline-primary w-100 px-2"
                    onClick={handleDeleteReport}
                    disabled={btn}
                  >
                    {btn ? (
                      <span className="piik-text-icon text-warning">
                        Processing
                      </span>
                    ) : (
                      "Delete"
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mx-0 justify-content-between px-4 pt-2 pb-4 ">
                <div className="col-12 piik-text-dashboard px-2 pt-2 pb-4">
                  Sorry you are not allowed to delete all the Report. Add a new
                  report first to clear the selected report
                </div>
              </div>
            )}
          </Modal>
        </div>
      </>
    )
  );
};

export default DisplayReport;
