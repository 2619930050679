import React, { useEffect, useState } from "react";

import EndpointTable from "../Endpoints/EndpointTable";
import Modal from "../../Common/Modal";
import KpiPlot from "./KpiPlot";

import { useAuth0 } from "@auth0/auth0-react";
import { useDashData } from "../../../hooks/useDash";

import {
  isValidFormula,
  handleFormulaValidation,
  FormulaDocumenation,
  checkDuplicateId,
  handleAddRemove,
  handleTableHeader,
  endpointValidation,
} from "../CustomDataUtility";

const CreateKpi = ({
  fullScreenStatus,
  updateVisualizationIndex,
  handleUpdateVisualizationIndex,
  handleVisualizationTab,
}) => {
  const {
    profile,
    calenderData,
    calenderDatesData,
    adminAccountInfo,
    account,
    isModalOpen,
    openModal,
    closeModal,
    handleAddVisualization,
    handleUpdateVisualization,
    handleDeleteVisulization,
  } = useDashData();
  const { user } = useAuth0();

  const [endpointModal, setEndpointModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [selectedKpiAccount, setSelectedKpiAccount] = useState({});

  const [title, setTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const [action, setAction] = useState("");
  const [group, setGroup] = useState([]);

  const [newGroup, setNewGroup] = useState("");
  const [newGroupErrorMessage, setNewGroupErrorMessage] = useState("");
  const [newGroupStatus, setNewGroupStatus] = useState(false);

  const [formula, setFormula] = useState("");
  const [errorFormulaMessage, setErrorFormulaMessage] = useState("");

  const [customEndpoint, setCustomEndpoint] = useState([
    { name: "EP_1", endpointId: "" },
  ]);
  const [trendChartIndex, setTrendChartIndex] = useState("1");

  const [category, setCategory] = useState([]);
  const [submitStatus, setSubmitStatus] = useState(false);

  const [password, setPassword] = useState({ value: "", errorMessage: "" });
  const [selectedKpiEndpoint, setSelectedKpiEndpoint] = useState(null);
  const [btn, setBtnState] = useState(false);

  const [localCalendarDate, setLocalCalendarDates] = useState({
    ...calenderData,
  });

  const handleCalendarLocal = (monthIndex, yearIndex) => {
    setLocalCalendarDates((prev) => {
      let temYearIndex = calenderDatesData.findIndex(
        (y) => y.year_index === yearIndex
      );
      let tempMonth = calenderDatesData[temYearIndex].months.find(
        (m) => m.month_index === parseInt(monthIndex)
      );
      let tempData = { ...prev };
      tempData.month_index = parseInt(monthIndex);
      tempData.month = tempMonth.month;
      tempData.year = calenderDatesData[temYearIndex].year;
      tempData.year_index = calenderDatesData[temYearIndex].year_index;
      tempData.status = tempMonth.status;
      return tempData;
    });
  };

  const handlePasswordInputChange = (event) => {
    const value = event.target.value;
    setPassword({ errorMessage: "", value: value });
  };

  const handleModal = (selectedKpiEndpoint) => {
    setSelectedKpiEndpoint(selectedKpiEndpoint);
    openModal();
  };

  const handleDeleteKpiEndpoint = async () => {
    if (password.value !== user.email) {
      setPassword({ errorMessage: "Invalid email", value: "" });
      return;
    } else {
      let deletedDataInfo = {
        userId: profile.user_id,
        accountId: selectedAccount.uid,
        kpiId: selectedKpiEndpoint.id,
        visualizationType: "KpiEndpoint",
      };
      setBtnState(true);
      handleDeleteVisulization(deletedDataInfo)
        .then((response) => {
          if (response) {
            setBtnState(false);
            setPassword({
              errorMessage: "",
              value: "",
            });
            setSelectedKpiEndpoint(null);
            closeModal();
          }
        })
        .catch((error) => {
          setBtnState(false);
        });
    }
  };

  const handleEditKpiEndpoint = (data) => {
    setSelectedType("custom");
    setAction("edit");
    setFormula(data.formula);
    setNewGroup(data.group);
    setCustomEndpoint(data.endpoints);
    setTitle(data.name);
    setSelectedKpiEndpoint(data);
  };

  const handleUpdateKpiEndpoint = () => {
    if (title === "") {
      setErrorMessage("Field Data is Missing");
      return;
    }
    if (newGroup === "") {
      setNewGroupErrorMessage("Field Data is Missing");
      return;
    }
    if (action === "create" && !isValidFormula(formula)) {
      setErrorFormulaMessage("Incorrect Formula.Please Check");
      return;
    }
    if (action === "create" && endpointValidation(customEndpoint)) {
      setErrorFormulaMessage("Please select all the endpoint");
      return;
    }
    if (action === "create" && checkDuplicateId(customEndpoint)) {
      setErrorFormulaMessage("Please remove the Duplicate Endpoint");
    }
    setSubmitStatus(true);
    let accountIndex = adminAccountInfo.findIndex(
      (data) => data.uid === account
    );
    let selectedAccountData = adminAccountInfo[accountIndex];
    let requestData = {
      userId: profile.user_id,
      accountId: selectedAccountData.uid,
      KpiId: selectedKpiEndpoint.id,
      kpiSettings: {
        data_endpoints: customEndpoint,
        formula: formula,
        group: newGroup,
        trendChartIndex: trendChartIndex,
      },
      kpiName: title,
      createdBy: profile.user_email,
      visualizationType: "KpiEndpoint",
    };
    handleUpdateVisualization(requestData)
      .then((response) => {
        setSubmitStatus(false);
        setAction("");
        setSelectedType("default");
        setFormula("");
        setNewGroup("");
        setTrendChartIndex("1");
        setNewGroupStatus(false);
        setCustomEndpoint([{ name: "EP_1", endpointId: "" }]);
        setTitle("");
        setSelectedKpiEndpoint(null);
      })
      .catch((error) => {
        setSubmitStatus(false);
      });
  };

  const handleAddKpiEndpoint = () => {
    if (title === "") {
      setErrorMessage("Field Data is Missing");
      return;
    }
    if (newGroup === "") {
      setNewGroupErrorMessage("Field Data is Missing");
      return;
    }
    if (selectedType === "custom" && !isValidFormula(formula)) {
      setErrorFormulaMessage("Incorrect Formula.Please Check");
      return;
    }
    if (selectedType === "custom" && endpointValidation(customEndpoint)) {
      setErrorFormulaMessage("Please select all the endpoint");
      return;
    }
    if (selectedType === "custom" && checkDuplicateId(customEndpoint)) {
      setErrorFormulaMessage("Please remove the Duplicate Endpoint");
    }
    setSubmitStatus(true);
    let accountIndex = adminAccountInfo.findIndex(
      (data) => data.uid === account
    );
    let selectedAccountData = adminAccountInfo[accountIndex];
    let requestData = {
      userId: profile.user_id,
      accountId: selectedAccountData.uid,
      kpiSettings: {
        data_endpoints: customEndpoint,
        formula: formula,
        group: newGroup,
        trendChartIndex: trendChartIndex,
      },
      kpiName: title,
      createdBy: profile.user_email,
      visualizationType: "KpiEndpoint",
    };
    handleAddVisualization(requestData)
      .then((response) => {
        if (response) {
          handleClear("all");
        }
        setSubmitStatus(false);
        setNewGroup("");
        setNewGroupStatus(false);
        setAction("");
        setSelectedType("default");
        setTrendChartIndex("1");
        setFormula("");
        setCustomEndpoint([{ name: "EP_1", endpointId: "" }]);
        setTitle("");
      })
      .catch((error) => {
        setSubmitStatus(false);
      });
  };

  const handleUpdateKpiDashboard = () => {
    if (title === "") {
      setErrorMessage("Field Data is Missing");
      return;
    }

    setSubmitStatus(true);
    const updatedCategory = category.map((obj) => {
      const newObj = { ...obj };
      delete newObj["data"];
      return newObj;
    });

    let requestData = {
      userId: profile.user_id,
      accountId: selectedAccount.uid,
      KpiId: selectedKpiAccount.id,
      kpiSettings: updatedCategory,
      kpiName: title,
      createdBy: profile.user_email,
      visualizationType: "Kpi",
    };

    handleUpdateVisualization(requestData)
      .then((response) => {
        if (response) {
          handleClear("all");
        }
        setSubmitStatus(false);
        handleVisualizationTab(updateVisualizationIndex);
        handleUpdateVisualizationIndex(null);
      })
      .catch((error) => {
        setSubmitStatus(false);
      });
  };

  const handleSubmit = () => {
    if (title === "") {
      setErrorMessage("Field Data is Missing");
      return;
    }
    setSubmitStatus(true);
    const updatedCategory = category.map((obj) => {
      const newObj = { ...obj };
      delete newObj["data"];
      return newObj;
    });

    let requestData = {
      userId: profile.user_id,
      accountId: selectedAccount.uid,
      kpiSettings: updatedCategory,
      kpiName: title,
      createdBy: profile.user_email,
      visualizationType: "Kpi",
    };
    handleAddVisualization(requestData)
      .then((response) => {
        if (response) {
          handleClear("all");
        }
        setSubmitStatus(false);
        handleUpdateVisualizationIndex(null);
      })
      .catch((error) => {
        setSubmitStatus(false);
      });
  };

  const handleInput = (e) => {
    const { id, value } = e.target;
    if (id === "title") {
      if (title.length > 25) {
        const truncatedValue = value.slice(0, 25);
        setTitle(truncatedValue);
        setErrorMessage("Maximum character limit reached");
      } else {
        setTitle(value);
        setErrorMessage("");
      }
    }
    if (id === "type") {
      setSelectedType(value);
      setGroup(() => {
        const groups = [
          ...new Set(selectedAccount.kpi_endpoints.map((obj) => obj.group)),
        ].map((gr) =>
          selectedAccount.kpi_endpoints.find((obj) => obj.group === gr)
        );
        return groups;
      });
    } else if (id === "trendChartIndex") {
      setTrendChartIndex(value);
    } else if (id === "assignGroup" && value === "new") {
      setNewGroupStatus(true);
      setNewGroupErrorMessage("");
      setNewGroup("");
    } else if (id === "assignGroup" && value !== "new") {
      setNewGroupStatus(false);
      setNewGroupErrorMessage("");
      setNewGroup(value);
    } else if (id === "newGroup") {
      if (newGroup.length > 25) {
        const truncatedValue = value.slice(0, 25);
        setNewGroup(truncatedValue);
        setNewGroupErrorMessage("Maximum character limit reached");
      } else {
        setNewGroup(value);
        setNewGroupErrorMessage("");
      }
      if (newGroup.length <= 25) {
        setNewGroup(value);
      }
    } else if (id === "formula") {
      let result = handleFormulaValidation(value);
      setErrorFormulaMessage("");
      if (result !== "NA") {
        setFormula(result);
      }
    } else if (id.indexOf("EP_") !== -1) {
      setCustomEndpoint((prev) => {
        const prevData = [...prev];
        const newData = [...prevData].map((obj) => {
          if (obj.name === id) {
            const newObj = { ...obj, endpointId: value };
            return newObj;
          }
          return obj;
        });
        return newData;
      });
    } else {
      if (e.target.checked) {
        let selectedCategory = selectedAccount.kpi_endpoints.find(
          (k) => k.id === id
        );
        if (selectedCategory !== undefined) {
          setCategory([...category, selectedCategory]);
        }
      } else {
        let updateCategory = category.filter((c) => c.id !== id);
        setCategory(updateCategory);
      }
    }
  };

  const handleClear = (id) => {
    if (id === "all") {
      setCategory([]);
      setTitle("");
    } else {
      let selectedSubCatagory = category.filter(
        (sub) => sub.categoryId !== parseInt(id)
      );
      setCategory(selectedSubCatagory);
    }
  };

  const handleGenerateKPI = (category, selectedAccountData) => {
    setGroup(() => {
      const groups = [
        ...new Set(selectedAccountData.kpi_endpoints.map((obj) => obj.group)),
      ].map((gr) =>
        selectedAccountData.kpi_endpoints.find((obj) => obj.group === gr)
      );
      return groups;
    });
    setCategory(() => {
      const updateData = [...category];
      const newData = updateData.map((kpi) => {
        let result = selectedAccountData.kpi_endpoints.find(
          (d) => d.id === kpi.id
        );
        return result;
      });
      return newData;
    });
  };

  useEffect(() => {
    let currentMonthIndex = calenderData.month_index;
    let currentYearIndex = calenderData.year_index;
    handleCalendarLocal(currentMonthIndex, currentYearIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calenderData]);

  useEffect(() => {
    const accountIndex = adminAccountInfo.findIndex(
      (data) => data.uid === account
    );
    const selectedAccountData = JSON.parse(
      JSON.stringify(adminAccountInfo[accountIndex])
    );
    setSelectedAccount(selectedAccountData);
    if (updateVisualizationIndex !== null) {
      let selectedKpiAccount =
        selectedAccountData.kpi_id[updateVisualizationIndex];
      if (selectedKpiAccount && selectedKpiAccount.settings) {
        setSelectedKpiAccount(selectedKpiAccount);
        handleGenerateKPI(selectedKpiAccount.settings, selectedAccountData);
        setTitle(selectedKpiAccount.name);
      } else {
        handleUpdateVisualizationIndex(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAccountInfo, account, localCalendarDate]);
  return (
    <>
      <div
        className={
          fullScreenStatus
            ? "d-flex flex-row justify-content-between  bg-light piik-visual-section-fullscreen piik-create-chart "
            : "d-flex flex-row justify-content-between  bg-light piik-visual-section piik-create-chart "
        }
      >
        <div className="flex-grow-1 overflow-y-scroll">
          <div className="d-flex flex-column  h-100 w-100 ">
            {selectedType === "custom" ? (
              <EndpointTable
                action="new"
                selectedAccount={selectedAccount}
                customEndpoint={customEndpoint}
                calenderDatesData={calenderDatesData}
                calenderData={localCalendarDate}
                handleCalendarLocal={handleCalendarLocal}
                formula={formula}
              />
            ) : selectedType === "default" ? (
              <div className="flex-grow-1 w-100  h-100 d-flex flex-column ">
                <div className="d-flex flex-row justify-content-between  align-items-center bg-white px-4 py-2 shadow-sm mb-2">
                  <div className="piik-text-dashboard  text-primary">
                    {title}
                  </div>
                  <i className="bi bi-info-circle text-primary"></i>
                </div>
                <KpiPlot
                  category={category}
                  account={selectedAccount}
                  calenderDatesData={calenderDatesData}
                  calenderData={localCalendarDate}
                  handleCalendarLocal={handleCalendarLocal}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div
          className="  bg-light shadow-sm d-flex flex-column justify-content-start w-25  piik-create-bar"
          style={{ zIndex: 10 }}
        >
          <div className=" px-3 py-2 piik-text-dashboard  ">
            <div className=" d-flex flex-row justify-content-between align-items-center ">
              <div className="fw-semibold text-primary"> Set Title</div>
              <i
                className="bi bi-eraser piik-pointer"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Clear all"
                id="all"
                onClick={() => handleClear("all")}
              ></i>
            </div>
            <div className="my-2">
              <input
                type="text"
                className="form-control form-control-sm w-100"
                id="title"
                placeholder="Max 25 Character"
                value={title}
                onChange={handleInput}
              />
              {errorMessage !== "" && (
                <div className="pt-1 text-danger piik-text-warning">
                  {errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className=" px-3 py-2 piik-text-dashboard border-top ">
            <div className=" d-flex flex-row justify-content-between align-items-center ">
              <div className="fw-semibold text-primary"> Select Type</div>
              <i
                className="bi bi-eraser piik-pointer"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Clear all"
                id="all"
                onClick={() => handleClear("all")}
              ></i>
            </div>
            <div className="my-2">
              <select
                className="form-select form-select-sm"
                id="type"
                value={selectedType}
                onChange={handleInput}
              >
                <option selected>Select</option>
                <option value="default">Show KPI</option>
                <option value="custom">Create Custom KPI</option>
              </select>
            </div>
          </div>

          <div className=" piik-text-visual overflow-y-scroll">
            {selectedType === "default" &&
              group.length > 0 &&
              group.map((endpoint) => {
                return (
                  <>
                    <div className=" fw-semibold px-3 py-2 text-uppercase  shadow-sm border-top">
                      {endpoint.group}
                    </div>
                    {selectedType === "default" &&
                      selectedAccount &&
                      selectedAccount.hasOwnProperty("kpi_endpoints") &&
                      selectedAccount.kpi_endpoints.length > 0 &&
                      selectedAccount.kpi_endpoints
                        .filter((d) => d.group === endpoint.group)
                        .map((d, i) => {
                          return (
                            <div className=" px-3 pt-2 piik-text-dashboard d-flex flex-row justify-content-between w-10 ">
                              <div className="form-check form-switch mb-2">
                                <label
                                  className="form-check-label piik-text-visual px-2"
                                  for="flexSwitchCheckChecked"
                                >
                                  <span>{d.name}</span>
                                  <span className="px-2">
                                    {!d.status ? "**" : null}
                                  </span>
                                </label>
                                <input
                                  className="form-check-input piik-pointer"
                                  type="checkbox"
                                  id={d.id}
                                  onChange={handleInput}
                                  checked={category.some(
                                    (obj) => obj.id === d.id
                                  )}
                                  disabled={
                                    d.status === true && d.endpoints.length > 0
                                      ? false
                                      : true
                                  }
                                />
                              </div>
                              {d.type === "custom" && (
                                <div>
                                  <i
                                    className="bi bi-pencil-square px-4 piik-pointer mb-2"
                                    onClick={() => handleEditKpiEndpoint(d)}
                                  ></i>
                                  <i
                                    className="bi bi-trash piik-pointer"
                                    onClick={() => handleModal(d)}
                                  ></i>
                                </div>
                              )}
                            </div>
                          );
                        })}
                  </>
                );
              })}
            {selectedType === "custom" && (
              <div>
                <div className="d-flex flex-column justify-content-between overflow-y-scroll">
                  <div className="px-3 py-1 piik-text-dashboard  piik-text-visual">
                    <div className="d-flex flex-row justify-content-between">
                      <div className="">Trend Chart Type </div>
                    </div>
                    <div className="my-2">
                      <select
                        className="form-select form-select-sm text-capitalize"
                        id="trendChartIndex"
                        value={trendChartIndex}
                        onChange={handleInput}
                      >
                        <option selected value="1">
                          Line Plot
                        </option>
                        <option selected value="2">
                          Bar Plot
                        </option>
                      </select>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                      <div className="">Select Group </div>
                    </div>
                    <div className="my-2">
                      <select
                        className="form-select form-select-sm text-capitalize"
                        id="assignGroup"
                        value={newGroupStatus ? "new" : newGroup}
                        onChange={handleInput}
                      >
                        <option selected value="">
                          Select
                        </option>
                        {group &&
                          group.map((d) => {
                            return <option value={d.group}>{d.group}</option>;
                          })}
                        <option value="new">Create New Group</option>
                      </select>
                      {!newGroupStatus && newGroupErrorMessage !== "" && (
                        <div className="pt-1 text-danger piik-text-warning ">
                          {newGroupErrorMessage}
                        </div>
                      )}
                    </div>
                  </div>
                  {newGroupStatus && (
                    <div className="px-3 piik-text-dashboard  piik-text-visual">
                      <div className="d-flex flex-row justify-content-between">
                        <div className="">Group Name </div>
                      </div>
                      <div className="my-2">
                        <input
                          className="form-control form-control-sm w-100"
                          id="newGroup"
                          placeholder="Max 25 Character"
                          value={newGroup}
                          onChange={handleInput}
                        />
                        {newGroupStatus && newGroupErrorMessage !== "" && (
                          <div className="pt-1 text-danger piik-text-warning ">
                            {newGroupErrorMessage}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="px-3 py-2 piik-text-dashboard  piik-text-visual">
                    <div className="d-flex flex-row justify-content-between">
                      <div className="mb-2">Create Formula </div>
                      <i
                        className="bi bi-info-circle piik-pointer"
                        onClick={() => setEndpointModal(true)}
                      ></i>
                    </div>
                    <textarea
                      className="form-control"
                      id="formula"
                      value={formula.toUpperCase()}
                      style={{ resize: "none" }}
                      rows="3"
                      onChange={handleInput}
                      placeholder="(EP_1+EP_2)*(EP_3/EP_4)"
                    ></textarea>
                    {errorFormulaMessage !== "" && (
                      <div className="pt-1 text-danger piik-text-warning">
                        {errorFormulaMessage}
                      </div>
                    )}
                  </div>
                  {customEndpoint.map((endpointData, index) => {
                    return (
                      <div className="mb-2 px-3" key={index}>
                        <div className="d-flex flex-row justify-content-between align-items-center py-1">
                          <div className="text-primary piik-text-visual">
                            {endpointData.name}
                          </div>
                          <div>
                            {index === 0 ? null : (
                              <span
                                className=" px-3 bi bi-trash piik-pointer piik-text-visual"
                                onClick={() =>
                                  handleAddRemove(
                                    "remove",
                                    endpointData.name,
                                    setCustomEndpoint
                                  )
                                }
                              ></span>
                            )}

                            <span
                              className="  bi bi-plus-square  piik-pointer piik-text-visual"
                              onClick={() =>
                                handleAddRemove("add", null, setCustomEndpoint)
                              }
                            ></span>
                          </div>
                        </div>
                        <select
                          className="form-select form-select-sm "
                          id={endpointData.name}
                          value={endpointData.endpointId}
                          onChange={handleInput}
                        >
                          <option selected value="">
                            Select
                          </option>
                          {selectedAccount &&
                            selectedAccount.data &&
                            selectedAccount.data.length > 0 &&
                            selectedAccount.data
                              .filter(
                                (d) =>
                                  d.data_type === "float" ||
                                  d.data_type === "int"
                              )
                              .filter((d) => d.category !== "Budget")
                              .filter((d) => d.tags !== "H")
                              .sort((a, b) =>
                                a.category.localeCompare(b.category)
                              )
                              .map((endpoint) => {
                                return (
                                  handleTableHeader(
                                    endpoint.name,
                                    endpoint.data
                                  ) && (
                                    <option value={endpoint.id}>
                                      {endpoint.category} - {endpoint.name}
                                      {endpoint.created &&
                                      endpoint.created === "custom"
                                        ? " *"
                                        : null}
                                    </option>
                                  )
                                );
                              })}
                        </select>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex flex-row justify-content-between">
        <div className="flex-grow-1 bg-light px-4 piik-text-visual py-2 border-top">
          {selectedType === "custom" && action !== "show" ? (
            <span className="px-2">
              {" "}
              Please note that the asterisk (*) symbol represents custom data
            </span>
          ) : (
            <span className="px-2"> ** Supported Data Not Found</span>
          )}
        </div>
        <div
          className="  bg-light shadow-sm d-flex flex-column justify-content-between w-25 piik-create-bar "
          style={{ zIndex: 10 }}
        >
          {submitStatus ? (
            <div className="p-2 piik-text-primary bg-dark-subtle text-center text-white piik-pointer">
              Processing
            </div>
          ) : updateVisualizationIndex === null ? (
            <>
              {selectedType === "default" && category.length > 0 ? (
                <div
                  className="p-2 piik-text-dashboard bg-primary text-center text-white piik-pointer"
                  onClick={handleSubmit}
                >
                  Save
                </div>
              ) : selectedType === "custom" &&
                action === "" &&
                customEndpoint.length > 0 ? (
                <div
                  className="p-2 piik-text-dashboard bg-primary text-center text-white piik-pointer"
                  onClick={handleAddKpiEndpoint}
                >
                  Add KPI
                </div>
              ) : selectedType === "custom" &&
                action === "edit" &&
                customEndpoint.length > 0 ? (
                <div
                  className="p-2 piik-text-dashboard bg-primary text-center text-white piik-pointer"
                  onClick={handleUpdateKpiEndpoint}
                >
                  Update KPI
                </div>
              ) : (
                <div className="p-2 piik-text-dashboard bg-secondary text-center text-white piik-pointer">
                  Save
                </div>
              )}
            </>
          ) : updateVisualizationIndex !== null &&
            selectedType === "default" &&
            action === "" &&
            customEndpoint.length > 0 ? (
            <div
              className="p-2 piik-text-dashboard bg-primary text-center text-white piik-pointer"
              onClick={handleUpdateKpiDashboard}
            >
              Update
            </div>
          ) : updateVisualizationIndex !== null &&
            selectedType === "custom" &&
            action === "edit" &&
            customEndpoint.length > 0 ? (
            <div
              className="p-2 piik-text-dashboard bg-primary text-center text-white piik-pointer"
              onClick={handleUpdateKpiEndpoint}
            >
              Update KPI
            </div>
          ) : updateVisualizationIndex !== null &&
            selectedType === "custom" &&
            action === "" &&
            customEndpoint.length > 0 ? (
            <div
              className="p-2 piik-text-dashboard bg-primary text-center text-white piik-pointer"
              onClick={handleAddKpiEndpoint}
            >
              ADD KPI
            </div>
          ) : null}
        </div>
      </div>
      <FormulaDocumenation
        endpointModal={endpointModal}
        setEndpointModal={setEndpointModal}
      />
      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        title={selectedKpiEndpoint && selectedKpiEndpoint.name}
      >
        {isModalOpen && selectedAccount ? (
          <div className="row mx-0 justify-content-between px-4 pt-2 pb-4 ">
            <div className="col-12 piik-text-warning px-2 pt-2 pb-4">
              This will permanently delete the Kpi Endpoint from the
              <span className="text-danger fw-semibold px-1">
                {user.email}
              </span>{" "}
              account.
            </div>
            <div className="col px-2 ">
              <input
                type="email"
                className="form-control form-control-sm"
                id="password"
                placeholder="Please enter the email
                  to confirm"
                value={password.value}
                onChange={handlePasswordInputChange}
              />
              {password.errorMessage !== "" && (
                <label className="piik-text-warning text-danger text-capitalize py-1">
                  {password.errorMessage}
                </label>
              )}
            </div>

            <div className="col-2 px-0">
              <button
                className="btn btn-sm btn-outline-primary w-100 px-2"
                onClick={handleDeleteKpiEndpoint}
                disabled={btn}
              >
                {btn ? (
                  <span className="piik-text-icon text-warning">
                    Processing
                  </span>
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className="row mx-0 justify-content-between px-4 pt-2 pb-4 ">
            <div className="col-12 piik-text-dashboard px-2 pt-2 pb-4">
              Sorry you are not allowed to delete all the Dashboard. Add a new
              dashboard first to clear the selected Chart
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default CreateKpi;
