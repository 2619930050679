import React, { useEffect, useState } from "react";
import CountryFlag from "./CountryFlag";
import { useDashData } from "../../hooks/useDash";
import { useAuth0 } from "@auth0/auth0-react";

import Modal from "./Modal";
import Help from "./Help";

const ContentHeader = (props) => {
  const { logout, user } = useAuth0();
  const {
    profile,
    adminAccountInfo,
    guestAccountInfo,
    navigation,
    contentOption,
    handleContentTab,
    handleRefresh,
    Loading,
    subscriptionDetails,
  } = useDashData();

  const [modalStatus, setModalStatus] = useState(false);
  const handleOpenModal = () => {
    setModalStatus(!modalStatus);
  };
  const [profileAlert, setProfileAlert] = useState(true);
  const handleProfileAlert = () => {
    setProfileAlert(() => {
      if (
        profile &&
        (profile.first_name === undefined ||
          profile.first_name === "" ||
          profile.last_name === undefined ||
          profile.last_name === "" ||
          profile.company === undefined ||
          profile.company === "")
      ) {
        return true;
      } else {
        return false;
      }
    });
  };

  const url = () => {
    let url =
      "https://piik.io/support?email=" +
      encodeURIComponent(user.email) +
      "&company=" +
      encodeURIComponent(profile.company) +
      "&firstname=" +
      encodeURIComponent(profile.first_name) +
      "&lastname=" +
      encodeURIComponent(profile.last_name);
    return url;
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const result = date.toLocaleDateString("en-US", {
      weekday: "short",
      month: "short",
      day: "2-digit",
      year: "numeric",
    });

    return result;
  };

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  let tabHeaderContent =
    contentOption.tab_id !== ""
      ? navigation.find((content) => content.tab_id === contentOption.tab_id)
      : null;

  let account = adminAccountInfo
    .concat(guestAccountInfo)
    .find((data) => data.uid === contentOption.subTab_id);

  useEffect(() => {
    if (profileAlert) {
      handleProfileAlert();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.last_name]);

  return (
    <div className="d-flex flex-row justify-content-between align-items-end">
      <div className="piik-text-content-header text-secondary piik-text-dashboard d-flex flex-row justify-content-between align-items-center">
        {contentOption.tab_id === "4" || contentOption.tab_id === "1" ? (
          <>
            <div
              className={`${
                contentOption.tab_id === "1"
                  ? navigation[0].logo
                  : "bi bi-house"
              }`}
            ></div>
            <div className="px-2 fw-bold piik-text-dashboard">
              {contentOption.tab_title}
            </div>
          </>
        ) : contentOption.tab_id === "2" && contentOption.subTab_id === "" ? (
          <>
            <div className={`bi bi-house`}></div>
            <div className="px-2 piik-text-dashboard fw-bold ">Home</div>
          </>
        ) : contentOption.tab_id === "2" && contentOption.subTab_id !== "" ? (
          <>
            <div className="d-flex flex-row align-items-center">
              <CountryFlag countryCode={account.country.toLowerCase()} />
              <span className="piik-text-dashboard fw-bold text-uppercase ">
                {account.company_name}
              </span>
            </div>
          </>
        ) : contentOption.tab_id === "3" && contentOption.subTab_id !== "" ? (
          <>
            <div className={`${tabHeaderContent.logo}`}></div>
            <div className="px-2  piik-text-dashboard fw-bold">
              {contentOption.tab_title} | {contentOption.subTab_title}
            </div>
          </>
        ) : null}
      </div>
      <div className="d-flex flex-row justify-content-end align-items-center piik-pointer">
        {Loading ? (
          <div className="px-4 piik-text-visual text-primary">
            Data Update in Progress....
          </div>
        ) : subscriptionDetails && subscriptionDetails.status === "trialing" ? (
          <div className="px-4 piik-text-visual text-dark">
            Your free trial ends on{" "}
            <span className="fw-bold">
              {subscriptionDetails &&
                subscriptionDetails.trial_end &&
                formatDate(subscriptionDetails.trial_end)}
            </span>
            . Need help setting up? Contact our{" "}
            <a
              href={url()}
              target="_blank"
              rel="noreferrer"
              className="fw-bold"
            >
              Customer Success Experts.
            </a>
          </div>
        ) : (
          <div className="px-4 piik-text-visual text-primary">
            Need help setting up? Contact our{" "}
            <a
              href={url()}
              target="_blank"
              rel="noreferrer"
              className="fw-bold"
            >
              Customer Success Experts.
            </a>
          </div>
        )}

        <div
          className="bi bi-link-45deg  border rounded-start py-1 px-3 piik-header-btn "
          data-toggle="tooltip"
          data-placement="bottom"
          title="Add Company"
          onClick={() => {
            handleContentTab("1");
          }}
        ></div>
        <div
          className="bi bi-arrow-clockwise  px-3 border border-start-0 border-end-0  py-1  piik-header-btn"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Refresh Profile"
          onClick={handleRefresh}
        ></div>
        <div
          className="bi bi-question-circle px-3 border border-end-0  py-1  piik-header-btn"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Help"
          onClick={handleOpenModal}
        ></div>

        <div
          className="border border-end-0 px-2 py-1 my-0 d-flex flex-row justify-content-center align-items-center piik-header-btn "
          onClick={() => {
            handleContentTab("3", "3");
          }}
          data-toggle="tooltip"
          data-placement="bottom"
          title={profileAlert ? "Update Your Profile" : "User Profile"}
        >
          {profileAlert ? (
            <>
              <div className="bi bi-exclamation-circle-fill text-danger"></div>
              <div className="piik-text-visual h-100 px-2">Profile</div>
            </>
          ) : (
            <>
              <div className="bi bi-person-circle"></div>
              <div className="piik-text-visual px-2">
                {profile && profile.first_name}
              </div>
            </>
          )}
        </div>
        <div
          className="border rounded-end py-1 my-0 d-flex flex-row justify-content-center align-items-center piik-header-btn"
          onClick={handleLogout}
          data-toggle="tooltip"
          data-placement="bottom"
          title="Signout"
        >
          <div className="bi bi-power px-3 "></div>
        </div>
      </div>
      <Modal
        isOpen={modalStatus}
        closeModal={handleOpenModal}
        title="Tutorials"
        size="modal-lg"
      >
        <Help />
      </Modal>
    </div>
  );
};

export default ContentHeader;
