import React, { useState } from "react";
import { useDashData } from "../../hooks/useDash";
import { useAuth0 } from "@auth0/auth0-react";
import Stripe from "./Stripe";
import Modal from "../Common/Modal";

const emailLink = '<a href="mailto:support@piik.io">support@piik.io</a>';
const subscriptionFAQs = [
  {
    id: 3,
    question: "How is billing handled for Premium accounts?",
    answer: "We charge monthly, in advance, to your credit card.",
  },
  {
    id: 4,
    question: "What's included in the 30-day free trial for Premium plans?",
    answer:
      "Try Dash free for 30 days without any charges. Billing starts only after the free trial ends.",
  },
  {
    id: 5,
    question: "How can I change my subscription plan?",
    answer:
      "To upgrade or downgrade, select your new plan from the options shown. Downgrading to the free Starter Plan means you'll lose the ability to connect new data sources and create new content. Also, any data and content from your Premium Plan will be removed at the end of your paid period.",
  },
  {
    id: 6,
    question: "What payment methods are accepted?",
    answer: "We accept all major credit cards.",
  },
  {
    id: 7,
    question: "Is my data secure with Dash?",
    answer:
      "Yes, we prioritize your data's security and confidentiality with industry-standard encryption and security practices. You can learn more about our security policy here, and our privacy policy here.",
  },
  {
    id: 8,
    question: "Can I cancel my subscription at any time?",
    answer:
      "Yes, you can cancel anytime. Your subscription will remain active until the end of the current billing cycle.",
  },
  {
    id: 9,
    question: "Do I get updates and support with my subscription?",
    answer:
      "All plans include regular updates and email support from our team.",
  },
  {
    id: 10,
    question: "What happens to my data if I cancel my subscription?",
    answer:
      "If you cancel, you'll have access until the end of your billing period. After that, your data will be securely archived for a limited time in case you decide to return.",
  },
  {
    id: 11,
    question: "Need more help?",
    answer: `Reach out to our Customer Success team at ${emailLink}for any further questions or assistance.`,
  },
];
const subscriptionPlan = [
  {
    id: 1,
    title: "Add New Company *",
    subtitle: "Quickbooks Online & Spreadsheet-CSV",
    starter: "-",
    premimum: "Unlimited",
    icon: "bi-building",
  },
  {
    id: 2,
    title: "Dashboards",
    subtitle: "Build custom Charts and create dashboards ",
    starter: "View Only",
    premimum: "Create, Edit, View",
    icon: "bi-clipboard2-data",
  },
  {
    id: 3,
    title: "KPIs",
    subtitle: "Create custom KPIs",
    starter: "View",
    premimum: "Create, Edit, View",
    icon: "bi-speedometer2 ",
  },
  {
    id: 4,
    title: "Reports",
    subtitle: "Generate custom Reports ",
    starter: "View Only",
    premimum: "Create, Edit, View",
    icon: "bi-file-earmark-spreadsheet",
  },
  {
    id: 5,
    title: "Analysis",
    subtitle: "Price Volume analysis and more...",
    starter: "View Only",
    premimum: "Create, Edit, View",
    icon: "bi-bar-chart ",
  },
  {
    id: 6,
    title: "Add New Data Source *",
    subtitle: "QuickBooks Online, CSV/spreadsheet, or Microsoft Azure",
    starter: "-",
    premimum: "Unlimited",
    icon: "bi-database",
  },
  {
    id: 7,
    title: "Share",
    subtitle: "Invite Unlimited users to view Dashboard, Reports or KPIs",
    starter: "-",
    premimum: "Create, Edit, View",
    icon: "bi-share",
  },
];
const SubscriptionCard = ({
  data,
  handleSelection,
  profile,
  handleSubscription,
}) => {
  const handleCard = () => {
    handleSelection(data.id, data.price_id);
    handleSubscription();
  };
  return (
    <div className="mb-3">
      <div
        className={`card px-4 p-3 mb-2 w-100 d-flex flex-row justify-content-between  mx-1 align-items-start ${
          data.name === "Premium" ? "border border-primary" : null
        }`}
      >
        <section className="flex-grow-1 mb-4">
          <div className="d-flex flex-row justify-content-between pb-1">
            <div className="fw-bold text-capitalize flex-grow-1  text-primary">
              {data.name}
            </div>
            <div className="fw-semibold pb-1 flex-grow-3 text-start piik-text-dashboard ">
              {data.price_id === profile.price_id ? (
                <div className="bi bi-bookmark-star-fill px-2 text-primary">
                  <span className="piik-text-icon px-2">Subscribed</span>
                </div>
              ) : (
                <div className="bi  px-2 text-primary"></div>
              )}
            </div>
          </div>
          <div className="fw-bold text-dark piik-text-dashboard pb-3">
            {data.name === "Starter" ? "Free for Ever" : "Try Free for 30 days"}
          </div>
          <div className="piik-text-dashboard">{data.description}</div>
        </section>
      </div>
      {data.price_id !== profile.price_id ? (
        <div className="text-end">
          <button className=" btn btn-primary btn-sm w-25" onClick={handleCard}>
            {data.name === "Starter" ? "Downgrade" : "Upgrade Now"}
          </button>
        </div>
      ) : (
        <div className="text-end">
          <button className=" btn btn-secondary btn-sm btn-disabled w-25">
            Current Plan
          </button>
        </div>
      )}
    </div>
  );
};

const Subscriptions = () => {
  const {
    products,
    contentOption,
    profile,
    isModalOpen,
    openModal,
    closeModal,
    handleUpdatesubscription,
  } = useDashData();
  const { user } = useAuth0();

  const [selectedProductId, setSelectedProduct] = useState({
    productId: profile.product_id,
    priceId: profile.price_id,
  });

  const [tab, setTab] = useState(3);
  const [subTab, setSubTab] = useState(0);

  const handleSelection = (productId, priceId) => {
    setSelectedProduct({ productId: productId, priceId: priceId });
  };

  const [paymentMode, setPaymentMode] = useState(false);
  const handlePaymentMode = () => {
    setPaymentMode(!paymentMode);
  };

  const [password, setPassword] = useState({ value: "", errorMessage: "" });
  const [btn, setBtnState] = useState(false);

  const handlePasswordInputChange = (event) => {
    const value = event.target.value;
    setPassword({ errorMessage: "", value: value });
  };

  const handleDeleteAccounts = async () => {
    if (password.value !== user.email) {
      setPassword({ errorMessage: "Invalid email", value: "" });
      return;
    } else {
      setBtnState(true);
      let response = await handleUpdatesubscription({}, selectedProductId);
      if (response) {
        setPassword({ errorMessage: "", value: "" });
        setBtnState(false);
        closeModal();
      } else {
        setPassword({
          errorMessage: "Error!Please try again",
          value: "",
        });
        setBtnState(false);
      }
    }
  };

  return (
    <>
      {contentOption.tab_id === "4" && (
        <div className="bg-light px-4  pt-4 border-bottom">
          <div className="piik-text-dashboard text-capitalize ">
            Hello
            <span className="fw-semibold text-title">
              {user && user.email && user.email.split("@")[0]}
            </span>
            ,
          </div>
          <div className="row">
            <div className="col">
              <h5 className="fw-semibold mb-3 mt-2">Welcome to Dash By Piik</h5>
            </div>
          </div>
        </div>
      )}
      <div className="bg-light p-4 piik-subscription-container">
        <div className="row">
          <div className="col-5 d-flex flex-column  justify-content-between ps-1">
            <div className="text-start text-capitalize piik-text-dashboard fw-semibold px-2 py-1 mb-2">
              Select you subscription plan
            </div>
            {products.length > 0 ? (
              <>
                <SubscriptionCard
                  data={products.find((d) => d.name === "Starter")}
                  profile={profile}
                  selectedProductId={selectedProductId}
                  handleSelection={handleSelection}
                  status={paymentMode}
                  handleSubscription={openModal}
                />

                <SubscriptionCard
                  data={products.find((d) => d.name === "Premium")}
                  profile={profile}
                  selectedProductId={selectedProductId}
                  handleSelection={handleSelection}
                  status={paymentMode}
                  handleSubscription={handlePaymentMode}
                />
              </>
            ) : null}
            <div className="flex-grow-1"></div>
          </div>
          <div className="col">
            {paymentMode ? (
              <Stripe
                handlePaymentMode={handlePaymentMode}
                productId={selectedProductId}
              />
            ) : (
              <>
                <div className="d-flex flex-row justify-content-start align-items-center  border-bottom ">
                  <div
                    className={`${
                      subTab === 1
                        ? "fw-bold piik-pointer piik-text-dashboard px-4 py-2 text-primary w-25"
                        : "fw-bold piik-pointer piik-text-dashboard px-4 py-2 text-white bg-primary w-25"
                    }`}
                    onClick={() => setSubTab(0)}
                  >
                    Plan Details
                  </div>
                  <div
                    className={`${
                      subTab === 0
                        ? "fw-bold piik-pointer piik-text-dashboard px-4 py-2 text-primary w-25"
                        : "fw-bold piik-pointer piik-text-dashboard px-4 py-2 text-white bg-primary w-25"
                    }`}
                    onClick={() => setSubTab(1)}
                  >
                    FAQs
                  </div>
                </div>
                {subTab === 0 ? (
                  <>
                    <div className="row py-2 border-bottom border-start border-end  mx-0 px-0 bg-white text-primary">
                      <div className="col-6">
                        <div className="piik-text-dashboard fw-semibold">
                          Features
                        </div>
                      </div>
                      <div className="col-3 piik-text-dashboard text-end fw-semibold">
                        Starter
                      </div>
                      <div className="col-3 piik-text-dashboard text-end fw-semibold">
                        Premium
                      </div>
                    </div>
                    {subscriptionPlan.map((data, index) => {
                      return (
                        <div className="row py-2 border-bottom border-start border-end mx-0 px-0">
                          <div className="col-7">
                            <div className="d-flex flex-row align-items-start">
                              <div
                                className={`bi ${data.icon} piik-text-dashboard`}
                              ></div>
                              <div className="px-2">
                                <div className="piik-text-visual fw-semibold">
                                  {data.title}
                                </div>
                                <div className="piik-text-visual">
                                  {data.subtitle}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-2 piik-text-dashboard text-end">
                            <div>
                              {data.starter === "-" ? (
                                "-"
                              ) : (
                                <div className="bi bi-eye-fill"></div>
                              )}
                            </div>
                          </div>
                          <div className="col-3 piik-text-dashboard text-end ">
                            <div className="piik-text-visual fw-semibold">
                              {data.premimum === "Unlimited" ? (
                                data.premimum
                              ) : (
                                <div className="bi bi-check-circle-fill text-primary"></div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="d-flex flex-row justify-content-between bg-white py-1 border-bottom border-start border-end">
                      <div className="ps-2 py-2 piik-text-visual">
                        * Each additional for just US$25 per month
                      </div>
                      <div className="px-2 py-2 piik-text-visual text-end">
                        <span className="bi bi-eye-fill px-4">
                          <span className="ps-2">View Only</span>
                        </span>
                        <span className="bi bi-check-circle-fill text-primary">
                          <span className="ps-2 text-dark">
                            Create | Edit | Update | Delete
                          </span>
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {subscriptionFAQs.map((data, index) => {
                      return tab === data.id ? (
                        <div>
                          <div className="d-flex flex-row justify-content-between px-2 py-2 border-start border-end">
                            <div className="piik-tab fw-semibold piik-text-dashboard text-primary">
                              {data.question}
                            </div>
                            <i
                              className="bi bi-chevron-up  piik-pointer"
                              onClick={() => setTab(null)}
                            ></i>
                          </div>
                          {data.id === 11 ? (
                            <div className="piik-tab piik-text-dashboard border-bottom border-start border-end  bg-white px-2 pb-4 pt-2">
                              Reach out to our Customer Success team at
                              <a href="mailto:support@piik.io" className="px-1">
                                support@piik.io
                              </a>
                              for any further questions or assistance.
                            </div>
                          ) : (
                            <div className="piik-tab piik-text-dashboard border-bottom border-start border-end  bg-white px-2 pb-4 pt-2">
                              {data.answer}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className={`${index === 0 ? "border-top" : null}`}>
                          <div className="d-flex flex-row justify-content-between px-2 border-bottom border-start border-end py-1">
                            <div className="piik-tab fw-semibold piik-text-dashboard py-1 ">
                              {data.question}
                            </div>

                            <i
                              className="bi bi-chevron-down piik-pointer"
                              onClick={() => setTab(data.id)}
                            ></i>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          closeModal={closeModal}
          title=" Delete Account"
        >
          <div className="row mx-0 justify-content-between px-4 pt-2 pb-4 ">
            <div className="col-12 piik-text-warning px-2 pt-2 pb-4">
              This will permanently delete all the Visulizations and Company
              Data added as an Administrator to
              <span className="text-danger fw-semibold px-1">
                {user.email}
              </span>{" "}
              account.
            </div>
            <div className="col px-2 ">
              <input
                type="email"
                className="form-control form-control-sm"
                id="password"
                placeholder="Please enter the email
              to confirm"
                value={password.value}
                onChange={handlePasswordInputChange}
              />
              {password.errorMessage !== "" && (
                <label className="piik-text-warning text-danger text-capitalize py-1">
                  {password.errorMessage}
                </label>
              )}
            </div>

            <div className="col-2 px-0">
              <button
                className="btn btn-sm btn-outline-primary w-100 px-2"
                onClick={handleDeleteAccounts}
                disabled={btn}
              >
                {btn ? (
                  <span className="piik-text-icon text-primary">
                    Processing
                  </span>
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Subscriptions;
