import React from "react";
const NavIcon = (props) => {
  const handleTabs = () => {
    if (props.type === "dropdown") {
      if (props.tab_id === "2") {
        props.handleClick(props.id);
      } else if (props.tab_id === "3") {
        props.handleClick();
      }
    } else if (props.type === "btn") {
      if (props.tab_id === "1" || props.tab_id === "4") {
        props.handleClick(props.tab_id, "", "");
      } else if (props.tab_id === "2") {
        props.handleClick(props.tab_id, props.subTab_id, props.tertiary_tab_id);
      } else if (props.tab_id === "3") {
        props.handleClick(props.tab_id, props.subTab_id, "");
      }
    }
  };

  return props.mode ? (
    <div
      className={` d-flex flex-row justify-content-between align-items-center ${props.backgroundColor}  px-3 py-2 piik-pointer  piik-hide-1`}
      onClick={handleTabs}
    >
      {props.logo === undefined ? (
        <div className=" d-flex flex-row justify-content-start align-items-center">
          <div className={`bi ${props.logo} ${props.color} `}></div>
          <div
            className={`${props.color} piik-text-dashboard text-truncate w-100`}
            style={{ maxWidth: "125px" }}
          >
            {props.title}
          </div>
        </div>
      ) : (
        <div className=" d-flex flex-row justify-content-start align-items-center">
          <div className={`bi ${props.logo} ${props.color} `}></div>
          <div
            className={`${props.color} px-2 piik-text-dashboard  d-inline-block text-truncate w-100`}
            style={{ maxWidth: "125px" }}
          >
            {props.title}
          </div>
        </div>
      )}
      <div className={`bi ${props.icon}`}></div>
    </div>
  ) : (
    <div
      className={` d-flex flex-row justify-content-between align-items-center ${props.backgroundColor} px-3 py-2 piik-pointer piik-hide-2 `}
      onClick={handleTabs}
    >
      {props.logo === undefined ? (
        <div className=" d-flex flex-row justify-content-center align-items-center px-0 mx-0">
          <div className={`bi ${props.logo} ${props.color} `}></div>
          <div
            className={`${props.color} text-truncate w-100`}
            style={{ maxWidth: "150px" }}
          >
            {props.title.charAt(0)}
          </div>
        </div>
      ) : (
        <div className=" d-flex flex-row justify-content-center align-items-center">
          <div className={`bi ${props.logo} ${props.color} `}></div>
        </div>
      )}
    </div>
  );
};

export default NavIcon;
