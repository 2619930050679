import React, { useEffect, useRef } from "react";

const VideoTutorials = ({ src }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    video.onloadedmetadata = () => {
      video.pause();
    };

    return () => {
      video.pause();
    };
  }, [src]);

  return (
    <div className="piik-video-container  shadow-sm text-center">
      <video controls ref={videoRef}>
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoTutorials;
