import React from "react";

const Modal = ({ isOpen, closeModal, title, children, size = "" }) => {
  if (!isOpen) return null;
  return (
    <div
      className={`modal show piik-modal-bg ${size} `}
      tabIndex="-1"
      style={{ display: "block" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div
            className="modal-header px-4 py-2 d-flex flex-row justify-content-between text-primary"
            style={{ width: "100%" }}
          >
            <div className="piik-text-warning fw-bold">{title}</div>
            <div className="bi bi-x-lg piik-pointer" onClick={closeModal}></div>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
