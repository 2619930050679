import React, { useEffect, useState } from "react";
import DataFrame from "dataframe-js";

import {
  convertDisplayDataUnit,
  performAggregateOperation,
} from "../Charts/Utility";

import BalanceSheet from "./BalanceSheet";
import IncomeStatement from "./IncomeStatement";

const ReportTable = ({
  baseData,
  selectedAccount,
  data,
  bottomSettings,
  divRef,
  reportType,
  rowFilter,
  columnFilter,
  dates,
  calenderData,
  calenderDatesData,
  handleCalendarLocal,
  handleTableData,
}) => {
  const [reportGl, setReportGL] = useState({});
  const [months, setMonths] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  //  Actual Function

  const handleGroupByAndAggregate = (data, rowFilter, columnFilter) => {
    const uniqueRowValue = [...new Set(data.map((d) => d[rowFilter.name]))];
    let totalAggregateResult = [];
    for (let value of uniqueRowValue) {
      const filterData = data.filter((d) => d[rowFilter.name] === value);
      const columns = Object.keys(filterData[0]).filter(
        (d) => d !== rowFilter.name
      );
      const df = new DataFrame(filterData, columns);
      const groupedColumns = [
        ...columnFilter
          .filter(
            (d) =>
              d.name !== rowFilter.name && ["str", "datetime"].includes(d.type)
          )
          .map((d) => d.name),
      ];

      const aggegateOutput = columnFilter
        .filter((d) => ["int", "float"].includes(d.type))
        .map((d) => {
          const operation = d.group_by;
          const result = df
            .groupBy(...groupedColumns)
            .aggregate((group) =>
              performAggregateOperation(group, operation, d.name)
            );
          const resultDict = result.toDict();
          const resultKeys = Object.keys(resultDict);
          const resultCombined = resultDict[resultKeys[0]].map((_, index) => {
            let tempResult = {};
            resultKeys.map((key) => {
              if (key === "aggregation") {
                tempResult = {
                  ...tempResult,
                  [d.name]: resultDict[key][index],
                };
              } else {
                tempResult = { ...tempResult, [key]: resultDict[key][index] };
              }
              return tempResult;
            });
            return tempResult;
          });

          return resultCombined;
        });

      const result = aggegateOutput[0].map((d, index) => {
        const sourceKeys = Object.keys(d);
        let temp = { ...d, [rowFilter.name]: value };
        aggegateOutput.forEach((x, i) => {
          if (i > 0) {
            let desKeys = Object.keys(x[0]);
            desKeys.forEach((k) => {
              if (!sourceKeys.includes(k)) {
                temp = { ...temp, [k]: x[index][k] };
              }
            });
          }
        });
        return temp;
      });
      totalAggregateResult = [...totalAggregateResult, ...result];
    }
    return totalAggregateResult;
  };

  const handleGroupSummaryTotal = (
    data,
    filter,
    category,
    group,
    columnFilter
  ) => {
    const filterData = data.filter((d) => d[filter.name] === category);
    const df = new DataFrame(filterData, [group]);
    const operation = columnFilter.find((d) => d.name === group).group_by;
    const result = performAggregateOperation(df, operation, group);
    return `${convertDisplayDataUnit(result, "RF")}`;
  };

  const handleGetEndOfMonth = (year, month) => {
    const nextMonthFirstDay = new Date(year, month, 1);
    const lastDayOfMonth = new Date(nextMonthFirstDay - 1);
    const formattedLastDay = lastDayOfMonth.toISOString().split("RF")[0];
    return formattedLastDay;
  };

  const handleDateFilterForGLData = (data, dates) => {
    const year = dates.currentDate.year;
    const month = dates.currentDate.month_index + 1;
    const end = handleGetEndOfMonth(year, month);

    const start = `${year}-${month}-01`;
    const startDate = new Date(start).getTime();
    const endDate = new Date(end).getTime();

    const filterData = data.filter((d) => {
      const itemDate = new Date(d.Date).getTime();
      return itemDate >= startDate && itemDate <= endDate;
    });
    return filterData;
  };

  const handleGeneralLedgerReport = (baseData, filter) => {
    let result = baseData[0].data
      .map((d, index) => {
        let object = {};
        for (let i = 0; i < baseData.length; i++) {
          if (i === 0) {
            object[baseData[i].name] = baseData[i].data[index].value;
            object["Date"] = baseData[i].data[index].date;
          } else {
            object[baseData[i].name] = baseData[i].data[index].value;
          }
        }
        return object;
      })
      .filter((d) => d.Amount !== 0);

    let filterResult = handleDateFilterForGLData(result, dates);
    let category = baseData
      .filter((d) => d.name !== filter)
      .map((d) => {
        let obj = {
          name: d.name,
          type: d.data_type,
          id: d.id,
          name_code: d.name_code,
          group_by: d.group_by,
        };
        return obj;
      });
    return {
      data: filterResult,
      category: [
        {
          name: "Date",
          type: "datetime",
          name_code: "Date",
          id: "date_123",
          group_by: "sum",
        },
        ...category,
      ],
    };
  };

  useEffect(() => {
    if (initialLoad) {
      setMonths(() => {
        let yearIndex =
          calenderData.fiscal_month_index > 1
            ? calenderData.year_index - 1
            : calenderData.year_index;
        let tempYearIndex = calenderDatesData.findIndex(
          (y) => y.year_index === yearIndex
        );
        let selectedMonths = calenderDatesData[tempYearIndex].months;
        return selectedMonths;
      });
      setInitialLoad(false);
    } else {
      setMonths(() => {
        let tempYearIndex = calenderDatesData.findIndex(
          (y) => y.year_index === calenderData.year_index
        );

        let selectedMonths = calenderDatesData[tempYearIndex].months;
        return selectedMonths;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calenderData]);

  useEffect(() => {
    if (reportType && reportType.record_type === "IV") {
      setReportGL(() => {
        let reportData = handleGeneralLedgerReport(baseData, rowFilter);
        reportData["data"] = handleGroupByAndAggregate(
          reportData.data,
          rowFilter,
          columnFilter
        );
        reportData["category"] = columnFilter;
        return reportData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, calenderData, rowFilter, columnFilter, dates]);

  return rowFilter &&
    rowFilter.name !== "" &&
    reportType &&
    reportType.record_type === "IV" ? (
    <div ref={divRef} className="px-3">
      {reportGl && reportGl.category && reportGl.category.length > 0 ? (
        <div className="row py-2 bg-white border-top">
          {reportGl.category
            .filter((d) => d.name !== rowFilter.name)
            .filter((d) => columnFilter.findIndex((v) => v.id === d.id) > -1)
            .map((cat, indexNo) => {
              return cat.type === "float" || cat.type === "int" ? (
                <div
                  className={`col-1 piik-text-visual bg-white text-end text-uppercase`}
                >
                  {cat.group_by && cat.group_by === "mean"
                    ? "avg"
                    : cat.group_by}
                </div>
              ) : indexNo === 0 ? (
                <div
                  className={`col-3 piik-text-dashboard text-primary ps-3 fw-semibold bg-white`}
                >
                  <div className="d-flex  flex-row justify-content-start align-items-center">
                    Period:
                    <select
                      value={calenderData && calenderData.month_index}
                      onChange={(e) =>
                        handleCalendarLocal(
                          e.target.value,
                          calenderData.year_index
                        )
                      }
                      className="form-select form-select-sm w-25 mx-2"
                      style={{ minWidth: "100px" }}
                    >
                      {months
                        .filter((m) => m.status)
                        .map((m) => {
                          return (
                            <option value={m.month_index}>{m.month}</option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              ) : cat.type === "str" ? (
                <div
                  className={`col piik-text-visual bg-white text-start fw-semibold`}
                ></div>
              ) : null;
            })}
        </div>
      ) : null}

      {reportGl && reportGl.category && reportGl.category.length > 0 ? (
        <div className="row py-2 bg-light border-top">
          {reportGl.category
            .filter((d) => d.name !== rowFilter.name)
            .filter((d) => columnFilter.findIndex((v) => v.id === d.id) > -1)
            .map((cat) => {
              return cat.type === "float" || cat.type === "int" ? (
                <div
                  className={`col-1 piik-text-dashboard  fw-semibold bg-light text-end `}
                >
                  {cat.name}
                </div>
              ) : cat.type === "str" ? (
                <div
                  className={`col piik-text-dashboard fw-semibold bg-light text-start `}
                >
                  {cat.name}
                </div>
              ) : null;
            })}
        </div>
      ) : null}

      {rowFilter &&
        rowFilter.name !== "" &&
        data &&
        data.length > 0 &&
        data
          .filter((rowData) => rowData.id !== "")
          .map((endpoint) => {
            return (
              reportGl.data &&
              reportGl.data.length > 0 &&
              reportGl.data.filter((d) => d[rowFilter.name] === endpoint.id)
                .length > 0 && (
                <>
                  <div className="row py-2 border-top bg-white">
                    <div className="col piik-text-dashboard fw-bold">
                      {endpoint.id}
                    </div>
                  </div>

                  {bottomSettings.levelTwo &&
                    reportGl &&
                    reportGl.data &&
                    reportGl.data.length > 0 &&
                    reportGl.data
                      .filter((d) => d[rowFilter.name] === endpoint.id)
                      .map((rowData) => {
                        return (
                          <div className="row py-2 border-top">
                            {reportGl.category
                              .filter((d) => d.name !== rowFilter.name)
                              .filter(
                                (d) =>
                                  columnFilter.findIndex((v) => v.id === d.id) >
                                  -1
                              )
                              .map((cat) => {
                                return cat.type === "float" ||
                                  cat.type === "int" ? (
                                  <div
                                    className={`piik-text-visual text-end col-1`}
                                  >
                                    {rowData[cat.name] &&
                                      convertDisplayDataUnit(
                                        rowData[cat.name],
                                        "RF"
                                      )}
                                  </div>
                                ) : cat.type === "str" ? (
                                  <div
                                    className={`piik-text-visual text-start col`}
                                  >
                                    {rowData[cat.name] && rowData[cat.name]}
                                  </div>
                                ) : null;
                              })}
                          </div>
                        );
                      })}

                  <div className="row py-2 border-top bg-light text-end piik-text-visual">
                    {bottomSettings.levelOne &&
                      reportGl &&
                      reportGl.data &&
                      reportGl.data.length > 0 &&
                      reportGl.category
                        .filter((d) => d.name !== rowFilter.name)
                        .filter(
                          (d) =>
                            columnFilter.findIndex((v) => v.id === d.id) > -1
                        )
                        .map((cat, index) => {
                          if (index === 0) {
                            return (
                              <div className="col fw-bold text-primary text-start">
                                Total
                              </div>
                            );
                          } else if (["float", "int"].includes(cat.type)) {
                            return (
                              <div className="col-1  fw-bold text-primary text-end">
                                {handleGroupSummaryTotal(
                                  reportGl.data,
                                  rowFilter,
                                  endpoint.id,
                                  cat.name,
                                  columnFilter
                                )}
                              </div>
                            );
                          } else {
                            return <div className="col"></div>;
                          }
                        })}
                  </div>
                </>
              )
            );
          })}
    </div>
  ) : (
    <div className="overflow-x-scroll">
      {reportType && reportType.record_formate === "II" ? (
        <BalanceSheet
          divRef={divRef}
          selectedAccount={selectedAccount}
          selectedEndpointId={data}
          bottomSettings={bottomSettings}
          reportType={reportType}
          dates={dates}
          months={months}
          calenderData={calenderData}
          calenderDatesData={calenderDatesData}
          handleCalendarLocal={handleCalendarLocal}
          handleTableData={handleTableData}
        />
      ) : reportType.record_formate === "I" ? (
        <IncomeStatement
          divRef={divRef}
          selectedAccount={selectedAccount}
          selectedEndpointId={data}
          bottomSettings={bottomSettings}
          reportType={reportType}
          dates={dates}
          months={months}
          calenderData={calenderData}
          calenderDatesData={calenderDatesData}
          handleCalendarLocal={handleCalendarLocal}
          handleTableData={handleTableData}
        />
      ) : null}
    </div>
  );
};

export default ReportTable;
