import html2canvas from "html2canvas";
import download from "downloadjs";

export const userDownloadApi = () => {
  const download_as_png = async (
    adminAccountInfo,
    guestAccountInfo,
    account,
    divRef,
    data
  ) => {
    try {
      const CombinedChartData = adminAccountInfo.concat(guestAccountInfo);
      const accountIndex = CombinedChartData.findIndex(
        (data) => data.uid === account
      );
      const selectedAccountData = JSON.parse(
        JSON.stringify(CombinedChartData[accountIndex])
      );

      const company_name = selectedAccountData.company_name;
      const currentDate = new Date();
      const isoDate = currentDate.toISOString().split("T")[0];

      const fileName = company_name + "_" + data.name + "_" + isoDate + ".png";
      const canvas = await html2canvas(divRef.current);
      canvas.toBlob((blob) => {
        download(blob, fileName);
      });
    } catch (error) {
      console.error("Error capturing div as image:", error);
    }
  };
  return { download_as_png };
};
