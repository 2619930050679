import axios from "axios";
export const userClientApi = (user, handleAuthToken) => {
  const handleAuthTokenFn = handleAuthToken;
  const mode = process.env.REACT_APP_MODE;

  let baseUrl =
    mode === "DEV"
      ? "http://127.0.0.1:5000"
      : "https://piik-dash-backend.azurewebsites.net";

  const getAuthToken = () => {
    return new Promise((resolve, reject) => {
      handleAuthTokenFn()
        .then(async (accessToken) => {
          const result = await connect(user, accessToken);
          resolve(result);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  const connect = async (user, token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${baseUrl}/settings/profile`,
          {
            name: user.name,
            email: user.email,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    });
  };

  const get = async (endpoint) => {
    try {
      const authToken = await handleAuthTokenFn();
      const response = await axios.get(`${baseUrl}${endpoint}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const post = async (endpoint, data) => {
    try {
      const authToken = await handleAuthTokenFn();
      const response = await axios.post(`${baseUrl}${endpoint}`, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return { getAuthToken, get, post };
};
