import React, { useEffect, useState, useRef } from "react";
import Plot from "react-plotly.js";

import { convertDisplayDataUnit } from "../Charts/Utility";
import { PvmConfig, PvmChartFx } from "./PvmConfig";

import { useDashData } from "../../../hooks/useDash";
import { useAuth0 } from "@auth0/auth0-react";

import Modal from "../../Common/Modal";
import DisplayBottomIcon from "../DisplayBottomIcon";

import { SelectPVMRadioButton, SelectPVMCheckBoxButton } from "./PvmSelectBtn";
import PvmTable from "./PvmTable";

const DisplayPvm = ({
  fullScreenStatus,
  index,
  handleVisualizationTab,
  handleUpdateVisualizationIndex,
}) => {
  const {
    profile,
    adminAccountInfo,
    guestAccountInfo,
    account,
    isModalOpen,
    calenderData,
    openModal,
    closeModal,
    handleDeleteVisulization,
    handleCalculator,
    handleDownloadAsImage,
    handleCopyData,
  } = useDashData();
  const { user } = useAuth0();

  const [plotState, setPlotState] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [data, setData] = useState({});
  const [title, setTitle] = useState("");
  const [chartData, setChartData] = useState({});
  const [tableData, setTableData] = useState({});
  const [tab, setTab] = useState(1);
  const [tableStatus, setTableStatus] = useState([
    "Active",
    "Discontinued",
    "New",
  ]);

  const [password, setPassword] = useState({ value: "", errorMessage: "" });
  const [btn, setBtnState] = useState(false);

  const handleStatusFilter = (key) => {
    setTableStatus((prev) => {
      if (prev.includes(key)) {
        let temp = prev.filter((d) => d !== key);
        return temp;
      } else {
        return [...prev, key];
      }
    });
  };

  const handleDuplicate = () => {
    let duplicate_data = {
      type: "analysis",
      id: data.id,
      accountId: selectedAccount.uid,
      userId: selectedAccount.owner_id,
    };
    handleCopyData(duplicate_data).then((response) => {
      if (response.status) {
        handleUpdateVisualizationIndex(selectedAccount.pvm_id.length);
        handleVisualizationTab(100);
      }
    });
  };

  const handlePasswordInputChange = (event) => {
    const value = event.target.value;
    setPassword({ errorMessage: "", value: value });
  };

  const handleEditPvm = () => {
    handleUpdateVisualizationIndex(index);
    handleVisualizationTab(100);
  };

  const handleDeletePvm = async () => {
    if (password.value !== user.email) {
      setPassword({ errorMessage: "Invalid email", value: "" });
      return;
    } else {
      let pvmInfo = {
        userId: profile.user_id,
        accountId: selectedAccount.uid,
        pvmId: data.id,
        visualizationType: "PVM",
      };
      handleDeleteVisulization(pvmInfo)
        .then((response) => {
          if (response) {
            setChartData({});
            setBtnState(false);
            setPassword({
              errorMessage: "",
              value: "",
            });
            handleVisualizationTab(0);
            closeModal();
          }
        })
        .catch((error) => {
          setBtnState(false);
        });
    }
  };

  const handlePVMData = (accountData, pvmData) => {
    setData(pvmData);
    let pvmDataInfo = {
      values: {
        categories: accountData.data.filter(
          (d) => d.id === pvmData.settings.data.categories
        )[0].data,
        price: accountData.data.filter(
          (d) => d.id === pvmData.settings.data.price
        )[0].data,
        quantity: accountData.data.filter(
          (d) => d.id === pvmData.settings.data.quantity
        )[0].data,
        rate: accountData.data.filter(
          (d) => d.id === pvmData.settings.data.price
        )[0].data,
        amount: accountData.data.filter(
          (d) => d.id === pvmData.settings.data.amount
        )[0].data,
        exchange:
          pvmData.settings.type === "PVM"
            ? []
            : accountData.data.filter(
                (d) => d.id === pvmData.settings.data.exchange
              )[0].data,
      },
      calender: calenderData,
      type: pvmData.settings.type,
    };
    handleCalculator(pvmDataInfo).then((res) => {
      if (res.status) {
        const chartData = PvmChartFx;
        setChartData(() => {
          const updatedData = {
            ...chartData,
            id: pvmData.id,
            data: [
              {
                ...chartData.data[0],
                x: res.data.chart.x,
                y: res.data.chart.y,
                measure: res.data.chart.measure,
                text: res.data.chart.y.map((v) => convertDisplayDataUnit(v)),
              },
            ],
          };
          return updatedData;
        });
        setTableData(res.data.table);
      }
    });
  };

  const divRef = useRef(null);
  const handleDownload = async () => {
    handleDownloadAsImage(divRef, data);
  };

  useEffect(() => {
    if (fullScreenStatus !== plotState) {
      setPlotState((preData) => !preData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullScreenStatus]);

  useEffect(() => {
    if (fullScreenStatus !== plotState) {
      setPlotState((preData) => !preData);
    }
    let CombinedChartData = adminAccountInfo.concat(guestAccountInfo);
    const accountIndex = CombinedChartData.findIndex(
      (data) => data.uid === account
    );
    let accountData = CombinedChartData[accountIndex];
    setSelectedAccount(accountData);

    if (parseInt(index) > accountData.pvm_id.length - 1) {
      const updateData = accountData.pvm_id[0];

      if (updateData && updateData.settings.hasOwnProperty("data")) {
        handlePVMData(accountData, updateData);
        setTitle(updateData.name || "");
      }
    } else {
      const updateData = accountData.pvm_id[index];
      if (updateData && updateData.settings.hasOwnProperty("data")) {
        handlePVMData(accountData, updateData);
        setTitle(updateData.name);
      }
    }
    return () => {
      setChartData({});
      setTableData({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAccountInfo, account, index, calenderData]);

  return chartData.hasOwnProperty("data") &&
    tableData.hasOwnProperty("columns") ? (
    <>
      <div
        className={
          fullScreenStatus
            ? "d-flex flex-column justify-content-between piik-visual-section-fullscreen piik-create-chart "
            : "d-flex flex-column justify-content-between piik-visual-section piik-create-chart "
        }
      >
        {tab === 1 ? (
          <div className="flex-grow-1 bg-white pt-4 w-100 h-100" ref={divRef}>
            {chartData.hasOwnProperty("data") &&
              plotState === fullScreenStatus && (
                <Plot
                  data={chartData.data}
                  layout={chartData.layout}
                  config={PvmConfig}
                  className="w-100  piik-text-visual  flex-grow-1 bg-white "
                />
              )}
          </div>
        ) : tab === 2 ? (
          <div className="flex-grow-1 bg-white w-100 h-100" ref={divRef}>
            {tableData.hasOwnProperty("columns") && (
              <PvmTable tableData={tableData} filterKey={tableStatus} />
            )}
          </div>
        ) : (
          <div className="flex-grow-1 bg-white pt-4 w-100 h-100" ref={divRef}>
            heelo
          </div>
        )}
      </div>
      <div
        className="d-flex flex-row justify-content-between  bg-light border-top align-self-center"
        style={{ zIndex: 2 }}
      >
        <div className="py-1 piik-text-dashboard text-primary  px-4">
          <SelectPVMRadioButton
            id="chart"
            name="Chart"
            value={tab}
            status={tab === 1}
            handleTab={() => {
              setTab(1);
            }}
          />
          <SelectPVMRadioButton
            id="table"
            name="Table"
            value={tab}
            status={tab === 2}
            handleTab={() => {
              setTab(2);
            }}
          />

          {tab === 2 && (
            <>
              <div className="form-check form-check-inline piik-pointer fw-bold">
                Status Filter:
              </div>

              <SelectPVMCheckBoxButton
                id="table"
                name="Active"
                value={tableStatus.includes("Active")}
                status={tableStatus.includes("Active")}
                handleTab={() => {
                  handleStatusFilter("Active");
                }}
              />
              <SelectPVMCheckBoxButton
                id="table"
                name="New"
                value={tableStatus.includes("New")}
                status={tableStatus.includes("New")}
                handleTab={() => {
                  handleStatusFilter("New");
                }}
              />
              <SelectPVMCheckBoxButton
                id="table"
                name="Discontinued"
                value={tableStatus.includes("Discontinued")}
                status={tableStatus.includes("Discontinued")}
                handleTab={() => {
                  handleStatusFilter("Discontinued");
                }}
              />
            </>
          )}
        </div>

        <div className="d-flex flex-row justify-content-end">
          <DisplayBottomIcon
            title="Download"
            icon="bi-download"
            handleIconAction={handleDownload}
          />

          {profile.user_id === selectedAccount.owner_id ? (
            <>
              <DisplayBottomIcon
                title="Copy Chart"
                icon="bi-clipboard-plus"
                handleIconAction={handleDuplicate}
              />
              <DisplayBottomIcon
                title="Edit PVM"
                icon="bi-pencil-square"
                handleIconAction={handleEditPvm}
              />
              <DisplayBottomIcon
                title="Delete PVM"
                icon="bi-trash"
                handleIconAction={openModal}
              />
            </>
          ) : null}

          <DisplayBottomIcon
            title="PVM Description"
            icon="bi-info-circle"
            status={false}
            // handleIconAction=
          />
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        title={chartData ? `${title}` : "Loading..."}
      >
        {isModalOpen && selectedAccount ? (
          <div className="row mx-0 justify-content-between px-4 pt-2 pb-4 ">
            <div className="col-12 piik-text-warning px-2 pt-2 pb-4">
              This will permanently delete the PVM chart from the
              <span className="text-danger fw-semibold px-1">
                {user.email}
              </span>{" "}
              account.
            </div>
            <div className="col px-2 ">
              <input
                type="email"
                className="form-control form-control-sm"
                id="password"
                placeholder="Please enter the email
                  to confirm"
                value={password.value}
                onChange={handlePasswordInputChange}
              />
              {password.errorMessage !== "" && (
                <label className="piik-text-warning text-danger text-capitalize py-1">
                  {password.errorMessage}
                </label>
              )}
            </div>

            <div className="col-2 px-0">
              <button
                className="btn btn-sm btn-outline-primary w-100 px-2"
                onClick={handleDeletePvm}
                disabled={btn}
              >
                {btn ? (
                  <span className="piik-text-icon text-warning">
                    Processing
                  </span>
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className="row mx-0 justify-content-between px-4 pt-2 pb-4 ">
            <div className="col-12 piik-text-dashboard px-2 pt-2 pb-4">
              Sorry you are not allowed to delete all the PVM Chart. Add a new
              PVM Chart first to delete this Chart
            </div>
          </div>
        )}
      </Modal>
    </>
  ) : null;
  // <VideoTutorials src="source/piikByDash-Analysis.mp4" />
};

export default DisplayPvm;
