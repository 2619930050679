import React, { useEffect, useState, useRef } from "react";
import Plot from "react-plotly.js";
import { useDashData } from "../../../hooks/useDash";

import { chartsOrginal, config } from "../Charts/ChartConfig";
import { filterByDateRange } from "../../../Data/DatesGenerator";
import { convertDisplayDataUnit, transformDate } from "../Charts/Utility";

import Modal from "../../Common/Modal";

const KpiPlot = ({
  category,
  calenderData,
  account,
  calenderDatesData,
  handleCalendarLocal,
}) => {
  const { handleDownloadAsImage } = useDashData();
  const [group, setGroup] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [calenderIndex, setCalenderIndex] = useState(11);
  const [months, setMonths] = useState([calenderDatesData[0].months]);
  const [timeHeader, setTimeHeader] = useState({
    currentMonth: "",
    previousMonth: "",
    lastYearSameMonth: "",
  });

  const calculatePercentage = (data1, data2) => {
    if (parseInt(data2) === 0.0) {
      let percentage = (data1 / 1) * 100;
      return percentage.toFixed(2);
    } else {
      let percentage = ((data1 - data2) / data2) * 100;
      return percentage.toFixed(2);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = (tempId) => {
    console.log(calenderData);
    const id = category.findIndex((d) => d.id === tempId);
    setModalContent(() => {
      const ChartConfig = chartsOrginal.find((d) => d.visualId === 2);
      const selectedData = JSON.parse(JSON.stringify(category[parseInt(id)]));
      if (selectedData.data) {
        const selectedKpiCurrentYr = [{ ...selectedData }];
        const selectedKpiPrYr = [{ ...selectedData }];
        const filterCurrentYr = [
          ...filterByDateRange(selectedKpiCurrentYr, calenderData),
        ];
        const filterPriorYr = [
          ...filterByDateRange(selectedKpiPrYr, {
            ...calenderData,
            year_index: calenderData.year_index - 1,
          }),
        ];
        const plotData = {
          name: selectedData.name,
          status: true,
          selectedCategory: category[parseInt(id)],
          data: [
            {
              ...ChartConfig.data[0],
              x: filterCurrentYr[0].data.map((d) => d.date),
              y: filterCurrentYr[0].data.map((d) => d.value),
              text: filterCurrentYr[0].data.map((d) =>
                convertDisplayDataUnit(d.value)
              ),
              textposition: "top",
              name: selectedData.name,
            },
            {
              ...ChartConfig.data[0],
              x: filterCurrentYr[0].data.map((d) => d.date),
              y: filterPriorYr[0].data.map((d) => d.value),
              text: filterPriorYr[0].data.map((d) =>
                convertDisplayDataUnit(d.value)
              ),
              textposition: "top",
              name: selectedData.name + " PRY",
            },
          ],
          layout: {
            ...ChartConfig.layout,
            xaxis: { ...ChartConfig.layout.xaxis, title: {}, type: "category" },
            yaxis: { ...ChartConfig.layout.yaxis, title: {} },
          },
        };
        return plotData;
      } else {
        return { name: selectedData.name, status: false };
      }
    });
    openModal();
  };

  const handleTrendIcon = (data, primary_index, secondary_index) => {
    const current = data && data[primary_index] && data[primary_index].value;
    const previous =
      data && data[secondary_index] && data[secondary_index].value;
    if (current === undefined && previous === undefined) {
      return false;
    }
    return (
      <span className="text-end piik-text-icon">
        {calculatePercentage(current, previous)}%
      </span>
    );
  };

  // Need to check
  const handleFormula = (kpiData) => {
    let data = kpiData.type === "custom" ? kpiData.endpoints : null;
    let text = kpiData.type === "custom" ? kpiData.formula : kpiData.formula;
    if (text === null) {
      return "";
    } else {
      if (kpiData.type === "custom") {
        for (let i = 0; i < data.length; i++) {
          let selectedData = account.data.find(
            (d) => d.id === data[i].endpointId
          );

          if (selectedData) {
            let newText = ` ${data[i].name}[${selectedData.name}]`;
            let textUpdated = text.replace(
              new RegExp(data[i].name, "g"),
              newText
            );
            text = textUpdated;
            textUpdated = "";
          }
        }
        return text;
      } else {
        return text;
      }
    }
  };

  const kpiRef = useRef(null);
  const handleDownload = async () => {
    handleDownloadAsImage(kpiRef, modalContent);
  };

  useEffect(() => {
    setMonths(() => {
      let temYearIndex = calenderDatesData.findIndex(
        (y) => y.year_index === calenderData.year_index
      );
      let selectedMonths = calenderDatesData[temYearIndex].months;
      return selectedMonths;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calenderData]);

  useEffect(() => {
    setGroup(() => {
      const groups = [...new Set(category.map((obj) => obj.group))].map((gr) =>
        category.find((obj) => obj.group === gr)
      );
      return groups;
    });
    setCalenderIndex(() => {
      if (category.length > 0) {
        const currentDate = `${calenderData.month}-${calenderData.year_index}`;
        const index = category[0].data.findIndex(
          (d) => transformDate(d.date, 0) === currentDate
        );
        setTimeHeader(() => {
          return {
            currentMonth:
              index > -1
                ? transformDate(category[0].data[index].date, 0)
                : "NA",
            previousMonth:
              index - 1 > -1
                ? transformDate(category[0].data[index - 1].date, 0)
                : "NA",
            lastYearSameMonth:
              index - 12 > -1
                ? transformDate(category[0].data[index - 12].date, 0)
                : "NA",
          };
        });
        return index;
      } else {
        return 0;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calenderIndex, category, calenderData]);

  return (
    <div>
      {category.length > 0 ? (
        <>
          <div className="row border-bottom py-2  px-4  fw-semibold gx-4">
            <div className="col-2  piik-text-visual">
              <div className="d-flex  flex-row justify-content-start align-items-center">
                Period:
                <select
                  value={calenderData && calenderData.month_index}
                  onChange={(e) =>
                    handleCalendarLocal(e.target.value, calenderData.year_index)
                  }
                  className="form-select form-select-sm w-25 mx-2"
                  style={{ minWidth: "100px" }}
                >
                  {months
                    .filter((m) => m.status)
                    .map((m) => {
                      return <option value={m.month_index}>{m.month}</option>;
                    })}
                </select>
              </div>
            </div>
            <div className="col-3"></div>

            <div className="col piik-text-visual text-end">
              {timeHeader.currentMonth}
            </div>
            <div className="col-1"></div>
            <div className="col piik-text-visual text-end">
              {timeHeader.previousMonth}
            </div>
            <div className="col-1"></div>
            <div className="col piik-text-visual text-end">
              {timeHeader.lastYearSameMonth}
            </div>
          </div>
          <div className="row border-bottom py-2  px-4 bg-light gx-4">
            <div className="col-1 piik-text-dashboard bg-light">#</div>
            <div className="col-4 piik-text-dashboard bg-light">Name</div>
            <div className="col piik-text-visual text-end">Current</div>
            <div className="col-1"></div>
            <div className="col piik-text-visual text-end">vs.Last Month</div>
            <div className="col-1"></div>
            <div className="col piik-text-visual text-end">vs.Last Year</div>
          </div>
        </>
      ) : null}
      {group.length > 0 &&
        group.map((gr) => {
          return (
            <>
              <div className="piik-text-dashboard px-4 py-2 text-uppercase fw-bold border-bottom text-primary">
                {gr.group}
              </div>
              {category.length > 0 &&
                category
                  .filter((d) => d.group === gr.group)
                  .map((cat, i) => (
                    <div
                      className="row border-bottom py-2 px-4 piik-pointer bg-light gx-4"
                      onClick={() => handleOpenModal(cat.id)}
                      key={`kpi-${i}`}
                    >
                      <div className="col-1 piik-text-visual text-uppercase fw-bold ">
                        {cat && cat.type && cat.type.charAt(0)}.{i + 1}
                      </div>
                      <div className="col-4 piik-text-visual ">
                        <span
                          className={`bi piik-icon ${
                            cat && cat.trendChartIndex === undefined
                              ? "bi-bar-chart-fill"
                              : cat.trendChartIndex === "1"
                              ? "bi-bar-chart-fill"
                              : "bi-bar-chart-fill"
                          } pe-2`}
                        ></span>{" "}
                        {cat && cat.name && cat.name}
                      </div>
                      <div className="col piik-text-visual text-end fw-semibold">
                        {calenderIndex > -1
                          ? cat &&
                            cat.data &&
                            cat.data[calenderIndex].value.toFixed(2)
                          : "NA"}
                      </div>
                      <div className="col-1"></div>
                      <div className="col piik-text-visual text-end fw-semibold row px-4">
                        <div className="col-6  text-end">
                          {calenderIndex - 1 > -1
                            ? cat &&
                              cat.data &&
                              cat.data[calenderIndex - 1].value.toFixed(2)
                            : "NA"}
                        </div>
                        <div className="col-6">
                          {cat &&
                            cat.data &&
                            handleTrendIcon(
                              cat.data,
                              calenderIndex,
                              calenderIndex - 1
                            )}
                        </div>
                      </div>
                      <div className="col-1"></div>
                      <div className="col piik-text-visual text-end fw-semibold row">
                        <div className="col-6  text-end">
                          {calenderIndex - 12 > -1
                            ? cat &&
                              cat.data &&
                              cat.data[calenderIndex - 12].value.toFixed(2)
                            : "NA"}
                        </div>
                        <div className="col-6">
                          {cat &&
                            cat.data &&
                            handleTrendIcon(
                              cat.data,
                              calenderIndex,
                              calenderIndex - 12
                            )}
                        </div>
                      </div>
                    </div>
                  ))}
            </>
          );
        })}

      <Modal
        isOpen={isModalOpen}
        closeModal={handleCloseModal}
        title={modalContent && modalContent.name}
        size="modal-xl"
      >
        <div className="" ref={kpiRef}>
          {modalContent.status ? (
            <>
              <div className="py-2 bg-light">
                <div className="font-monospace fw-semibold text-primary px-4 piik-text-dashboard ">
                  Formula
                </div>
                <div className="pt-2 font-monospace text-wrap text-break px-4 mb-4 piik-text-dashboard bg-light">
                  = {handleFormula(modalContent.selectedCategory)}
                </div>
              </div>

              <Plot
                data={modalContent.data}
                layout={modalContent.layout}
                config={config}
                className="w-100 piik-text-visual flex-grow-1 bg-white shadow-sm "
              />
            </>
          ) : (
            <div className="px-4 pb-4 fw-bold piik-text-visual">
              Supported Data Not Found
            </div>
          )}
        </div>
        <div
          className=" px-2 py-2 piik-text-visual piik-pointer text-end w-100"
          onClick={handleDownload}
        >
          <div
            className="bi bi-download text-primary px-4 piik-pointer"
            data-toggle="tooltip"
            data-placement="top"
            title="Download"
          >
            <span className="px-2">Download</span>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default KpiPlot;
