import React, { useState } from "react";
import AddSpreadSheet from "./AddSpreadSheet";
import { useDashData } from "../../hooks/useDash";

const AddAccount = () => {
  const { handleAddAccount, accountDetails, accountStatusError } =
    useDashData();
  const [accountType, setAccountType] = useState("");
  const handleInputChange = (event) => {
    const { value } = event.target;
    setAccountType(value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    handleAddAccount({
      name: accountDetails.name,
      email: accountDetails.email,
    });
  };

  return (
    <section className="py-2">
      <form>
        <div className="card p-4 d-flex flex-row">
          <div className="form-group pb-2 w-100 pe-2">
            <label className="piik-text-dashboard pb-2">Data Source</label>
            <select
              className="form-select form-select-sm bg-light"
              id="account"
              value={accountType}
              onChange={handleInputChange}
            >
              <option selected>Select</option>
              <option value="online">QuickBooks Online</option>
              <option value="csv">Spreadsheet/CSV</option>
            </select>
          </div>
        </div>
        {accountType === "online" ? (
          <div className="d-flex flex-row justify-content-between  py-2  align-items-start">
            {accountStatusError !== "" ? (
              <div className="piik-text-warning text-danger">
                {accountStatusError}
              </div>
            ) : (
              <div>.</div>
            )}
            <button
              type="handleSubmit"
              onClick={handleSubmit}
              className="btn btn-success px-4"
            >
              Connect
            </button>
          </div>
        ) : accountType === "desktop" ? (
          <div className="d-flex flex-row justify-content-end  py-2  align-items-end text-primary">
            Coming Soon
          </div>
        ) : accountType === "csv" ? (
          <div className="form-group pb-2 w-100 py-2 h-100">
            <AddSpreadSheet />
          </div>
        ) : null}
      </form>
    </section>
  );
};

export default AddAccount;
