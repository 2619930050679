import React, { useEffect, useState, useRef } from "react";

import { useDashData } from "../../../hooks/useDash";
import { removeDuplicatesObject } from "../Charts/Utility";
import {
  csvToArrayOfObjects,
  validateCSV,
  validateOldDataWithNewData,
  mergeOldDataWithNew,
} from "./Utility";

import {
  isValidFormula,
  handleFormulaValidation,
  FormulaDocumenation,
  checkDuplicateId,
  handleAddRemove,
  endpointValidation,
} from "../CustomDataUtility";

import EndpointTable from "./EndpointTable";

const CreateEndpoint = ({
  fullScreenStatus,
  updateVisualizationIndex,
  handleUpdateVisualizationIndex,
  handleVisualizationTab,
}) => {
  const {
    profile,
    adminAccountInfo,
    account,
    handleAddVisualization,
    handleUpdateVisualization,
    calenderData,
    handleFileUpload,
    handleCloudStorageAuthetication,
  } = useDashData();

  const fileInputRef = useRef(null);
  const [fileType, setFileType] = useState("");
  const [fileHeader, setFileHeaders] = useState([]);
  const [fileDatapoint, setFileDatapoint] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);

  const [error, setError] = useState({ message: "" });

  const [endpointModal, setEndpointModal] = useState(false);
  const [updatedData, setUpdatedData] = useState({});

  const [title, setTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedAccount, setSelectedAccount] = useState({});

  const [action, setAction] = useState("");
  const [errorActionMessage, setErrorActionMessage] = useState("");

  const [selectedReport, setSelectedReport] = useState("");
  const [selectedReportEndpoints, setSelectedReportEndpoints] = useState([]);
  const [errorMessageReport, setErrorMessageReport] = useState("");

  const [formula, setFormula] = useState("");
  const [errorFormulaMessage, setErrorFormulaMessage] = useState("");
  const [customEndpoint, setCustomEndpoint] = useState([
    { name: "EP_1", endpointId: "" },
  ]);
  const [datapointProperties, setDatapointProperties] = useState({
    tags: "",
    level: "",
    index: 0,
  });

  const [submitStatus, setSubmitStatus] = useState(false);
  const [reportCategories, setReportCategories] = useState([]);

  const [cloudStorageEndpoints, setCloudStorageEndpoints] = useState([]);
  const [cloudStorageSelectedEndpoints, setCloudStorageSelectedEndpoints] =
    useState([]);
  const [cloudStorageError, setCloudStorageError] = useState("");
  const [cloudDetails, setCloudDetails] = useState({
    cloud_storage: "",
    account_name: "",
    container_name: "",
    file_name: "",
    file_type: "",
    account_key: "",
    authenticate: false,
    status: false,
    date_endpoint: [],
  });
  const [tab, setTab] = useState("customer");
  const [query, setQuery] = useState([]);

  const handleDownload = async (e) => {
    const filename = e.target.id;
    const baseUrl = window.location.origin;
    const downloadFiles = async () => {
      await downloadFile(baseUrl, filename);
    };

    const downloadFile = async (baseUrl, filename) => {
      try {
        const fileUrl = baseUrl + "/sample/excel/" + filename + ".xlsx";
        const response = await fetch(fileUrl);
        const blob = await response.blob();

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.xlsx`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    };
    downloadFiles();
  };

  const [dropDown, setDropDown] = useState({
    table: null,
    date: null,
    data: null,
  });

  const handleDropDown = (key, value) => {
    setDropDown((prevData) => {
      if (key === "table") {
        let newData = {
          table: value,
          date: null,
          data: null,
        };
        return newData;
      } else if (key !== "table") {
        let newData = {
          ...prevData,
          [key]: value,
        };
        return newData;
      }
    });
  };

  const handleAssignDate = (event) => {
    const name = event.target.name;
    const id = event.target.id;
    setCloudDetails((prevData) => {
      setCloudStorageError("");
      const date_endpoint = { table: name, endpoint: id };
      if (prevData.date_endpoint.length === 0) {
        let updated = {
          ...prevData,
          date_endpoint: [date_endpoint],
        };
        return updated;
      } else {
        const oldEndpoint = prevData.date_endpoint.filter(
          (d) => d.table !== name
        );
        let updated = {
          ...prevData,
          date_endpoint: [...oldEndpoint, date_endpoint],
        };
        return updated;
      }
    });
  };

  const handleCloudStorage = (e) => {
    const { id, value } = e.target;
    setCloudDetails((prevData) => {
      let updated = {
        ...prevData,
        [id]: value,
        status: true,
        authenticate: false,
      };
      setCloudStorageError("");
      setCloudStorageEndpoints(() => []);
      for (let key in updated) {
        if (
          key !== "authenticate" &&
          key !== "date_endpoint" &&
          updated[key] === ""
        ) {
          updated = { ...updated, status: false };
        }
      }
      return updated;
    });
  };

  const handleCloudStorageEndpointSelection = (e) => {
    const { id } = e.target;
    if (e.target.checked) {
      let selectedCategory = cloudStorageEndpoints.filter(
        (k) => k.key_name === id
      );

      if (selectedCategory.length > 0) {
        let newData = [...cloudStorageSelectedEndpoints, ...selectedCategory];
        setCloudStorageSelectedEndpoints(newData);
      }
    } else {
      let updateCategory = cloudStorageSelectedEndpoints.filter(
        (k) => k.key_name !== id
      );
      setCloudStorageSelectedEndpoints(updateCategory);
      setCloudStorageError("");
    }
  };

  const handleCloudStorageEndpointDataType = (e) => {
    const { id, value, name } = e.target;
    setCloudStorageSelectedEndpoints((prevData) => {
      const newCategoryUpdate = prevData.map((d) => {
        if (d.key_name === id) {
          d[name] = value;
          return d;
        } else {
          return d;
        }
      });
      setCloudStorageError("");
      return newCategoryUpdate;
    });
  };

  const handleCloudStorageAuth = () => {
    if (!cloudDetails.status) {
      setCloudStorageError("Field Value Missing");
      return;
    }
    setSubmitStatus(true);
    handleCloudStorageAuthetication(cloudDetails).then((response) => {
      if (response.status) {
        setCloudDetails(() => response.data);
        setCloudStorageEndpoints(() => response.endpoints);
        setCloudStorageError("");
        setSubmitStatus(false);
      } else {
        setCloudStorageError("Authentication Error");
        setSubmitStatus(false);
      }
    });
  };

  const handleTab = (value) => {
    setTab(value);
  };

  const handleDataTitle = (title) => {
    const title_text = title.replace(/[^a-zA-Z]/g, "").toLowerCase();
    const lib_text = [
      ...["generalledger", "balancesheet", "profitloss", "profitandloss"],
      ...reportCategories.map((d) => d.name),
    ];
    const tempIndex = lib_text.findIndex(
      (v) => v.replace(/[^a-zA-Z]/g, "").toLowerCase() === title_text
    );
    if (tempIndex > -1) {
      return true;
    } else {
      return false;
    }
  };

  const handleQuery = (type, id) => {
    setQuery((prevData) => {
      let tempIndex = prevData.findIndex((d) => d.id === id);
      if (tempIndex !== -1) {
        const filterData = prevData.filter((d) => d.id !== id);
        return filterData;
      } else {
        const newData = [...prevData, { type: type, id: id }];
        return newData;
      }
    });
  };

  //  Handling CSV Upload
  const handleFileChange = (event) => {
    if (title === "") {
      setErrorMessage("Field Data is Missing");
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      return;
    } else {
      const file = event.target.files[0];
      if (file) {
        if (file.type === "text/csv") {
          const reader = new FileReader();
          reader.onload = function (e) {
            const text = e.target.result;
            csvToArrayOfObjects(text, fileType).then((result) => {
              if (result && result.hasOwnProperty("header") && result.status) {
                const response = validateCSV(
                  result.header,
                  result.data,
                  fileType
                );
                if (response.status) {
                  if (
                    selectedReport.toLowerCase() !== "new" &&
                    updateVisualizationIndex !== null
                  ) {
                    let oldData = {
                      header: fileHeader,
                      datapoint: fileDatapoint,
                    };
                    let newData = {
                      header: result.header,
                      datapoint: result.datapoint,
                    };
                    if (
                      !validateOldDataWithNewData(oldData, newData, fileType)
                    ) {
                      if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                      }
                      setError({
                        type: "CSV",
                        message:
                          "Datapoint of the old file is not present in New file",
                      });
                      return;
                    }
                    //  Merge the old and new file info
                    setFileHeaders(
                      mergeOldDataWithNew(oldData, newData, "header")
                    );
                    setSelectedFile(result.data);
                    if (fileType !== "others") {
                      setFileDatapoint(
                        mergeOldDataWithNew(oldData, newData, "datapoint")
                      );
                    }
                    setError({ type: "", message: "" });
                  } else {
                    setFileHeaders(result.header);
                    setSelectedFile(result.data);
                    if (fileType !== "others") {
                      setFileDatapoint(result.datapoint);
                    }
                    setError({ type: "", message: "" });
                  }
                } else {
                  setFileDatapoint([]);
                  setError({
                    type: "CSV",
                    message: response.message,
                  });
                }
              } else {
                setFileDatapoint([]);
                setError({
                  type: "CSV",
                  message: "CSV file is empty or not properly formatted.",
                });
              }
            });
          };
          reader.onerror = function () {
            setError({
              type: "CSV",
              message: "Error reading the CSV file.",
            });
          };
          reader.readAsText(file);
        } else {
          setSelectedFile([]);
          setFileDatapoint([]);
          setError({
            type: "CSV",
            message: "Invalid file type. Please upload a CSV file.",
          });
        }
      }
    }
  };

  const handleFileTypeForCSV = (event) => {
    const { id, value } = event.target;
    setFileHeaders((prev) => {
      let updatedData = [...prev];
      let tempIndex = updatedData.findIndex((d) => d.title === id);
      updatedData[tempIndex].type = value;
      return updatedData;
    });
  };

  const handleDataSelectionForCSV = (event) => {
    const { id } = event.target;
    if (fileType === "others") {
      setFileHeaders((prev) => {
        let updatedData = [...prev];
        let tempIndex = updatedData.findIndex((d) => d.title === id);
        updatedData[tempIndex].status = !updatedData[tempIndex].status;
        return updatedData;
      });
    } else {
      setFileDatapoint((prev) => {
        let updatedData = [...prev];
        let tempIndex = updatedData.findIndex((d) => d.title === id);
        updatedData[tempIndex].status = !updatedData[tempIndex].status;
        return updatedData;
      });
    }
  };

  const handleUpload = () => {
    if (title === "") {
      setErrorMessage("Field Data is Missing");
      return;
    }
    if (selectedReport.toLowerCase() === "new" && handleDataTitle(title)) {
      setErrorMessage("Title already in use.");
      return;
    }
    if (selectedFile.length === 0) {
      setError({
        type: "CSV File",
        message: "Please upload at the CSV file.",
      });
      return;
    }
    if (fileType === "others") {
      let tempIndex = fileHeader.findIndex((d) => d.type === "" && d.status);
      if (tempIndex > -1) {
        setError({
          type: "CSV File",
          message: "Data Type missing for all datapoint",
        });
        return;
      }
    } else {
      let tempIndex = fileDatapoint.findIndex((d) => d.status);
      if (tempIndex === -1) {
        setError({
          type: "CSV File",
          message: "Minimum One Datapoint required",
        });
        return;
      }
    }

    setSubmitStatus(true);
    let accountIndex = adminAccountInfo.findIndex(
      (data) => data.uid === account
    );
    let selectedAccountData = adminAccountInfo[accountIndex];
    const uploadData = {
      id: updatedData.id || "",
      createdBy: profile.user_email,
      userId: profile.user_id,
      accountId: selectedAccountData.uid,
      files: {
        type: fileType,
        header: fileHeader,
        data: selectedFile,
        datapoint: fileDatapoint,
      },
      requestType: selectedReport.toLowerCase(),
      action: action,
      title: title,
    };
    handleFileUpload(uploadData).then((response) => {
      if (response.status) {
        setTitle("");
        fileInputRef.current.value = "";
        setSelectedFile([]);
        handleClear("all");
        setError({ message: "" });
        setSubmitStatus(false);
        if (updateVisualizationIndex !== null) {
          setSubmitStatus(false);
          handleVisualizationTab(updateVisualizationIndex);
          handleUpdateVisualizationIndex(null);
        }
      } else {
        setError({ message: response.message });
        setSubmitStatus(false);
      }
    });
  };

  const handleUpdate = () => {
    if (title === "") {
      setErrorMessage("Field Data is Missing");
      return;
    }
    if (action !== "cloud" && handleDataTitle(title)) {
      setErrorMessage("Title already in use.");
      return;
    }
    if (action === "") {
      setErrorActionMessage("Field Data is Missing");
      return;
    }
    if (action !== "cloud" && selectedReport === "") {
      setErrorMessageReport("Field Data is Missing");
      return;
    }
    if (action === "new" && !isValidFormula(formula)) {
      setErrorFormulaMessage("Incorrect Formula.Please Check");
      return;
    }
    if (action === "new" && endpointValidation(customEndpoint)) {
      setErrorFormulaMessage("Please check the valid endpoint");
      return;
    }
    if (action === "new" && checkDuplicateId(customEndpoint)) {
      setErrorFormulaMessage("Please remove the Duplicate Endpoint");
      return;
    }
    if (action === "group" && selectedReportEndpoints.length === 0) {
      setErrorMessageReport("Please select valid endpoint");
      return;
    }
    if (action === "query" && query.length === 0) {
      setErrorMessageReport("Please select at least one query parameter");
      return;
    }
    if (action === "cloud" && cloudStorageSelectedEndpoints.length === 0) {
      setCloudStorageError("Please add at least one datapoint");
      return;
    }
    if (action === "cloud" && cloudDetails.date_endpoint.length === 0) {
      setCloudStorageError("Please assign the Date key for each table");
      return;
    }
    if (
      action === "cloud" &&
      cloudStorageSelectedEndpoints.findIndex(
        (d) =>
          d.assignValueType === "" ||
          (d.assignValueType === "float" && d.groupBy === "")
      ) > -1
    ) {
      setCloudStorageError(
        "Please select the  Data formate type and aggerate By for all selected datapoint"
      );
      return;
    }

    setSubmitStatus(true);
    let accountIndex = adminAccountInfo.findIndex(
      (data) => data.uid === account
    );
    let selectedAccountData = adminAccountInfo[accountIndex];
    let requestData = {};
    if (action === "query") {
      requestData = {
        userId: profile.user_id,
        accountId: selectedAccountData.uid,
        customQueryId: updatedData.id,
        dataSettings: {
          action: action,
          category: selectedReport,
          query: query,
        },
        dataName: title,
        createdBy: profile.user_email,
        visualizationType: "EndpointQuery",
      };
    } else if (action === "cloud") {
      requestData = {
        userId: profile.user_id,
        accountId: selectedAccountData.uid,
        customDataId: updatedData.id,
        dataSettings: {
          action: action,
          details: cloudDetails,
          baseEndpoint: cloudStorageEndpoints,
          endpoints: cloudStorageSelectedEndpoints,
        },
        dataName: title,
        createdBy: profile.user_email,
        visualizationType: "cloudEndpoint",
      };
    } else {
      requestData = {
        userId: profile.user_id,
        accountId: selectedAccountData.uid,
        customDataId: updatedData.id,
        dataSettings: {
          action: action,
          level: updatedData.level || 1,
          tags: updatedData.tags || "S",
          index: updatedData.index || 0,
          status: updatedData.status,
          category: selectedReport,
          data_type: updatedData.data_type,
          formula: formula,
          data_endpoints:
            action === "group" ? selectedReportEndpoints : customEndpoint,
        },
        dataName: title,
        createdBy: profile.user_email,
        visualizationType: "Endpoint",
      };
    }
    handleUpdateVisualization(requestData)
      .then((response) => {
        if (response.status) {
          handleClear("all");
        } else {
          if (action === "cloud") {
            setCloudStorageError(response.message);
          }
        }
        setSubmitStatus(false);
        handleVisualizationTab(updateVisualizationIndex);
        handleUpdateVisualizationIndex(null);
      })
      .catch((error) => {
        setSubmitStatus(false);
      });
  };

  const handleSubmit = () => {
    if (title === "") {
      setErrorMessage("Field Data is Missing");
      return;
    }
    if (handleDataTitle(title)) {
      setErrorMessage("Title already in use.");
      return;
    }

    if (action === "") {
      setErrorActionMessage("Field Data is Missing");
      return;
    }
    if (action !== "cloud" && selectedReport === "") {
      setErrorMessageReport("Field Data is Missing");
      return;
    }
    if (action === "new" && !isValidFormula(formula)) {
      setErrorFormulaMessage("Incorrect Formula.Please Check");
      return;
    }
    if (action === "new" && endpointValidation(customEndpoint)) {
      setErrorFormulaMessage("Please select all the endpoint");
      return;
    }
    if (action === "new" && checkDuplicateId(customEndpoint)) {
      setErrorFormulaMessage("Please remove the Duplicate Endpoint");
    }
    if (action === "group" && selectedReportEndpoints.length === 0) {
      setErrorMessageReport("Please select valid endpoint");
      return;
    }
    if (action === "query" && query.length === 0) {
      setErrorMessageReport("Please select at least one query parameter");
      return;
    }
    if (action === "cloud" && cloudStorageSelectedEndpoints.length === 0) {
      setCloudStorageError("Please add at least one datapoint");
      return;
    }
    if (action === "cloud" && cloudDetails.date_endpoint.length === 0) {
      setCloudStorageError("Please assign the Date key for each table");
      return;
    }
    if (
      action === "cloud" &&
      cloudStorageSelectedEndpoints.findIndex(
        (d) =>
          d.assignValueType === "" ||
          (d.assignValueType === "float" && d.groupBy === "")
      ) > -1
    ) {
      setCloudStorageError(
        "Please select the  Data formate type and aggerate By for all selected datapoint"
      );
      return;
    }

    setSubmitStatus(true);
    let accountIndex = adminAccountInfo.findIndex(
      (data) => data.uid === account
    );
    let selectedAccountData = adminAccountInfo[accountIndex];
    let requestData = {};
    if (action === "query") {
      requestData = {
        userId: profile.user_id,
        accountId: selectedAccountData.uid,
        dataSettings: {
          action: action,
          category: selectedReport,
          query: query,
        },
        dataName: title,
        createdBy: profile.user_email,
        visualizationType: "EndpointQuery",
      };
    } else if (action === "cloud") {
      requestData = {
        userId: profile.user_id,
        accountId: selectedAccountData.uid,
        dataSettings: {
          action: action,
          details: cloudDetails,
          baseEndpoint: cloudStorageEndpoints,
          endpoints: cloudStorageSelectedEndpoints,
        },
        dataName: title,
        createdBy: profile.user_email,
        visualizationType: "cloudEndpoint",
      };
    } else {
      requestData = {
        userId: profile.user_id,
        accountId: selectedAccountData.uid,
        dataSettings: {
          action: action,
          category: selectedReport,
          formula: formula,
          data_endpoints:
            action === "group" ? selectedReportEndpoints : customEndpoint,
        },
        dataName: title,
        tags: datapointProperties.tags,
        level: datapointProperties.level,
        index: datapointProperties.index,
        createdBy: profile.user_email,
        visualizationType: "Endpoint",
      };
    }

    handleAddVisualization(requestData)
      .then((response) => {
        if (response.status) {
          handleClear("all");
        } else {
          if (action === "cloud") {
            setCloudStorageError(response.message);
          }
        }
        setSubmitStatus(false);
        handleUpdateVisualizationIndex(null);
      })
      .catch((error) => {
        setSubmitStatus(false);
      });
  };

  const handleInput = (e) => {
    const { id, value } = e.target;
    if (id === "title") {
      if (title.length > 25) {
        const truncatedValue = value.slice(0, 25);
        setTitle(truncatedValue);
        setErrorMessage("Maximum character limit reached");
      } else {
        setTitle(value);
        setErrorMessage("");
      }
    } else if (id === "fileType") {
      setFileType(value);
      setSelectedFile([]);
      setFileHeaders([]);
      setFileDatapoint([]);
      setError({ type: "", message: "" });
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } else if (id === "action") {
      setAction(value);
      setErrorActionMessage("");
      setSelectedReport("");
      setSelectedReportEndpoints([]);
    } else if (id === "report") {
      if (action === "upload" && value.toLowerCase() !== "new") {
        setTitle(value);
      }
      setSelectedReport(value);
      setQuery([]);
      setErrorMessageReport("");
      setErrorMessage("");
      setSelectedReportEndpoints([]);
    } else if (id === "formula") {
      let result = handleFormulaValidation(value);
      setErrorFormulaMessage("");
      if (result !== "NA") {
        setFormula(result);
      }
    } else if (id.indexOf("EP_") !== -1) {
      setCustomEndpoint((prev) => {
        const prevData = [...prev];
        const newData = [...prevData].map((obj) => {
          if (obj.name === id) {
            const newObj = { ...obj, endpointId: value };
            return newObj;
          }
          return obj;
        });
        return newData;
      });
    } else if (id === "level" || id === "tags") {
      setDatapointProperties((prev) => ({ ...prev, [id]: value }));
    } else {
      if (e.target.checked) {
        setSelectedReportEndpoints((prevData) => {
          const data = selectedAccount.data.filter((d) => d.id === id);
          const updateData = [
            ...prevData,
            ...data.map((d) => ({
              id: d.id,
            })),
          ];
          return updateData;
        });
      } else {
        setSelectedReportEndpoints((prevData) => {
          const data = prevData.filter((d) => d.id !== id);
          return data;
        });
      }
    }
  };

  const handleClear = (id) => {
    if (id === "all") {
      setError({ type: "", message: "" });
      setTitle("");
      setAction("");
      setSelectedReport("");
      setSelectedReportEndpoints([]);
      setCustomEndpoint([{ name: "EP_1", endpointId: "" }]);
      setFormula("");
      setUpdatedData({});
      setDatapointProperties({ tags: "", level: "", index: 0 });
      setQuery([]);
      setFileType("");
      setFileDatapoint([]);
      setSelectedFile([]);
      setError({ type: "", message: "" });
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } else if (id === "rep") {
      setSelectedReport("");
      setSelectedReportEndpoints([]);
      setCustomEndpoint([{ name: "EP_1", endpointId: "" }]);
      setFormula("");
      setQuery([]);
      setSelectedFile([]);
    } else if (id === "act") {
      setAction("");
      setSelectedReport("");
      setSelectedReportEndpoints([]);
      setCustomEndpoint([{ name: "EP_1", endpointId: "" }]);
      setFormula("");
      setQuery([]);
      setSelectedFile([]);
    }
  };

  useEffect(() => {
    const accountIndex = adminAccountInfo.findIndex(
      (data) => data.uid === account
    );
    const selectedAccountData = adminAccountInfo[accountIndex];
    setSelectedAccount(selectedAccountData);
    setReportCategories(() => {
      const uniqueData = removeDuplicatesObject(
        selectedAccountData.data,
        "category"
      );
      const report_list = uniqueData
        .filter((d) => d.category !== "Date")
        .map((d) => {
          const { category, created, name_code, record_type, code } = d;
          let action = "";
          const tempIndex = selectedAccountData.custom_data.findIndex(
            (cust) => cust.name === category
          );
          if (tempIndex > -1) {
            action = selectedAccountData.custom_data[tempIndex].action;
          }
          return {
            name: category,
            type: created,
            name_code,
            code,
            record_type,
            action,
          };
        });

      return report_list;
    });
  }, [adminAccountInfo, account]);

  useEffect(() => {
    const accountIndex = adminAccountInfo.findIndex(
      (data) => data.uid === account
    );
    const selectedAccountData = JSON.parse(
      JSON.stringify(adminAccountInfo[accountIndex])
    );
    setSelectedAccount(selectedAccountData);
    if (updateVisualizationIndex !== null) {
      let selectedCustomData =
        selectedAccountData.custom_data[updateVisualizationIndex];

      if (selectedCustomData && selectedCustomData.action) {
        if (selectedCustomData.action === "new") {
          setTitle(selectedCustomData.name);
          setAction(selectedCustomData.action);
          setSelectedReport(selectedCustomData.category);
          setCustomEndpoint(selectedCustomData.data_endpoints);
          setFormula(selectedCustomData.formula);
          setDatapointProperties({
            tags: selectedCustomData.tags,
            level: selectedCustomData.level,
          });
          setUpdatedData(selectedCustomData);
        }
        if (selectedCustomData.action === "query") {
          setTitle(selectedCustomData.name);
          setAction(selectedCustomData.action);
          setSelectedReport(selectedCustomData.category);
          setQuery(selectedCustomData.query_endpoints);
          setUpdatedData(selectedCustomData);
        }
        if (selectedCustomData.action === "cloud") {
          setUpdatedData(selectedCustomData);
          setTitle(selectedCustomData.name);
          setAction(selectedCustomData.action);
          setCloudStorageEndpoints(selectedCustomData.base_endpoint);
          setCloudStorageSelectedEndpoints(selectedCustomData.endpoints);
          setCloudDetails(selectedCustomData.authentication_details);
        }
        if (selectedCustomData.action === "upload") {
          setTitle(selectedCustomData.name);
          setAction(selectedCustomData.action);
          setSelectedReport(selectedCustomData.name);
          setFileDatapoint(selectedCustomData.settings.datapoint);
          setFileHeaders(selectedCustomData.settings.header);
          setFileType(selectedCustomData.settings.type);
          setUpdatedData(selectedCustomData);
        }
      } else {
        handleUpdateVisualizationIndex(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAccountInfo, account]);

  return (
    <React.Fragment>
      <div
        className={
          fullScreenStatus
            ? "d-flex flex-row justify-content-between  bg-light piik-visual-section-fullscreen piik-create-chart "
            : "d-flex flex-row justify-content-between  bg-light piik-visual-section piik-create-chart "
        }
      >
        <div className="flex-grow-1 overflow-y-scroll">
          <div className="d-flex flex-column  h-100 w-100">
            {action === "group" && (
              <EndpointTable
                action={action}
                selectedAccount={selectedAccount}
                selectedReportEndpoints={selectedReportEndpoints}
                calenderData={calenderData}
              />
            )}
            {action === "new" && (
              <EndpointTable
                action={action}
                selectedAccount={selectedAccount}
                customEndpoint={customEndpoint}
                calenderData={calenderData}
                formula={formula}
              />
            )}
            {action === "query" && (
              <EndpointTable
                action={action}
                selectedAccount={selectedAccount}
                customEndpoint={{
                  query_endpoints: query,
                  category: selectedReport,
                }}
                calenderData={calenderData}
                type="create"
              />
            )}
            {action === "cloud" && (
              <EndpointTable
                action={action}
                selectedAccount={selectedAccount}
                customEndpoint={{
                  query_endpoints: cloudStorageSelectedEndpoints,
                  category: cloudDetails,
                }}
                type="create"
                handleData={handleCloudStorageEndpointDataType}
              />
            )}
            {action === "upload" && (
              <EndpointTable
                action={action}
                fileType={fileType}
                customEndpoint={
                  fileType === "others" ? fileHeader : fileDatapoint
                }
                type="create"
                handleData={handleFileTypeForCSV}
                handleDataSelection={handleDataSelectionForCSV}
              />
            )}
          </div>
        </div>

        <div
          className="  bg-light shadow-sm d-flex flex-column justify-content-start w-25 piik-create-bar "
          style={{ zIndex: 10 }}
        >
          {/* Title */}
          <div className=" px-3 py-2 piik-text-dashboard border-bottom ">
            <div className=" d-flex flex-row justify-content-between align-items-center">
              <div className="fw-semibold text-primary"> Set Title</div>
              {updateVisualizationIndex === null && (
                <i
                  className="bi bi-eraser piik-pointer"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Clear all"
                  id="all"
                  onClick={() => handleClear("all")}
                ></i>
              )}
            </div>
            <div className="my-2">
              <input
                type="text"
                className="form-control form-control-sm w-100"
                id="title"
                placeholder="Max 25 Character"
                value={title}
                onChange={handleInput}
                disabled={updateVisualizationIndex !== null ? true : false}
              />
              {errorMessage !== "" && (
                <div className="pt-1 text-danger piik-text-warning">
                  {errorMessage}
                </div>
              )}
            </div>
          </div>

          {/* Data Management Operations */}
          <div className=" px-3 py-2 piik-text-dashboard border-bottom">
            <div className=" d-flex flex-row justify-content-between align-items-center">
              <div className="fw-semibold text-primary">
                Data Management Operations
              </div>
              {updateVisualizationIndex === null && (
                <i
                  className="bi bi-eraser piik-pointer"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Clear Action"
                  id="act"
                  onClick={() => handleClear("act")}
                ></i>
              )}
            </div>
            <div>
              <div className="my-2">
                <select
                  className="form-select form-select-sm w-100 "
                  id="action"
                  value={action}
                  onChange={handleInput}
                  disabled={updateVisualizationIndex !== null ? true : false}
                >
                  <option value="" selected>
                    Select
                  </option>
                  <option value="new">Create Custom Data</option>
                  {selectedAccount &&
                    selectedAccount.account_name === "quickbooks" && (
                      <option value="query">Create QuickBooks Query</option>
                    )}
                  <option value="upload">Upload CSV </option>
                  <option value="cloud">Connect Cloud Storage</option>
                </select>
                {errorActionMessage !== "" && (
                  <div className="pt-1 text-danger piik-text-warning">
                    {errorActionMessage}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Operation Type */}
          {(action === "new" || action === "query" || action === "upload") && (
            <div className=" px-3 py-2 piik-text-dashboard border-bottom ">
              <div className=" d-flex flex-row justify-content-between align-items-center">
                <div className="fw-semibold text-primary">Report Category</div>{" "}
                {updateVisualizationIndex === null && (
                  <i
                    className="bi bi-eraser piik-pointer"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Clear Report"
                    id="rep"
                    onClick={() => handleClear("rep")}
                    disabled={
                      action === "upload" &&
                      selectedReport.toLowerCase() !== "new"
                        ? true
                        : false
                    }
                  ></i>
                )}
              </div>
              {action === "query" ? (
                <div className="my-2">
                  <select
                    className="form-select form-select-sm w-100 "
                    id="report"
                    value={selectedReport}
                    onChange={handleInput}
                  >
                    <option selected value="">
                      Select
                    </option>
                    <option value="Balance Sheet">
                      <span className="text-uppercase">Balance Sheet</span>
                    </option>
                    <option value="Profit And Loss">
                      <span className="text-uppercase">Profit & Loss</span>
                    </option>
                    <option value="General Ledger">
                      <span className="text-uppercase">General Ledger</span>
                    </option>
                  </select>
                  {errorMessageReport !== "" && (
                    <div className="pt-1 text-danger piik-text-warning">
                      {errorMessageReport}
                    </div>
                  )}
                </div>
              ) : action === "upload" &&
                selectedAccount &&
                selectedAccount.account_name === "quickbooks" ? (
                <div className="my-2">
                  <select
                    className="form-select form-select-sm w-100 "
                    id="report"
                    value={selectedReport}
                    onChange={handleInput}
                    disabled={updateVisualizationIndex !== null ? true : false}
                  >
                    <option selected value="">
                      Select
                    </option>
                    {reportCategories.length !== 0 &&
                      reportCategories
                        .filter((d) => d.type === "csv" && d.action !== "cloud")
                        .map((data) => {
                          return (
                            <option key={data.name} value={data.name}>
                              <span className="text-uppercase">
                                {data.name}
                              </span>
                            </option>
                          );
                        })}
                    <option value="New">
                      <span className="text-uppercase">New</span>
                    </option>
                  </select>
                  {errorMessageReport !== "" && (
                    <div className="pt-1 text-danger piik-text-warning">
                      {errorMessageReport}
                    </div>
                  )}
                </div>
              ) : action === "upload" &&
                selectedAccount &&
                selectedAccount.account_name !== "quickbooks" ? (
                <div className="my-2">
                  <select
                    className="form-select form-select-sm w-100 "
                    id="report"
                    value={selectedReport}
                    onChange={handleInput}
                    disabled={updateVisualizationIndex !== null ? true : false}
                  >
                    <option selected value="">
                      Select
                    </option>
                    {reportCategories.length !== 0 &&
                      reportCategories
                        .filter((d) => d.type === "csv" && d.action !== "cloud")
                        .map((data) => {
                          return (
                            <option key={data.name} value={data.name}>
                              <span className="text-uppercase">
                                {data.name}
                              </span>
                            </option>
                          );
                        })}
                    <option value="new">
                      <span className="text-uppercase">New</span>
                    </option>
                  </select>
                  {errorMessageReport !== "" && (
                    <div className="pt-1 text-danger piik-text-warning">
                      {errorMessageReport}
                    </div>
                  )}
                </div>
              ) : (
                <div className="my-2">
                  <select
                    className="form-select form-select-sm w-100 "
                    id="report"
                    value={selectedReport}
                    onChange={handleInput}
                  >
                    <option selected value="">
                      Select
                    </option>
                    {reportCategories.length !== 0 &&
                      reportCategories.map((data) => {
                        return (
                          <option key={data.name} value={data.name}>
                            <span className="text-uppercase">{data.name}</span>
                          </option>
                        );
                      })}
                  </select>
                  {errorMessageReport !== "" && (
                    <div className="pt-1 text-danger piik-text-warning">
                      {errorMessageReport}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {action === "cloud" && (
            <div className=" px-3 py-2 piik-text-dashboard border-bottom ">
              <div className=" d-flex flex-row justify-content-between align-items-center">
                <div className="fw-semibold text-primary">Cloud Platform</div>
                <i
                  className="bi bi-eraser piik-pointer"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Clear Report"
                  id="rep"
                  onClick={() => handleClear("rep")}
                ></i>
              </div>
              <div className="my-2">
                <select
                  className="form-select form-select-sm w-100 "
                  id="cloud_storage"
                  value={cloudDetails.cloud_storage}
                  onChange={handleCloudStorage}
                  disabled={
                    action === "cloud" && updateVisualizationIndex !== null
                      ? true
                      : false
                  }
                >
                  <option selected value="">
                    Select
                  </option>
                  <option selected value="azure">
                    Microsoft Azure
                  </option>
                  <option selected value="amazon">
                    Amazon Web Serivce
                  </option>
                  <option selected value="google">
                    Google Cloud
                  </option>
                </select>
                {cloudStorageError !== "" ? (
                  <div className="pt-1 piik-text-visual text-danger">
                    {cloudStorageError}
                  </div>
                ) : cloudDetails.authenticate ? null : (
                  <div className="pt-1 piik-text-visual">
                    There is a $25 charge for each new data source added to the
                    dataset.
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Operation Details */}
          {/* Operation Details - datapoint*/}
          {action === "new" && (
            <div className="d-flex flex-column justify-content-between overflow-y-scroll ">
              <div className="px-3 piik-text-dashboard  piik-text-visual">
                <div className="my-1 ">Datapoint</div>
                <select
                  className="form-select form-select-sm w-100 mb-2 "
                  id="report"
                  value={selectedReport}
                  onChange={handleInput}
                >
                  <option selected value="">
                    Select
                  </option>
                  {reportCategories.length !== 0 &&
                    reportCategories.map((data) => {
                      return (
                        <option key={data.code} value={data.code}>
                          <span className="text-uppercase">{data.name}</span>
                        </option>
                      );
                    })}
                </select>
                {selectedReport !== "General Ledger" && (
                  <>
                    <div className="my-1 ">Tags</div>
                    <select
                      className="form-select form-select-sm "
                      id="tags"
                      value={datapointProperties.tags}
                      onChange={handleInput}
                    >
                      <option selected value="">
                        Select
                      </option>
                      <option value="R">Rows</option>
                      <option value="S">Summary</option>
                    </select>
                  </>
                )}
              </div>

              {selectedReport !== "General Ledger" && (
                <div className="px-3 mt-1 piik-text-dashboard  piik-text-visual">
                  <div className="my-1 "> Indentation level</div>
                  <select
                    className="form-select form-select-sm "
                    id="level"
                    value={datapointProperties.level}
                    onChange={handleInput}
                  >
                    <option selected value="">
                      Select
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                </div>
              )}

              <div className="px-3 py-2 piik-text-dashboard  piik-text-visual">
                <div className="d-flex flex-row justify-content-between">
                  <div className="mb-2">Create Formula </div>
                  <i
                    className="bi bi-info-circle piik-pointer"
                    onClick={() => setEndpointModal(true)}
                  ></i>
                </div>
                <textarea
                  className="form-control"
                  id="formula"
                  value={formula.toUpperCase()}
                  style={{ resize: "none" }}
                  rows="3"
                  onChange={handleInput}
                  placeholder="(EP_1+EP_2)*(EP_3/EP_4)"
                ></textarea>
                {errorFormulaMessage !== "" && (
                  <div className="pt-1 text-danger piik-text-warning">
                    {errorFormulaMessage}
                  </div>
                )}
              </div>

              {customEndpoint.map((endpointData, index) => {
                return (
                  <div className="mb-2 px-3" key={index}>
                    <div className="d-flex flex-row justify-content-between align-items-center py-1">
                      <div className="text-primary piik-text-visual">
                        {endpointData.name}
                      </div>
                      <div>
                        {index === 0 ? null : (
                          <span
                            className=" px-3 bi bi-trash piik-pointer piik-text-visual"
                            onClick={() =>
                              handleAddRemove(
                                "remove",
                                endpointData.name,
                                setCustomEndpoint
                              )
                            }
                          ></span>
                        )}

                        <span
                          className="  bi bi-plus-square  piik-pointer piik-text-visual"
                          onClick={() =>
                            handleAddRemove("add", null, setCustomEndpoint)
                          }
                        ></span>
                      </div>
                    </div>
                    <select
                      className="form-select form-select-sm "
                      id={endpointData.name}
                      value={endpointData.endpointId}
                      onChange={handleInput}
                    >
                      <option selected value="">
                        Select
                      </option>
                      {selectedAccount &&
                        selectedAccount.data &&
                        selectedAccount.data.length > 0 &&
                        selectedAccount.data
                          .filter((d) => d.code === selectedReport)
                          .filter((d) => d.tags !== "H")
                          .filter((d) => d.data_type !== "str")
                          .map((endpoint, index) => {
                            return (
                              <option value={endpoint.id}>
                                {endpoint.name}
                                {endpoint.created &&
                                endpoint.created === "custom"
                                  ? " *"
                                  : null}
                              </option>
                            );
                          })}
                    </select>
                    {errorMessageReport !== "" && (
                      <div className="pt-1 text-danger piik-text-warning">
                        {errorMessageReport}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}

          {/* Operation Details - qbooks*/}
          {action === "query" &&
            selectedReport !== "" &&
            selectedReport !== "General Ledger" && (
              <div className="d-flex flex-column justify-content-start overflow-y-scroll h-100  piik-text-visual">
                <div className=" d-flex flex-row justify-content-between px-3 py-2 border-bottom w-100 ">
                  <div className="fw-semibold">Query By Customers</div>
                  {tab === "customer" ? (
                    <i className="bi bi-chevron-up  piik-pointer"></i>
                  ) : (
                    <i
                      className="bi bi-chevron-down  piik-pointer"
                      onClick={() => handleTab("customer")}
                    ></i>
                  )}
                </div>
                <div className=" px-3 piik-text-dashboard overflow-y-scroll">
                  {tab === "customer" &&
                    selectedAccount &&
                    selectedAccount.customer &&
                    selectedAccount.customer.length > 0 &&
                    selectedAccount.customer.map((d, index) => {
                      return (
                        <div className="d-flex flex-row justify-content-between align-self-center py-2">
                          <div className="piik-text-visual text-capitalize">
                            {d.name}
                          </div>
                          <div
                            className="form-check form-switch "
                            style={{ minHeight: "0rem" }}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id={d.id}
                              onChange={() => handleQuery("customer", d.id)}
                              checked={
                                query.findIndex((e) => e.id === d.id) > -1
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>

                <div className=" d-flex flex-row justify-content-between px-3 py-2 border-bottom border-top w-100 ">
                  <div className="fw-semibold">Query By Items</div>
                  {tab === "item" ? (
                    <i className="bi bi-chevron-up piik-pointer"></i>
                  ) : (
                    <i
                      className="bi bi-chevron-down  piik-pointer"
                      onClick={() => handleTab("item")}
                    ></i>
                  )}
                </div>
                <div className=" px-3 piik-text-dashboard overflow-y-scroll">
                  {tab === "item" &&
                    selectedAccount &&
                    selectedAccount.item &&
                    selectedAccount.item.length > 0 &&
                    selectedAccount.item.map((d, index) => {
                      return (
                        <div className="d-flex flex-row justify-content-between align-self-center py-2">
                          <div className="piik-text-visual text-capitalize">
                            {d.name}
                          </div>
                          <div
                            className="form-check form-switch "
                            style={{ minHeight: "0rem" }}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id={d.id}
                              onChange={() => handleQuery("item", d.id)}
                              checked={
                                query.findIndex((e) => e.id === d.id) > -1
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>

                <div className=" d-flex flex-row justify-content-between px-3 py-2 border-bottom border-top w-100 ">
                  <div className="fw-semibold">Query By Class</div>
                  {tab === "class" ? (
                    <i className="bi bi-chevron-up  piik-pointer"></i>
                  ) : (
                    <i
                      className="bi bi-chevron-down  piik-pointer"
                      onClick={() => handleTab("class")}
                    ></i>
                  )}
                </div>
                <div className=" px-3 piik-text-dashboard overflow-y-scroll">
                  {tab === "class" &&
                    selectedAccount &&
                    selectedAccount.class &&
                    selectedAccount.class.length > 0 &&
                    selectedAccount.class.map((d, index) => {
                      return (
                        <div className="d-flex flex-row justify-content-between align-self-center py-2">
                          <div className="piik-text-visual text-capitalize">
                            {d.name}
                          </div>
                          <div
                            className="form-check form-switch "
                            style={{ minHeight: "0rem" }}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id={d.id}
                              onChange={() => handleQuery("class", d.id)}
                              checked={
                                query.findIndex((e) => e.id === d.id) > -1
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

          {action === "query" &&
            selectedReport !== "" &&
            selectedReport === "General Ledger" && (
              <div className="d-flex flex-column justify-content-start overflow-y-scroll h-100  piik-text-visual">
                <div className=" d-flex flex-row justify-content-between px-3 py-2 border-bottom w-100 ">
                  <div className="fw-semibold">Query By Customers</div>
                  {tab === "customer" ? (
                    <i className="bi bi-chevron-up  piik-pointer"></i>
                  ) : (
                    <i
                      className="bi bi-chevron-down  piik-pointer"
                      onClick={() => handleTab("customer")}
                    ></i>
                  )}
                </div>
                <div className=" px-3 piik-text-dashboard overflow-y-scroll">
                  {tab === "customer" &&
                    selectedAccount &&
                    selectedAccount.customer &&
                    selectedAccount.customer.length > 0 &&
                    selectedAccount.customer.map((d, index) => {
                      return (
                        <div className="d-flex flex-row justify-content-between align-self-center py-2">
                          <div className="piik-text-visual text-capitalize">
                            {d.name}
                          </div>
                          <div
                            className="form-check form-switch "
                            style={{ minHeight: "0rem" }}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id={d.id}
                              onChange={() => handleQuery("customer", d.id)}
                              checked={
                                query.findIndex((e) => e.id === d.id) > -1
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>

                <div className=" d-flex flex-row justify-content-between px-3 py-2 border-bottom border-top w-100 ">
                  <div className="fw-semibold">Query By Financial Account</div>
                  {tab === "financialAccount" ? (
                    <i className="bi bi-chevron-up piik-pointer"></i>
                  ) : (
                    <i
                      className="bi bi-chevron-down  piik-pointer"
                      onClick={() => handleTab("financialAccount")}
                    ></i>
                  )}
                </div>
                <div className=" px-3 piik-text-dashboard overflow-y-scroll">
                  {tab === "financialAccount" &&
                    selectedAccount &&
                    selectedAccount.financial_account &&
                    selectedAccount.financial_account.length > 0 &&
                    selectedAccount.financial_account.map((d, index) => {
                      return (
                        <div className="d-flex flex-row justify-content-between align-self-center py-2">
                          <div className="piik-text-visual text-capitalize">
                            {d.name}
                          </div>
                          <div
                            className="form-check form-switch "
                            style={{ minHeight: "0rem" }}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id={d.id}
                              onChange={() =>
                                handleQuery("financialAccount", d.id)
                              }
                              checked={
                                query.findIndex((e) => e.id === d.id) > -1
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}

          {/* Operation Details - spreadsheet*/}
          {action === "upload" && selectedReport !== "" && (
            <div className="my-1 px-3">
              {selectedReport.toLowerCase() === "new" ? (
                <div className="  pb-2 d-flex flex-row justify-content-between align-items-end py-2">
                  <div className="fw-semibold text-primary piik-text-dashboard">{`Upload ${selectedReport}`}</div>
                  <div
                    className="piik-pointer  text-primary piik-text-visual"
                    id="samplecsv"
                    onClick={handleDownload}
                  >
                    <i className="bi bi-download px-2"></i> Samples
                  </div>
                </div>
              ) : (
                <div className="fw-semibold text-primary piik-text-dashboard pb-2">
                  {`Update ${selectedReport}`}
                </div>
              )}

              {selectedReport.toLowerCase() === "new" && (
                <select
                  className="form-select form-select-sm w-100 mb-2 "
                  id="fileType"
                  value={fileType}
                  onChange={handleInput}
                >
                  <option value="" selected>
                    Select Report Type
                  </option>
                  <option value="balanceSheet">Balance Sheet </option>
                  <option value="profit&loss">Income Statement </option>
                  <option value="budgetSheet">Budget Sheet</option>
                  <option value="others">Others</option>
                </select>
              )}

              {selectedReport.toLowerCase() !== "new" ? (
                <input
                  className="form-control form-control-sm"
                  type="file"
                  id={selectedReport}
                  accept=".csv"
                  multiple={false}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
              ) : fileType !== "" ? (
                <input
                  className="form-control form-control-sm"
                  type="file"
                  id={selectedReport}
                  accept=".csv"
                  multiple={false}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
              ) : null}

              {error && error.message !== "" && (
                <div className="pt-1 text-danger piik-text-warning">
                  {error.message}
                </div>
              )}
              {selectedReport.toLowerCase() === "new" ? (
                <div className="pt-1 piik-text-visual">
                  There is a $25 charge for each new data point added to the
                  dataset.
                </div>
              ) : (
                <div className="pt-1 piik-text-visual">
                  Uploading new {selectedReport} data will update the existing{" "}
                  {selectedReport} data
                </div>
              )}
            </div>
          )}

          {/* Operation Details - cloud*/}
          {action === "cloud" &&
            cloudDetails.cloud_storage === "azure" &&
            cloudStorageEndpoints.length === 0 && (
              <div className=" px-3">
                <div className="my-2">
                  <div className="piik-text-visual mb-1 ">Account Name</div>
                  <input
                    type="text"
                    className="form-control form-control-sm w-100"
                    id="account_name"
                    placeholder="Type here.."
                    value={cloudDetails.account_name}
                    onChange={handleCloudStorage}
                  />
                </div>
                <div className="my-2">
                  <div className="piik-text-visual mb-1 ">Account Key</div>
                  <input
                    type="password"
                    className="form-control form-control-sm w-100"
                    id="account_key"
                    placeholder="Type here.."
                    value={cloudDetails.account_key}
                    onChange={handleCloudStorage}
                  />
                </div>
                <div className="my-2">
                  <div className="piik-text-visual mb-1 ">Container Name</div>
                  <input
                    type="text"
                    className="form-control form-control-sm w-100"
                    id="container_name"
                    placeholder="Type here.."
                    value={cloudDetails.container_name}
                    onChange={handleCloudStorage}
                  />
                </div>

                <div className="my-2">
                  <div className="piik-text-visual mb-1 ">File Name</div>
                  <input
                    type="text"
                    className="form-control form-control-sm w-100"
                    id="file_name"
                    placeholder="Type here.."
                    value={cloudDetails.file_name}
                    onChange={handleCloudStorage}
                  />
                </div>
                <div className="my-2">
                  <div className="piik-text-visual mb-1 ">File Type</div>
                  <select
                    className="form-select form-select-sm w-100 "
                    id="file_type"
                    value={cloudDetails.file_type}
                    onChange={handleCloudStorage}
                  >
                    <option value="" selected>
                      Select
                    </option>
                    <option value="csv">CSV </option>
                    <option value="json">JSON </option>
                  </select>
                </div>
              </div>
            )}

          {action === "cloud" &&
            cloudDetails.cloud_storage !== "azure" &&
            cloudDetails.cloud_storage !== "" && (
              <div className="px-3 py-2 piik-text-visual">Coming Soon</div>
            )}

          {action === "cloud" &&
            cloudDetails.cloud_storage === "azure" &&
            cloudStorageEndpoints.length > 0 && (
              <div className="overflow-y-scroll">
                {cloudStorageEndpoints.length > 0 &&
                  removeDuplicatesObject(cloudStorageEndpoints, "key").map(
                    (table, index) => {
                      return (
                        <div>
                          <div className=" d-flex flex-row justify-content-between px-3 py-2 w-100  piik-text-visual shadow-sm">
                            <div className="fw-semibold text-primary">
                              {table.key && table.key.toUpperCase()}
                            </div>
                            {dropDown.table === table.key ? (
                              <i
                                className="bi bi-chevron-up  piik-pointer"
                                onClick={() => handleDropDown("table", "")}
                              >
                                {" "}
                              </i>
                            ) : (
                              <i
                                className="bi bi-chevron-down  piik-pointer"
                                onClick={() =>
                                  handleDropDown("table", table.key)
                                }
                              ></i>
                            )}
                          </div>
                          {dropDown.table === table.key ? (
                            <>
                              <div className=" d-flex flex-row justify-content-between border-top border-bottom px-3 py-2 w-100  piik-text-visual">
                                <div className="fw-semibold">
                                  Select Date Key
                                </div>
                                {dropDown.date === table.key ? (
                                  <i
                                    className="bi bi-chevron-up  piik-pointer"
                                    onClick={() => handleDropDown("date", "")}
                                  >
                                    {" "}
                                  </i>
                                ) : (
                                  <i
                                    className="bi bi-chevron-down  piik-pointer"
                                    onClick={() =>
                                      handleDropDown("date", table.key)
                                    }
                                  ></i>
                                )}
                              </div>

                              {dropDown.date === table.key &&
                                cloudStorageEndpoints
                                  .filter(
                                    (k) =>
                                      k.key === table.key &&
                                      k.valueType === "datetime"
                                  )
                                  .map((d) => {
                                    return (
                                      <div className="d-flex flex-row justify-content-between px-3 form-check form-switch my-2">
                                        <div className="px-2 d-flex flex-row">
                                          <div className="piik-text-visual py-1 ">
                                            {d.key_name}
                                          </div>
                                        </div>
                                        <input
                                          type="radio"
                                          className="piik-pointer"
                                          id={d.name}
                                          name={table.key}
                                          onClick={handleAssignDate}
                                          checked={
                                            cloudDetails.date_endpoint.findIndex(
                                              (x) => x.endpoint === d.name
                                            ) > -1
                                          }
                                        ></input>
                                      </div>
                                    );
                                  })}
                              <div className=" d-flex flex-row justify-content-between border-top border-bottom px-3 py-2 w-100  piik-text-visual ">
                                <div className="fw-semibold">
                                  Select DataPoint
                                </div>
                                {dropDown.data === table.key ? (
                                  <i
                                    className="bi bi-chevron-up  piik-pointer"
                                    onClick={() => handleDropDown("data", "")}
                                  ></i>
                                ) : (
                                  <i
                                    className="bi bi-chevron-down  piik-pointer"
                                    onClick={() =>
                                      handleDropDown("data", table.key)
                                    }
                                  ></i>
                                )}
                              </div>
                              {dropDown.data === table.key &&
                                cloudStorageEndpoints
                                  .filter((k) => k.key === table.key)
                                  .map((d) => {
                                    return (
                                      <div className="d-flex flex-row justify-content-between px-3 form-check form-switch my-2">
                                        <div className="px-2 d-flex flex-row">
                                          <div className="piik-text-visual py-1 ">
                                            {d.key_name}
                                          </div>
                                        </div>

                                        <input
                                          className="form-check-input piik-pointer"
                                          type="checkbox"
                                          role="switch"
                                          id={d.key_name}
                                          onChange={
                                            handleCloudStorageEndpointSelection
                                          }
                                          checked={cloudStorageSelectedEndpoints.some(
                                            (obj) => d.key_name === obj.key_name
                                          )}
                                        />
                                      </div>
                                    );
                                  })}
                            </>
                          ) : null}
                        </div>
                      );
                    }
                  )}
              </div>
            )}
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <div className="flex-grow-1 bg-light">
          <div className="d-flex flex-row align-items-centerjustify-content-between pt-2  border-top px-4">
            <div className="piik-text-visual">
              Please note that the asterisk (*) symbol represents custom data
            </div>
          </div>
        </div>

        <div
          className="  bg-light shadow-sm d-flex flex-column justify-content-between w-25 piik-create-bar "
          style={{ zIndex: 10 }}
        >
          {submitStatus ? (
            <div className="p-2 piik-text-primary bg-dark-subtle text-center text-white piik-pointer">
              Processing
            </div>
          ) : updateVisualizationIndex === null &&
            action !== "upload" &&
            action !== "delete" &&
            action !== "cloud" ? (
            <>
              {selectedReportEndpoints.length > 0 ||
              customEndpoint.length > 0 ? (
                <div
                  className="p-2 piik-text-dashboard bg-primary text-center text-white piik-pointer"
                  onClick={handleSubmit}
                >
                  Save
                </div>
              ) : (
                <div className="p-2 piik-text-dashboard bg-secondary text-center text-white piik-pointer">
                  Save
                </div>
              )}
            </>
          ) : updateVisualizationIndex === null && action === "upload" ? (
            <div
              className="p-2 piik-text-dashboard bg-primary text-center text-white piik-pointer"
              onClick={handleUpload}
            >
              Upload
            </div>
          ) : updateVisualizationIndex !== null && action === "upload" ? (
            <div
              className="p-2 piik-text-dashboard bg-primary text-center text-white piik-pointer"
              onClick={handleUpload}
            >
              Update
            </div>
          ) : updateVisualizationIndex === null && action === "delete" ? (
            <div className="p-2 piik-text-dashboard bg-secondary text-center text-white piik-pointer">
              Upload
            </div>
          ) : updateVisualizationIndex === null &&
            action === "cloud" &&
            cloudDetails.status &&
            !cloudDetails.authenticate ? (
            <div
              className="p-2 piik-text-dashboard bg-primary text-center text-white piik-pointer"
              onClick={handleCloudStorageAuth}
            >
              Authenticate
            </div>
          ) : updateVisualizationIndex === null &&
            action === "cloud" &&
            !cloudDetails.status ? (
            <div className="p-2 piik-text-dashboard bg-secondary text-center text-white piik-pointer">
              Authenticate
            </div>
          ) : updateVisualizationIndex === null &&
            action === "cloud" &&
            cloudDetails.status &&
            cloudDetails.authenticate &&
            cloudStorageSelectedEndpoints.length > 0 ? (
            <div
              className="p-2 piik-text-dashboard bg-primary text-center text-white piik-pointer"
              onClick={handleSubmit}
            >
              Save
            </div>
          ) : updateVisualizationIndex === null &&
            action === "cloud" &&
            cloudDetails.status &&
            cloudDetails.authenticate &&
            cloudStorageSelectedEndpoints.length === 0 ? (
            <div className="p-2 piik-text-dashboard bg-secondary text-center text-white piik-pointer">
              Save
            </div>
          ) : updateVisualizationIndex !== null ? (
            <div
              className="p-2 piik-text-dashboard bg-primary text-center text-white piik-pointer"
              onClick={handleUpdate}
            >
              Update
            </div>
          ) : null}
        </div>
      </div>

      <FormulaDocumenation
        endpointModal={endpointModal}
        setEndpointModal={setEndpointModal}
      />
    </React.Fragment>
  );
};

export default CreateEndpoint;
