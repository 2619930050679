import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";

export default function PlotChart({ data, layout, config, className }) {
  const [plotDetails, setPlotDetails] = useState({ data: [], layout: {} });

  const handleSortArraysByDates = (x, y) => {
    const areDates = x.every((textDate) => {
      const date = new Date(`${textDate} 1, 2000`);
      return !isNaN(date.getDate());
    });
    const sortedX = areDates
      ? x.map((textDate) => new Date(`${textDate} 1, 2000`))
      : [...x];

    const indices = Array.from({ length: sortedX.length }, (_, i) => i);
    indices.sort((a, b) =>
      sortedX[a] < sortedX[b] ? -1 : sortedX[a] > sortedX[b] ? 1 : 0
    );

    const finalSortedX = indices.map((index) => x[index]);
    const finalSortedY = indices.map((index) => y[index]);
    return { sortedX: finalSortedX, sortedY: finalSortedY };
  };

  const handleSortArrayByValue = (x, y, text, type) => {
    if (type.code === "A") {
      const sortedY = y;
      const indices = Array.from({ length: sortedY.length }, (_, i) => i);
      indices.sort((a, b) =>
        sortedY[a] < sortedY[b] ? -1 : sortedY[a] > sortedY[b] ? 1 : 0
      );

      const finalSortedX = indices.map((index) => x[index]);
      const finalSortedY = indices.map((index) => y[index]);
      const finalSortedText = indices.map((index) => text[index]);

      return {
        sortedX: finalSortedX,
        sortedY: finalSortedY,
        sortedText: finalSortedText,
      };
    } else if (type.code === "D") {
      const sortedY = y;
      const indices = Array.from({ length: sortedY.length }, (_, i) => i);
      indices.sort((a, b) =>
        sortedY[a] > sortedY[b] ? -1 : sortedY[a] < sortedY[b] ? 1 : 0
      );

      const finalSortedX = indices.map((index) => x[index]);
      const finalSortedY = indices.map((index) => y[index]);
      const finalSortedText = indices.map((index) => text[index]);

      return {
        sortedX: finalSortedX,
        sortedY: finalSortedY,
        sortedText: finalSortedText,
      };
    } else if (type.code === "AN") {
      const sortedY = y;
      const len =
        type.nValue <= sortedY.length ? parseInt(type.nValue) : sortedY.length;
      const indices = Array.from({ length: sortedY.length }, (_, i) => i);
      indices.sort((a, b) =>
        sortedY[a] < sortedY[b] ? -1 : sortedY[a] > sortedY[b] ? 1 : 0
      );

      const finalSortedX = indices.map((index) => x[index]);
      const finalSortedY = indices.map((index) => y[index]);
      const finalSortedText = indices.map((index) => text[index]);

      return {
        sortedX: finalSortedX.slice(0, len + 1).reverse(),
        sortedY: finalSortedY.slice(0, len + 1).reverse(),
        sortedText: finalSortedText.slice(0, len + 1).reverse(),
      };
    } else if (type.code === "DN") {
      const sortedY = y;
      const len =
        type.nValue <= sortedY.length ? parseInt(type.nValue) : sortedY.length;
      const indices = Array.from({ length: sortedY.length }, (_, i) => i);
      indices.sort((a, b) =>
        sortedY[a] > sortedY[b] ? -1 : sortedY[a] < sortedY[b] ? 1 : 0
      );
      const finalSortedX = indices.map((index) => x[index]);
      const finalSortedY = indices.map((index) => y[index]);
      const finalSortedText = indices.map((index) => text[index]);

      return {
        sortedX: finalSortedX.slice(0, len + 1).reverse(),
        sortedY: finalSortedY.slice(0, len + 1).reverse(),
        sortedText: finalSortedText.slice(0, len + 1).reverse(),
      };
    }
  };

  useEffect(() => {
    setPlotDetails(() => {
      let updatedSortDataByDate = data
        .filter((d) => d.visibilityState)
        .map((d) => {
          const resultYValue = handleSortArraysByDates(d.x, d.y, d.text);
          const resultTextValue = handleSortArraysByDates(d.x, d.text);
          const newData = {
            ...d,
            x: resultYValue.sortedX,
            y: resultYValue.sortedY,
            text: resultTextValue.sortedY.map((d) => (d === "0" ? "" : d)),
          };
          return newData;
        });
      let updatedDetails = { data: updatedSortDataByDate, layout: layout };
      if (layout && layout.sortBy && layout.sortBy.code !== "") {
        let updatedSortDataByValue = data
          .filter((d) => d.visibilityState)
          .map((d) => {
            const resultValue = handleSortArrayByValue(
              d.x,
              d.y,
              d.text,
              layout.sortBy
            );

            const newData = {
              ...d,
              x: resultValue.sortedX,
              y: resultValue.sortedY,
              text: resultValue.sortedText.map((d) => (d === "0" ? "" : d)),
            };
            return newData;
          });

        updatedDetails = { data: updatedSortDataByValue, layout: layout };
      }

      let tempIndexOrienation = updatedDetails.data.findIndex(
        (d) => d.orientation === "h"
      );

      if (tempIndexOrienation > -1) {
        const oldLayout = updatedDetails.layout;
        if (oldLayout.hasOwnProperty("yaxis2")) {
          const { yaxis2, ...updatedLayout } = {
            ...oldLayout,
            yaxis: { ...oldLayout.xaxis },
            xaxis: { ...oldLayout.yaxis },
            xaxis2: { ...oldLayout.yaxis, overlaying: "x", side: "left" },
          };
          const updatedData = updatedDetails.data.map((d) => {
            if (d.chartType === "YTD") {
              const newData = { ...d, x: d.y, y: d.x, xaxis: "x2", yaxis: "y" };
              return newData;
            } else {
              const newData = {
                ...d,
                x: d.y,
                y: d.x,
                hovertemplate:
                  "%{xaxis.title.text}: %{x:.0f}<br><extra></extra>",
              };
              return newData;
            }
          });

          updatedDetails["layout"] = updatedLayout;
          updatedDetails["data"] = updatedData;
        } else {
          const updatedLayout = {
            ...oldLayout,
            yaxis: { ...oldLayout.xaxis },
            xaxis: { ...oldLayout.yaxis },
          };
          const updatedData = updatedDetails.data.map((d) => {
            const newData = {
              ...d,
              x: d.y,
              y: d.x,

              hovertemplate:
                "%{xaxis.title.text}: %{x:.0f}<br> <extra></extra>",
            };
            return newData;
          });
          updatedDetails["layout"] = updatedLayout;
          updatedDetails["data"] = updatedData;
        }
      }
      return updatedDetails;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, layout]);

  return (
    plotDetails &&
    plotDetails.data.length > 0 &&
    plotDetails.layout && (
      <Plot
        key={JSON.stringify(plotDetails.data)}
        data={plotDetails.data}
        layout={plotDetails.layout}
        config={config}
        className={className}
      />
    )
  );
}
