import React, { useEffect, useState } from "react";

import { useDashData } from "../../../hooks/useDash";
import { addKeysToObjects, generateUniqueCode } from "../Charts/Utility";

import { config } from "../Charts/ChartConfig";
import PlotChart from "../Charts/Plot";

const PlotCharts = ({ chartData, columnIndex, layout, fullScreenStatus }) => {
  const [plotState, setPlotState] = useState(false);
  const { adminAccountInfo, guestAccountInfo, account, calenderData } =
    useDashData();

  const [chartDetails, setChartDetails] = useState({});
  const [isMounted, setIsMounted] = useState(true);

  const handleChartData = (chartData) => {
    let combinedChartData = adminAccountInfo.concat(guestAccountInfo);
    let accountIndex = combinedChartData.findIndex(
      (data) => data.uid === account
    );
    const accountData = JSON.parse(
      JSON.stringify(combinedChartData[accountIndex])
    );
    let chartIndex = accountData.chart_id.findIndex(
      (d) => d.id === chartData.visualization_id
    );
    addKeysToObjects(accountData, chartIndex, calenderData)
      .then((data) => {
        setChartDetails(data);
        setIsMounted(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (fullScreenStatus !== plotState) {
      setPlotState((preData) => !preData);
    }
    setIsMounted(false);
    handleChartData(chartData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, layout, fullScreenStatus, calenderData]);

  return isMounted && chartDetails.hasOwnProperty("name") ? (
    <div className="d-flex flex-column" key={`${columnIndex}-${layout.length}`}>
      <div className="d-flex flex-row justify-content-between bg-white py-2 px-4 w-100">
        <div className="piik-text-visual ">{chartDetails.name}</div>
        <i className="bi bi-info-circle text-primary"></i>
      </div>
      {plotState === fullScreenStatus && (
        <PlotChart
          key={`${columnIndex}-${layout.length}`}
          data={chartDetails.settings.data}
          layout={chartDetails.settings.layout}
          config={config}
          className="piik-text-visual bg-white flex-grow-1"
          style={{ width: "100%", height: "100%" }}
        />
      )}
    </div>
  ) : (
    <div className="d-flex flex-column ">
      <div className="d-flex flex-row justify-content-between bg-white p-2">
        <div
          className="piik-text-visual bg-white flex-grow-1"
          style={{ width: "100%", height: "100%" }}
        >
          Chart-{columnIndex}
        </div>
      </div>
    </div>
  );
};

const Dashboard = ({ type, layout, fullScreenStatus }) => {
  const [currentLayout, setCurrentLayout] = useState(layout);
  const [plotState, setPlotState] = useState(false);
  useEffect(() => {
    if (fullScreenStatus !== plotState) {
      setPlotState((preData) => !preData);
    }
    setCurrentLayout(layout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout, fullScreenStatus]);

  if (type === "chart") {
    return (
      <div className="row g-2">
        {currentLayout.length > 0 &&
          currentLayout.map((colValue, columnIndex) => (
            <div key={columnIndex} className={`col-${colValue.width}`}>
              {plotState === fullScreenStatus && (
                <PlotCharts
                  chartData={colValue}
                  columnIndex={columnIndex}
                  layout={currentLayout}
                  fullScreenStatus={fullScreenStatus}
                />
              )}
            </div>
          ))}
      </div>
    );
  }
};

// utility function
const CreateDashBoard = ({
  fullScreenStatus,
  updateVisualizationIndex,
  handleUpdateVisualizationIndex,
  handleVisualizationTab,
}) => {
  const {
    profile,
    adminAccountInfo,
    account,
    handleAddVisualization,
    handleUpdateVisualization,
  } = useDashData();

  const [plotState, setPlotState] = useState(false);
  const [accountData, setAccountData] = useState({});
  const [updatedDataSelection, setUpdatedDataSelection] = useState({});
  const [tab, setTab] = useState(1);
  const [selectedChart, setSelectedChart] = useState({});
  const [plotChange, setPlotChange] = useState(true);
  const [prevChange, setPrevChange] = useState(null);

  const [layout, setLayout] = useState([
    {
      id: generateUniqueCode(),
      width: 4,
      visualization_id: "",
      visualization_type: "",
    },
  ]);

  const [title, setTitle] = useState(``);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitStatus, setSubmitStatus] = useState(false);

  const handleUpdate = () => {
    if (title === "") {
      setErrorMessage("Field Data is Missing");
      return;
    }
    setSubmitStatus(true);
    let requestData = {
      userId: profile.user_id,
      accountId: accountData.uid,
      dashboardId: updatedDataSelection.id,
      dashboardSettings: {
        layout: layout,
      },
      dashboardName: title,
      createdBy: profile.user_email,
      visualizationType: "Dashboard",
    };

    handleUpdateVisualization(requestData)
      .then((response) => {
        if (response) {
          handleClear("all");
        }
        setSubmitStatus(false);
        handleVisualizationTab(updateVisualizationIndex);
        handleUpdateVisualizationIndex(null);
      })
      .catch((error) => {
        setSubmitStatus(false);
      });
  };

  const handleSubmit = () => {
    if (title === "") {
      setErrorMessage("Field Data is Missing");
      return;
    }
    setSubmitStatus(true);
    let requestData = {
      userId: profile.user_id,
      accountId: accountData.uid,
      dashboardSettings: {
        layout: layout,
      },
      dashboardName: title,
      createdBy: profile.user_email,
      visualizationType: "Dashboard",
    };

    handleAddVisualization(requestData)
      .then((response) => {
        if (response) {
          handleClear("details");
        }
        setSubmitStatus(false);
        handleUpdateVisualizationIndex(null);
      })
      .catch((error) => {
        setSubmitStatus(false);
      });
  };

  const handleAddChart = (e) => {
    let chart = {
      id: generateUniqueCode(),
      width: 4,
      visualization_id: "",
    };
    setLayout([...layout, chart]);
  };

  const handleRemoveChart = (e) => {
    const id = e.target.id;
    setLayout((pre) => {
      const updatedLayout = pre.filter((d) => d.id !== id);
      return updatedLayout;
    });
  };

  const handleSelectChart = (e) => {
    const data =
      layout.length > 0 && layout.find((d) => d.id === e.target.value);
    setSelectedChart(data);
  };

  const handleVisualization = (e) => {
    const { id, value } = e.target;
    setLayout((pre) => {
      const tempIndex = pre.findIndex((d) => d.id === id);
      const updatedLayout = [...pre];
      if (e.target.name === "width") {
        updatedLayout[tempIndex] = {
          ...updatedLayout[tempIndex],
          width: value,
        };
      } else {
        updatedLayout[tempIndex] = {
          ...updatedLayout[tempIndex],
          visualization_id: value,
          visualization_type: "chart",
        };
      }

      return updatedLayout;
    });
  };

  const handleInput = (e) => {
    const id = e.target.id;
    if (id === "title") {
      const value = e.target.value;
      if (title.length > 25) {
        const truncatedValue = value.slice(0, 25);
        setTitle(truncatedValue);
        setErrorMessage("Maximum character limit reached");
      } else {
        setTitle(value);
        setErrorMessage("");
      }
    }
  };

  const handleClear = (id) => {
    if (id === "details") {
      setTitle("");
    }
    setLayout([
      {
        id: generateUniqueCode(),
        width: 4,
        visualization_id: "",
        visualization_type: "",
      },
    ]);
  };

  useEffect(() => {
    if (fullScreenStatus !== plotState) {
      setPlotState((preData) => !preData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullScreenStatus]);

  useEffect(() => {
    setPrevChange(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotChange]);

  useEffect(() => {
    setPlotChange(!plotChange);
    setPrevChange(!plotChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout]);

  useEffect(() => {
    if (account !== "" && adminAccountInfo.length > 0) {
      const accountIndex = adminAccountInfo.findIndex(
        (data) => data.uid === account
      );
      const selectedAccountData = adminAccountInfo[accountIndex];
      setAccountData(selectedAccountData);
      if (updateVisualizationIndex !== null) {
        const selectedDashboardData =
          selectedAccountData.dashboard_id[updateVisualizationIndex];
        if (selectedDashboardData && selectedDashboardData.settings) {
          setUpdatedDataSelection(selectedDashboardData);
          setLayout(selectedDashboardData.settings.layout);
          setTitle(selectedDashboardData.name);
        } else {
          handleUpdateVisualizationIndex(null);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, adminAccountInfo]);

  return (
    <>
      <div
        className={
          fullScreenStatus
            ? "d-flex flex-row justify-content-between  bg-light piik-visual-section-fullscreen piik-create-chart "
            : "d-flex flex-row justify-content-between  bg-light piik-visual-section piik-create-chart "
        }
      >
        <div className="flex-grow-1">
          <div className="d-flex flex-column  h-100 w-100 ">
            <div className="flex-grow-1 w-100  h-100 d-flex flex-column overflow-y-scroll ">
              <div
                className="d-flex flex-row justify-content-between  bg-white px-4 py-2 shadow-sm "
                style={{ zIndex: 2 }}
              >
                <div className="piik-text-dashboard  text-primary p-0">
                  {title}
                </div>
                <i className="bi bi-info-circle text-primary"></i>
              </div>

              {layout &&
                plotState === fullScreenStatus &&
                prevChange !== plotChange && (
                  <Dashboard
                    layout={layout}
                    type="chart"
                    fullScreenStatus={fullScreenStatus}
                  />
                )}
            </div>
          </div>
        </div>
        <div
          className="  bg-light shadow-sm d-flex flex-column justify-content-start w-25 piik-create-bar "
          style={{ zIndex: 10 }}
        >
          <div className=" px-3 py-2 piik-text-dashboard">
            <div className=" d-flex flex-row justify-content-between align-items-center">
              <div className="fw-semibold text-primary"> Set Title</div>
              <i
                className="bi bi-eraser piik-pointer"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Clear Details"
                onClick={() => handleClear("details")}
              ></i>
            </div>
            <div className="my-2">
              <input
                type="text"
                className="form-control form-control-sm w-100"
                id="title"
                placeholder="Max 25 Character"
                onChange={handleInput}
                value={title}
              />
              {errorMessage !== "" && (
                <div className="pt-1 text-danger piik-text-warning">
                  {errorMessage}
                </div>
              )}
            </div>
          </div>

          <div className=" px-3 py-2 shadow-sm piik-text-dashboard border-top ">
            <div className=" d-flex flex-row justify-content-between align-items-center">
              <div className="fw-semibold text-primary"> Add Charts</div>
              <div>
                <i
                  className="bi bi-eraser piik-pointer px-4"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Clear Charts"
                  onClick={() => handleClear("layout")}
                ></i>
                <i
                  className="bi bi-chevron-down piik-pointer"
                  onClick={() => setTab(1)}
                ></i>
              </div>
            </div>
          </div>
          {tab === 1 && (
            <div className=" piik-text-visual  flex-grow-1 overflow-y-scroll piik-create">
              <div className="px-3 pt-2">
                {layout.length > 0 &&
                  layout.map((col, columnIndex) => (
                    <div className="mb-3">
                      <div className="d-flex flex-row justify-content-between align-items-center my-1 ">
                        <label
                          htmlFor="chartName"
                          className="form-label piik-text-visual mb-1"
                        >
                          Chart-{columnIndex + 1}
                        </label>
                        <div className="d-flex flex-row justify-content-end align-items-center ">
                          {layout.length > 1 ? (
                            <i
                              id={col.id}
                              className="bi bi-trash piik-pointer px-4"
                              onClick={handleRemoveChart}
                            ></i>
                          ) : null}

                          <i
                            className="bi bi-plus-square piik-pointer"
                            onClick={handleAddChart}
                          ></i>
                        </div>
                      </div>
                      <select
                        id={col.id}
                        className="form-select form-select-sm"
                        value={col.visualization_id}
                        onChange={handleVisualization}
                      >
                        <option key={-1} selected>
                          Select...
                        </option>
                        {accountData &&
                          accountData.chart_id &&
                          accountData.chart_id.map((d, index) => {
                            return (
                              <option key={index} value={d.id}>
                                {d.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  ))}
              </div>
            </div>
          )}

          <div className=" px-3 py-2 shadow-sm piik-text-dashboard border-top">
            <div className=" d-flex flex-row justify-content-between align-items-center">
              <div className="fw-semibold text-primary">Adjust Chart Width</div>
              <i
                className="bi bi-chevron-down piik-pointer"
                onClick={() => setTab(2)}
              ></i>
            </div>
          </div>
          {tab === 2 && (
            <div className=" piik-text-visual  flex-grow-1 overflow-y-scroll piik-create">
              <div className="px-3 pt-2 ">
                <div className="mb-3">
                  <div className="d-flex flex-row justify-content-between align-items-center my-1 ">
                    <label
                      htmlFor="chartName"
                      className="form-label piik-text-visual mb-1"
                    >
                      Chart
                    </label>
                  </div>
                  <select
                    className="form-select form-select-sm"
                    value={selectedChart && selectedChart.id}
                    onChange={handleSelectChart}
                  >
                    <option key={-1} selected>
                      Select...
                    </option>
                    {layout.length > 0 &&
                      layout.map((col, columnIndex) => {
                        return (
                          <option key={columnIndex} value={col.id}>
                            {accountData &&
                              accountData.chart_id &&
                              accountData.chart_id.find(
                                (value) => value.id === col.visualization_id
                              ) &&
                              accountData.chart_id.find(
                                (value) => value.id === col.visualization_id
                              ).name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="px-3 ">
                <div className="mb-3">
                  <div className="d-flex flex-row justify-content-between align-items-center my-1 ">
                    <label className="form-label piik-text-visual mb-1">
                      Chart Width
                    </label>
                  </div>
                  <select
                    className="form-select form-select-sm"
                    id={selectedChart && selectedChart.id}
                    name="width"
                    value={
                      selectedChart &&
                      selectedChart.id &&
                      layout.length > 0 &&
                      layout.find((col) => col.id === selectedChart.id)
                        ? layout.find((col) => col.id === selectedChart.id)
                            .width
                        : 3
                    }
                    onChange={handleVisualization}
                  >
                    <option key={-1} selected>
                      Select...
                    </option>
                    {selectedChart &&
                      [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((value) => (
                        <option key={value} value={value}>
                          {value}-Unit
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <div className="w-100 bg-white"></div>
        <div
          className="bg-light shadow-sm d-flex flex-column justify-content-between w-25  piik-create-bar"
          style={{ zIndex: 10 }}
        >
          {submitStatus ? (
            <div className="p-2 piik-text-primary bg-dark-subtle text-center text-white piik-pointer">
              Processing
            </div>
          ) : updateVisualizationIndex === null ? (
            <>
              {layout.length > 0 && layout[0].visualization_id !== "" ? (
                <div
                  className="p-2 piik-text-dashboard bg-primary text-center text-white piik-pointer"
                  onClick={handleSubmit}
                >
                  Save
                </div>
              ) : (
                <div className="p-2 piik-text-dashboard bg-secondary text-center text-white piik-pointer">
                  Save
                </div>
              )}
            </>
          ) : (
            <div
              className="p-2 piik-text-dashboard bg-primary text-center text-white piik-pointer"
              onClick={handleUpdate}
            >
              Update
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateDashBoard;
