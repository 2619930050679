import React, { useEffect, useState, useRef } from "react";

import { useDashData } from "../../../hooks/useDash";
import { useAuth0 } from "@auth0/auth0-react";

import Modal from "../../Common/Modal";
import DisplayBottomIcon from "../DisplayBottomIcon";

import { addKeysToObjects } from "../Charts/Utility";
import { config } from "../Charts/ChartConfig";
import PlotChart from "../Charts/Plot";

const PlotCharts = ({ chartData, layout }) => {
  const {
    adminAccountInfo,
    guestAccountInfo,
    account,
    calenderData,
    columnIndex,
  } = useDashData();

  const [chartDetails, setChartDetails] = useState({});
  const handleChartData = (chartData) => {
    let combinedChartData = adminAccountInfo.concat(guestAccountInfo);
    let accountIndex = combinedChartData.findIndex(
      (data) => data.uid === account
    );
    const accountData = JSON.parse(
      JSON.stringify(combinedChartData[accountIndex])
    );
    let tempChartId = chartData.visualization_id;
    let chartIndex = accountData.chart_id.findIndex(
      (d) => d.id === tempChartId
    );
    addKeysToObjects(accountData, chartIndex, calenderData)
      .then((data) => {
        setChartDetails(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    handleChartData(chartData);
    return () => {
      setChartDetails({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, adminAccountInfo, calenderData]);

  return chartDetails.hasOwnProperty("name") ? (
    <div className="d-flex flex-column ">
      <div className="d-flex flex-row justify-content-between bg-white py-2 px-3">
        <div className="piik-text-visual 2">{chartDetails.name}</div>
        <i
          className="bi bi-info-circle text-primary piik-text-icon"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Chart Description"
        ></i>
      </div>
      <PlotChart
        key={`${columnIndex}-${layout.length}`}
        data={chartDetails.settings.data}
        layout={chartDetails.settings.layout}
        config={config}
        className="piik-text-visual bg-white "
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  ) : (
    <div className="d-flex flex-column ">
      <div className="d-flex flex-row justify-content-between bg-white p-2">
        <div
          className="piik-text-visual bg-white flex-grow-1"
          style={{ width: "100%", height: "100%" }}
        ></div>
      </div>
    </div>
  );
};

const Dashboard = ({ layout, fullScreenStatus }) => {
  return (
    layout.length > 0 && (
      <div className="row g-2">
        {layout.length > 0 &&
          layout.map((colValue, columnIndex) => (
            <div key={colValue.id} className={`col-${colValue.width}`}>
              <PlotCharts
                chartData={colValue}
                columnIndex={columnIndex}
                layout={layout}
                fullScreenStatus={fullScreenStatus}
              />
            </div>
          ))}
      </div>
    )
  );
};

const DisplayDashboard = ({
  index,
  fullScreenStatus,
  handleVisualizationTab,
  handleUpdateVisualizationIndex,
}) => {
  const {
    profile,
    adminAccountInfo,
    guestAccountInfo,
    account,
    isModalOpen,
    openModal,
    closeModal,
    handleDeleteVisulization,
    handleDownloadAsImage,
    handleCopyData,
  } = useDashData();
  const { user } = useAuth0();
  const [plotState, setPlotState] = useState(false);

  const [forceUpdateFlag, setForceUpdateFlag] = useState(true);
  const [selectedAccountData, setSelectedAccountData] = useState({});
  const [data, setData] = useState({});

  const [password, setPassword] = useState({ value: "", errorMessage: "" });
  const [btn, setBtnState] = useState(false);

  const handlePasswordInputChange = (event) => {
    const value = event.target.value;
    setPassword({ errorMessage: "", value: value });
  };
  const divRef = useRef(null);
  const handleDownload = async () => {
    handleDownloadAsImage(divRef, data);
  };
  const handleDuplicate = () => {
    let duplicate_data = {
      type: "dashboard",
      id: data.id,
      accountId: selectedAccountData.uid,
      userId: selectedAccountData.owner_id,
    };
    handleCopyData(duplicate_data).then((response) => {
      if (response.status) {
        handleUpdateVisualizationIndex(selectedAccountData.dashboard_id.length);
        handleVisualizationTab(100);
      }
    });
  };

  const handleDeleteDashboard = async () => {
    if (password.value !== user.email) {
      setPassword({ errorMessage: "Invalid email", value: "" });
      return;
    } else {
      let deletedDataInfo = {
        userId: profile.user_id,
        accountId: selectedAccountData.uid,
        dashboardId: data.id,
        visualizationType: "Dashboard",
      };
      handleDeleteVisulization(deletedDataInfo)
        .then((response) => {
          if (response) {
            setBtnState(false);
            setPassword({
              errorMessage: "",
              value: "",
            });
            handleVisualizationTab(0);
            closeModal();
          }
        })
        .catch((error) => {
          setBtnState(false);
        });
    }
  };

  const handleEditDashboard = () => {
    handleUpdateVisualizationIndex(index);
    handleVisualizationTab(100);
  };

  useEffect(() => {
    if (fullScreenStatus !== plotState) {
      setPlotState((preData) => !preData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullScreenStatus]);

  useEffect(() => {
    setForceUpdateFlag((prevFlag) => !prevFlag);
    let CombinedChartData = adminAccountInfo.concat(guestAccountInfo);
    let accountIndex = CombinedChartData.findIndex(
      (data) => data.uid === account
    );
    let accountData = CombinedChartData[accountIndex];
    setSelectedAccountData(accountData);
    const updateData = accountData.dashboard_id[index];
    setForceUpdateFlag((prevFlag) => !prevFlag);
    setData(updateData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAccountInfo, account, index]);

  return (
    data &&
    data.settings &&
    data.settings.layout && (
      <>
        <div
          className={
            fullScreenStatus
              ? "d-flex flex-column justify-content-between piik-visual-section-fullscreen piik-create-chart "
              : "d-flex flex-column justify-content-between piik-visual-section piik-create-chart "
          }
        >
          <div
            className="flex-grow-1 w-100  h-100 d-flex flex-column bg-light "
            ref={divRef}
          >
            <div className="d-flex flex-column justify-content-between w-100 h-100 overflow-y-scroll ">
              {forceUpdateFlag && plotState === fullScreenStatus && (
                <Dashboard
                  layout={data && data.settings && data.settings.layout}
                  plotState={plotState}
                />
              )}
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-row justify-content-between  bg-light border-top align-self-center"
          style={{ zIndex: 2 }}
        >
          <div className="py-1 piik-text-dashboard text-primary  px-4">
            {data.name}
          </div>
          <div className="d-flex flex-row justify-content-end">
            <DisplayBottomIcon
              title="Download"
              icon="bi-download"
              handleIconAction={handleDownload}
            />
            {profile.user_id === selectedAccountData.owner_id ? (
              <>
                <DisplayBottomIcon
                  title="Copy Chart"
                  icon="bi-clipboard-plus"
                  handleIconAction={handleDuplicate}
                />
                <DisplayBottomIcon
                  title="Edit Dashboard"
                  icon="bi-pencil-square"
                  handleIconAction={handleEditDashboard}
                />

                <DisplayBottomIcon
                  title="Delete Dashboard"
                  icon="bi-trash"
                  handleIconAction={openModal}
                />
              </>
            ) : null}
            <DisplayBottomIcon
              title="Dashboard Description"
              icon="bi-info-circle"
              status={false}
              // handleIconAction={openModal}
            />
          </div>
        </div>

        <Modal
          isOpen={isModalOpen}
          closeModal={closeModal}
          title={data ? `${data.name}` : "Loading..."}
        >
          {isModalOpen && selectedAccountData ? (
            <div className="row mx-0 justify-content-between px-4 pt-2 pb-4 ">
              <div className="col-12 piik-text-warning px-2 pt-2 pb-4">
                This will permanently delete the dashboard from the
                <span className="text-danger fw-semibold px-1">
                  {user.email}
                </span>{" "}
                account.
              </div>
              <div className="col px-2 ">
                <input
                  type="email"
                  className="form-control form-control-sm"
                  id="password"
                  placeholder="Please enter the email
                  to confirm"
                  value={password.value}
                  onChange={handlePasswordInputChange}
                />
                {password.errorMessage !== "" && (
                  <label className="piik-text-warning text-danger text-capitalize py-1">
                    {password.errorMessage}
                  </label>
                )}
              </div>

              <div className="col-2 px-0">
                <button
                  className="btn btn-sm btn-outline-primary w-100 px-2"
                  onClick={handleDeleteDashboard}
                  disabled={btn}
                >
                  {btn ? (
                    <span className="piik-text-icon text-warning">
                      Processing
                    </span>
                  ) : (
                    "Delete"
                  )}
                </button>
              </div>
            </div>
          ) : (
            <div className="row mx-0 justify-content-between px-4 pt-2 pb-4 ">
              <div className="col-12 piik-text-dashboard px-2 pt-2 pb-4">
                Sorry you are not allowed to delete all the Dashboard. Add a new
                dashboard first to clear the selected Chart
              </div>
            </div>
          )}
        </Modal>
      </>
    )
  );
};

export default DisplayDashboard;
