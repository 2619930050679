import Modal from "../Common/Modal";

const handleFormulaValidation = (value) => {
  let key = value.charAt(value.length - 1);
  const keyLibrary = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "*",
    "^",
    "+",
    "/",
    "-",
    "_",
    "E",
    "P",
    "e",
    "p",
    "(",
    ")",
    " ",
    "",
  ];
  if (keyLibrary.includes(key)) {
    const text = autocomplete(value, key);
    return text;
  } else {
    return "NA";
  }
};
const autocomplete = (value, input) => {
  let suggestedText = input;
  if (input.toUpperCase() === "E") {
    suggestedText = "EP_";
    return value.slice(0, -1) + suggestedText;
  } else if (input.toUpperCase() === "P") {
    suggestedText = "EP";
    return value.slice(0, -2);
  } else {
    return value;
  }
};
const isValidFormula = (formula) => {
  if (!formula.startsWith("E") && !formula.startsWith("(")) {
    return false;
  }

  let openBracketCount = 0;
  let closeBracketCount = 0;
  for (let i = 0; i < formula.length; i++) {
    if (formula[i] === "(") {
      openBracketCount++;
    } else if (formula[i] === ")") {
      closeBracketCount++;
    }
  }
  return openBracketCount === closeBracketCount && /[0-9)]$/.test(formula);
};

const checkDuplicateId = (arrayOfObjects) => {
  const idSet = new Set();
  for (const obj of arrayOfObjects) {
    if (idSet.has(obj.endpointId)) {
      return true;
    } else {
      idSet.add(obj.endpointId);
    }
  }
  return false;
};

const handleTableHeader = (text, data) => {
  const totalDataValue = data.reduce(function (accumulator, currentObject) {
    return accumulator + currentObject.value;
  }, 0);
  if (totalDataValue !== 0) {
    return true;
  } else {
    return false;
  }
};

const handleAddRemove = (type, id, setCustomEndpoint) => {
  if (type === "add") {
    setCustomEndpoint((prev) => {
      const prevData = [...prev];
      const len = prevData.length;
      const newData = [...prevData, { name: `EP_${len + 1}`, endpointId: "" }];
      return newData;
    });
  } else if (type === "remove") {
    setCustomEndpoint((prev) => {
      const prevData = [...prev];
      const updatedData = [...prevData].filter((d) => d.name !== id);
      const newData = [...updatedData].map((d, i) => ({
        ...d,
        name: `EP_${i + 1}`,
      }));
      return newData;
    });
  }
};

const FormulaDocumenation = ({ endpointModal, setEndpointModal }) => {
  const handleCloseModal = () => {
    setEndpointModal(false);
  };
  return (
    <Modal
      isOpen={endpointModal}
      closeModal={handleCloseModal}
      title="Custom Formula Documentation"
    >
      <div className="row mx-0 justify-content-between px-4 pt-2 pb-4">
        <div className="piik-text-visual pb-2">
          Custom data refers to user-defined data that allows you to create and
          store new data endpoints for data visualization. This documentation
          will provide an overview of custom data, how to create it, and the
          rules to follow when writing formulas for custom data.
        </div>

        <div className="piik-text-visual px-2">
          <div className="fw-semibold">1. Sample Formula</div>
          <div className="piik-text-visual fw-semibold py-1 text-primary">
            ((EP_1 + EP_2) * (EP_3 - (EP_4^2))) / 100
          </div>
          <div>
            where EP_1, EP_2, EP_3, EP_4 are the endpoints which need to be
            selected from the existing data.
          </div>

          <div className="piik-text-visual fw-semibold pt-2 pb-1">2. Rules</div>
          <div className="px-3 pb-1">
            A. Following operations are allowed for creating the custom formula:
            <table className="px-4 mx-3">
              <tr>
                <td>i.</td>
                <td>Addition</td>
                <td>(symbol: + )</td>
              </tr>
              <tr>
                <td>ii.</td>
                <td>Subtraction</td>
                <td>(symbol: - )</td>
              </tr>
              <tr>
                <td>iii.</td>
                <td>Multiplication</td>
                <td>(symbol: * )</td>
              </tr>
              <tr>
                <td>iv.</td>
                <td>Division</td>
                <td>(symbol: / )</td>
              </tr>
              <tr>
                <td>v.</td>
                <td>Power</td>
                <td>(symbol: ^ )</td>
              </tr>
            </table>
          </div>
          <div className="px-3 pb-1">
            B. Only parentheses are allowed to be used for grouping, and all
            open "(" should be closed with ")".
          </div>
          <div className="px-3 pb-1">
            C. Custom formulas can start with "(" or "E" character and end with
            ")" or "0-9" character.
          </div>
          <div className="px-3 pb-1">
            D. Only the following characters from the keyboard are allowed to
            create the formula:
            <div className="px-4">
              "1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "*", "^", "+",
              "/", "-", "_", "E", "P", "e", "p", "(", ")"
            </div>
          </div>
          <div className="px-3 pb-1">
            E. Old Custom Data cannot be used to create a new Custom Data.{" "}
          </div>

          <div className="piik-text-visual fw-semibold pt-2 pb-1">
            3. Recommendation
          </div>
          <div className="piik-text-visual">
            Use all the endpoints (EP) for the formula and check the formula bar
            in the dashboard before submitting or updating the Custom Data.
          </div>
        </div>
      </div>
    </Modal>
  );
};

const endpointValidation = (data) => {
  let hasEmptyValue = data.some(function (obj) {
    return obj["endpointId"] === "";
  });
  return hasEmptyValue;
};

export {
  isValidFormula,
  handleFormulaValidation,
  FormulaDocumenation,
  checkDuplicateId,
  handleAddRemove,
  handleTableHeader,
  endpointValidation,
};
