import React, { useEffect } from "react";
import Dashboard from "./Dashboard";

import { useAuth0 } from "@auth0/auth0-react";
import { useLocalStorage } from "../hooks/useLocalStorage";

export default function Home() {
  const { getAuthState, setAuthState } = useLocalStorage();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      setAuthState("authState", isAuthenticated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (getAuthState("authState")) {
    return <Dashboard />;
  } else {
    loginWithRedirect();
  }
}
