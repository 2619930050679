import { filterByDateRange } from "../../../Data/DatesGenerator";
import DataFrame from "dataframe-js";

// Handle Chart variable
const handlePriorYear = (data, calendarData, sourceData, displayUnit) => {
  const prevCalanderData = {
    ...calendarData,
    year_index: calendarData.year_index - 1,
  };

  let filterEndpoints = data.filterEndpoint;

  const tempIdx = sourceData.data.findIndex((acc) => acc.id === data.yaxisIds);
  const selectedData = [sourceData.data[tempIdx]];

  const filteredDataPr = [...filterByDateRange(selectedData, prevCalanderData)];
  let x_value = filteredDataPr[0].data.map((obj) => obj.date);
  let y_value = filteredDataPr[0].data.map((obj) => obj.value);

  const { x, y } = aggegateCategoricalData(
    x_value,
    y_value,
    data.groupBy,
    sourceData,
    prevCalanderData,
    filterEndpoints
  );
  data.x = x.map((d) => transformDate(d, 1));
  data.y = y.length > 0 ? y : [];
  data.text =
    y.length > 0
      ? y.map((v) => `${convertDisplayDataUnit(v, displayUnit)}`)
      : [];
  data.hovertemplate = handleHoverTemplate(data.orientation, data.name);
  return data;
};

const handleYearToDate = (data, calendarData, sourceData, displayUnit) => {
  let filterEndpoints = data.filterEndpoint;
  const tempIdx = sourceData.data.findIndex((acc) => acc.id === data.yaxisIds);
  const filteredDataYtd = [
    ...filterByDateRange([sourceData.data[tempIdx]], calendarData),
  ];

  let x_value = filteredDataYtd[0].data.map((obj) => obj.date);
  let y_value = filteredDataYtd[0].data.map((obj) => obj.value);

  let operationType = data.groupBy;
  const { x, y } = aggegateCategoricalData(
    x_value,
    y_value,
    operationType,
    sourceData,
    calendarData,
    filterEndpoints
  );

  let sourceDateData = sourceData.data.find((d) => d.id === "date_1234");
  const date = x.map(
    (d) => sourceDateData.data.find((v) => v.date === d).value
  );

  let cum_y_data = y.reduce(
    (acc, val) => [...acc, acc.length > 0 ? acc[acc.length - 1] + val : val],
    []
  );

  data.x = date;
  data.y = cum_y_data.length > 0 ? cum_y_data : [];
  data.text =
    cum_y_data.length > 0
      ? cum_y_data.map((v) => `${convertDisplayDataUnit(v, displayUnit)}`)
      : [];
  data.hovertemplate = handleHoverTemplate(data.orientation, data.name);
  return data;
};

function addKeysToObjects(
  accountData,
  index,
  calendarData,
  displayUnit,
  plotData = {}
) {
  return new Promise((resolve, reject) => {
    let chartData = {};
    if (index === null) {
      if (plotData) {
        chartData = plotData;
      }
    } else {
      chartData = accountData.chart_id[index];
    }
    let orginalData = chartData.settings.data;
    let layout = chartData.settings.layout;

    let hiddenCharList = orginalData
      .filter((d) => !d.visibilityState)
      .map((d) => d.index);

    let updatedPlotData = [];
    for (let i = 0; i < orginalData.length; i++) {
      let newItem = orginalData[i];
      const dataSourceForFilter = JSON.parse(JSON.stringify(accountData));

      if (newItem.chartType === "actual") {
        let filterEndpoints = newItem.filterEndpoint;
        const dataSource = JSON.parse(JSON.stringify(accountData));

        let xaxisId = newItem.xaxisIds;
        let yaxisId = newItem.yaxisIds;
        let sourceDateData = dataSource.data.find((d) => d.id === "date_1234");
        let xaxis = dataSource.data.find((d) => d.id === xaxisId);
        newItem.x = xaxis.length > 0 ? xaxis.data.map((obj) => obj.value) : [];
        newItem.x_original =
          xaxis.length > 0 ? xaxis.data.map((obj) => obj.value) : [];

        let legendId = newItem.legendId;
        let groupBy = newItem.groupBy;

        let yaxis = dataSource.data.find((d) => d.id === yaxisId);
        if (newItem.xaxisDataType === "str") {
          const filterDataXaxis = [...filterByDateRange([xaxis], calendarData)];
          const filterDataYaxis = [...filterByDateRange([yaxis], calendarData)];
          let x_value = filterDataXaxis[0].data.map((d) => d.value);
          let y_value = filterDataYaxis[0].data.map((d) => d.value);
          const { x, y } = aggegateCategoricalData(
            x_value,
            y_value,
            groupBy,
            dataSourceForFilter,
            calendarData,
            filterEndpoints
          );
          newItem.x = x.length > 0 ? x : [];
          newItem.y = y.length > 0 ? y : [];
          newItem.text =
            y.length > 0
              ? y.map(
                  (v) => `${convertDisplayDataUnit(v, layout.displayUnits)}`
                )
              : [];

          let name = filterDataYaxis[0].name + "-" + groupBy;
          newItem.hovertemplate = handleHoverTemplate(
            newItem.orientation,
            name
          );
          let visible = hiddenCharList.includes(newItem.index) ? false : true;
          newItem.visibilityState = visible;

          updatedPlotData.push(newItem);
        } else if (
          newItem.xaxisDataType === "datetime" &&
          newItem.legendId === ""
        ) {
          const filterData = [...filterByDateRange([yaxis], calendarData)];
          let x_value = filterData[0].data.map((d) => d.date);
          let y_value = filterData[0].data.map((d) => d.value);

          const { x, y } = aggegateCategoricalData(
            x_value,
            y_value,
            groupBy,
            dataSourceForFilter,
            calendarData,
            filterEndpoints
          );
          const date = x.map(
            (d) => sourceDateData.data.find((v) => v.date === d).value
          );

          newItem.x = date.length > 0 ? date : [];
          newItem.y = y.length > 0 ? y : [];
          newItem.text =
            y.length > 0
              ? y.map(
                  (v) => `${convertDisplayDataUnit(v, layout.displayUnits)}`
                )
              : [];

          newItem.hovertemplate = handleHoverTemplate(
            newItem.orientation,
            newItem.name
          );

          updatedPlotData.push(newItem);
        } else if (
          newItem.xaxisDataType === "datetime" &&
          newItem.legendId !== ""
        ) {
          const legend = dataSource.data.find((d) => d.id === legendId);
          const filterLegendData = [
            ...filterByDateRange([legend], calendarData),
          ];
          const filterYaxisData = [...filterByDateRange([yaxis], calendarData)];
          const filterSourceDateData = [
            ...filterByDateRange([sourceDateData], calendarData),
          ];

          const yaxisData = filterYaxisData[0].data;
          const dateData = filterSourceDateData[0].data;
          const legendData = filterLegendData[0].data;
          const resultData = handleGroupAndAggregateData(
            dateData,
            yaxisData,
            legendData,
            groupBy,
            dataSourceForFilter,
            calendarData,
            filterEndpoints
          );

          for (let i = 0; i < resultData.length; i++) {
            let indexId = i === 0 ? newItem.index : `${newItem.index}-CAT-${i}`;
            let tempIndex = orginalData.findIndex((d) => d.index === indexId);
            if (tempIndex > -1) {
              const newplot = {
                ...orginalData[tempIndex],
                x: resultData[i]["data"].map((d) => d.date),
                y: resultData[i]["data"].map((d) => d.value),
                text: resultData[i]["data"].map(
                  (d) =>
                    `${convertDisplayDataUnit(d.value, layout.displayUnits)}`
                ),
              };
              updatedPlotData.push(newplot);
            } else {
              let visible = hiddenCharList.includes(indexId) ? false : true;
              let name =
                yaxis.name + "-" + resultData[i].name + "-" + newItem.groupBy;
              let hoverText = handleHoverTemplate(newItem.orientation, name);
              const newplot = {
                ...newItem,
                x: resultData[i]["data"].map((d) => d.date),
                y: resultData[i]["data"].map((d) => d.value),
                text: resultData[i]["data"].map(
                  (d) =>
                    `${convertDisplayDataUnit(d.value, layout.displayUnits)}`
                ),
                // name: name,
                chartType: i === 0 ? "actual" : "CAT",
                yaxisIds: yaxis.id,
                yaxisDataType: yaxis.data_type,
                groupBy: groupBy,
                index: indexId,
                hovertemplate: hoverText,
                visibilityState: visible,
              };
              updatedPlotData.push(newplot);
            }
          }
        } else if (
          newItem.xaxisDataType === "int" ||
          newItem.xaxisDataType === "float"
        ) {
          const sourceXaxisData = dataSource.data.find(
            (d) => d.id === newItem.xaxisIds
          );
          const filterXaxisData = [
            ...filterByDateRange([sourceXaxisData], calendarData),
          ];

          let x_valueXaxis = filterXaxisData[0].data.map((d) => d.date);
          let y_valueXaxis = filterXaxisData[0].data.map((d) => d.value);

          const xAxisAggegateResult = aggegateCategoricalData(
            x_valueXaxis,
            y_valueXaxis,
            groupBy,
            dataSourceForFilter,
            calendarData,
            filterEndpoints
          );

          const filterYaxisData = [...filterByDateRange([yaxis], calendarData)];
          let x_valueYaxis = filterYaxisData[0].data.map((d) => d.date);
          let y_valueYaxis = filterYaxisData[0].data.map((d) => d.value);
          const yAxisAggegateResult = aggegateCategoricalData(
            x_valueYaxis,
            y_valueYaxis,
            groupBy,
            dataSourceForFilter,
            calendarData,
            filterEndpoints
          );

          newItem.x =
            xAxisAggegateResult && xAxisAggegateResult.y.length > 0
              ? xAxisAggegateResult.y
              : [];
          newItem.y =
            yAxisAggegateResult && yAxisAggegateResult.y.length > 0
              ? yAxisAggegateResult.y
              : [];
          newItem.text =
            yAxisAggegateResult.y.length > 0
              ? yAxisAggegateResult.y.map(
                  (d) => `${convertDisplayDataUnit(d, layout.displayUnits)}`
                )
              : [];

          newItem.hovertemplate = handleHoverTemplate(
            newItem.orientation,
            newItem.name
          );

          updatedPlotData.push(newItem);
        }
      } else if (newItem.chartType === "YTD") {
        const dataSource1 = JSON.parse(JSON.stringify(accountData));
        const data = handleYearToDate(
          orginalData[i],
          calendarData,
          dataSource1,
          layout.displayUnits
        );
        updatedPlotData.push(data);
      } else if (newItem.chartType === "PRY") {
        const dataSource2 = JSON.parse(JSON.stringify(accountData));
        const priorYrPlot = handlePriorYear(
          orginalData[i],
          calendarData,
          dataSource2,
          layout.displayUnits
        );
        updatedPlotData.push(priorYrPlot);
      }
    }
    chartData.settings.data = updatedPlotData;
    resolve(chartData);
  });
}

function transformDate(inputDate, adjustment = 1) {
  const dateParts = inputDate.split("-");
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1;

  const transformedDate = new Date(year, month, 1);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const updatedYear = year + adjustment;
  const formattedOutput =
    monthNames[transformedDate.getMonth()] + "-" + updatedYear;
  return formattedOutput;
}

function performAggregateOperation(group, operation, column) {
  switch (operation) {
    case "mean":
      return group.stat.mean(column);
    case "sum":
      return group.stat.sum(column);
    case "max":
      return group.stat.max(column);
    case "count":
      return group.count(column);
    case "distinct":
      return group.count(column);
    case "min":
      return group.stat.min(column);
    default:
      throw new Error("Invalid operation: " + operation);
  }
}

const aggegateCategoricalData = (
  data1,
  data2,
  operationType,
  sourceData = [],
  calendarDate = {},
  filterEndpoints = []
) => {
  if (filterEndpoints.length > 0) {
    let filterDf = null;

    let dataframeValues = {
      x: data1,
      y: data2,
    };

    let columns = ["x", "y"];
    for (let index in filterEndpoints) {
      const { y_value_based_filterId } = getDataBasedOnFilterID(
        sourceData,
        filterEndpoints[index].id,
        calendarDate
      );
      dataframeValues = {
        ...dataframeValues,
        [filterEndpoints[index].id]: y_value_based_filterId,
      };
      columns = [...columns, filterEndpoints[index].id];
    }

    let df = new DataFrame(dataframeValues, columns);
    filterDf = df.filter((row) => row.get("y") !== "NA");
    for (let index in filterEndpoints) {
      filterDf = filterDf.filter((row) =>
        filterEndpoints[index].endpointList.includes(
          row.get(filterEndpoints[index].id)
        )
      );
    }
    if (operationType === "distinct") {
      filterDf = filterDf.groupBy("x", "y");
      const result = filterDf.aggregate((group) =>
        performAggregateOperation(group, "count", "y")
      );
      const resultDict = result
        .groupBy("x")
        .aggregate((group) =>
          performAggregateOperation(group, "count", "aggregation")
        )
        .toDict();
      return { x: resultDict.x, y: resultDict.aggregation };
    } else {
      const result = filterDf
        .groupBy("x")
        .aggregate((group) =>
          performAggregateOperation(group, operationType, "y")
        );
      const resultDict = result.toDict();
      return { x: resultDict.x, y: resultDict.aggregation };
    }
  } else {
    let dataset = {
      x: data1,
      y: data2,
    };
    let df = new DataFrame(dataset, ["x", "y"]);
    df = df.filter((row) => row.get("y") !== "NA");
    if (operationType === "distinct") {
      df = df.groupBy("x", "y");
      const result = df.aggregate((group) =>
        performAggregateOperation(group, "count", "y")
      );
      const resultDict = result
        .groupBy("x")
        .aggregate((group) =>
          performAggregateOperation(group, "count", "aggregation")
        )
        .toDict();
      return { x: resultDict.x, y: resultDict.aggregation };
    } else {
      const result = df
        .groupBy("x")
        .aggregate((group) =>
          performAggregateOperation(group, operationType, "y")
        );
      const resultDict = result.toDict();
      return { x: resultDict.x, y: resultDict.aggregation };
    }
  }
};

const handleGroupAndAggregateData = (
  date,
  yValue,
  legend,
  operationType,
  sourceData = [],
  calendarDate = {},
  filterEndpoints = []
) => {
  const dateData = yValue.map((d) => d.date);
  let numericalData = [];
  if (operationType === "distinct") {
    numericalData = yValue.map((d) => d.value);
  } else {
    numericalData = yValue.map((d) =>
      typeof d.value === "string" ? 1 : d.value
    );
  }

  const catgeoricalData = legend.map((d) => d.value);
  let resultDict = {};
  if (filterEndpoints.length > 0) {
    let filterDf = null;
    let dataframeValues = {
      date: dateData,
      numerical: numericalData,
      categorical: catgeoricalData,
    };
    let columns = ["date", "numerical", "categorical"];
    for (let index in filterEndpoints) {
      const { y_value_based_filterId } = getDataBasedOnFilterID(
        sourceData,
        filterEndpoints[index].id,
        calendarDate
      );
      dataframeValues = {
        ...dataframeValues,
        [filterEndpoints[index].id]: y_value_based_filterId,
      };
      columns = [...columns, filterEndpoints[index].id];
    }
    filterDf = new DataFrame(dataframeValues, columns);
    for (let index in filterEndpoints) {
      filterDf = filterDf.filter((row) =>
        filterEndpoints[index].endpointList.includes(
          row.get(filterEndpoints[index].id)
        )
      );
    }
    filterDf = filterDf.filter((row) => row.get("categorical") !== "NA");
    if (operationType === "distinct") {
      let groupedDF = filterDf.groupBy("date", "categorical", "numerical");
      let result = groupedDF.aggregate((group) =>
        performAggregateOperation(group, "count", "numerical")
      );
      resultDict = result
        .groupBy("date", "categorical")
        .aggregate((group) => performAggregateOperation(group, "count", "date"))
        .toDict();
    } else {
      let groupedDF = filterDf.groupBy("date", "categorical");
      let result = groupedDF.aggregate((group) =>
        performAggregateOperation(group, operationType, "numerical")
      );
      resultDict = result.toDict();
    }
  } else {
    let df = new DataFrame(
      {
        date: dateData,
        numerical: numericalData,
        categorical: catgeoricalData,
      },
      ["date", "numerical", "categorical"]
    );
    df = df.filter((row) => row.get("categorical") !== "NA");
    if (operationType === "distinct") {
      let groupedDF = df.groupBy("date", "categorical", "numerical");
      let result = groupedDF.aggregate((group) =>
        performAggregateOperation(group, "count", "numerical")
      );
      resultDict = result
        .groupBy("date", "categorical")
        .aggregate((group) => performAggregateOperation(group, "count", "date"))
        .toDict();
    } else {
      let groupedDF = df.groupBy("date", "categorical");
      let result = groupedDF.aggregate((group) =>
        performAggregateOperation(group, operationType, "numerical")
      );
      resultDict = result.toDict();
    }
  }

  const uniqueCategorical = [...new Set(resultDict.categorical)];
  const combinedDataDict = resultDict.categorical.map((d, index) => ({
    date: date.find((d) => d.date === resultDict.date[index])
      ? date.find((d) => d.date === resultDict.date[index]).value
      : null,
    categorical: d,
    value: resultDict.aggregation[index],
  }));

  const resultData = [];
  const dummyXaxisData = handleDatetimeInXaxis(
    calendarDate.month_index,
    calendarDate.year_index
  );
  for (let cat of uniqueCategorical) {
    let dataPoint = combinedDataDict.filter((x) => x.categorical === cat);
    const dataPointWithUniformXasis = dummyXaxisData.map((d) => {
      let tempIndex = dataPoint.findIndex((value) => value.date === d.date);
      if (tempIndex !== -1) {
        return {
          ...d,
          value: dataPoint[tempIndex].value,
          categorical: dataPoint[tempIndex].categorical,
        };
      } else {
        return { ...d, categorical: cat };
      }
    });
    resultData.push({ name: cat, data: dataPointWithUniformXasis });
  }

  return resultData;
};

//  Helper function
function handleDatetimeInXaxis(month_index, year_index) {
  const months_data = [
    { month: "Jan", month_index: 0 },
    { month: "Feb", month_index: 1 },
    { month: "Mar", month_index: 2 },
    { month: "Apr", month_index: 3 },
    { month: "May", month_index: 4 },
    { month: "Jun", month_index: 5 },
    { month: "Jul", month_index: 6 },
    { month: "Aug", month_index: 7 },
    { month: "Sep", month_index: 8 },
    { month: "Oct", month_index: 9 },
    { month: "Nov", month_index: 10 },
    { month: "Dec", month_index: 11 },
  ];

  const xaxis_dummy_data = months_data
    .filter((d) => d.month_index <= month_index)
    .map((d) => ({
      date: `${d.month}-${year_index}`,
      value: null,
      categorical: "",
    }));
  return xaxis_dummy_data;
}

function getDataBasedOnFilterID(accountData, filterId, calendarData) {
  if (filterId === undefined || filterId === "") {
    return {
      x_value_based_filterId: [],
      y_value_based_filterId: [],
    };
  }
  let filterData = accountData.data.find((d) => d.id === filterId);
  const filterDataForFilterIds = [
    ...filterByDateRange([filterData], calendarData),
  ];
  let x_value_based_filterId = filterDataForFilterIds[0].data.map(
    (d) => d.date
  );
  let y_value_based_filterId = filterDataForFilterIds[0].data.map(
    (d) => d.value
  );
  return {
    x_value_based_filterId: x_value_based_filterId,
    y_value_based_filterId: y_value_based_filterId,
  };
}

function handleHoverTemplate(orientation, name) {
  let corrdinate = orientation === "v" ? "y" : "x";
  let hovertemplate = `${name}:%{${corrdinate}:.0f}<br><extra></extra>`;
  return hovertemplate;
}

function reduceByDate(source_data) {
  if (source_data.data_type === "float" || source_data.data_type === "int") {
    const reducedObject = source_data.data.reduce(
      (accumulator, currentObject) => {
        const currentDate = currentObject.date;
        if (accumulator[currentDate]) {
          accumulator[currentDate].value += currentObject.value;
        } else {
          accumulator[currentDate] = {
            date: currentDate,
            value: currentObject.value,
          };
        }
        return accumulator;
      },
      {}
    );
    const reducedArray = Object.values(reducedObject);
    source_data["data"] = reducedArray;
    return source_data;
  } else {
    return source_data;
  }
}

function generateUniqueCode() {
  const timestamp = Date.now();
  const randomPart = Math.floor(Math.random() * 1000);
  const uniqueCode = `${timestamp}${randomPart}`;
  return uniqueCode;
}

function handleInsertAndMoveUp(array, index, data) {
  if (index >= 0 && index <= array.length) {
    array.splice(index, 0, data);
    for (let i = array.length - 1; i > index; i--) {
      array[i] = array[i - 1];
    }
    array[index] = data;
  }
  return array;
}

function removeKeysFromObjects(arr) {
  const keysToRemove = ["x", "y", "labels", "values"];
  return arr.map((item) => {
    const newItem = { ...item };
    keysToRemove.forEach((key) => {
      if (newItem.hasOwnProperty(key)) {
        delete newItem[key];
      }
    });
    return newItem;
  });
}

function yearToDate(dataObject, calendarDate) {
  const financialStartMonth = calendarDate.fiscal_month_index;
  const newDataObject = [...dataObject];
  if (newDataObject.length > 0) {
    let ytdSum = 0;
    let ytdData = [];

    for (const item of newDataObject[0].data) {
      const itemDate = new Date(item.date);
      const itemMonth = itemDate.getMonth() + 1;
      if (itemMonth === financialStartMonth) {
        ytdSum = item.value;
        ytdData.push({ date: item.date, value: ytdSum });
      } else {
        ytdSum += item.value;

        ytdData.push({ date: item.date, value: ytdSum });
      }
    }
    newDataObject[0].data = ytdData;
  }
  return newDataObject;
}

function removeDuplicates(arr, prop) {
  let keys = [];
  let newArray = [];
  for (let i = 0; i < arr.length; i++) {
    if (keys.length === 0 || keys.indexOf(arr[i][prop]) === -1) {
      newArray.push(arr[i]);
      keys.push(arr[i][prop]);
    }
  }
  return newArray;
}

function extractUniqueValues(arr, key, type = "Budget") {
  const uniqueValues = new Set();
  arr.forEach((obj) => {
    if (obj[key] !== undefined && obj[key] !== type && obj[key] !== "Budget") {
      uniqueValues.add(obj[key]);
    }
  });
  return Array.from(uniqueValues);
}

function removeDuplicatesObject(array, key) {
  const seen = new Set();
  return array.filter((item) => {
    const itemValue = item[key];
    if (!seen.has(itemValue)) {
      seen.add(itemValue);
      return true;
    }
    return false;
  });
}

function convertDisplayDataUnit(number, option) {
  let units = option === undefined ? "T" : option;
  if (number > 100 || number < -100) {
    switch (units) {
      case "A":
        if (number > 1000000000 || number < -1000000000) {
          return (number / 1000000000).toFixed(1) + "B";
        } else if (number > 1000000 || number < -1000000) {
          return (number / 1000000).toFixed(1) + "M";
        } else if (number > 100 || number < -100) {
          return (number / 1000).toFixed(1) + "K";
        }
        break;
      case "T":
        let text = (number / 1000).toFixed(1) + "K";
        return text;

      case "M":
        return (number / 1000000).toFixed(1) + "M";

      case "B":
        return (number / 1000000000).toFixed(1) + "B";

      case "R":
        return parseInt(number);

      case "RF":
        return parseInt(number).toLocaleString();

      case "none":
        return number.toFixed(2);

      default:
        return number;
    }
  } else {
    if (number === null) {
      return 0;
    } else {
      switch (units) {
        case "R":
          return parseInt(number);

        case "none":
          return number.toFixed(2);

        default:
          return number.toFixed(2);
      }
    }
  }
}

const formatDate = (timestamp, isTimestamp = false) => {
  let date;
  if (isTimestamp) {
    date = new Date(timestamp * 1000);
  } else {
    date = new Date(timestamp);
  }

  const result = date.toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  return result;
};

export {
  removeDuplicates,
  removeDuplicatesObject,
  removeKeysFromObjects,
  addKeysToObjects,
  yearToDate,
  extractUniqueValues,
  convertDisplayDataUnit,
  reduceByDate,
  generateUniqueCode,
  aggegateCategoricalData,
  handleGroupAndAggregateData,
  handleInsertAndMoveUp,
  handleHoverTemplate,
  transformDate,
  performAggregateOperation,
  formatDate,
};
