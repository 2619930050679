import React, { useState } from "react";
import VideoTutorials from "../Tutorials/VideoTutorials";

export default function Help() {
  const tutorialData = [
    {
      id: 1,
      question: " How to create chart and style it?",
      url: "https://example.com/favorite-language",
    },
    {
      id: 2,
      question: "How to setup a company data using quickbooks and local data?",
      url: "https://example.com/problem-solving",
    },
    {
      id: 3,
      question: "How to connect the cloud storage data to piik-dash?",
      url: "https://example.com/career-goals",
    },
    {
      id: 4,
      question: "How to share reports and charts with others?",
      url: "https://example.com/career-goals",
    },
    {
      id: 5,
      question: "How to create a datapoint with custom formula? ",
      url: "https://example.com/career-goals",
    },
    {
      id: 6,
      question: "How to create a custom KPI? ",
      url: "https://example.com/career-goals",
    },
    {
      id: 7,
      question: "How to create a report? ",
      url: "https://example.com/career-goals",
    },
    {
      id: 8,
      question: "How to create a dashboard? ",
      url: "https://example.com/career-goals",
    },
  ];

  const [tab, setTab] = useState(1);

  return (
    <div className="row gx-0 vh-50">
      <div className="col-5 px-0 mx-0 overflow-y-scroll shadow-sm">
        {tutorialData.map((d) => {
          return tab === d.id ? (
            <div className="piik-text-dashboard py-3 border-bottom px-4 d-flex flex-row justify-content-start align-items-start piik-pointer bg-primary">
              <i className="bi bi-check-circle-fill pe-2 text-white"></i>
              <div className="piik-text-visual text-white"> {d.question}</div>
            </div>
          ) : (
            <div
              className="piik-text-dashboard py-3 border-bottom px-4 d-flex flex-row justify-content-start align-items-start piik-pointer"
              onClick={() => setTab(d.id)}
            >
              <i className="bi bi-circle-fill pe-2 text-secondary"></i>
              <div className="piik-text-visual"> {d.question}</div>
            </div>
          );
        })}
      </div>
      <div className="col-7 mx-0 h-100 flex-grow-1 ">
        <div className="bg-light rounded-3 shadow-sm">
          {tab === 1 ? (
            <VideoTutorials src="source/piikByDash-Charts.mp4" />
          ) : null}
        </div>
      </div>
    </div>
  );
}
