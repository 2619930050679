import React, { useState, useRef } from "react";
import CSVFileValidator from "csv-file-validator";

import countryList from "country-list";
import currencyCodes from "currency-codes";
import { useDashData } from "../../hooks/useDash";
import Modal from "../Common/Modal";

export default function AddSpreadSheet() {
  const { profile, handleFileUpload } = useDashData();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const countries = countryList.getData();
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  const [btnStatus, setBtnStatus] = useState({ state: false, message: "" });
  const [companyName, setCompanyName] = useState("");
  const [currency, setCurrency] = useState("");
  const [country, setCountry] = useState("");
  const [fiscalMonth, setFiscalMonth] = useState("");

  const [selectedFile, setSelectedFile] = useState([]);
  const [error, setError] = useState({ type: "", message: "" });

  const handleModalClosed = () => {
    setBtnStatus({
      state: false,
      comment: "",
    });
  };
  const handleReset = () => {
    setCompanyName("");
    setCountry("");
    setCurrency("");
    setFiscalMonth("");
    setSelectedFile([]);
    setError({ type: "", message: "" });
    setBtnStatus({
      state: false,
      comment: "",
    });
    fileInputRef1.current.value = "";
    fileInputRef2.current.value = "";
    fileInputRef3.current.value = "";
  };

  const handleDownload = async (e) => {
    const filename = e.target.id;
    const baseUrl = window.location.origin;
    const downloadFiles = async () => {
      await downloadFile(baseUrl, filename);
    };

    const downloadFile = async (baseUrl, filename) => {
      try {
        const fileUrl = baseUrl + "/sample/excel/" + filename + ".xlsx";
        const response = await fetch(fileUrl);
        const blob = await response.blob();

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.xlsx`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    };
    downloadFiles();
  };

  //  Need to chekc the GL Report before deleting

  // const handleDownload_ = (e) => {
  //   const filename = e.target.id;
  //   const baseUrl = window.location.origin;
  //   const downloadFiles = async () => {
  //     await downloadFile(baseUrl, filename);
  //   };
  //   const downloadFile = async (baseUrl, filename) => {
  //     const csvFilePath = baseUrl + "/sample/csv/" + filename + ".csv";
  //     try {
  //       const response = await fetch(csvFilePath);
  //       const content = await response.text();

  //       const blob = new Blob([content], { type: "text/csv" });
  //       const url = URL.createObjectURL(blob);

  //       const a = document.createElement("a");
  //       a.href = url;
  //       a.download = `${filename}.csv`;
  //       document.body.appendChild(a);
  //       a.click();

  //       document.body.removeChild(a);
  //       URL.revokeObjectURL(url);
  //     } catch (error) {
  //       console.error("Error fetching or downloading the file:", error);
  //     }
  //   };
  //   downloadFiles();
  // };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "companyName") {
      setCompanyName(value);
    } else if (id === "fiscalMonth") {
      setFiscalMonth(value);
    } else if (id === "currency") {
      setCurrency(value);
    } else {
      setCountry(value);
    }
    setError({
      type: id,
      message: "",
    });
  };

  function isValidDateFormat(dateString) {
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateFormatRegex.test(dateString);
  }
  function isNumber(input) {
    return !isNaN(input);
  }
  function isText(input) {
    return typeof input === "string";
  }

  const csvFileValidator = async (file, event) => {
    const catgeoricalColumn = [
      "Tags",
      "Account",
      "Customer",
      "Product",
      "Currency",
    ];
    const numericalColumn = ["Rate", "Quantity", "Amount", "Level", "FxRate"];
    const headerConfigMap = [
      {
        headers: ["Level", "Tags", "Account"],
        commonHeaders: ["Level", "Tags", "Account"],
      },
      {
        headers: [
          "Date",
          "Customer",
          "Product",
          "Rate",
          "Quantity",
          "Amount",
          "Currency",
          "FxRate",
        ],
        commonHeaders: [
          "Date",
          "Customer",
          "Product",
          "Rate",
          "Quantity",
          "Amount",
          "Currency",
          "FxRate",
        ],
      },
      {
        headers: ["Date"],
        commonHeaders: ["Date"],
      },
    ];
    const readCsvFile = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target.result);
        reader.onerror = (error) => reject(error);
        reader.readAsText(file);
      });
    };
    try {
      const csvContent = await readCsvFile(file);
      const header = csvContent.split("\r\n")[0].split(",");

      const isInvalidHeader = () => {
        if (
          (event.target.id === "Profit & Loss" ||
            event.target.id === "Balance Sheet") &&
          !headerConfigMap[0].headers.every((d) => header.includes(d))
        ) {
          return !header
            .filter((d) => headerConfigMap[0].headers.includes(d))
            .every(isValidDateFormat);
        }
        return false;
      };

      if (isInvalidHeader()) {
        resetFileInputValue(event.target.id);
        setError({ type: "CSV", message: "Invalid CSV Data Header" });
        return;
      }

      const config = headerConfigMap.find((configItem) =>
        configItem.headers.every((h) => header.includes(h))
      );

      if (config) {
        const headers = header.map((d) => ({
          name: d,
          isHeaderNameOptional: !config.commonHeaders.includes(d),
          optional: !config.commonHeaders.includes(d),
          validate:
            d === "Date"
              ? isValidDateFormat
              : catgeoricalColumn.includes(d)
              ? isText
              : numericalColumn.includes(d)
              ? isNumber
              : undefined,
          validateError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
          },
        }));

        const newConfig = { headers };
        const newFileName = `${event.target.id}.csv`;
        const csvData = await CSVFileValidator(file, newConfig);

        if (csvData.inValidData.length > 0) {
          setError({ type: "CSV", message: csvData.inValidData[0].message });
        } else {
          const modifiedFile = new File([file], newFileName, {
            type: file.type,
          });
          setSelectedFile((prev) => [...prev, modifiedFile]);
          setError({ type: "", message: "" });
        }
      } else {
        resetFileInputValue(event.target.id);
        setError({ type: "CSV", message: "Invalid CSV Data format" });
      }
    } catch (err) {
      resetFileInputValue(event.target.id);
      setError({ type: "CSV", message: err.message });
    }
  };

  const resetFileInputValue = (id) => {
    const fileInputRef =
      id === "Profit & Loss"
        ? fileInputRef2
        : id === "Balance Sheet"
        ? fileInputRef1
        : id === "General Ledger"
        ? fileInputRef3
        : null;

    if (fileInputRef) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "text/csv") {
        csvFileValidator(file, event);
      } else {
        setError({
          type: "CSV",
          message: "Invalid file type. Please upload a CSV file.",
        });
      }
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();
    if (companyName === "") {
      setError({
        type: "companyName",
        message: "Company Name Missing",
      });
      return;
    }
    if (fiscalMonth === "") {
      setError({
        type: "fiscalMonth",
        message: "Fiscal Month Missing.",
      });
      return;
    }
    if (country === "") {
      setError({
        type: "country",
        message: "Country Missing.",
      });
      return;
    }
    if (currency === "") {
      setError({
        type: "currency",
        message: "Currency  Missing.",
      });
      return;
    }
    if (selectedFile.length === 0) {
      setError({
        type: "CSV File",
        message: "Please upload at least one CSV file.",
      });
      return;
    }
    setBtnStatus({
      state: true,
      comment: "Please wait while the company data is added to your account",
    });

    const companyData = {
      companyName: companyName,
      country: country,
      currency: currency,
      fiscalMonth: fiscalMonth,
      userId: profile.user_id,
      type: "create",
    };

    selectedFile.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });
    Object.entries(companyData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    handleFileUpload(formData).then((response) => {
      if (response.status) {
        setCompanyName("");
        setCountry("");
        setCurrency("");
        setFiscalMonth("");
        setSelectedFile([]);
        setError({ type: "", message: response.message });
        setBtnStatus({
          state: false,
          comment: "",
        });
      } else {
        setError({ type: "", message: response.message });
        setSelectedFile([]);
        setBtnStatus({
          state: true,
          comment: response.message,
        });
      }
      fileInputRef1.current.value = "";
      fileInputRef2.current.value = "";
      fileInputRef3.current.value = "";
    });
  };

  return (
    <div className="d-flex flex-column ">
      <div className="row py-2 flex-grow-1 h-100">
        <div className="col-7 ">
          <div className="piik-text-visual flex-grow-1   overflow-y-scroll pb-4">
            <h6>Documentation: CSV Data Upload</h6>
            <p>
              This documentation will guide you through the process of
              downloading the CSV sample format, filling in the data, and
              uploading it to our system. Please follow these steps carefully to
              ensure a smooth experience.
            </p>

            <h6>Step 1: Download the CSV Sample Format</h6>
            <ol>
              <li>Visit our platform's website and log in to your account.</li>
              <li>
                Navigate to the "Data Upload" section or the designated area for
                data submission.
              </li>
              <li>
                Look for the "Download CSV Sample" button and click on it.
              </li>

              <li>
                The CSV sample file will be downloaded to your device. Save it
                in a location where you can easily access it.
              </li>
            </ol>

            <h6>Step 2: Fill in the Data</h6>
            <ol>
              <li>
                Open the downloaded CSV file using a spreadsheet software like
                Microsoft Excel or Google Sheets.
              </li>
              <li>
                You will find columns labeled with specific data fields. Fill in
                the required information for each record.
              </li>
              <li>
                Pay special attention to the "Date" column format. Dates should
                be entered in the YYYY-MM-DD format (e.g., 2023-11-13 for
                November 13, 2023).
              </li>

              <li>
                Do not change the layout or structure of the CSV file. Ensure
                that each column corresponds to the correct data type.
              </li>
            </ol>

            <h6>Step 3: Upload in CSV Format</h6>
            <ol>
              <li>
                Save the filled-in CSV file once you have entered all the
                necessary data.
              </li>
              <li>Return to the "Data Upload" section on our platform.</li>
              <li>
                Look for the "Upload CSV" or similar button and click on it.
              </li>
              <li>
                Choose the saved CSV file from your device and click "Upload."
              </li>
            </ol>
          </div>
        </div>
        <div className="col">
          <div className="piik-text-dashboard d-flex flex-column justify-content-start mb-2">
            <div className="d-flex flex-row justify-content-between  piik-pointer border-bottom ">
              <div className="px-4 py-2 bg-light text-primary  shadow-sm my-0 w-75">
                Create New
              </div>
              <div
                className="px-4 py-2 bg-primary text-white  shadow-sm my-0 w-25 piik-pointer text-center"
                onClick={handleReset}
              >
                Clear All
              </div>
            </div>

            <div className="d-flex flex-column justify-content-start h-100 bg-white pb-4">
              <div className="px-4">
                <div className="my-1">
                  <div className="text-primary py-1 piik-text-visual">
                    Company Name *
                  </div>
                  <input
                    type="text"
                    className="form-control form-control-sm w-100"
                    id="companyName"
                    placeholder="Max 25 Character"
                    value={companyName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="my-1 d-flex flex-row justify-content-between">
                  <div className="w-100 ">
                    <div className="text-primary py-1 piik-text-visual">
                      Country *
                    </div>

                    <select
                      type="text"
                      className="form-select form-select-sm"
                      id="country"
                      value={country}
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>
                      {countries
                        .filter((d) => d.code === "US" || d.code === "CA")
                        .map((country) => (
                          <option value={country.code}>{country.name}</option>
                        ))}
                      {countries
                        .filter((d) => d.code !== "US" || d.code !== "CA")
                        .map((country) => (
                          <option value={country.code}>{country.name}</option>
                        ))}
                    </select>
                  </div>
                  <div className="w-100 px-2">
                    <div className="text-primary py-1 piik-text-visual">
                      Currency *
                    </div>

                    <select
                      type="text"
                      className="form-select form-select-sm"
                      id="currency"
                      value={currency}
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>
                      {currencyCodes.data
                        .filter((d) => d.code === "USD" || d.code === "CAD")
                        .map((d) => (
                          <option value={d.code}>
                            {d.currency}-{d.code}
                          </option>
                        ))}

                      {currencyCodes.data
                        .filter((d) => d.code !== "USD" || d.code !== "CAD")
                        .map((d) => (
                          <option value={d.code}>
                            {d.currency}-{d.code}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="w-100">
                    <div className="text-primary py-1 piik-text-visual">
                      Fiscal Year Start Month *
                    </div>
                    <select
                      type="text"
                      className="form-select form-select-sm"
                      id="fiscalMonth"
                      value={fiscalMonth}
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>
                      {months.map((d) => {
                        return <option value={d}>{d}</option>;
                      })}
                    </select>
                  </div>
                </div>

                <div className="my-2">
                  <div className="d-flex flex-row justify-content-between">
                    <div className="text-primary py-1 piik-text-visual">
                      Upload Balance Sheet
                    </div>
                    <div
                      className="text-primary py-1 piik-text-visual piik-pointer"
                      onClick={handleDownload}
                      id="sample1"
                    >
                      <i className="bi bi-download px-2"></i>
                      Sample-1
                    </div>
                  </div>
                  <input
                    className="form-control form-control-sm"
                    type="file"
                    id="Balance Sheet"
                    accept=".csv"
                    multiple={false}
                    ref={fileInputRef1}
                    onChange={handleFileChange}
                  />
                </div>
                <div className="my-1">
                  <div className="d-flex flex-row justify-content-between">
                    <div className="text-primary py-1 piik-text-visual">
                      Upload Income Statement
                    </div>
                    <div
                      className="text-primary py-1 piik-text-visual piik-pointer"
                      onClick={handleDownload}
                      id="sample1"
                    >
                      <i className="bi bi-download px-2"></i>
                      Sample-2
                    </div>
                  </div>
                  <input
                    className="form-control form-control-sm"
                    type="file"
                    id="Profit & Loss"
                    accept=".csv"
                    multiple={false}
                    ref={fileInputRef2}
                    onChange={handleFileChange}
                  />
                </div>
                <div className="my-2">
                  <div class="d-flex flex-row justify-content-between">
                    <div className="text-primary py-1 piik-text-visual">
                      Upload Others
                    </div>
                    <div
                      className="text-primary py-1 piik-text-visual piik-pointer"
                      onClick={handleDownload}
                      id="sample2"
                    >
                      <i className="bi bi-download px-2"></i>
                      Sample-3
                    </div>
                  </div>

                  <input
                    className="form-control form-control-sm"
                    type="file"
                    id="General Ledger"
                    accept=".csv"
                    multiple={false}
                    ref={fileInputRef3}
                    onChange={handleFileChange}
                  />
                </div>
              </div>
              <div className="piik-text-visual py-2 px-4">
                * Once submited, the information cannot be altered or updated
              </div>
            </div>
          </div>

          <div className=" mb-2 w-100 d-flex flex-row justify-content-between">
            {error && error.type !== "" && (
              <div className="pt-1 text-danger piik-text-warning">
                {error.message}
              </div>
            )}
            .
            {btnStatus && btnStatus.state ? (
              <div className="btn btn-sm btn-primary px-4 w-25">Processing</div>
            ) : (
              <div
                className="btn btn-sm btn-primary px-4 w-25"
                onClick={handleSubmit}
              >
                Upload
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={btnStatus && btnStatus.state}
        closeModal={handleModalClosed}
        title="Add Account"
      >
        <div className="row mx-0 justify-content-between px-4 pt-2 pb-4 ">
          <div className="col-12 piik-text-warning p-2 mb-2">
            {btnStatus && btnStatus.comment}
          </div>
        </div>
      </Modal>
    </div>
  );
}
