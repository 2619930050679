import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";

import "./index.css";
import App from "./App";

const domain = process.env.REACT_APP_AUTH_DOMAIN;
const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: `https://piik-dash-api.com`,
    }}
    authorizeTimeoutInSeconds="3600"
    useRefreshTokens={true}
    scope="openid profile email"
    cacheLocation="localstorage"
  >
    <App />
  </Auth0Provider>
);
